import { Transition, Dialog } from "@headlessui/react";
import { useState, Fragment, useEffect, useRef } from "react";
import HelpDocumentation from "@common/components/HelpDocumentation";
import { toast } from "react-toastify";
import classNames from "classnames";
import usePatientState from "../../../../state/usePatientState";
import { useSelector } from "react-redux";
import { Doctor } from "@common/utils/types";
import { useDataContextApi } from "@providers/DataProvider";
import CalendarPlusIcon from "@icons/calendar-plus-01.svg?react";
import ChevronUpIcon from "@icons/chevron-up.svg?react";
import ChevronDownIcon from "@icons/chevron-down.svg?react";
import PlusIcon from "@icons/plus-circle.svg?react";

export default function AddAppointmentModal({
	open,
	setOpen,
	disabled = false,
}: {
	open: boolean;
	setOpen: any;
	disabled?: boolean;
}) {
	const {
		patientName,
		setPatientName,
		patientId,
		setPatientId,
		scheduledAtDay,
		setScheduledAtDay,
		scheduledAtTime,
		setScheduledAtTime,
		appointmentDuration,
		setAppointmentDuration,
		clearFields,
		generateTimeOptions,
		adjustTime,
		handleInsertPatientRecord,
		localSelectedDoctor,
		setLocalSelectedDoctor,
		formatDate,
		calculateNextAvailableTime,
	} = usePatientState(null);

	const { selectedDoctor, doctors } = useSelector((state: any) => state.doctors);
	const { selectedDate } = useDataContextApi();

	const patientNameInputRef = useRef<HTMLInputElement>(null);
	const saveAndNewButtonRef = useRef<HTMLButtonElement>(null);
	const [showTooltip, setShowTooltip] = useState(false);

	//Match the Dates of the Add Appointment to the selected date
	useEffect(() => {
		//Set scheduleAtTime to match date
		if (selectedDate?.startDate) {
			setScheduledAtDay(formatDate(selectedDate.startDate));
		}
	}, [selectedDate]);

	//Focus on the patient name input when the modal opens
	useEffect(() => {
		if (open) {
			const timer = setTimeout(() => {
				patientNameInputRef.current?.focus();
			}, 100);

			return () => clearTimeout(timer);
		}
	}, [open]);

	useEffect(() => {
		if (selectedDoctor) {
			setLocalSelectedDoctor(selectedDoctor);
		} else {
			setLocalSelectedDoctor(null);
		}
	}, [selectedDoctor]);

	//Submit the form when the user presses Enter
	const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		toast.success(`${patientName || ""} Added!`, {
			autoClose: 1000,
		});
		handleInsertPatientRecord();
		clearFields();
		setScheduledAtTime(calculateNextAvailableTime(scheduledAtTime, appointmentDuration));
		patientNameInputRef.current?.focus();
	};

	return (
		<>
			<div className="flex w-full flex-grow ">
				<button
					id="add-appointment-modal"
					disabled={disabled}
					className={classNames(
						disabled ? "opacity-50 cursor-not-allowed" : "hover:cursor-pointer",
						"rounded-md w-full flex-grow  bg-white px-4 py-2.5 text-sm font-semibold hover:bg-gray-100 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-max-700",
					)}
					onClick={() => setOpen(true)}
				>
					<span className="flex flex-row items-center justify-center whitespace-nowrap">
						<CalendarPlusIcon className="h-4 w-4 text-gray-900 mr-1" />
						<span className="sm:hidden">Add</span>
						<span className="hidden sm:inline">Add Visit</span>
					</span>
				</button>
			</div>
			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div" className="relative z-50" onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 w-screen overflow-y-auto text-gray-900">
						<div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all p-4 sm:my-8 w-[80%] min-h-[450px] sm:max-w-lg">
									<div className="hidden md:block absolute top-0 left-0 z-50">
										<HelpDocumentation
											link=" https://help.gohappydoc.com/en/articles/8563195-adding-and-managing-appointments"
											displayText="Pro Tips for Power Users"
											toolTipClassNames="absolute opacity-0 group-hover:opacity-100 left-20 transform -translate-x-1/2 top-0 px-0.5 py-0.5 bg-gray-700 text-white text-[10px] whitespace-nowrap rounded-md z-50 font-semibold"
										/>
									</div>
									<form onSubmit={handleFormSubmit} className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-col">
										<div className="flex flex-col w-full items-center justify-center">
											<h1>Add New Record</h1>
											<span className="text-gray-400 text-[10px] truncate">(Every field is optional)</span>
											<div className="flex items-center mt-2 text-gray-600">
												Doctor:
												<select
													className="ml-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-max-800 focus:ring focus:ring-max-800 focus:ring-opacity-50"
													value={localSelectedDoctor ? localSelectedDoctor.id : ""}
													onChange={(e) => {
														const selectedId = e.target.value;
														if (selectedId === "") {
															setLocalSelectedDoctor(null);
														} else {
															const selectedDoctor =
																doctors.find((doctor: Doctor) => doctor?.id === selectedId) || null;
															setLocalSelectedDoctor(selectedDoctor);
														}
													}}
													tabIndex={-1}
												>
													<option value="">None</option>
													{doctors.map((doctor: Doctor) => (
														<option key={doctor.id} value={doctor.id}>
															{doctor.doctor_name}
														</option>
													))}
												</select>
											</div>
										</div>

										{/* Input for Patient Name */}
										<div className="mt-3">
											<label htmlFor="patient-name" className="block text-sm font-medium text-gray-700">
												Patient Name(s): <span className="text-gray-400 text-[10px] truncate">(Hotkey: ENTER)</span>
											</label>
											<div className="mt-1">
												<input
													ref={patientNameInputRef}
													type="text"
													name="patient-name"
													id="patient-name"
													className="shadow-sm focus:ring-max-600 focus:border-max-600 block w-full sm:text-sm border-gray-300 rounded-md"
													placeholder="Stormy and Allen"
													value={patientName}
													onChange={(e) => setPatientName(e.target.value)}
												/>
											</div>
										</div>

										{/* Input for Patient ID */}
										<div className="mt-3 hidden sm:block">
											<label htmlFor="patient-id" className="block text-xs font-medium leading-tight truncate">
												Patient ID:
											</label>
											<div className="mt-1">
												<input
													type="text"
													name="patient-id"
													id="patient-id"
													className="shadow-sm focus:ring-max-600 focus:border-max-600 block w-full sm:text-sm border-gray-300 rounded-md"
													placeholder="Enter Patient ID"
													value={patientId}
													onChange={(e) => setPatientId(e.target.value)}
												/>
											</div>
										</div>

										{/* Input for Record/Appointment Time */}
										<div className="mt-3">
											<label htmlFor="schedule-at" className="block text-xs font-medium leading-tight truncate mb-1">
												{/* Scheduled Time <span className="text-gray-400 text-[10px] truncate">(optional)</span> */}
												Scheduled Time: <span className="text-gray-400 text-[10px] truncate"> (Auto increments) </span>
											</label>

											<div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0">
												{/* Date Input */}
												<div className="flex-1">
													<input
														type="date"
														tabIndex={-1}
														className="shadow-sm focus:ring-max-600 focus:border-max-600 block w-full sm:text-sm border-gray-300 rounded-md sm:rounded-none sm:rounded-l-md"
														value={scheduledAtDay}
														onChange={(e) => setScheduledAtDay(e.target.value)}
														onClick={(e) => {
															(e.target as any).showPicker();
														}}
													/>
												</div>
												{/* Start Time Input */}
												<div className="flex-1 relative">
													<div className="relative inline-block w-full text-gray-700">
														<select
															tabIndex={-1}
															className="bg-none shadow-sm focus:ring-max-600 focus:border-max-600 block w-full sm:text-sm border-t border-b border-gray-300 sm:rounded-none rounded-md"
															value={scheduledAtTime}
															onChange={(e) => setScheduledAtTime(e.target.value)}
														>
															{generateTimeOptions()}
														</select>
													</div>
													<div className="absolute inset-y-0 right-0 flex items-center">
														<button
															type="button"
															tabIndex={-1}
															className="absolute right-0 top-0 mt-1 mr-2"
															onClick={() => adjustTime("up")}
															aria-label="Increase time"
														>
															<ChevronUpIcon className="h-5 w-5 pr-0.5 text-gray-700" />
														</button>
														<button
															type="button"
															tabIndex={-1}
															className="absolute right-0 top-0 mt-4 mr-2"
															onClick={() => adjustTime("down")}
															aria-label="Decrease time"
														>
															<ChevronDownIcon className="h-5 w-5 pr-0.5 text-gray-700" />
														</button>
													</div>
												</div>
												{/* Duration Interval Input */}
												<div className="flex-1">
													<select
														tabIndex={-1}
														className="shadow-sm focus:ring-max-600 focus:border-max-600 block w-full sm:text-sm border-gray-300 rounded-md sm:rounded-none sm:rounded-r-md"
														value={appointmentDuration}
														onChange={(e) => setAppointmentDuration(Number(e.target.value))}
														onMouseEnter={() => setShowTooltip(true)}
														onMouseLeave={() => setShowTooltip(false)}
													>
														<option value="15">15 min</option>
														<option value="30">30 min</option>
														<option value="45">45 min</option>
														<option value="60">60 min</option>
													</select>
													{showTooltip && (
														<div className="absolute mt-1 bg-gray-100 text-gray-900 text-xs p-2 rounded-md shadow-lg">
															Appointment Duration
														</div>
													)}
												</div>
											</div>
										</div>

										{/* Buttons */}
										<div className="mt-5 flex flex-col sm:flex-row sm:justify-center space-y-3 sm:space-y-0 sm:space-x-3 w-full">
											<button
												type="submit"
												ref={saveAndNewButtonRef}
												className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-max-700 text-base font-medium text-white hover:bg-max-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-max-600 sm:text-sm items-center"
											>
												Add and New
												<PlusIcon className="h-5 w-5 pr-0.5 ml-1" />
											</button>
											<button
												type="button"
												className="inline-flex justify-center rounded-md border border-gray-400 shadow-sm px-4 py-2 bg-gray-400 text-base font-medium text-white hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray sm:text-sm items-center"
												onClick={() => {
													handleInsertPatientRecord();
													toast.success(`${patientName || ""} Added!`, {
														autoClose: 1000,
													});
													clearFields();
													setOpen(false);
												}}
											>
												Add and Close
												<PlusIcon className="h-5 w-5 pr-0.5 ml-1" />
											</button>
										</div>

										<div className="mt-3 flex justify-center">
											<button
												type="button"
												className="inline-flex justify-center underline border-b-1-gray-900 bg-transparent px-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"
												onClick={() => {
													clearFields();
													setOpen(false);
												}}
											>
												Cancel and Close
											</button>
										</div>
									</form>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}
