import AudioLevels from "@common/components/AudioLevels";
import useAudioRecorder, { isRecordingError } from "@common/hooks/useAudioRecorder";
import { PatientRecordTableRow, RecordingState, TranscriptSection } from "@common/utils/types";
import useFileUpload from "@common/hooks/useFileUpload";
import UploadProgressBar from "@common/components/UploadProgressBar";
import classNames from "classnames";
import Timer from "@common/components/Timer";
import Spinner from "@common/components/Spinner";
import { useDispatch, useSelector } from "react-redux";
import {
	getPatientData,
	updateOrAddTranscriptSection,
} from "@features/patient-view/state/redux/activePatientDataSlice";
import { useEffect } from "react";
import TrashIcon from "@icons/trash-03.svg?react";
import MicrophoneIcon from "@icons/microphone-01.svg?react";
import StopIcon from '@icons/stop-circle.svg?react';
import * as Sentry from "@sentry/react";

export default function TranscriptionControlPanel({
	transcriptionId,
	setIsDeleteEntireModalOpen,
	supaClientRef,
	setIsRecording,
}: {
	transcriptionId: string;
	setIsDeleteEntireModalOpen: (x: boolean) => void;
	supaClientRef: React.MutableRefObject<string | undefined>;
	setIsRecording: (x: boolean) => void;
}) {
	const { startRecording, stopRecording, recordingState, audioStream, resetRecorder, recordingTime } =
		useAudioRecorder();
	const dispatch = useDispatch();
	//NOTE: Must be full record of patientData, w/all ChatCompletions and Transcriptions.
	const patientRecord = useSelector(getPatientData);

	const updateTranscriptSection = (transcriptSection: TranscriptSection) => {
		dispatch(updateOrAddTranscriptSection({ transcriptId: transcriptionId, transcriptSection }));
	};

	useEffect(() => {
		if (recordingState === RecordingState.recording) {
			setIsRecording(true);
		} else {
			setIsRecording(false);
		}
	}, [recordingState]);

	const { uploadAudio, uploadProgress, isUploading } = useFileUpload(updateTranscriptSection);

	const onAudioDataAvailable = async (
		audioFile: File,
		patientRecord: PatientRecordTableRow,
		transcriptSectionId: string,
	) => {
		try {
			if (!patientRecord.transcriptions?.id || !patientRecord.id) {
				throw new Error("patientRecord is undefined");
			}
			// upload audio
			const uploadResponse = await uploadAudio(
				audioFile,
				audioFile.name,
				patientRecord.transcriptions.id,
				transcriptSectionId,
				patientRecord,
				false,
			);

			if (uploadResponse?.err) {
				throw new Error(uploadResponse?.err);
			}
		} catch (error) {
			Sentry.captureException(error);
		}
	};

	return (
		<div className="bg-white p-2 grid grid-cols-3">
			<div className="h-full flex flex-col items-center justify-center">
				<div className="flex flex-col h-full justify-end w-full">
					<div className="h-1/2 flex items-end justify-center">
						<div className="w-full h-full pl-0 pr-4 pt-0">
							<AudioLevels
								darkColor="#26B5BB"
								brightColor="#26B5BB"
								barSpacing={1}
								numBars={20}
								recordingState={recordingState}
								audioStream={audioStream}
							/>
						</div>
					</div>

					<div className="h-1/2 justify-end w-full text-left ">
						<div className="text-sm text-gray-900 leading-tight">Status:</div>
						<div className="leading-tight text-lg text-gray-900">
							{recordingState === RecordingState.recording ? "Recording..." : "Ready"}
						</div>
					</div>
				</div>
			</div>

			<div className="flex items-center justify-center">
				{recordingState === RecordingState.loading ? (
					<Spinner size="medium" />
				) : isUploading ? (
					<UploadProgressBar percentage={uploadProgress} />
				) : recordingState === RecordingState.inactive || isRecordingError(recordingState) ? (
					<button
						type="button"
						className={classNames(
							isRecordingError(recordingState)
								? `bg-red-600 hover:bg-red-500 focus-visible:outline-red-600`
								: `bg-max-700  hover:bg-max-800  focus-visible:outline-bg-max-700 `,
							`w-20 h-20 sm:h-24 sm:w-24 md:h-32 md:w-32 sm:h-35 rounded-full flex flex-col items-center justify-center text-xs font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:animate-pulse z-10 cursor-pointer`,
						)}
						onClick={(e) => {
							e.preventDefault();

							if (recordingState === RecordingState.recording) {
								stopRecording();
							} else if (isRecordingError(recordingState)) {
								resetRecorder();
							} else {
								startRecording(
									patientRecord,
									(audioFile: File, patientRecord: PatientRecordTableRow, transcriptSectionId: string) =>
										onAudioDataAvailable(audioFile, patientRecord, transcriptSectionId),
									supaClientRef.current || "",
								);
							}
						}}
					>
						<MicrophoneIcon className="h-10 w-10 text-white mb-1" />
						<span className="text-[10px] sm:text-xs md:text-xs">
							{recordingState === RecordingState.no_permission
								? "Microphone Error! Enable Permissions"
								: recordingState === RecordingState.no_device
									? "Microphone Error! No Device Found"
									: recordingState === RecordingState.error
										? "Microphone Error!"
										: ""}
						</span>
					</button>
				) : (
					(recordingState === "recording" || recordingState === "paused") && (
						<div className="w-full flex flex-row items-center justify-evenly gap-x-6">
							<button
								type="button"
								className={`bg-red-600 hover:bg-red-500 focus-visible:outline-red-600 w-20 h-20 sm:h-24 sm:w-24 md:h-32 md:w-32 sm:h-35 rounded-full flex flex-col items-center justify-center text-xs font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:animate-pulse z-10 cursor-pointer`}
								onClick={(e) => {
									e.preventDefault();
									stopRecording();
								}}
							>
								<StopIcon className="h-10 w-10 sm:h-10 sm:w-10 text-white mb-1" />
								<span className="hidden md:inline-block sm:text-xs md:text-sm">
									{(recordingState === "recording" || recordingState === "paused") && "Stop"}
								</span>
							</button>
						</div>
					)
				)}
			</div>
			<div className="flex flex-col justify-between items-center">
				<div className="mt-2">
					{recordingState === RecordingState.recording && (
						<Timer time={recordingTime} recordingState={recordingState} />
					)}
				</div>

				<div className="flex flex-row self-end justify-end items-end gap-2">
					<div className="tooltip tooltip-left" data-tip="Delete Entire Transcript">
						<button
							className="bg-transparent "
							onClick={() => {
								setIsDeleteEntireModalOpen(true);
							}}
						>
							<TrashIcon className="w-6 h-6 text-gray-900" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
