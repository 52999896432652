const buildUrl = (base: string, ...segments: string[]) => `/${base}/${segments.join("/")}/`;

export const ENDPOINTS = {
	Login: "login",
	SignUp: "signup",
	UpdatePassword: "update-password",
	ResetPassword: "reset-password",
	Calendar: "calendar",
	Appointments: "appointments",
	DocumentTypes: "document-types",
	Integrations: "integrations",
	Settings: "settings",
	Payment: "payment",
	Confirmation: "confirmation",
	PermissionDenied: "permission-denied",
	DocumentBuilder: "document-builder",
};

export const APPOINTMENTS_ROUTE = buildUrl(ENDPOINTS.Appointments, ":appointment_id");

export const INDIVDUAL_DOCUMENT_TYPE_ROUTE_ADVANCED = buildUrl(ENDPOINTS.DocumentTypes, ":doc_type_id");
//export const INDIVDUAL_DOCUMENT_TYPE_ROUTE_BUILDER = buildUrl(ENDPOINTS.DocumentBuilder, ":doc_type_id");

export const PAYMENT_CONFIRMATION_ROUTE = buildUrl(ENDPOINTS.Payment, ENDPOINTS.Confirmation);
