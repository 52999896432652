import React, { useEffect, useState } from "react";
import { useSession } from "@supabase/auth-helpers-react";

const FeedbackModal = ({
	isOpen,
	onCancel,
	onSubmit,
	setFeedback,
}: {
	isOpen: boolean;
	onCancel: () => void;
	onSubmit: () => void;
	setFeedback: (feedback: string) => void;
}) => {
	const session = useSession();
	const [inputValue, setInputValue] = useState("");

	useEffect(() => {
		setFeedback(inputValue);
	}, [inputValue]);

	const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setInputValue(event.target.value);
	};

	const handleSubmit = () => {
		onSubmit();
		setInputValue("");
	};

	const handleCancel = () => {
		onCancel();
		setInputValue("");
	};

	return (
		<dialog open={isOpen} className="modal bg-gray-500 bg-opacity-75" id="terminologyFlagger">
			<div className="relative p-5 border w-512 shadow-lg rounded-md bg-white">
				<h1 className="w-[464px] text-gray-900 font-semibold leading-normal">
					Document Feedback
				</h1>
				<div className="mt-3 text-zinc-500 font-normal font-['Plus Jakarta Sans'] leading-normal text-[14px]">
					Tell us what we can improve!
				</div>

				<div className="mt-2 px-1 py-3">
					<textarea
						id="misspelled"
						value={inputValue}
						onChange={(e) => handleInputChange(e)}
						className="mt-1 p-2 border w-full h-150 rounded-md border-gray-300 text-[15px]"
						placeholder="i.e. Used layman terms in the Objective section."
					/>
				</div>

				<div className="items-center px-4 py-3 flex justify-end gap-2">
					<button
						type="button"
						onClick={handleCancel}
						className="px-4 py-2 bg-gray-200 text-gray-900 rounded hover:bg-gray-300"
					>
						Cancel
					</button>
					<button
						type="button"
						onClick={handleSubmit}
						className="px-4 py-2 bg-max-700 text-white rounded hover:bg-max-800"
					>
						Submit
					</button>
				</div>
			</div>
		</dialog>
	);
};

export default FeedbackModal;
