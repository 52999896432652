import { useEffect, useState } from "react";
import { getActiveProductsWithPrices } from "@common/lib/supabaseClient";
import { StripeProduct } from "@common/utils/types";
import { useSessionContext } from "@supabase/auth-helpers-react";
import HappyDocLogo from "../../assets/HD-Horizontal-Blue.png";
import { useNavigate } from "react-router-dom";
import Product from "./Product";
import * as Sentry from "@sentry/react";

function sortProducts(products: StripeProduct[]) {
	return products.sort((a, b) => a.prices[0].unit_amount - b.prices[0].unit_amount);
}

export default function ProductDisplay() {
	const navigate = useNavigate();
	const [products, setProducts] = useState<StripeProduct[]>([]);
	const { supabaseClient: supabase } = useSessionContext();

	useEffect(() => {
		(async () => {
			const res = await getActiveProductsWithPrices();
			setProducts(sortProducts(res));
		})();
	}, []);

	const signout = async (e: { preventDefault: () => void }) => {
		e.preventDefault();
		navigate("/login");

		const { error } = await supabase.auth.signOut({ scope: "local" });
		if (error) {
			Sentry.captureException(error)
			for (let i = 0; i < localStorage.length; i++) {
				const key = localStorage.key(i);
				if (key && key.match(/^sb-.*-auth-token$/)) {
					localStorage.removeItem(key);
					window.location.reload();
				}
			}
		}
	};

	return (
		<div className="flex item-center min-h-screen flex-1 flex-col justify-center bg-max-50">
			<div className="mx-auto max-w-7xl px-6 lg:px-8 mt-4">
				<img className="h-16 w-auto mx-auto" src={HappyDocLogo} alt="HappyDoc" />
				<div className="mx-auto max-w-4xl text-center">
					<p className="mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
						Pricing plans for hospitals of all sizes
					</p>
					<p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
						Remember why you became a vet? It wasn't for the paperwork.
						<br /> Reclaim your passion with HappyDoc.
					</p>
				</div>
				<div className="my-6 isolate mx-auto grid max-w-md grid-cols-1 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3 justify-center">
					{products.map((product, pindex) => (
						<Product key={product.id} products={products} product={product} pindex={pindex} />
					))}
				</div>
				<div className="mt-6 mb-20 flex gap-4 justify-center">
					<button
						type="button"
						onClick={(e) => signout(e)}
						className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
					>
						Sign out
					</button>
				</div>
			</div>
		</div>
	);
}
