import classNames from "classnames";
import RefreshIcon from "@icons/refresh-cw-03.svg?react";

const Spinner = ({
	size = "small",
	color = "text-gray-600",
}: {
	size?: "xsmall" | "small" | "medium" | "large";
	color?: string;
}) => {
	let sizeSwitch = (): string => {
		switch (size) {
			case "xsmall":
				return "h-4 w-4";
			case "small":
				return "h-6 w-6";
			case "medium":
				return "h-10 w-10";
			case "large":
				return "h-16 w-16";
			default:
				return "lds-ring";
		}
	};

	return (
		<RefreshIcon className={classNames([sizeSwitch(), color, "animate-spin"])} />
	);
};

export default Spinner;
