import XCloseIcon from "@icons/x-close.svg?react";
import { useEffect, useState } from "react";

/**
 * Banner to promote the HappyDoc app
 * @returns JSX.Element
 */
const AppBanner = () => {
	const [showBanner, setShowBanner] = useState(true);

	// Check if the banner has been closed before and hide it if it has
	useEffect(() => {
		const bannerClosed = localStorage.getItem('bannerClosed');
		if (bannerClosed === 'true') {
			setShowBanner(false);
		}
	}, []);

	// Close the banner and set the local storage value
	const handleClose = () => {
		setShowBanner(false);
		localStorage.setItem('bannerClosed', 'true');
	};

	// Redirect to the app store
	const handleRedirect = () => {
		window.location.href = "https://apps.apple.com/us/app/happydoc/id6479448993";
	};

	// If the banner is hidden, return null
	if (!showBanner) return null;

	return (
		<div className="w-full fixed top-0  bg-white border border-gray-300 rounded-b-lg p-4 flex items-center shadow-lg z-50">
			<span className="text-xl cursor-pointer mr-2" onClick={handleClose}>
				<XCloseIcon width={24} />
			</span>
			<img src="/hd-app-icon-1024.png" alt="HappyDoc Logo" className="w-10 h-10 mr-4" />
			<div className="flex-grow mr-4">
				<h1 className="text-sm font-bold mb-1">HappyDoc</h1>
				<p className="text-xs text-gray-600">Download the HappyDoc app to record appointments with ease.</p>
			</div>
			<button className="bg-max-700  text-white px-4 py-2 rounded-lg hover:bg-max-800 " onClick={handleRedirect}>
				Open
			</button>
		</div>
	);
};

export default AppBanner;
