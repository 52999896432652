import FileSearchIcon from "@icons/file-search-02.svg?react";

const TranscriptionModalButton = ({ setOpenTranscriptModal }: { setOpenTranscriptModal: (value: boolean) => void }) => {
	return (
		<div>
			<button
				id="open-transcript-button"
				className="w-full rounded-md bg-white hover:bg-gray-100 px-3.5 py-2 text-gray-900 text-xs font-semibold shadow-sm ring-1 ring-inset ring-gray-300 flex items-center justify-center space-x-2"
				onClick={() => setOpenTranscriptModal(true)}
			>
				<FileSearchIcon className="h-4 w-4" />
				<span>Open Transcript</span>
			</button>
		</div>
	);
};
export default TranscriptionModalButton;
