import { useSession } from "@supabase/auth-helpers-react";
import { useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";

export const IntercomManager = () => {
	const pathname = window.location.pathname;
	const session = useSession();

	const accessToken = session?.access_token ?? null;
	const initializedRef = useRef(false);
	const user = session?.user;

	//Attempt to load once.
	useEffect(() => {
		// Only try to load Intercom script once
		if (!initializedRef.current) {
			try {
				var w: any = window;
				var ic = w.Intercom;
				if (typeof ic !== "function") {
					var d = document;
					var i: any = function () {
						i.c(arguments);
					};
					i.q = [];
					i.c = function (args: any) {
						i.q.push(args);
					};
					w.Intercom = i;
					var l = function () {
						var s = d.createElement("script");
						s.type = "text/javascript";
						s.async = true;
						s.src = "https://widget.intercom.io/widget/qd1sb79b";
						var x: any = d.getElementsByTagName("script")[0];
						x.parentNode.insertBefore(s, x);
					};
					if (document.readyState === "complete") {
						l();
					} else if (w.attachEvent) {
						w.attachEvent("onload", l);
					} else {
						w.addEventListener("load", l, false);
					}
				}
				initializedRef.current = true;
			} catch (e: any) {
				Sentry.captureException(e);
			}
		}
	}, []);
	//Set User.
	useEffect(() => {
		try {
			if (accessToken && initializedRef.current) {
				// Call boot method
				(window as any)?.Intercom("boot", {
					app_id: "qd1sb79b",
					custom_launcher_selector: "#IntercomCustomLink",
					email: user?.email ?? "Unknown User",
				});
			} else if (!accessToken && initializedRef.current) {
				// Shutdown Intercom if user is not authenticated and Intercom is initialized
				(window as any)?.Intercom("shutdown");
			}
		} catch (e: any) {
			Sentry.captureException(e);
		}
	}, [accessToken, user]);

	// Update Intercom on route changes
	useEffect(() => {
		try {
			const handleRouteChange = () => {
				if (accessToken) {
					(window as any)?.Intercom("update");
				}
			};
			handleRouteChange();
		} catch (e: any) {
			Sentry.captureException(e);
		}
	}, [pathname, accessToken]);

	return null; // This component doesn't render anything itself
};

export const IntercomCustomLink = () => {
	return (
		<a
			id="IntercomCustomLink"
			href=""
			className="text-blue-500 blue-glow text-xs sm:text- whitespace-nowrap flex items-center"
			style={{ transition: "box-shadow .3s ease" }}
		>
			<span>👋 24/7 Chat Support</span>
		</a>
	);
};
