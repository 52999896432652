import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export function useNetworkStatus() {
	const [isOnline, setIsOnline] = useState(true);

	useEffect(() => {
		const goOnline = () => setIsOnline(navigator.onLine);
		const goOffline = () => {
			setIsOnline(false);
			// Call the global toast function directly
			toast.error("Uh oh! We're detecting some network instability!! Please double check your internet");
		};

		window.addEventListener("online", goOnline);
		window.addEventListener("offline", goOffline);

		return () => {
			window.removeEventListener("online", goOnline);
			window.removeEventListener("offline", goOffline);
		};
	}, []);

	return isOnline;
}
