import { Route } from "react-router-dom";
import Login from "../features/auth/Login";
import { ENDPOINTS } from "../common/utils/url-registry";
import NotFoundPage from "../common/components/NotFound";
import AsyncComponent from "./AsyncComponent";

export function UnAuthenticatedRoutes() {
	return (
		<>
			<Route index element={<Login />} />
			<Route path={"/login"} element={<Login />} />
			<Route
				path={ENDPOINTS.SignUp}
				element={<AsyncComponent loadComponent={() => import("../features/auth/SignUp")} />}
			/>
			<Route
				path={ENDPOINTS.ResetPassword}
				element={<AsyncComponent loadComponent={() => import("../features/auth/ResetPassword")} />}
			/>
			<Route path="*" element={<NotFoundPage />} />
		</>
	);
}
