import { configureStore } from "@reduxjs/toolkit";
import patientTableRecordsReducer from "../state/redux/patientTableRecordsSlice";
import doctorSlice from "@redux/doctorSlice";
import documentSlice from "@redux/documentSlice";
import activeDocumentsSlice from "@features/patient-view/state/redux/activeDocumentsSlice";
import activePatientDataSlice from "@features/patient-view/state/redux/activePatientDataSlice";
import globalStateSlice from "@redux/globalState";
export default configureStore({
	reducer: {
		patientTableRecords: patientTableRecordsReducer,
		doctors: doctorSlice,
		documents: documentSlice,
		activeDocuments: activeDocumentsSlice,
		activePatientData: activePatientDataSlice,
		globalState: globalStateSlice
	},
});
