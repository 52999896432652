import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useState } from "react";
import Alert from "../../common/components/Alert";

const UpdatePassword = () => {
	const supabase = useSupabaseClient();
	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<string | null>(null);
	const [formState, setFormState] = useState({
		newPassword: "",
		confirmPassword: "",
	});

	async function updatePassword(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		if (formState.newPassword !== formState.confirmPassword) {
			setError("Passwords do not match");
			return;
		}

		const { data, error } = await supabase.auth.updateUser({
			password: formState.newPassword,
		});

		if (error) {
			setError(error.message);
			return;
		}

		setSuccess("Password updated successfully");
	}

	return (
		<>
			<div className="pt-14 pb-14 flex min-h-full flex-1 flex-col px-6 py-12 lg:px-8 items-center">
				<h2 className="font-bold text-lg">Update Password</h2>
				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
					<form className="space-y-6" onSubmit={updatePassword}>
						<div>
							<div className="flex items-center justify-between">
								<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
									New Password
								</label>
							</div>
							<div className="mt-2">
								<input
									id="new-password"
									name="new-password"
									type="password"
									required
									onChange={(e) => setFormState({ ...formState, newPassword: e.target.value })}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-max-700  sm:text-sm sm:leading-6"
								/>
							</div>
							<div className="flex items-center justify-between">
								<label htmlFor="confirm-password" className="block text-sm font-medium leading-6 text-gray-900">
									Confirm Password
								</label>
							</div>
							<div className="mt-2">
								<input
									id="confirm-password"
									name="confirm-password"
									type="password"
									required
									onChange={(e) => setFormState({ ...formState, confirmPassword: e.target.value })}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-max-700  sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div>
							<button
								type="submit"
								className="flex w-full justify-center rounded-md bg-max-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-max-800  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bg-max-700 "
							>
								Reset Password
							</button>
						</div>
					</form>
					<div className="mt-4">{error && <Alert alertType="error" title="Error" description={error} />}</div>
					<div className="mt-4">{success && <Alert alertType="success" title="Success" description={success} />}</div>
				</div>
			</div>
		</>
	);
};

export default UpdatePassword;
