let env: any;

if (typeof process !== "undefined") {
	env = process.env; // Node.js environment
} else {
	env = import.meta.env; // Browser environment
}

export default env;

const {
	VITE_HAPPYDOC_API_SERVER_URL,
	VITE_HAPPYDOC_TRANSCRIBE_SERVER_URL,
	VITE_PUBLIC_LOGFLARE_ID,
	VITE_PUBLIC_LOGFLARE_API_KEY,
	VITE_PUBLIC_ENV,
	VITE_PUBLIC_SUPABASE_URL,
	VITE_PUBLIC_SUPABASE_ANON_KEY,
	VITE_PUBLIC_BRAND,
	VITE_PENDO_API_KEY,
	VITE_ONBOARDING_HELP_URL,
	VITE_HAPPY_SOAP_ID,
	VITE_NOTIFICATIONS_BANNER_MESSAGE,
} = env;

export {
	VITE_HAPPYDOC_API_SERVER_URL,
	VITE_HAPPYDOC_TRANSCRIBE_SERVER_URL,
	VITE_PUBLIC_LOGFLARE_ID,
	VITE_PUBLIC_LOGFLARE_API_KEY,
	VITE_PUBLIC_ENV,
	VITE_PUBLIC_SUPABASE_URL,
	VITE_PUBLIC_SUPABASE_ANON_KEY,
	VITE_PUBLIC_BRAND,
	VITE_PENDO_API_KEY,
	VITE_ONBOARDING_HELP_URL,
	VITE_HAPPY_SOAP_ID,
	VITE_NOTIFICATIONS_BANNER_MESSAGE,
};

export const HD_CHROME_EXTENSION_ID = "cipkdomngkmgmfekckaokaidmnikjdco";
