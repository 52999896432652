import { useRef, useEffect } from 'react';

// adding horizontal scroll functionality
function useScroll() {
	const sliderRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const slider = sliderRef.current;
		if (!slider) return;


		let isDown = false;
		let startX: number;
    let scrollLeft: number;
		const end = () => {
			isDown = false;
		};

		const start = (e: MouseEvent) => {
			isDown = true;
			startX = e.pageX - slider.offsetLeft;
			scrollLeft = slider.scrollLeft;
		};

		const move = (e: MouseEvent) => {
			if (!isDown) return;
			e.preventDefault();
			const x = e.pageX - slider.offsetLeft;
			const walk = (x - startX);
			slider.scrollLeft = scrollLeft - walk;
		};

		const scroll = (e: WheelEvent) => {
			e.preventDefault();
			slider.scrollLeft += e.deltaY ;
		};

		slider.addEventListener('wheel', scroll, { passive: false });
		slider.addEventListener('mousedown', start);
		slider.addEventListener('mouseleave', end);
		slider.addEventListener('mouseup', end);
		slider.addEventListener('mousemove', move);

		return () => {
      slider.removeEventListener('wheel', scroll);
      slider.removeEventListener('mousedown', start);
			slider.removeEventListener('mouseleave', end);
			slider.removeEventListener('mouseup', end);
			slider.removeEventListener('mousemove', move);
		};
	}, []);

  return sliderRef;
}

export default useScroll;
