import { useState, useCallback } from "react";
import { PatientRecordTableRow } from "@common/utils/types";
import { insertPatientRecordTableRow, updatePatientRecordTableRow } from "@redux/patientTableRecordsSlice";
import { Doctor, PatientStage } from "@common/utils/types";
import { v4 as uudiv4 } from "uuid";
import { isSameDay } from "@common/utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { useDataContextApi } from "@providers/DataProvider";

const usePatientState = (patientTableRecord: PatientRecordTableRow | null = null) => {
	const dispatch = useDispatch();
	const { selectedDoctor } = useSelector((state: any) => state.doctors);
	const { selectedDate } = useDataContextApi();

	// State variables
	const [patientName, setPatientName] = useState("");
	//NOTE: PatientId is a seperate field associated PMS patient ids. Don't confuse it with the patientrecord id
	const [patientId, setPatientId] = useState("");
	const [scheduledAtDay, setScheduledAtDay] = useState(formatDate());
	const [scheduledAtTime, setScheduledAtTime] = useState(getCurrentTimeRounded());
	const [appointmentDuration, setAppointmentDuration] = useState(30);
	const [localSelectedDoctor, setLocalSelectedDoctor] = useState<Doctor | null>(null);

	// Clear Fields
	const clearFields = useCallback(() => {
		setPatientName("");
		setPatientId("");
		// Clear additional fields as necessary
	}, []);

	// Generate Time Options

	//Date Time Handlers
	const adjustTime = (direction: string) => {
		let [hours, minutes] = scheduledAtTime.split(":").map(Number);
		if (direction === "up") {
			minutes += appointmentDuration;
		} else if (direction === "down") {
			minutes -= appointmentDuration;
		}
		while (minutes >= 60) {
			hours++;
			minutes -= 60;
		}
		while (minutes < 0) {
			hours--;
			minutes += 60;
		}
		if (hours >= 24) hours = 0;
		if (hours < 0) hours = 23;
		const newTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
		setScheduledAtTime(newTime);
	};

	function generateTimeOptions(): JSX.Element[] {
		const options: JSX.Element[] = [];
		for (let hour = 0; hour < 24; hour++) {
			for (let minute = 0; minute < 60; minute += 15) {
				let formattedHour = hour % 12 === 0 ? 12 : hour % 12; // convert to 12-hour format
				const formattedMinute = minute.toString().padStart(2, "0");
				const period = hour < 12 ? "AM" : "PM"; // period based on hour
				const displayTime = `${formattedHour}:${formattedMinute} ${period}`;
				const valueTime = `${hour.toString().padStart(2, "0")}:${formattedMinute}`;
				options.push(
					<option key={valueTime} value={valueTime}>
						{displayTime}
					</option>,
				);
			}
		}
		return options;
	}

	function calculateNextAvailableTime(startTime: string, duration: number) {
		const [hours, minutes] = startTime.split(":").map(Number);
		const startTimeDate = new Date();
		startTimeDate.setHours(hours, minutes, 0, 0); // set the date object to current appointment time

		// add the duration to get the end time of the current appointment
		startTimeDate.setMinutes(startTimeDate.getMinutes() + duration);

		// format the next available time
		const nextHours = startTimeDate.getHours().toString().padStart(2, "0");
		const nextMinutes = startTimeDate.getMinutes().toString().padStart(2, "0");

		return `${nextHours}:${nextMinutes}`;
	}

	function convertTo24HourFormat(time12h: string) {
		const [time, modifier] = time12h.split(" ");
		let [hours, minutes] = time.split(":");
		if (hours === "12") {
			hours = modifier === "AM" ? "00" : "12";
		} else if (modifier === "PM") {
			hours = (parseInt(hours, 10) + 12).toString();
		}
		return `${hours}:${minutes}`;
	}

	function formatDate(date = new Date()) {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");
		return `${year}-${month}-${day}`;
	}

	function getCurrentTimeRounded(date = new Date(), isQuickStart = false) {
		let minutes = date.getMinutes();
		let hours = date.getHours();

		if (isQuickStart) {
			// Round to the nearest 15-minute block within the current hour
			const roundedMinutes = Math.round(minutes / 15) * 15;
			minutes = roundedMinutes % 60;
			if (roundedMinutes >= 60) {
				// If rounding up exceeds 60 minutes, increment the hour
				// Also, ensure hours wrap around at 24
				hours = (hours + 1) % 24;
			}
		} else {
			// Round up to the next 15-minute block
			const m = 15;
			const roundedMinutes = Math.ceil(minutes / m) * m;
			minutes = roundedMinutes % 60;
			if (roundedMinutes >= 60) {
				// If rounding up exceeds 60 minutes, increment the hour
				// Also, ensure hours wrap around at 24
				hours = (hours + 1) % 24;
			}
		}

		const formattedHours = hours.toString().padStart(2, "0");
		const formattedMinutes = minutes.toString().padStart(2, "0");

		return `${formattedHours}:${formattedMinutes}`;
	}

	//Creates the object.
	const createNewPatientTableRecord = (isQuickStart: boolean = false) => {
		let scheduledAt = scheduledAtDay + "T" + scheduledAtTime + ":00";

		//If Quickstart, we round to the nearest 15 minute increment.
		if (isQuickStart) {
			let updatedTime = getCurrentTimeRounded(new Date(), true);
			scheduledAt = scheduledAtDay + "T" + updatedTime + ":00";
		}

		let newPatientTableRecord: PatientRecordTableRow = {
			id: uudiv4(),
			doctors: localSelectedDoctor || null,
			patient_id: patientId,
			patient_name: patientName,
			appointment_duration: appointmentDuration, // Use appointmentDuration
			scheduled_at: new Date(scheduledAt).toISOString(),
			created_at: new Date().toISOString(),
			stage: PatientStage.Ongoing,
			chat_completions: [],
			transcriptions: {
				id: uudiv4(),
				transcript_sections: [],
			},
		};
		return newPatientTableRecord;
	};

	//Shallow Copy.
	const createNewUpdatedTableRecord = (patientTableRecord: PatientRecordTableRow) => {
		const scheduledAt = scheduledAtDay + "T" + scheduledAtTime + ":00";

		let updatedPatientRecord: PatientRecordTableRow = {
			...patientTableRecord,
			doctors: localSelectedDoctor,
			patient_id: patientId,
			patient_name: patientName,
			appointment_duration: appointmentDuration,
			scheduled_at: new Date(scheduledAt).toISOString(),
		};
		return updatedPatientRecord;
	};

	//Handle insert and update patient record
	const handleInsertPatientRecord = () => {
		let newTableRecord = createNewPatientTableRecord();
		//Add if we have the same day, same doctor
		let addToTable =
			isSameDay(new Date(newTableRecord.scheduled_at!), selectedDate.startDate) &&
			(selectedDoctor?.id === newTableRecord.doctors?.id || !selectedDoctor);
		dispatch(insertPatientRecordTableRow({ newTableRecord, addToTable }) as any);
		return newTableRecord;
	};

	const handleUpdatePatientRecord = () => {
		let updatedTableRecord = createNewUpdatedTableRecord(patientTableRecord!);

		let updateTable =
			isSameDay(new Date(updatedTableRecord.scheduled_at!), selectedDate.startDate) &&
			(selectedDoctor?.id === updatedTableRecord.doctors?.id || !selectedDoctor);
		dispatch(updatePatientRecordTableRow({ updatedTableRecord, updateTable }) as any);
	};

	return {
		patientName,
		setPatientName,
		patientId,
		setPatientId,
		scheduledAtDay,
		setScheduledAtDay,
		scheduledAtTime,
		setScheduledAtTime,
		appointmentDuration,
		setAppointmentDuration,
		clearFields,
		generateTimeOptions,
		adjustTime,
		handleInsertPatientRecord,
		localSelectedDoctor,
		setLocalSelectedDoctor,
		formatDate,
		calculateNextAvailableTime,
		handleUpdatePatientRecord,
		createNewPatientTableRecord,
	};
};

export default usePatientState;
