import { useNavigate } from "react-router-dom";
import HappyDocLogo from "../../assets/HD-Horizontal-Blue.png";
import { createStripePortalSession } from "@common/lib/api";
import { useSession } from "@supabase/auth-helpers-react";
import * as Sentry from "@sentry/react";

function PaymentConfirmation({ sessionId }: { sessionId?: string }) {
	const navigate = useNavigate();
	const session = useSession();

	const manageAccount = async () => {
		if (!session) {
			Sentry.captureMessage("Session not found in PaymentConfirmation.tsx", {
				level: "error",
			});
			return;
		}

		const { url } = await createStripePortalSession(session, window.location.href);

		if (url) {
			window.location.replace(url);
		} else {
			Sentry.captureMessage("Stripe portal session creation failed in PaymentConfirmation.tsx", {
				level: "error",
			});
		}
	};

	return (
		<div className="flex item-center min-h-screen bg-max-50 flex justify-center items-center">
			<div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8 text-center">
				<img className="h-24 w-auto mx-auto" src={HappyDocLogo} alt="HappyDoc" />
				<h2 className=" mt-10 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
					You have successfully completed your payment.
				</h2>
				<p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">Boost your productivity.</p>
				<div className="mt-10 flex justify-center gap-x-6">
					<button
						onClick={() => navigate("/")}
						className="rounded-md bg-max-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-max-800  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bg-max-700 "
					>
						Start using our app!
					</button>
					<button onClick={manageAccount} className="text-sm font-semibold leading-6 text-gray-900">
						Manage your account <span aria-hidden="true">→</span>
					</button>
				</div>
			</div>
		</div>
	);
}

export default PaymentConfirmation;
