import InfoCircleIcon from "@icons/info-circle.svg?react";
import classNames from "classnames";
import { toast } from "react-toastify";

const Timer = ({ time, recordingState, topDivClassName }: any) => {
	// Function to format time
	const formatTime = (totalSeconds: number) => {
		const hours = Math.floor(totalSeconds / 3600);
		const minutes = Math.floor((totalSeconds % 3600) / 60);
		const seconds = totalSeconds % 60;

		if (hours > 0) {
			return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
		} else {
			return `${minutes}:${seconds.toString().padStart(2, "0")}`;
		}
	};

	if (time === 2400) {
		toast.warning("Recording longer than 40 minutes decrease the quality of the transcription", {
			autoClose: false,
			closeOnClick: true,
			draggable: true,
		});
	}

	return (
		<div className={topDivClassName}>
			{formatTime(time)}
			<div className="flex flex-row items-center">
				<InfoCircleIcon
					className={classNames(time > 2400 ? "text-red-500" : "text-gray-900", "mr-1 h-4 w-4 text-gray-900 opacity-30")}
				/>
				<span
					className={classNames(time > 2400 ? "text-red-500" : "text-gray-900", "text-[8px] sm:text-[10px] opacity-80")}
				>
					{time > 2400 ? "File Size may exceed capacity" : "Pause/Stop When Not in Use"}
				</span>
			</div>
		</div>
	);
};

export default Timer;
