import axios from "axios";
import isDate from "validator/lib/isDate";
import { StripePrice } from "../utils/types";
import { Session } from "@supabase/supabase-js";
import { v4 as uuidv4 } from "uuid";
import { supabase } from "@common/lib/supabaseClient";
import { VITE_HAPPYDOC_TRANSCRIBE_SERVER_URL, VITE_HAPPYDOC_API_SERVER_URL } from "@common/utils/constants";
import * as Sentry from "@sentry/react";

export const transcribeAudio = async (
	session: Session,
	transcriptionId: string,
	transcriptSectionId: string,
	audioName: string,
	realtimeClientId?: string | null,
	patientRecordId?: string | null,
	patientName: string = "",
	doctorId: string = "",
	documentIds: string[] = [],
	chatMessageIds: string[] = [],
): Promise<{ error: boolean | null }> => {
	const userId = session?.user?.id;

	const postData = {
		userId,
		transcriptionId,
		transcriptSectionId,
		audioName,
		realtimeClientId,
		patientRecordId,
		patientName,
		doctorId,
		documentIds,
		chatMessageIds,
	};

	const url = `${VITE_HAPPYDOC_TRANSCRIBE_SERVER_URL}/transcription/start`;
	let accessToken = session?.access_token;
	const {
		data: { session: session2 },
		error,
	} = await supabase.auth.getSession();
	if (session2?.access_token && !error && session?.access_token !== session2?.access_token) {
		accessToken = session2?.access_token;
	}

	const makeRequest = async () => {
		try {
			// throws err if not 2XX response
			const response = await axios.post(url, postData, {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${accessToken}`,
				},
			});

			if (response.status !== 200 || !response.data.message) {
				throw new Error("No message returned");
			}

			return { error: null };
		} catch (error: any) {
			Sentry.captureException(error);

			// throw to retry
			throw error;
		}
	};

	try {
		return await makeRequest();
	} catch (error: any) {
		Sentry.captureException(error);
		await new Promise((resolve) => setTimeout(resolve, 5000)); // wait 3 second
		try {
			return await makeRequest();
		} catch (retryError: any) {
			Sentry.captureException(retryError);

			return { error: true };
		}
	}
};

export const addTerm = async (session: Session, wrongSpelling: string, term: string) => {
	try {
		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/correction/terms`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				term,
				wrong_spellings: new Array(wrongSpelling),
			}),
		});
		return await response.json();
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

/**
 * Retrieves appointments based on the specified criteria.
 *
 * @param userId - The ID of the user.
 * @param queryStartDate - The start date of the query.
 * @param integration_name - An array of integration names.
 * @returns A Promise that resolves to an object containing the retrieved appointments and any errors.
 */
export const getAppointments = async (
	session: Session,
	userId: string,
	queryStartDate: any,
	integration_name: Array<string>,
): Promise<any> => {
	try {
		// Validate and get timezone offset, else use current date's offset
		let clientTimezoneOffset = isDate(new Date(queryStartDate).toString())
			? new Date(queryStartDate).getTimezoneOffset() * 60000
			: new Date().getTimezoneOffset() * 60000;

		let startDate = new Date(queryStartDate).getTime() - clientTimezoneOffset;
		let startDateObj = new Date(startDate); // Create a Date object from the timestamp
		let startDateISO = startDateObj.toISOString();

		// Create start and end of day in UTC using the Date object
		let startOfDay = new Date(
			Date.UTC(startDateObj.getUTCFullYear(), startDateObj.getUTCMonth(), startDateObj.getUTCDate(), 0, 0, 0, 0),
		);
		let endOfDay = new Date(
			Date.UTC(startDateObj.getUTCFullYear(), startDateObj.getUTCMonth(), startDateObj.getUTCDate(), 23, 59, 59, 999),
		);

		let startOfDayString = startOfDay.toISOString();
		let endOfDayString = endOfDay.toISOString();

		let queryParams = {
			StartTime: startOfDayString,
			EndTime: endOfDayString,
		};

		const response = await fetch(`${VITE_HAPPYDOC_API_SERVER_URL}/integration/appointments`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				userId: userId,
				integrationName: integration_name,
				queryParams: queryParams,
				timezoneOffset: clientTimezoneOffset / 3600000, // timezone offset in hours -- this can be removed once every account has their timezone set
			}),
		});

		if (!response.ok) {
			throw new Error(`Server Error: ${response.status}`);
		}
		const responseData = await response.json();
		if (responseData?.errors?.length > 0) {
			throw new Error(`Server reported errors: ${JSON.stringify(responseData.errors)}`);
		}

		return { newPatientRecords: responseData?.updatedRecords, error: null };
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true, newPatientRecords: null };
	}
};

export const exportRecords = async (
	session: Session | null,
	userId: string,
	chatJson: any,
	patientRecordId: string,
): Promise<any> => {
	try {
		const response = await fetch(`${VITE_HAPPYDOC_API_SERVER_URL}/integration/export`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				userId: userId,
				chatJson: JSON.stringify(chatJson), // Ensure chatJson is stringified if it's an object
				integrationName: "bitwerx",
				patientRecordId: patientRecordId,
			}),
		});

		if (!response.ok) {
			throw new Error(`Server Error: ${response.status}`);
		} else {
			return await response.json();
		}
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

export const exportCornerstoneRecord = async (session: Session, userId: string, documentId: string): Promise<any> => {
	try {
		const response = await fetch(`${VITE_HAPPYDOC_API_SERVER_URL}/integration/export-cornerstone`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session.access_token}`,
			},
			body: JSON.stringify({
				userId: userId,
				documentId: documentId,
				integrationName: "bitwerx",
			}),
		});

		if (!response.ok) {
			throw new Error(`Server Error: ${response.status}`);
		} else {
			return await response.json();
		}
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

export const generateDocument = async (
	session: Session,
	patientRecordId: string,
	documentTypeId: string,
	transcriptionId: string,
	multiplePatientCase: boolean = false,
	patientNameToParse: string = "",
): Promise<any> => {
	const url = `${VITE_HAPPYDOC_API_SERVER_URL}/generate/generate`;
	try {
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session.access_token}`,
			},
			body: JSON.stringify({
				document_type_id: documentTypeId,
				transcriptionId: transcriptionId,
				userId: session.user.id,
				patientRecordId: patientRecordId,
				newMessageId: uuidv4(),
				respondAsStream: false,
				multiplePatientCase: multiplePatientCase,
				patientNameToParse: patientNameToParse,
			}),
		});
		return response.json();
	} catch (error: any) {
		if (error?.message === "Load failed" || error?.message === "Failed to fetch") {
			return { error: true };
		}

		Sentry.captureException(error);
		return { error: true };
	}
};

// Stripe

export const createCheckoutSession = async (session: Session, price: StripePrice, metaData: {}, redirect: string) => {
	try {
		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/stripe/create-checkout-session`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session.access_token}`,
			},
			body: JSON.stringify({
				price,
				quanity: 1,
				metaData,
				redirect,
			}),
		});
		return await response.json();
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

//CACHE TEST
export const createStripePortalSession = async (session: Session, redirect: string) => {
	try {
		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/stripe/create-portal-session`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session.access_token}`,
			},
			body: JSON.stringify({
				redirect,
			}),
		});
		return await response.json();
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

export const newSignUpSlackNotification = async (session: Session, email?: string, id?: string, token?: string) => {
	try {
		if (!email || !id) {
			Sentry.captureMessage("Missing required parameters - newSignUpSlackNotification", {
				extra: { email, id },
				level: "error",
			});
			return { error: true };
		}

		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/slack/new-signup`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				email,
				id,
			}),
		});
		return response;
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

export const postNewFeedbackToSlack = async (
	session: Session,
	chat_completion_id?: string,
	feedbackType?: string,
	feedback?: string,
	acct_id?: string,
	doc_name?: string,
	email?: string,
) => {
	try {
		if (!chat_completion_id || !feedbackType || !feedback) {
			Sentry.captureMessage("Missing required parameters - postNewFeedbackToSlack", {
				extra: { chat_completion_id, feedbackType, feedback, acct_id, doc_name, email },
				level: "error",
			});
			return { error: true };
		}

		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/slack/provided-feedback`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				chat_completion_id,
				feedbackType,
				feedback,
				acct_id,
				doc_name,
				email,
			}),
		});
		return response;
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

export const newIntegrationRequestSlack = async (session: Session, email?: string, id?: string, pims?: string) => {
	try {
		if (!email || !id || !pims) {
			Sentry.captureMessage("Missing required parameters - newIntegrationRequestSlack", {
				extra: { email, id, pims },
				level: "error",
			});
			return { error: true };
		}

		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/slack/integration-request`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				email,
				id,
				pims,
			}),
		});
		return response;
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

/*
 * Send a slack notification for a new builder element request
 * @param session - The current session
 * @param email - The email of the user
 * @param id - The id of the user
 * @param elementName - The name of the element requested
 * @param description - The description of the requested element
 * @returns error: true if the request returns an error
 */
export const newBuilderElementRequestSlack = async (
	session: Session,
	email?: string,
	id?: string,
	elementName?: string,
	description?: string,
	exampleOutput?: string,
) => {
	try {
		if (!email || !id || !elementName || !description || !exampleOutput) {
			Sentry.captureMessage("Missing required parameters - newBuilderElementRequestSlack", {
				extra: {
					email,
					id,
					elementName,
					description,
					exampleOutput,
				},
				level: "error",
			});


			return { error: true };
		}

		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/slack/builder-element-request`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				accountId: id,
				email,
				elementName,
				description,
				exampleOutput
			}),
		});
		return response;
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

/*
 * Send a slack notification for a new misspelling correction
 * @param session - The current session
 * @param email - The email of the user
 * @param id - The id of the user
 * @param misspelling - The misspelled word
 * @param misspellingFix - The corrected spelling
 * @returns error: true if the request returns an error
 */
export const newMisspellingCorrectionSlack = async (
	session: Session,
	email?: string,
	id?: string,
	misspelling?: string,
	misspellingFix?: string,
) => {
	try {
		if (!email || !id || !misspelling || !misspellingFix) {
			Sentry.captureMessage("Missing required parameters - newMisspellingCorrectionSlack", {
				extra: {
					email,
					id,
					misspelling,
					misspellingFix,
				},
				level: "error",

			})

			return { error: true };
		}

		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/slack/misspelling-correction`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				accountId: id,
				email,
				misspelling,
				misspellingFix,
			}),
		});
		return response;
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

export const invalidateDocumentCache = async (documentId: string) => {
	try {

		// get session
		const {
			data: { session },
			error: supaSessionError,
		} = await supabase.auth.getSession();

		if (!session) {
			Sentry.captureMessage("Session not found - invalidateDocumentCache", {
				extra: { documentId },
				level: "error",
			});
			return;
		}

		if (!documentId) {
			Sentry.captureMessage("Missing required parameters documentId - invalidateDocumentCache");
			return { error: true };
		}

		if (supaSessionError) {
			Sentry.captureException(supaSessionError);
			return;
		}

		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/generate/invalidateCache`;

		await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				key: documentId,
			}),
		});
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};
