import { useSessionContext } from "@supabase/auth-helpers-react";
import { useEffect, createContext, useContext } from "react";
import { useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useSelector } from "react-redux";
import { RecordingState } from "@/common/utils/types";

const CacheBusterContext = createContext({ refreshApp: () => { } });

export const useCacheBuster = () => useContext(CacheBusterContext);

const CacheBuster = ({ children }: { children: React.ReactNode }) => {
	const { supabaseClient, session } = useSessionContext();
	const { globalRecordingState, isUploadingGlobal } = useSelector((state: any) => state.globalState);
	const location = useLocation();

	useEffect(() => {
		const abortController = new AbortController();

		if (!session) return;
		checkVersionUpdate(abortController.signal);

		// when effect finishes, call abort to cancel execution of other checkVersionUpdate calls in progress
		return () => abortController.abort();
	}, [location]);

	/**
	 * Check if the current version is the latest version
	 * @returns {Promise<void>}
	 */
	const checkVersionUpdate = async (signal: AbortSignal): Promise<void> => {
		const isRecording = globalRecordingState === RecordingState.recording || globalRecordingState === RecordingState.paused;

		const { data, error } = await supabaseClient.from("app_versions").select("id, version").abortSignal(signal).eq("id", 1).single();

		if (error?.code === '20') {
			// AbortError from a race condition - stop execution silently
			return;
		}

		if (error) {
			Sentry.captureException(error);
			return;
		}

		const latestVersion = data.version;
		const localVersion = localStorage.getItem("app_versions");
		const currentVersion = localVersion ? JSON.parse(localVersion).version : 0;

		if (!localVersion) {
			localStorage.setItem("app_versions", JSON.stringify({ id: data.id, version: latestVersion }));
			return;
		}

		// check if localVersion is out of date
		if (latestVersion.toString() !== currentVersion.toString()) {

			// make sure app is in a safe state to refresh
			if (!isRecording && !isUploadingGlobal) {

				// ensure that another checkVersionUpdate hasn't been called
				if (signal.aborted) {
					// Call aborted indicating a race condition - stop execution silently
					return;
				}

				refreshApp();
				localStorage.setItem("app_versions", JSON.stringify({ id: data.id, version: latestVersion }));
			}
		}
	};

	const refreshApp = () => {
		if (caches) {
			caches.keys().then((names) => {
				for (let name of names) caches.delete(name);
			});
		}

		window.location.reload();
	};

	return <CacheBusterContext.Provider value={{ refreshApp }}>{children}</CacheBusterContext.Provider>;
};

export default CacheBuster;
