import { Route } from "react-router-dom";
import PermissionWrapper from "./PermissionWrapper";
import AsyncComponent from "./AsyncComponent";
import {
	APPOINTMENTS_ROUTE,
	ENDPOINTS,
	INDIVDUAL_DOCUMENT_TYPE_ROUTE_ADVANCED,
	PAYMENT_CONFIRMATION_ROUTE,
} from "../common/utils/url-registry";
import List from "../features/list-view/PatientListView";
import UpdatePassword from "../features/auth/UpdatePassword";
import NotFoundPage from "../common/components/NotFound";
import PatientPage from "../features/patient-view/PatientView";
import PaymentConfirmation from "../features/payment/PaymentConfirmation";
import Payment from "../features/payment/Payment";
import CheckEmailPage from "../common/components/CheckEmail";

function AuthenticatedRoutes() {
	return (
		<>
			<Route
				index
				path="/"
				element={
					<PermissionWrapper>
						<List />
					</PermissionWrapper>
				}
			/>
			<Route index path={ENDPOINTS.SignUp} element={<CheckEmailPage />} />
			<Route
				path={PAYMENT_CONFIRMATION_ROUTE}
				element={
					<PermissionWrapper>
						<PaymentConfirmation />
					</PermissionWrapper>
				}
			/>
			<Route path={ENDPOINTS.Payment} element={<Payment />} />
			<Route
				path={ENDPOINTS.UpdatePassword}
				element={
					<PermissionWrapper>
						<UpdatePassword />
					</PermissionWrapper>
				}
			/>
			<Route
				path={ENDPOINTS.Calendar}
				element={
					<PermissionWrapper>
						<AsyncComponent loadComponent={() => import("../features/calendar/Calendar")} />
					</PermissionWrapper>
				}
			/>
			<Route
				path={APPOINTMENTS_ROUTE}
				element={
					<PermissionWrapper>
						<PatientPage />
					</PermissionWrapper>
				}
			/>
			<Route
				path={ENDPOINTS.DocumentBuilder}
				element={
					<PermissionWrapper>
						<AsyncComponent
							loadComponent={() => import("../features/document-types/document-builder/BuilderPage")}
						/>
					</PermissionWrapper>
				}
			/>
			<Route
				path={INDIVDUAL_DOCUMENT_TYPE_ROUTE_ADVANCED}
				element={
					<PermissionWrapper>
						<AsyncComponent
							loadComponent={() => import("../features/document-types/advanced-builder/IndividualDocType")}
						/>
					</PermissionWrapper>
				}
			/>
			<Route
				path={ENDPOINTS.Settings}
				element={
					<PermissionWrapper>
						<AsyncComponent loadComponent={() => import("../features/settings/Settings")} />
					</PermissionWrapper>
				}
			/>
			<Route path="*" element={<NotFoundPage />} />
		</>
	);
}

export default AuthenticatedRoutes;
