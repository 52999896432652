import { useState, useEffect, useMemo } from "react";
import Document from "./Document";
import useScroll from "@common/hooks/useScroll";
import { ChatCompletions, DocumentTab, DocumentType } from "@common/utils/types";
import { useSelector, useDispatch } from "react-redux";
import {
	getAllDocumentTabs,
	getActiveTabId,
	updateActiveTabAndDocumentIndex,
	addOrUpdateTabAndAddDocument,
	getActiveDocumentIndex,
	getActiveDocuments,
} from "../../state/redux/activeDocumentsSlice";
import { getPatientData, getMultiplePatients } from "../../state/redux/activePatientDataSlice";
import { insertChatCompletion } from "@/common/lib/supabaseClient";
import { useUser } from "@supabase/auth-helpers-react";
import { createTabId, determineChatHeaderAndPatientForMultiplePatientCase } from "@/common/utils/helpers";
import TableTabs from "@/common/components/HDTabs";

const DocumentTabs = ({
	setCompletionId,
	completionId,
	generateDocument,
	isLoadingCompletion,
	completion,
	stop,
}: {
	setCompletionId: (id: string | null) => void;
	completionId: string | null;
	generateDocument: (
		newAssistantMessage: ChatCompletions,
		tabId: string,
		multiplePatientCase: boolean,
		patientNameToParse: string,
	) => void;
	isLoadingCompletion: boolean;
	completion: any;
	stop: () => void;
}) => {
	const dispatch = useDispatch();
	const documentTabs = useSelector(getAllDocumentTabs);
	const activeTabId = useSelector(getActiveTabId);
	const patientData = useSelector(getPatientData);
	const currentDocumentIndex = useSelector(getActiveDocumentIndex);
	const activeDocument = useSelector(getActiveDocuments)[currentDocumentIndex];
	const multiplePatients = useSelector(getMultiplePatients);
	const scrollRef = useScroll();
	const user = useUser();
	const [showLeftGradient, setShowLeftGradient] = useState(false);
	const [showRightGradient, setShowRightGradient] = useState(true);

	const handleRetry = async (document: DocumentType, header: string) => {
		//(1) Determine if Multiple Patient Case
		let { multiplePatientCase, patientNameToParse } = determineChatHeaderAndPatientForMultiplePatientCase(
			"",
			multiplePatients,
			header,
		);

		//(1) Insert new chat message
		let newAssistantMessage = await insertChatCompletion({
			userId: user?.id,
			documentType: activeDocument?.document_types,
			completion: "",
			patientRecordId: patientData?.id,
			transcriptionId: patientData?.transcriptions?.id,
			header: header,
		})
		let tabId = createTabId(activeDocument?.document_types?.id!, header);

		//(2) Set Completion id
		setCompletionId(newAssistantMessage?.id || null);
		//(3) Add the new chat message to the active documents and update the index
		await dispatch<any>(addOrUpdateTabAndAddDocument({ tabId, document: newAssistantMessage }));

		//(4) Generate the document
		await generateDocument(newAssistantMessage, activeTabId, multiplePatientCase, patientNameToParse);

		setCompletionId(null);
	};

	//Adds horizontal scrolling effect to the tabs.
	useEffect(() => {
		const slider = scrollRef.current;
		if (!slider) return;

		const checkScroll = () => {
			setShowLeftGradient(slider.scrollLeft > 0);
			setShowRightGradient(slider.scrollLeft < slider.scrollWidth - slider.clientWidth);
		};
		checkScroll();

		slider.addEventListener("scroll", checkScroll);
		return () => {
			slider.removeEventListener("scroll", checkScroll);
		};
	}, [documentTabs]);

	//Handle the Tab Clicks
	const handleTab = (tabId: string) => {
		dispatch<any>(updateActiveTabAndDocumentIndex(tabId));
	};

	const activeTab = useMemo(() => {
		return documentTabs.find((tab) => tab?.tabId === activeTabId) as DocumentTab;
	}, [activeTabId, documentTabs]);

	const tabs = documentTabs.map((tab) => {
		return {
			id: tab.tabId,
			header: tab.header,
			isActive: tab.tabId === activeTabId,
		};
	}
	);

	return (
		<div className={`bg-white border-b border-gray-200 bg-gray-50 shadow ring-1 ring-gray-900 ring-opacity-5 rounded-lg ${documentTabs?.length === 0 && "h-full"}`}>
			{documentTabs?.length > 0 ? (
				<div className="">
					<div
						className={`px-6 pt-2 gradient-tabs ${showLeftGradient ? "show-left-gradient" : ""} ${showRightGradient ? "show-right-gradient" : ""}`}
					>
						<div ref={scrollRef} className="flex w-full overflow-x-auto scrollbar-hover">
							<TableTabs tabs={tabs} changeTab={handleTab} />
						</div>
					</div>
					{activeTabId && (
						<Document
							key={activeTabId}
							activeTabId={activeTabId}
							tab={activeTab}
							isLoadingCompletion={isLoadingCompletion}
							completionId={completionId}
							completion={completion}
							handleRetry={handleRetry}
							patientData={patientData}
							stop={stop}
						/>
					)}
				</div>
			) : (
				<div className="flex flex-col justify-center items-center w-full h-full px-6 py-6">
					<svg
						className="mx-auto h-12 w-12 text-gray-400"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						aria-hidden="true"
					>
						<path
							vectorEffect="non-scaling-stroke"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
						/>
					</svg>
					<p className="mt-2 text-sm">No Documents</p>
					<p className="mt-1 text-sm text-gray-500">Get started by selecting a document and then clicking "Generate"</p>
				</div>
			)}
		</div>
	);
};

export default DocumentTabs;
