import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import { useState } from "react";
import { ErrorType, TranscriptSection, FeatureFlags } from "@common/utils/types";
import ConfirmModal from "@common/components/ConfirmModal";
import AudioPlayer from "@common/components/AudioPlayer";
import TranscriptionModalButton from "../transcription-modal/TranscriptionModalButton";
import TranscriptionModal from "../transcription-modal/TranscriptionModal";
import { useSelector } from "react-redux";
import { useAccountContext } from "@providers/AccountProvider";
import { getPatientData, getTranscriptSections } from "@features/patient-view/state/redux/activePatientDataSlice";
import ThreeDotRecordingMenu from "./ThreeDotRecordingMenu";
import AlertIcon from "@icons/alert-triangle.svg?react";
import ChevronRightIcon from "@icons/chevron-right.svg?react";

const Recordings = () => {
	const { containsFeatureFlag } = useAccountContext();

	const [recordingToDelete, setRecordingToDelete] = useState<any>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [openTranscriptModal, setOpenTranscriptModal] = useState(false);

	const patientData = useSelector(getPatientData);
	const transcriptId = useSelector(getPatientData)?.transcriptions?.id;
	const transcriptSections = useSelector(getTranscriptSections);

	return (
		<div className={classNames(containsFeatureFlag(FeatureFlags.HIDE_TRANSCRIPTIONS) ? "hidden" : "")}>
			<Disclosure defaultOpen={window?.innerWidth > 770 ? true : false}>
				{({ open }) => (
					<>
						<div className="border-b border-gray-200 bg-white px-4 py-4 sm:px-6 shadow ring-1 ring-gray-900 ring-opacity-5 rounded-lg">
							{transcriptSections &&
								(transcriptSections.some(
									(section: { data: any }) =>
										section?.data === "loading" || section?.data === "error" || section?.data === "",
								) ||
									transcriptSections.reduce(
										(totalLength: any, section: { data: any }) => totalLength + (section?.data?.length || 0),
										0,
									) < 150) && (
									<div className="flex flex-row items-center gap-x-1 mb-2">
										<AlertIcon className="h-5 w-5 text-amber-300 opacity-50" />
										<span className="text-[10px] opacity-80 text-gray-900">
											{" "}
											Transcript contains loading, error, or short output.
										</span>
									</div>
								)}

							<div className="mb-2">
								{transcriptId && <TranscriptionModalButton setOpenTranscriptModal={setOpenTranscriptModal} />}
							</div>
							{/* Header for list of recordings */}
							<div className="hidden md:block">
								<div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
									<div className="ml-4 mt-2">
										<Disclosure.Button className={"flex items-center p-0 m-0"}>
											<ChevronRightIcon className={classNames(open ? "rotate-90" : "", "h-5 w-5 mr-1")} />

											<h1 className="text-lg lg:text-xl whitespace-nowrap">
												Recordings
												<span className="ml-1 font-light text-gray-700">{`(${transcriptSections?.length || 0})`}</span>
											</h1>
										</Disclosure.Button>
									</div>
								</div>
								<Disclosure.Panel>
									{transcriptSections?.length > 0 && (
										<ul role="list" className="divide-y divide-gray-200">
											{transcriptSections
												?.filter((section: TranscriptSection) => section.error !== ErrorType.UPLOAD_ERROR)
												.map((item: { id: any }, index: any) => (
													<li key={item.id || index} className="flex flex-col pt-2 pb-2">
														<div className="flex items-center justify-between w-full">
															<AudioPlayer transcriptSection={{ ...item, transcription_id: transcriptId }} />
															<ThreeDotRecordingMenu
																patientId={patientData.id}
																transcriptSection={{ ...item, transcription_id: transcriptId }}
															/>
														</div>
													</li>
												))}
										</ul>
									)}
								</Disclosure.Panel>
							</div>
						</div>
					</>
				)}
			</Disclosure>
			<div className="" data-theme="happydoc">
				{transcriptId && (
					<TranscriptionModal
						patientRecordId={patientData?.id}
						transcriptionId={transcriptId}
						transcriptSections={transcriptSections}
						isOpen={openTranscriptModal}
						setIsOpen={setOpenTranscriptModal}
					/>
				)}
				<ConfirmModal
					id="DeleteRecording"
					headerText="Are you sure you want to delete this recording?"
					cancelButtonText="Cancel"
					confirmButtonText="Delete"
					confirmButtonColor="bg-red-600"
					confirmHoverButtonColor="bg-red-500"
					isOpen={isModalOpen}
					onCancel={() => {
						setIsModalOpen(false);
					}}
					onConfirm={() => {
						if (recordingToDelete?.sectionId && recordingToDelete?.transcriptId) {
							setRecordingToDelete(null);
							setIsModalOpen(false);
						}
					}}
				/>
			</div>
		</div>
	);
};

export default Recordings;
