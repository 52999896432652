import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import { useDataContextApi } from "@providers/DataProvider";
import { PatientRecordTableRow, RecordingState } from "@common/utils/types";
import AudioLevels from "@common/components/AudioLevels";
import DocumentSelect from "@common/components/DocumentSelect";
import Timer from "@common/components/Timer";
import { isRecordingError } from "@common/hooks/useAudioRecorder";
import HelpDocumentation from "@common/components/HelpDocumentation";
import Spinner from "@common/components/Spinner";
import useFileUpload from "@common/hooks/useFileUpload";
import UploadProgressBar from "@common/components/UploadProgressBar";
import usePatientState from "../../state/usePatientState";
import { useEffect } from "react";
import { transformTableRecordToPatientRecord } from "@common/utils/helpers";
import { insertPatientRecord } from "@common/lib/supabaseClient";
import { useAudioRecordingProvider } from "../../state/recordingProvider";
import { useSelector } from "react-redux";
import { useRealtimeContextApi } from "@providers/RealtimeProvider";
import MicrophoneIcon from "@icons/microphone-01.svg?react";
import PlayIcon from '@icons/play-circle.svg?react';
import PauseIcon from '@icons/pause-circle.svg?react';
import StopIcon from '@icons/stop-circle.svg?react';
import InformationCircleIcon from '@icons/info-circle.svg?react';
import * as Sentry from "@sentry/react";

const QuickStart = () => {
	const isDisabled = (rState: String) => {
		return rState == "recording" || rState == "paused";
	};
	const {
		recorderControls: {
			startRecording,
			stopRecording,
			togglePauseResume,
			recordingState,
			recordingTime,
			audioStream,
			isQuickStart,
			setIsQuickStart,
			resetRecorder,
		},
	} = useAudioRecordingProvider();
	const {
		patientName,
		setPatientName,
		clearFields,
		createNewPatientTableRecord,
		setScheduledAtDay,
		formatDate,
		setLocalSelectedDoctor,
	} = usePatientState();
	const { supaClientRef } = useRealtimeContextApi();
	const { selectedDoctor } = useSelector((state: any) => state.doctors);
	const { selectedDate } = useDataContextApi();

	useEffect(() => {
		if (selectedDoctor) {
			setLocalSelectedDoctor(selectedDoctor);
		} else {
			setLocalSelectedDoctor(null);
		}
	}, [selectedDoctor]);

	useEffect(() => {
		//Set scheduleAtTime to match date
		if (selectedDate?.startDate) {
			setScheduledAtDay(formatDate(selectedDate.startDate));
		}
	}, [selectedDate]);

	const { uploadAudio, uploadProgress, isUploading } = useFileUpload();

	const onAudioDataAvailable = async (
		audioFile: File,
		patientRecord: PatientRecordTableRow,
		transcriptSectionId: string,
	) => {
		try {
			if (!patientRecord.transcriptions?.id || !patientRecord.id) {
				throw new Error("patientRecord is undefined");
			}
			// upload audio
			const uploadResponse = await uploadAudio(
				audioFile,
				audioFile.name,
				patientRecord.transcriptions.id,
				transcriptSectionId,
				patientRecord,
				true,
			);

			// update transcript section for table
			if (uploadResponse?.err) {
				throw new Error(uploadResponse?.err);
			}
		} catch (error) {
			Sentry.captureException(error);
		}
	};

	//Type this
	const createPatientTableRecordQuickStart = async () => {
		let newPatientRecord: PatientRecordTableRow = createNewPatientTableRecord(true);
		let pRecord = transformTableRecordToPatientRecord(newPatientRecord);
		await insertPatientRecord(pRecord, newPatientRecord.transcriptions?.id);
		return newPatientRecord;
	};

	return (
		<Disclosure defaultOpen={true}>
			{({ open }) => (
				<div className="w-full bg-white bg-gray-50 shadow-sm ring-1 ring-gray-300  rounded-md">
					<div className="p-4 border-b bg-gray-50 rounded-t-md  ">
						<h1 className="relative">
							Quick Start

							<div className="absolute hidden md:inline-block top-0 z-30">
								<HelpDocumentation
									link="https://help.gohappydoc.com/en/articles/8857766-success-with-quick-start"
									isDisabled={recordingState === "recording" || recordingState === "paused"}
									displayText="Quick Start Help"
								/>
							</div>
						</h1>
					</div>
					<div className="bg-white py-2 px-6 shadow-sm  ring-gray-300 rounded-md">
						<Disclosure.Panel>
							<form className="flex flex-row items-center w-full p-2 " onSubmit={(e) => e.preventDefault()}>
								<div className="w-1/2 h-full flex flex-col items-center justify-start gap-y-3 relative mr-4">
									{recordingState === RecordingState.loading && isQuickStart ? (
										<Spinner />
									) : isUploading && recordingState !== RecordingState.recording ? (
										<UploadProgressBar percentage={uploadProgress} />
									) : recordingState === RecordingState.inactive ||
										isRecordingError(recordingState) ||
										!isQuickStart ? (
										<button
											id="quick-start-button"
											disabled={isDisabled(recordingState)}
											type="button"
											className={classNames(
												isRecordingError(recordingState)
													? `bg-red-600 hover:bg-red-500 focus-visible:outline-red-600`
													: `bg-max-700  hover:bg-max-800  focus-visible:outline-bg-max-700 `,
												`w-20 h-20 sm:h-24 sm:w-24 md:h-32 md:w-32 sm:h-35 rounded-full flex flex-col items-center justify-center text-xs font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:animate-pulse z-10 cursor-pointer`,
											)}
											onClick={async (e) => {
												e.preventDefault();

												if (isRecordingError(recordingState)) {
													resetRecorder();
													return;
												}

												let newPatientRecord = await createPatientTableRecordQuickStart();
												startRecording(
													newPatientRecord,
													(audioFile: File, patientRecord: PatientRecordTableRow, transcriptSectionId: string) =>
														onAudioDataAvailable(audioFile, patientRecord, transcriptSectionId),
													supaClientRef.current as any,
												);
												setIsQuickStart(true);
											}}
										>
											<MicrophoneIcon style={{ color: 'white' }} className="h-[48px] w-[48px]  mb-1" />
											<span className="text-[10px] sm:text-xs md:text-xs">
												{recordingState === RecordingState.no_permission
													? "Microphone Error! Enable Permissions"
													: recordingState === RecordingState.no_device
														? "Microphone Error! No Device Found"
														: recordingState === RecordingState.error
															? "Microphone Error!"
															: ""}
											</span>
										</button>
									) : (
										(recordingState === "recording" || recordingState === "paused") &&
										isQuickStart && (
											<div className="w-full flex flex-row items-center justify-evenly gap-x-6">
												<button
													id="quick-start-stop-button"
													type="button"
													className="bg-red-500 focus-visible:outline-red-600
													w-16 h-16 sm:h-24 sm:w-24 md:h-28 md:w-28 rounded-full flex flex-col items-center justify-center text-xs font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 z-10 cursor-pointer"
													onClick={(e) => {
														e.preventDefault();
														stopRecording();

														setIsQuickStart(false);
														clearFields();
													}}
												>
													<StopIcon className="h-7 w-7 sm:h-10 sm:w-10 text-white mb-1" />
													<span className="hidden md:inline-block sm:text-xs md:text-sm">
														{(recordingState === "recording" || recordingState === "paused") && "Stop"}
													</span>
												</button>
												<button
													id="quick-start-pause-button"
													type="button"
													className="bg-max-600  hover:bg-max-800  focus-visible:outline-bg-max-700 
													w-16 h-16 sm:h-24 sm:w-24 md:h-28 md:w-28 rounded-full flex flex-col items-center justify-center text-xs font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 z-10 cursor-pointer"
													onClick={() => togglePauseResume()}
												>
													{recordingState === "recording" && (
														<>
															<PauseIcon className="h-7 w-7 sm:h-10 sm:w-10 text-white mb-1" />
															<span className="hidden md:inline-block sm:text-xs md:text-sm">Pause</span>
														</>
													)}
													{recordingState === "paused" && (
														<>
															<PlayIcon className="h-7 w-7 sm:h-10 sm:w-10 text-white mb-1" />
															<span className="hidden md:inline-block text-xs md:text-sm whitespace-normal">
																Resume
															</span>
														</>
													)}
												</button>
											</div>
										)
									)}
									{(recordingState === "recording" || recordingState === "paused") && isQuickStart && (
										<>
											<div className="absolute inset-0 z-0">
												<div className="flex h-full items-center justify-center">
													<AudioLevels
														darkColor="#26B5BB"
														brightColor="#26B5BB"
														barSpacing={1}
														numBars={64}
														recordingState={recordingState}
														audioStream={audioStream}
													/>
												</div>
											</div>
											<Timer
												recordingState={recordingState}
												time={recordingTime}
												topDivClassName="absolute bottom-[-1.5rem] left-0 z-10 items-center justify-center w-full text-sm md:text-lg text-gray-400 flex flex-col"
											/>
										</>
									)}
								</div>
								<div className="w-1/2 h-full flex flex-col items-center justify-between gap-y-3 relative">
									<div className="w-full">
										<label
											htmlFor="patient-name"
											className={classNames(
												isDisabled(recordingState) ? "text-gray-400" : "text-gray-900",
												"block text-xs font-medium leading-tight ",
											)}
										>
											Patient Name(s)
										</label>
										<div id="quick-start-patient-name" className="mt-0.5">
											<input
												id="quick-start-patient-name"
												type="text"
												name="patient-name"
												placeholder="Say the patient's name..."
												value={patientName}
												onChange={(e) => {
													setPatientName(e.target.value);
												}}
												disabled={isDisabled(recordingState)}
												className={classNames(
													isDisabled(recordingState) ? "text-gray-400" : "text-gray-900  focus:ring-max-700 ",
													"block w-full rounded-md border-0 py-1.5 px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset text-xs leading-tight sm:text-sm sm:leading-6",
												)}
											/>
											{
												<div className="flex flex-col lg:flex-row lg:gap-x-1 lg:justify-start">
													{!patientName && (
														<div className="flex flex-row items-center justify-start gap-x-0.5 mt-1">
															<InformationCircleIcon className="h-[13px] w-[13px] text-gray-900 opacity-30 flex-shrink-0" />
															<span className="text-[10px] opacity-80">AI Auto Add Patient Name(s)</span>
														</div>
													)}
													{patientName.toLowerCase().split(/(\band\b)/i).length >= 2 ? (
														<div className="flex flex-row items-center justify-start gap-x-0.5 mt-1 text-max-700 ">
															<InformationCircleIcon className="h-[13px] w-[13px] text-max-700  opacity-30" />
															<span className="text-[10px] opacity-80"> Multiple Patients (beta)</span>
														</div>
													) : (
														<div className="flex flex-row items-center justify-start gap-x-0.5 mt-1 whitespace-normal">
															<InformationCircleIcon className="h-[13px] w-[13px] text-gray-900 opacity-30 flex-shrink-0" />
															<span className="text-[10px] opacity-80"> Multiple Patients: "Stormy and Allen"</span>
														</div>
													)}
												</div>
											}
										</div>
									</div>

									<div className="w-full">
										<label
											className={classNames(
												isDisabled(recordingState) ? "text-gray-400" : "text-gray-900",
												"block text-xs font-medium leading-tight ",
											)}
										>
											Generate On Stop
										</label>
										<div className="mt-0.5 w-full ">
											<DocumentSelect from="quick-start" />
										</div>
									</div>
								</div>
							</form>
						</Disclosure.Panel>
					</div>
				</div>
			)}
		</Disclosure>
	);
};
export default QuickStart;
