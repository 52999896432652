import { Disclosure } from "@headlessui/react";
import CircleIcon from "@icons/circle.svg?react";
import CheckCircleIcon from "@icons/check-circle.svg?react";
import classNames from "classnames";
import { useState, useEffect, useCallback } from "react";
import DocumentSelect from "@common/components/DocumentSelect";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { createTabId } from "@common/utils/helpers";
import {
	getTranscriptSections,
	getMultiplePatients,
	setSelectedMultiPatient,
	getPatientData,
} from "../../state/redux/activePatientDataSlice";
import { addOrUpdateTabAndAddDocument } from "../../state/redux/activeDocumentsSlice";
import { ChatCompletions } from "@common/utils/types";
import { useUser } from "@supabase/auth-helpers-react";
import { insertChatCompletion, getSingleSupabaseDocumentType } from "@/common/lib/supabaseClient";
import ChevronRightIcon from "@icons/chevron-right.svg?react";
import FilePlusIcon from "@icons/file-plus-02.svg?react";
import InfoCircleIcon from "@icons/info-circle.svg?react";

import { MultiPatientData } from "@common/utils/types";
import _ from "lodash";
import { determineChatHeaderAndPatientForMultiplePatientCase } from "@/common/utils/helpers";
import { documentTypeRemoved, removeSelectedDocument } from "@/state/redux/documentSlice";
import ConfirmModal from "@/common/components/ConfirmModal";
import { Tooltip } from "react-tooltip";


const GenerateDocuments = ({
	setCompletionId,
	generateDocument,
	isLoadingCompletion,
}: {
	setCompletionId: (id: string | null) => void;
	completionId: string | null;
	generateDocument: (
		newAssistantMessage: ChatCompletions,
		tabId: string,
		multiplePatientCase: boolean,
		patientNameToParse: string,
	) => void;
	isLoadingCompletion: boolean;
}) => {
	const dispatch = useDispatch();
	const { selectedDocuments } = useSelector((state: any) => state.documents);
	const transcriptSections = useSelector(getTranscriptSections);
	const multiplePatients: MultiPatientData[] = useSelector(getMultiplePatients);
	const patientData = useSelector(getPatientData);
	const user = useUser();
	const [showHasArchivedModal, setShowHasArchivedModal] = useState(false);
	const [unUseableTranscript, setIsUnusableTranscript] = useState(false);

	useEffect(() => {
		if (Array.isArray(transcriptSections)) {
			const isUnusable = transcriptSections.every((section) => ["loading", "", "error"].includes(section?.data));
			if (isUnusable) {
				setIsUnusableTranscript(true);
			} else {
				setIsUnusableTranscript(false);
			}
		}
	}, [transcriptSections]);

	const handleGenerateDocuments = useCallback(async () => {
		//Check to see if TabId exists, if not add.
		const abortController = new AbortController();

		if (selectedDocuments?.length > 0) {
			if (multiplePatients?.length > 1) {
				for (let patient of _.filter(multiplePatients, { isSelected: true })) {
					for (let document of selectedDocuments) {
						document = await generateDocumentForPatient(document, patient.name);
					}
				}
			}
			else {
				for (let document of selectedDocuments) {
					document = await generateDocumentForPatient(document, patientData?.patient_name || "");
				}
			}
		} else {
			toast.warn("Please select more than one document to generate");
			return;
		}

		/**
		 * Generates a document for a patient.
		 * 
		 * @param document - The document to generate.
		 * @param patientName - The name of the patient.
		 * @returns The generated document.
		 */
		async function generateDocumentForPatient(document: any, patientName: string) {
			//HDD-594: always go get the most recent document definition from the DB before generating a document
			document = await getSingleSupabaseDocumentType(document?.id, abortController.signal);

			// check if the document is archived and if so, do not generate a new document and display a modal
			if (document?.is_archived) {

				// remove the document from the selected documents
				dispatch(removeSelectedDocument(document?.id));
				dispatch(documentTypeRemoved(document?.id));

				// show the modal
				setShowHasArchivedModal(true);

				return document;
			}

			//(1) Create new assistant message and tab Id
			let { header: newHeader, multiplePatientCase, patientNameToParse } =
				determineChatHeaderAndPatientForMultiplePatientCase(
					patientName,
					multiplePatients,
					document?.document_type_name
				);

			let newAssistantMessage = await insertChatCompletion({
				userId: user?.id,
				documentType: document,
				completion: "",
				patientRecordId: patientData?.id,
				transcriptionId: patientData?.transcriptions?.id,
				header: newHeader,
			});

			let tabId = createTabId(document?.id, newHeader);
			//(2) Update Tab State
			await dispatch<any>(addOrUpdateTabAndAddDocument({ tabId, document: newAssistantMessage }));
			//(3) Set Completion Id
			setCompletionId(newAssistantMessage?.id || null);

			// this await is needed to ensure that the document is generated before the next one is generated
			await generateDocument(newAssistantMessage, tabId, multiplePatientCase, patientNameToParse);
			return document;
		}
	}, [
		selectedDocuments,
		dispatch,
		generateDocument,
		multiplePatients,
		patientData,
		setCompletionId,
	]);

	/**
	 * Determines whether the generate button should be disabled.
	 * The button is disabled if any of the following conditions are met:
	 * - No documents are selected
	 * - The completion is currently loading
	 * - There are no transcript sections
	 * - The transcript is unusable
	 * - There are multiple patient but all of them are unchecked
	 */
	const generateDisabled = selectedDocuments?.length < 1 ||
		isLoadingCompletion ||
		transcriptSections?.length < 1 ||
		unUseableTranscript ||
		(multiplePatients?.length > 0 && _.countBy(multiplePatients, "isSelected").false == multiplePatients.length);

	return (
		<Disclosure defaultOpen={true}>
			{({ open }) => (
				<>
					<div className="border-b border-gray-200 bg-white px-4 py-4 sm:px-6 shadow ring-1 ring-gray-900 ring-opacity-5 rounded-lg">
						<div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
							<div className="ml-4 mt-2">
								<Disclosure.Button className={"flex items-center p-0 m-0"}>
									<ChevronRightIcon className={classNames(open ? "rotate-90" : "", "h-5 w-5 mr-1")} />
									<h1
										className={classNames(
											transcriptSections?.length < 1 || unUseableTranscript ? "text-gray-400" : "text-gray-900",
											"text-lg lg:text-xl whitespace-nowrap",
										)}
									>
										Generate Documents
									</h1>
								</Disclosure.Button>
							</div>
						</div>
						<Disclosure.Panel>
							<div className="mt-1">
								<DocumentSelect
									from="patient-page"
									disabled={isLoadingCompletion || transcriptSections?.length < 1 || unUseableTranscript}
									verticalLayout={true}
								/>
							</div>
							<ConfirmModal
								id="archived-document-modal"
								onExit={() => setShowHasArchivedModal(false)}
								headerText="Document does not exist"
								contentText="The selected document no longer exists. Select a different document."
								isOpen={showHasArchivedModal}
								onCancel={() => setShowHasArchivedModal(false)}
								onConfirm={() => setShowHasArchivedModal(false)}
								confirmButtonText="OK"
								cancelButtonText="Cancel"
								confirmButtonColor="bg-max-700"
								confirmHoverButtonColor="hover:bg-max-800"
							/>

							{multiplePatients?.length > 0 && (
								<div className="flex flex-col gap-y-2 mt-2 ">
									<hr className="mt-2" />

									<div className="text-sm flex flex-row items-center justify-between">
										<div className="whitespace-break-spaces relative flex items-center">
											<div className="group flex items-center gap-2">
												Generate for:
												<span>
													<Tooltip style={{ maxWidth: '200px' }} anchorSelect="#generate-for" content="Document(s) selected above will generate for the chosen patient(s)." />
													<span className="text-gray-400">
														<InfoCircleIcon id="generate-for" className={`h-[16px] w-[16px] mr-1 `} />
													</span>
												</span>
											</div>
										</div>
									</div>

									<div className="flex flex-row gap-x-4 gap-y-4 justify-start items-center flex-wrap">
										{multiplePatients.map((patient: MultiPatientData, index: number) => (
											<button
												id={`7d83073d-53a2-4278-9a52-4a63bd9712cd-${index}-${patient.name}`}
												key={`${index}-${patient}`}
												className={classNames(
													(patient.isSelected)
														? "ring-max-700"
														: "text-gray-900 ring-gray-300",
													"bg-white rounded-md  px-2 py-2 text-sm font-semibold hover:cursor-pointer shadow-sm ring-1 ring-inset ",
												)}
												onClick={() => { dispatch(setSelectedMultiPatient(patient)) }}
											>
												<span>
													{(patient.isSelected
														? <CheckCircleIcon className="h-4 w-4 mr-1 pb-1 inline text-max-700" />
														: <CircleIcon className="h-4 w-4 mr-1 pb-1 inline text-gray-400" />)}
													{patient.name}
												</span>
											</button>
										))}
									</div>
								</div>
							)}

							<div className="flex w-full justify-end mt-3 mb-2">
								<button
									id="generate-documents"
									type="button"
									className={classNames(
										generateDisabled
											? "text-gray-400 bg-max-200"
											: "text-white bg-max-700 hover:bg-max-800 ",
										"flex items-center justify-center w-full rounded-lg px-3.5 py-1.5 text-[14px] font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bg-max-700 ",
									)}
									disabled={generateDisabled}
									onClick={(e) => {
										e.preventDefault();
										if (
											selectedDocuments?.length < 1 ||
											isLoadingCompletion ||
											transcriptSections?.length < 1 ||
											unUseableTranscript
										) {
											toast.warn("Transcript is loading or blank! Try clicking, Open Transcript");
											return;
										}
										handleGenerateDocuments();
									}}
								>
									<FilePlusIcon className="h-5 w-5 mr-1" />
									Generate
								</button>
							</div>
						</Disclosure.Panel>
					</div>
				</>
			)}
		</Disclosure>
	);
};

export default GenerateDocuments;
