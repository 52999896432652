import { getSupabaseDocumentTypes, toggleDocumentSupabase, getDocumentTemplates, fetchAccountData } from "@common/lib/supabaseClient";
import { DocumentType } from "@common/utils/types";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

const getLocalDocumentTypes = () => {
	const docs = localStorage.getItem("selectedDocuments");
	try {
		if (docs) {
			return JSON.parse(docs);
		}
		return [];
	} catch (e) {
		Sentry.captureException(e);
		return [];
	}
};

const initialState: {
	documentTypes: DocumentType[];
	documentTemplates: DocumentType[];
	selectedDocuments: DocumentType[];
	hiddenDocuments: DocumentType[],
	status: "idle" | "loading" | "succeeded" | "failed";
	error: string | null;
} = {
	documentTypes: [],
	documentTemplates: [],
	hiddenDocuments: [],
	selectedDocuments: getLocalDocumentTypes() || [],
	status: "idle",
	error: null,
};

export const fetchDocumentTypes = createAsyncThunk(
	"document/fetchDocumentTypes",
	async ({ accountID, hiddenDocIDs, signal }: { accountID: string; hiddenDocIDs: string[], signal: AbortSignal }, { rejectWithValue }) => {
		try {
			const documentTypes = await getSupabaseDocumentTypes(signal, hiddenDocIDs, accountID);
			return documentTypes;
		} catch (error: any) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const fetchDocumentTemplates = createAsyncThunk(
	"document/fetchDocumentTemplates",
	async ({ accountID, hiddenDocIDs, signal }: { accountID: string; hiddenDocIDs: string[], signal: AbortSignal }, { rejectWithValue }) => {
		try {
			const documentTemplates = await getDocumentTemplates(signal, hiddenDocIDs, accountID);
			return documentTemplates;
		} catch (error: any) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const fetchHiddenDocuments = createAsyncThunk(
	"document/fetchHiddenDocuments",
	async ({ signal }: { signal: AbortSignal }, { rejectWithValue }) => {

		try {
			const hiddenDocuments = await fetchAccountData(signal).then((data) => data.hidden_documents);
			return hiddenDocuments;
		} catch (error: any) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const fetchAllDocuments = createAsyncThunk(
	"document/fetchAllDocuments",
	async ({ accountID, signal }: { accountID: string; signal: AbortSignal }, { rejectWithValue, dispatch }) => {
		try {
			const hiddenDocuments = await dispatch(fetchHiddenDocuments({ signal }) as any).unwrap();
			const documentTemplates = await dispatch(fetchDocumentTemplates({ accountID, hiddenDocIDs: hiddenDocuments, signal }) as any).unwrap();
			const documentTypes = await dispatch(fetchDocumentTypes({ accountID, hiddenDocIDs: hiddenDocuments, signal }) as any).unwrap();

			return { hiddenDocuments, documentTemplates, documentTypes };
		} catch (error: any) {
			return rejectWithValue(error.response?.data || error.message);
		}
	}
);

export const toggleDocumentInclude = createAsyncThunk(
	"document/toggleDocumentInclude",
	async ({ docTypeId, isActive }: { docTypeId: string; isActive: boolean }, { dispatch, rejectWithValue }) => {
		try {
			const error = await toggleDocumentSupabase(docTypeId, isActive);
			if (error) throw error;

			dispatch(documentIncludeToggled({ docTypeId, isActive }));
		} catch (error: any) {
			return rejectWithValue(error.response.data);
		}
	},
);

const documentSlice = createSlice({
	name: "document",
	initialState,
	reducers: {
		documentTypeAdded(state, action) {
			const doc = state.documentTypes.find((doc) => doc.id === action.payload.id);
			if (!doc) state.documentTypes.push(action.payload);
		},
		documentTypeRemoved(state, action) {
			state.documentTypes = state.documentTypes.filter((doc) => doc.id !== action.payload);
		},
		documentIncludeToggled(state, action) {
			const { docTypeId, isActive } = action.payload;

			// update document types
			state.documentTypes = state.documentTypes.map((doc) =>
				doc.id === docTypeId ? { ...doc, is_active: isActive } : doc,
			);

			// remove from selected documents
			if (!isActive) {
				state.selectedDocuments = state.selectedDocuments.filter((doc) => doc.id !== docTypeId);
				localStorage.setItem("selectedDocuments", JSON.stringify(state.selectedDocuments));
			}
		},
		sharedDocumentHiddenToggled(state, action) {
			const { docTypeId } = action.payload;

			// check if the document type ID is already in hiddenDocuments
			const isHidden = state.hiddenDocuments.some((doc) => doc === docTypeId);

			if (isHidden) {
				// if it's hidden, remove it from hiddenDocuments
				state.hiddenDocuments = state.hiddenDocuments.filter((doc) => doc !== docTypeId);

			} else {
				// otherwise, add it to hiddenDocuments
				state.hiddenDocuments.push(docTypeId);

				// update state and local storage of selected docs
				state.selectedDocuments = state.selectedDocuments.filter((doc) => doc.id !== docTypeId);
				localStorage.setItem("selectedDocuments", JSON.stringify(state.selectedDocuments));
			}
		},
		updateDocument: (state, action) => {
			const { id, ...rest } = action.payload;
			// update in documentTypes
			state.documentTypes = state.documentTypes.map((doc) => (doc.id === id ? { ...doc, ...rest } : doc));
			// update in selectedDocuments if it exists there
			state.selectedDocuments = state.selectedDocuments.map((doc) => (doc.id === id ? { ...doc, ...rest } : doc));

			// update local storage with new selectedDocuments
			localStorage.setItem("selectedDocuments", JSON.stringify(state.selectedDocuments));
		},
		documentSelected(state, action) {
			state.selectedDocuments = action.payload;

			localStorage.setItem("selectedDocuments", JSON.stringify(action.payload));
		},
		removeSelectedDocument(state, action) {
			state.selectedDocuments = state.selectedDocuments.filter((doc) => doc.id !== action.payload);

			localStorage.setItem("selectedDocuments", JSON.stringify(state.selectedDocuments));
		},
		clearDocumentState(state) {
			state.documentTypes = [];
			state.selectedDocuments = [];
			state.documentTemplates = [];
			state.hiddenDocuments = [];
			localStorage.removeItem("selectedDocuments");
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchDocumentTypes.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchDocumentTypes.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.documentTypes = action.payload;
			})
			.addCase(fetchDocumentTypes.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error?.message || null;
			})
			.addCase(fetchDocumentTemplates.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchDocumentTemplates.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.documentTemplates = action.payload; // Update the documentTemplates state with the fetched data
			})
			.addCase(fetchDocumentTemplates.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error?.message || null;
			})
			.addCase(fetchHiddenDocuments.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchHiddenDocuments.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.hiddenDocuments = action.payload; // Update the documentTemplates state with the fetched data
			})
			.addCase(fetchHiddenDocuments.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error?.message || null;
			})
			.addCase(fetchAllDocuments.fulfilled, (state, action) => {
				state.hiddenDocuments = action.payload.hiddenDocuments;
				state.documentTemplates = action.payload.documentTemplates;
				state.documentTypes = action.payload.documentTypes;

				// Filter out archived selected documents
				state.selectedDocuments = state.selectedDocuments.filter(doc =>
					state.documentTypes.some(type => type.id === doc.id)
				);

				// Filter out in active documents
				state.selectedDocuments = state.selectedDocuments.filter(doc =>
					state.documentTypes.some(type => type.id === doc.id && type.is_active)
				);

				// Update local storage with new selectedDocuments
				localStorage.setItem("selectedDocuments", JSON.stringify(state.selectedDocuments));
			})
	},
});

export const {
	documentTypeAdded,
	documentIncludeToggled,
	sharedDocumentHiddenToggled,
	documentSelected,
	documentTypeRemoved,
	removeSelectedDocument,
	updateDocument,
	clearDocumentState,
} = documentSlice.actions;

export default documentSlice.reducer;
