import { AuthError } from "@supabase/supabase-js";
import * as tus from "tus-js-client";
import AudioDb, { INDEX_DB_VERSION } from "../classes/audioDb";
import { useState } from "react";
import { DocumentType, ErrorType, PatientRecordTableRow, TranscriptSection } from "../utils/types";
import { getDocumentTypesByIdsList, insertChatCompletion, upsertTranscriptSection } from "../lib/supabaseClient";
import { toast } from "react-toastify";
import { transcribeAudio } from "../lib/api";
import { useDispatch } from "react-redux";
import { isFileSizeValid } from "./useAudioRecorder";
import { addOrUpdateRecord, updateTableRecord } from "@redux/patientTableRecordsSlice";
import { cloneDeep } from "lodash";
import { useRealtimeContextApi } from "@providers/RealtimeProvider";
import { supabase } from "../lib/supabaseClient";
import { VITE_PUBLIC_SUPABASE_URL } from "@common/utils/constants";
import { documentTypeRemoved, removeSelectedDocument } from "@/state/redux/documentSlice";
import * as Sentry from "@sentry/react";
import { setIsUploadingGlobal } from "@/state/redux/globalState";

/**
 * @param `updateOrAddTranscriptSections`: The function to update or add the transcript section unfortunately
 * we need this to update the transcription line if the upload fails
 * @returns `uploadAudio`: The function to upload the audio file
 * @returns `uploadProgress`: The progress of the upload
 * @returns `isUploading`: The state of the upload
 * @description A hook to handle the file upload process
 */
function useFileUpload(
	updateOrAddTranscriptSections?: (newTranscriptSection: TranscriptSection, isUploading: boolean) => void,
) {
	const [uploadProgress, setUploadProgress] = useState(0);
	const [isUploading, setIsUploading] = useState(false);
	const { supaClientRef, selectedDocumentsRef } = useRealtimeContextApi();
	const dispatch = useDispatch();

	/**
	 * @param `size`: The size of the file
	 * @param `patientRecord`: The patient record to update
	 * @description Handles the error that occured during the recording process
	 * */
	const handleLargeFile = async (size: number, patientRecord: PatientRecordTableRow) => {
		if (!isFileSizeValid(size)) {
			toast.error("File Size Too Large", {
				autoClose: false,
				closeOnClick: true,
				draggable: true,
				toastId: "recording-error",
			});

			if (patientRecord?.id) {
				updateTableRecord({
					id: patientRecord?.id,
					updatedRecord: { id: patientRecord?.id },
				});
			}

			return { resp: null, err: "file size too large", is409: false };
		}

		return { resp: null, err: null, is409: false };
	};

	/**
	 *
	 * @param patientRecord
	 * @param newTranscriptSection
	 * @description Handles the error that occured during the recording process
	 */
	async function handleError(
		patientRecord: PatientRecordTableRow,
		newTranscriptSection: TranscriptSection,
		errorType: ErrorType,
	) {
		toast.error(
			errorType === ErrorType.UPLOAD_ERROR
				? "Error while uploading audio. Please check the incomplete uploads modal"
				: "Error transcribing, please retry. If the issue persists, contact support.",
			{
				closeOnClick: true,
				draggable: true,
				toastId: "recording-error",
			},
		);

		newTranscriptSection = cloneDeep(newTranscriptSection);
		patientRecord = cloneDeep(patientRecord);

		newTranscriptSection.data = "error";
		newTranscriptSection.error = errorType;

		if (!patientRecord.transcriptions) patientRecord.transcriptions = { id: newTranscriptSection.transcription_id };

		if (!patientRecord?.transcriptions?.transcript_sections) {
			patientRecord.transcriptions.transcript_sections = [];
		}

		// push or update the new transcript section
		if (patientRecord?.transcriptions?.transcript_sections) {
			const index = patientRecord.transcriptions.transcript_sections.findIndex(
				(section) => section.id === newTranscriptSection.id,
			);

			if (index !== -1) {
				patientRecord.transcriptions.transcript_sections[index] = newTranscriptSection;
			} else {
				patientRecord.transcriptions.transcript_sections.push(newTranscriptSection);
			}
		}

		if (updateOrAddTranscriptSections) updateOrAddTranscriptSections(newTranscriptSection, true);

		dispatch(addOrUpdateRecord(patientRecord));

		await upsertTranscriptSection({
			id: newTranscriptSection.id,
			data: "error",
			transcription_id: newTranscriptSection.transcription_id,
			error: errorType,
		});
		return { resp: null, err: errorType, is409: false };
	}

	/**
	 * @param `err`: The error that occured
	 * @param `newTranscriptSection`: The new transcript section that was created
	 * @returns `void`
	 * @description Handles the error that occured during the recording process
	 */
	async function handleTranscribeError(
		err: any,
		patientRecord: PatientRecordTableRow,
		newTranscriptSection: TranscriptSection,
	) {

		Sentry.captureException(err, {
			extra: {
				patientRecord,
				newTranscriptSection,
			},
		});

		if (err?.message === "File Size Too Large") {
			toast.error("File Size Too Large");
			return;
		}

		await handleError(patientRecord, newTranscriptSection, ErrorType.TRANSCRIBE_ERROR);
	}
	/**
	 * @param `audioBlob`: The audio blob to be handled
	 * @returns `void`
	 * @description Handles the audio blob that is created after the recording is stopped
	 */
	async function handleAudioBlob(
		audioFile: File,
		patientRecord: PatientRecordTableRow,
		newTranscriptSection: TranscriptSection,
		isQuickStart: boolean,
		realTimeClientId?: string,
	): Promise<void> {
		// set up new record
		try {
			// get session
			const { data: { session } } = await supabase.auth.getSession();

			// check if session is valid
			if (!session || !patientRecord) {
				Sentry.captureMessage("Session or patient record not found", {
					extra: {
						session,
						patientRecord,
					},
					level: "error",
				});
				return;
			}

			// update patient record
			if (updateOrAddTranscriptSections) updateOrAddTranscriptSections(newTranscriptSection, true);

			// get the chat header and patient name
			let docIds = selectedDocumentsRef.current?.map((doc: DocumentType) => doc.id) || [];

			// look up the selected documents
			const documents = await getDocumentTypesByIdsList(docIds);

			documents.forEach((doc) => {
				// check if the document is archived and if so, do not generate a new document and display a modal
				if (doc?.is_archived) {
					toast.warn(`${doc?.document_type_name} no longer exists. Select a different document.`, {
						closeButton: true,
						autoClose: false,
					});
					// remove the document from the selected documents
					dispatch(removeSelectedDocument(doc?.id));
					dispatch(documentTypeRemoved(doc?.id));
					// remove the document from the selected documents
					if (selectedDocumentsRef.current) {
						selectedDocumentsRef.current = selectedDocumentsRef.current.filter((selectedDoc: DocumentType) => selectedDoc.id !== doc.id);
					}

					docIds = docIds.filter((docId: string) => docId !== doc.id);

					return doc;
				}
			});

			// insert chat completion as a promise for each selected document return chatMessageId and documentId a
			const chatMessageInsertPromises = selectedDocumentsRef.current?.map(async (doc: DocumentType) => {
				let newAssistantMessage = await insertChatCompletion({
					userId: session?.user?.id,
					documentType: doc,
					completion: "",
					patientRecordId: patientRecord?.id,
					transcriptionId: patientRecord?.transcriptions?.id,
					header: doc?.document_type_name,
				});

				return newAssistantMessage;
			}) || [];

			// get all the document ids from the promises to send to the transcribe endpoint
			const chatMessages = await Promise.all(chatMessageInsertPromises);

			// get all the chat ids from the chat messages
			const chatIds = chatMessages.map((chatMessage) => chatMessage.id);

			// hit transcribe endpoint
			const { error } = await transcribeAudio(
				session,
				patientRecord?.transcriptions?.id as string,
				newTranscriptSection.id,
				audioFile.name,
				realTimeClientId,
				patientRecord.id,
				patientRecord?.patient_name || "",
				patientRecord.doctors?.id || "",
				docIds,
				chatIds
			);

			if (error) throw new Error("Transcription failed");

		} catch (err) {
			await handleTranscribeError(err, patientRecord, newTranscriptSection);
		}
	}

	const uploadAudio = async (
		audioFile: File,
		audioName: string,
		transcriptId: string,
		transcriptSectionId: string,
		patientRecord: PatientRecordTableRow,
		isQuickStart?: boolean,
	): Promise<{ transcriptSection: TranscriptSection | null; err: Error | AuthError | any; is409: Boolean }> => {
		//Make a copy of patientRecord
		patientRecord = cloneDeep(patientRecord);

		if (!tus.canStoreURLs) {
			Sentry.captureMessage("TUS cannot store urls");
		}

		if (!tus.isSupported) {
			Sentry.captureMessage("TUS is not supported");
		}
		const {
			data: { session },
			error,
		} = await supabase.auth.getSession();

		const localAudioDb = AudioDb.getInstance(INDEX_DB_VERSION);

		if (error) {
			Sentry.captureException(error);
			return { transcriptSection: null, err: error, is409: false };
		}

		if (!session) {
			Sentry.captureMessage("Session not found - useFileUpload.tsx", {
				level: "error",
			});
			return { transcriptSection: null, err: "no session", is409: false };
		}

		if (
			!patientRecord.transcriptions ||
			!patientRecord.transcriptions.id ||
			!patientRecord.id ||
			!transcriptSectionId ||
			!transcriptId ||
			!audioFile
		) {
			Sentry.captureMessage("Invalid arguments record - useFileUpload.tsx", {
				extra: {
					patientRecord,
					transcriptSectionId,
					transcriptId,
					audioFile,
				},
				level: "error",
			});
			return { transcriptSection: null, err: "invalid patient record", is409: false };
		}

		// check file size and update patient record status if necessary
		let isFileTooLarge = await handleLargeFile(audioFile.size, patientRecord);
		if (isFileTooLarge.err) {
			return { transcriptSection: null, err: isFileTooLarge.err, is409: false };
		}

		// add chat completion if it doesn't exist
		if (!patientRecord.chat_completions) patientRecord.chat_completions = [];

		// add new transcript section
		const timeNow = new Date().toISOString();
		let newTranscriptSection = {
			transcription_id: patientRecord.transcriptions.id,
			data: "loading",
			created_at: timeNow,
			unprocessed_filename: audioFile.name,
			processed_filename: "",
			was_removed: false,
			id: transcriptSectionId,
			retried_automatically: false,
		} as TranscriptSection;

		const userId = session?.user.id;
		let access_token = session?.access_token;

		try {
			return await new Promise(async (resolve, reject) => {
				let upload = new tus.Upload(audioFile, {
					endpoint: `${VITE_PUBLIC_SUPABASE_URL}/storage/v1/upload/resumable`,
					retryDelays: [0, 3000, 5000, 10000, 20000, 60000],
					headers: {
						Authorization: `Bearer ${access_token}`,
						"x-upsert": "true",
					},
					uploadDataDuringCreation: true,
					removeFingerprintOnSuccess: true, // Important if you want to allow re-uploading the same file https://github.com/tus/tus-js-client/blob/main/docs/api.md#removefingerprintonsucces
					metadata: {
						bucketName: "audio-storage",
						objectName: `${userId}/${transcriptId}/${audioName}`,
						filename: audioName,
						contentType: audioFile.type,
						cacheControl: "3600", // 24 hours
					},
					uploadSize: audioFile.size,
					chunkSize: 6 * 1024 * 1024, // NOTE: it must be set to 6MB (for now) do not change it
					onError: async function (error) {
						if (error.message.includes("already exists") || error.message.includes("409")) {
							await upload.abort(true);
							Sentry.captureMessage("409 error - useFileUpload.tsx");
							resolve({ transcriptSection: newTranscriptSection, err: null, is409: true });
							return;
						}

						setIsUploading(false);
						dispatch(setIsUploadingGlobal(false))

						await localAudioDb.updateUploadState(transcriptId, transcriptSectionId, upload.url || "", 0, false);

						Sentry.captureException(error, {
							extra: {
								patientRecord,
								newTranscriptSection,
							},
						});

						reject({ resp: null, err: error });
					},
					onUploadUrlAvailable: async function () {
						await localAudioDb.updateUploadState(transcriptId, transcriptSectionId, upload.url || "");
					},

					onProgress: async function (bytesUploaded, bytesTotal) {
						let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);

						if (percentage !== "100.00") {
							await localAudioDb.updateUploadState(
								transcriptId,
								transcriptSectionId,
								upload.url || "",
								bytesUploaded,
								true,
							);
						}

						setUploadProgress(Number(percentage));
					},
					onShouldRetry: function (err, retryAttempt, options) {
						var status = err.originalResponse ? err.originalResponse.getStatus() : 0;
						// Do not retry if the status is a 409.
						if (status === 409) {
							return false;
						}
						// For any other status code, we retry.
						return true;
					},

					onSuccess: async function () {
						// Update the upload state in the local db
						await localAudioDb.updateUploadState(
							transcriptId,
							transcriptSectionId,
							upload.url || "",
							audioFile.size,
							false,
						);

						// set uploading to false
						setIsUploading(false);
						dispatch(setIsUploadingGlobal(false))
						setUploadProgress(0);

						if (!patientRecord?.transcriptions?.transcript_sections) {
							patientRecord!.transcriptions!.transcript_sections = [];
						}
						// push or update the new transcript section
						if (patientRecord?.transcriptions?.transcript_sections) {
							const index = patientRecord.transcriptions.transcript_sections.findIndex(
								(section) => section.id === newTranscriptSection.id,
							);

							if (index !== -1) {
								// Update existing section
								patientRecord.transcriptions.transcript_sections[index] = newTranscriptSection;
							} else {
								// Push new section
								patientRecord.transcriptions.transcript_sections.push(newTranscriptSection);
							}
						}

						dispatch(addOrUpdateRecord(patientRecord));

						// add transcription to db
						await upsertTranscriptSection({
							id: transcriptSectionId,
							transcription_id: transcriptId,
							error: null,
							data: "loading",
							unprocessed_filename: audioFile.name,
						});

						// handle audio blob
						await handleAudioBlob(
							audioFile,
							patientRecord,
							newTranscriptSection,
							isQuickStart || false,
							supaClientRef.current || "",
						);

						// delete uploaded audio from indexedDB
						await localAudioDb.deleteTranscriptSectionAudio(transcriptId, transcriptSectionId);

						resolve({ transcriptSection: newTranscriptSection, err: null, is409: false });
					},
				});

				// Check if there are any previous uploads to continue.
				const previousUploads = await upload.findPreviousUploads();

				// Found previous uploads so we select the first one. If you are
				if (previousUploads.length > 0) {
					upload.resumeFromPreviousUpload(previousUploads[0]);
				}
				// Start the upload
				upload.start();
				setIsUploading(true);
				dispatch(setIsUploadingGlobal(true))
			});
		} catch (err: any) {
			Sentry.captureException(err, {
				extra: {
					patientRecord,
					newTranscriptSection,
				},
			});

			await localAudioDb.updateUploadState(transcriptId, transcriptSectionId, "", 0, false);
			setIsUploading(false);
			dispatch(setIsUploadingGlobal(false))
			setUploadProgress(0);
			//Still add the record
			dispatch(addOrUpdateRecord(patientRecord));

			const errObj = await handleError(patientRecord, newTranscriptSection, ErrorType.UPLOAD_ERROR);

			return { transcriptSection: null, err: errObj.err, is409: false };
		}
	};

	return { uploadAudio, uploadProgress, isUploading };
}

export default useFileUpload;
