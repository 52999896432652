import { useSessionContext } from "@supabase/auth-helpers-react";
import { createContext, useRef, useEffect, useContext } from "react";
import {
	ErrorType,
	BROADCAST_EVENT,
	BroadcastEvents,
	BroadcastTypes,
	PatientRecordTableRow,
} from "@common/utils/types";
import {
	incrementRecordingCount,
	updateTableRecord,
	removeRecord,
} from "@redux/patientTableRecordsSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateOrAddTranscriptSection } from "@features/patient-view/state/redux/activePatientDataSlice";
import { RealtimeChannel } from "@supabase/supabase-js";
import { removeDocumentFromTab, resetProgress, updateDocumentInTab } from "@features/patient-view/state/redux/activeDocumentsSlice";
import { getPatientData, updatePatientData } from "@features/patient-view/state/redux/activePatientDataSlice";
import axios from "axios";
import { VITE_PUBLIC_SUPABASE_URL, VITE_PUBLIC_SUPABASE_ANON_KEY } from "@/common/utils/constants";
import { createTabId } from "@/common/utils/helpers";
import * as Sentry from "@sentry/react";

export const RealTimeContextApi = createContext<{
	supaSuccessChannel: any;
	isMultiplePatients: (patientName: string) => boolean;
	transcriptSectionUpdaterRef: React.MutableRefObject<any>;
	supaClientRef: React.MutableRefObject<string>;
	selectedDocumentsRef: React.MutableRefObject<any>;
	emitPatientDeletedEvent: (patientRecordId: string) => void;
}>({
	supaSuccessChannel: null,
	isMultiplePatients: () => false,
	transcriptSectionUpdaterRef: { current: null },
	supaClientRef: { current: "" },
	selectedDocumentsRef: { current: null },
	emitPatientDeletedEvent: () => { },
});

const SUPABASE_URL = VITE_PUBLIC_SUPABASE_URL as string;
const SUPABASE_KEY = VITE_PUBLIC_SUPABASE_ANON_KEY as string;

export const RealTimeContextProvider = ({ children }: { children: React.ReactNode }) => {
	const { session, supabaseClient: supabase, isLoading } = useSessionContext();

	const supaClientRef = useRef<string>(session?.user.id || "");
	const { selectedDocuments, documentTypes } = useSelector((state: any) => state.documents);
	const { tableRecords } = useSelector((state: any) => state.patientTableRecords);
	const activePatientId = useSelector(getPatientData)?.id;

	let supaSuccessChannel: RealtimeChannel | null = null;

	if (session?.user?.id) {
		supaSuccessChannel = supabase?.channel(session.user.id as string);
	}

	const selectedDocumentsRef = useRef(selectedDocuments);
	selectedDocumentsRef.current = selectedDocuments;

	const transcriptSectionUpdaterRef = useRef<any>(null);
	const dispatch = useDispatch();

	const isMultiplePatients = (patientName: string) => {
		// Splitting by "and" or "And" using a case-insensitive regular expression
		const splitNames = patientName.split(/(\band\b)/i);
		if (splitNames.length > 2) {
			// This checks if there are multiple splits indicating more than one patient
			return true;
		}

		return false;
	};

	// check if the patientRecordId is in state
	const doesPatientRecordExist = (patientRecordId: string) => {
		return tableRecords.some((record: PatientRecordTableRow) => record.id === patientRecordId);
	};

	const isPatientRecordActive = (patientRecordId: string | null) => {
		return activePatientId === patientRecordId;
	};

	// emit a generic event on the supa success channel
	const emitEvent = async (eventType: string, payload: any) => {
		const supabaseUrl = SUPABASE_URL;
		const apiKey = SUPABASE_KEY; // Use your ANON or SERVICE role key
		const authToken = session?.access_token; // Assuming you have the auth token from the session

		if (!authToken || !session?.user?.id || !supabaseUrl || !apiKey) {
			Sentry.captureMessage("Error in emitEvent in RealtimeProvider.tsx", {
				level: "error",
				extra: { eventType, payload, },
			});
			return;
		}

		try {
			await axios.post(
				`${supabaseUrl}/realtime/v1/api/broadcast`,
				{
					messages: [
						{
							event: BROADCAST_EVENT,
							payload: { eventType, data: payload },
							topic: session?.user?.id,
						},
					],
				},
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
						apikey: apiKey,
						"Content-Type": "application/json; charset=utf-8",
					},
				},
			);
		} catch (error) {
			Sentry.captureException(error);
		}
	};

	const emitPatientDeletedEvent = async (patientRecordId: string) => {
		emitEvent(BroadcastEvents.patientDeleted, { patientRecordId });
	};

	//Subscribe to Transcript Section Updates.
	// state refactor will be huge for this type of usecase
	useEffect(() => {
		if (!supabase || !session?.user?.id || isLoading) {
			return;
		}

		if (!supaSuccessChannel) {
			supaSuccessChannel = supabase.channel(session?.user?.id as string);
		}

		if (session?.user.id && supaClientRef.current !== session?.user?.id) {
			supaClientRef.current = session?.user.id as string;
		}

		supaSuccessChannel
			.on("broadcast", { event: BROADCAST_EVENT }, (message: { payload: { type: any; eventType: any; data: any } }) => {
				const { type, eventType, data } = message?.payload;
				const isSuccess = type === BroadcastTypes.success;

				switch (eventType) {
					case BroadcastEvents.transcription:
						if (isSuccess) {
							let { transcriptionId, transcriptSectionId, data: transcript, patientRecordId } = data;

							if (!doesPatientRecordExist(patientRecordId)) break;

							//Add Transcript Section if Patient View is open and the right patient.
							dispatch(
								updateOrAddTranscriptSection({
									transcriptId: transcriptionId,
									transcriptSection: { id: transcriptSectionId, data: transcript },
								}),
							);
							//Increment Recording Count
							dispatch(incrementRecordingCount({ transcriptionId, sectionId: transcriptSectionId, error: null }));
						} else {
							let { transcriptSectionId, transcriptionId } = message?.payload?.data;

							dispatch(
								updateOrAddTranscriptSection({
									transcriptId: transcriptionId,
									transcriptSection: { id: transcriptSectionId, data: "error" },
								}),
							);

							dispatch(
								incrementRecordingCount({
									transcriptionId: transcriptionId,
									sectionId: transcriptSectionId,
									error: ErrorType.TRANSCRIBE_ERROR,
								}),
							);
						}
						break;
					case BroadcastEvents.document:
						// in either success or failed case update document state
						let { patientRecordId: id, chatCompletion } = data;

						// if the patient record is active, add the document to the tab
						if (isPatientRecordActive(id)) {
							let tabId = null;
							// if chatCompletion has chat_json and header, create a tabId
							if (chatCompletion?.document_type_id && chatCompletion?.chat_json?.header) {
								tabId = createTabId(chatCompletion?.document_type_id, chatCompletion?.chat_json?.header);
							}
							// if tabId exists, add the document to the tab
							if (tabId) {
								dispatch(updateDocumentInTab({ tabId, document: chatCompletion }));
								dispatch(resetProgress({ tabId: tabId }));
							}
						}

						break;

					case BroadcastEvents.documentDeleted:
						let { chatMessageId, patientRecordId: recordId, documentId } = data;

						// find document name from documentId
						const document = documentTypes.find((doc: any) => doc.id === documentId);

						// if the patient record is active, remove the document from the tab
						if (isPatientRecordActive(recordId)) {
							let tabId = null;
							if (chatMessageId && document?.document_type_name) {
								tabId = createTabId(documentId, document.document_type_name);
							}
							// if tabId exists, remove the document from the tab
							if (tabId) {
								dispatch(removeDocumentFromTab({ tabId, documentId: chatMessageId }));
								dispatch(resetProgress({ tabId: tabId }));
							}
						}

						break;


					case BroadcastEvents.name:
						if (isSuccess) {
							let { patientRecordId, patientName } = data;

							if (doesPatientRecordExist(patientRecordId)) {
								dispatch(
									updateTableRecord({
										id: patientRecordId,
										updatedRecord: { id: patientRecordId, patient_name: patientName },
									}),
								);
							}

							if (isPatientRecordActive(patientRecordId)) {
								dispatch(updatePatientData({ id: patientRecordId, patient_name: patientName }));
							}
						}

						break;

					case BroadcastEvents.patientDeleted:
						let { patientRecordId } = data;

						if (doesPatientRecordExist(patientRecordId)) {
							dispatch(removeRecord({ id: patientRecordId }));
						}
						break;

					default:
						break;
				}
			})
			.subscribe();
		return () => {
			if (supaSuccessChannel) supabase.removeChannel(supaSuccessChannel);
		};
	}, [supabase, session?.user?.id, isLoading, tableRecords, activePatientId, dispatch]);

	const value = {
		supaClientRef,
		supaSuccessChannel,
		isMultiplePatients,
		transcriptSectionUpdaterRef,
		selectedDocumentsRef,
		emitPatientDeletedEvent,
	};

	return <RealTimeContextApi.Provider value={value}>{children}</RealTimeContextApi.Provider>;
};

export const useRealtimeContextApi = () => {
	const context = useContext(RealTimeContextApi);
	if (context === undefined) {
		throw new Error(`DataContextAPi must be used within a DataContextProvider`);
	}
	return context;
};
