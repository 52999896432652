import { getProgress, setProgress } from "@/features/patient-view/state/redux/activeDocumentsSlice";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

interface ProgressBarProps {
	seconds: number;
	tabId: string;
	currentProgress?: number;
	isJson?: boolean;

}
const ProgressBar: React.FC<ProgressBarProps> = ({ seconds, tabId, currentProgress = 0, isJson = false }) => {
	const dispatch = useDispatch();
	const progressFromRedux = useSelector((state: { activeDocuments: any }) => getProgress(state, tabId));
	const [progress, setProgressState] = useState(progressFromRedux);
	const intervalRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (isJson && currentProgress !== undefined) {

			setProgressState(currentProgress);
			dispatch(setProgress({ tabId, progress: currentProgress }));
		}
	}, [currentProgress, isJson, tabId, dispatch]);

	useEffect(() => {
		if (isJson) {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
			return;
		}

		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}

		intervalRef.current = setInterval(() => {
			setProgressState((prevProgress) => {
				const newProgress = Math.min(prevProgress + 0.1, 100);
				dispatch(setProgress({ tabId, progress: newProgress }));
				return newProgress;
			});
		}, (seconds * 1000) / 1000);

		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		};
	}, [seconds, tabId, isJson, dispatch]);

	return (
		<div className="flex items-center gap-[10px]">
			<progress className="w-full progress progress-bar max-w-80" value={progress} max="100"></progress>
			<span className="text-sm text-center text-gray-500">
				{progress === 100
					? "Finalizing"
					: isJson && progress === 0
						? "Loading"
						: `${isJson ? `Generating (${progress.toFixed(0)}%)` : `${progress.toFixed(0)}%`}`}
			</span>

		</div>
	);
};

export default ProgressBar;