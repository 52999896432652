import ChevronUpIcon from "@icons/chevron-up.svg?react";
import ChevronDownIcon from "@icons/chevron-down.svg?react";

const TableHeaderBtn = ({ text, columnName, sortedColumn, handleClick }: any) => {
	return (
		<button className="group inline-flex" onClick={() => handleClick(columnName)}>
			{text}
			{sortedColumn.column === columnName ? (
				<span id="column-header" className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
					{sortedColumn.direction === "asc" ? (
						<ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
					) : (
						<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
					)}
				</span>
			) : (
				<span
					id="column-header"
					className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
				>
					<ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
				</span>
			)}
		</button>
	);
};
export default TableHeaderBtn;
