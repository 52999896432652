import { addDoctorToSupabase, deleteDoctorInSupabase, getSupabaseDoctors } from "@common/lib/supabaseClient";
import { Doctor } from "@common/utils/types";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

type status = "idle" | "loading" | "succeeded" | "failed";

const getLocalDoctor = () => {
	const doctor = localStorage.getItem("selectedDoctor");
	if (doctor) {
		return JSON.parse(doctor);
	}
	return null;
};

const initialState: {
	doctors: Doctor[];
	selectedDoctor: Doctor | null;
	status: status;
	error: string | null;
} = {
	doctors: [],
	selectedDoctor: getLocalDoctor(),
	status: "idle",
	error: null,
};

export const fetchDoctors = createAsyncThunk(
	"doctors/fetchDoctors",
	async (abortSignal: AbortSignal, { rejectWithValue }) => {
		try {
			const doctors = await getSupabaseDoctors(abortSignal);
			return doctors;
		} catch (error: any) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const addNewDoctor = createAsyncThunk(
	"doctors/addNewDoctor",
	async (doctor: Doctor, { dispatch, rejectWithValue }) => {
		try {
			await addDoctorToSupabase(doctor);
			dispatch(doctorAdded(doctor)); // Dispatch a local reducer action to add the doctor to the state
		} catch (error: any) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const deleteDoctor = createAsyncThunk(
	"doctors/deleteDoctor",
	async (doctorId: string, { dispatch, rejectWithValue }) => {
		try {
			await deleteDoctorInSupabase(doctorId);
			dispatch(doctorRemoved(doctorId));
		} catch (error: any) {
			return rejectWithValue(error.response.data);
		}
	},
);

const doctorsSlice = createSlice({
	name: "doctors",
	initialState,
	reducers: {
		doctorAdded(state, action) {
			const doctor = state.doctors.find((doc) => doc.id === action.payload.id);
			if (!doctor) state.doctors.push(action.payload);
		},
		doctorRemoved(state, action) {
			state.doctors = state.doctors.filter((doc) => doc.id !== action.payload);
			// remove from local storage if the selected doctor is deleted
			if (state.selectedDoctor?.id === action.payload) {
				state.selectedDoctor = null;
				localStorage.removeItem("selectedDoctor");
			}
		},
		doctorSelected(state, action) {
			state.selectedDoctor = action.payload;
			localStorage.setItem("selectedDoctor", JSON.stringify(action.payload));
		},
		setDoctors(state, action) {
			state.doctors = action.payload;
		},
		clearDoctorState(state) {
			state.doctors = [];
			state.selectedDoctor = null;
			localStorage.removeItem("selectedDoctor");
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchDoctors.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchDoctors.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.doctors = action.payload;
			})
			.addCase(fetchDoctors.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error?.message || null;
			});
	},
});

export const { doctorAdded, doctorRemoved, setDoctors, doctorSelected, clearDoctorState } = doctorsSlice.actions;

export default doctorsSlice.reducer;
