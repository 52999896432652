import { useState, useEffect } from "react";
import ProductDisplay from "./ProductDisplay";
import PaymentConfirmation from "./PaymentConfirmation";

function Payment() {
	let [message, setMessage] = useState("");
	let [success, setSuccess] = useState(false);
	let [sessionId, setSessionId] = useState("");

	useEffect(() => {
		// Check to see if this is a redirect back from Checkout
		const query = new URLSearchParams(window.location.search);

		if (query.get("success")) {
			setSuccess(true);
			setSessionId(query.get("session_id") || "");
		}

		if (query.get("canceled")) {
			setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
		}
	}, [sessionId]);

	if (!success && sessionId === "") {
		return <ProductDisplay />;
	} else if (success && sessionId !== "") {
		return <PaymentConfirmation sessionId={sessionId} />;
	} else {
		return <ProductDisplay />;
	}
}

export default Payment;
