import { Combobox } from "@headlessui/react";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import classNames from "classnames";
import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Appointment } from "../utils/types";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import SearchIcon from "@icons/search-lg.svg?react";
import * as Sentry from "@sentry/react";
import XIcon from "@icons/x-close.svg?react";

type Props = {
	verticalLayout?: boolean;
	onSelect?: (appointment: Appointment) => void;
	lastNDays?: number;
	notId?: string;
	isSearchBarVisible: boolean;
	toggleSearchBar: () => void;
	disabled?: boolean;
};

const SearchBar = ({ verticalLayout = false, onSelect, lastNDays, notId, isSearchBarVisible, toggleSearchBar, disabled = false }: Props) => {
	const supabase = useSupabaseClient();
	const [searchTerm, setSearchTerm] = useState("");
	const [fetchedData, setFetchedData] = useState<Appointment[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedAppointment, setSelectedAppointment] = useState<Appointment | null>(null);
	const navigate = useNavigate();
	const selectedTableRecords = useSelector((state: any) => state.patientTableRecords.selectedTableRecords);


	const fetchResults = useCallback(
		debounce(async () => {
			if (disabled) return

			if (searchTerm) {
				setIsLoading(true);
				try {
					const query = supabase
						.from("patient_record")
						.select("patient_name, id, patient_id, scheduled_at, is_archived")
						.or(`patient_name.ilike.%${searchTerm}%,patient_id.ilike.%${searchTerm}%`)
						.eq("is_archived", false);

					// if notId is passed, filter out the notId
					if (notId) {
						query.neq("id", notId);
					}

					// if lastNDays is passed, filter created_at greater than lastNDays
					if (lastNDays) {
						query.gte("scheduled_at", moment().subtract(lastNDays, "days").toISOString());
					}

					const { data, error } = await query;

					if (error) {
						Sentry.captureException(error);
						return;
					}

					if (data) {
						setFetchedData(data as any);
					}
				} finally {
					setIsLoading(false);
				}
			}
		}, 500),
		[searchTerm, supabase, disabled],
	);

	useEffect(() => {
		if (disabled) return

		fetchResults();
	}, [fetchResults, disabled]);

	const handleClick = (appointment: Appointment) => {
		if (disabled) return

		if (onSelect) {
			onSelect(appointment);
		} else {
			navigate(`/appointments/${appointment.id}`, { state: { appointmentId: appointment.id } });
		}
		setSearchTerm("");
		setFetchedData([]);
		setSelectedAppointment(null);
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			if (selectedAppointment) {
				handleClick(selectedAppointment);
				return;
			}

			if (fetchedData.length > 0) {
				handleClick(fetchedData[0]);
				return;
			}
		}
	};

	const renderLoading = () => isLoading && <div>Loading...</div>;

	const renderNoMatches = () =>
		searchTerm && fetchedData.length === 0 && <div className="px-4 py-[6px] text-gray-900">No matches</div>;

	const renderOptions = () =>
		searchTerm &&
		fetchedData.length > 0 &&
		fetchedData.map((appointment, idx) => (
			<Combobox.Option key={idx} value={appointment} as={React.Fragment}>
				{({ active }) => {
					const formattedDate = moment(appointment?.scheduled_at).format("MM/DD/YY");
					const formattedTime = moment(appointment?.scheduled_at).format("h:mm A");
					return (
						<li
							className={`cursor-default select-none px-4 py-[6px] ${active ? "text-white bg-max-700" : "text-gray-900"
								}`}
							onClick={() => handleClick(appointment)}
							onMouseEnter={() => setSelectedAppointment(appointment)}
						>
							<div className="font-bold">{`${appointment?.patient_name}`}</div>
							<div
								className={`text-xs ${active ? "text-white bg-max-700" : "text-gray-700"}`}
							>{`ID: ${appointment?.patient_id} - ${formattedDate}, ${formattedTime}`}</div>
						</li>
					);
				}}
			</Combobox.Option>
		));

	const renderComboboxOptions = () => {
		return (
			<>
				{renderLoading()}
				{renderOptions()}
				{renderNoMatches()}
			</>
		);
	};

	return (
		<div
			className={classNames(
				selectedTableRecords.length > 0 ? "hidden" : "grid w-full gap-2",
				verticalLayout ? "grid-cols-1" : "grid-cols-1",
				disabled ? "opacity-50 cursor-not-allowed" : "hover:cursor-pointer",
				"z-50 w-full",
			)}
		>
			{/* Search Icon Button for Small Screens */}
			{!isSearchBarVisible && (
				<div className="block md:hidden">
					<button
						onClick={toggleSearchBar}
						className="p-2 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-max-700 ring-1 ring-inset rounded-md ring-gray-300"
					>
						<SearchIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
					</button>
				</div>
			)}

			{/* Search Bar for Small Screens */}
			{isSearchBarVisible && (
				<div className="relative flex items-center w-full md:hidden">
					<Combobox as="div" value={selectedAppointment} onChange={setSelectedAppointment}>
						<form className="relative flex flex-1" onSubmit={(e) => e.preventDefault()}>
							<button
								type="button"
								onClick={toggleSearchBar}
								className="absolute inset-y-0 right-2 flex items-center rounded-r-md focus:outline-none"
							>
								<XIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
							</button>
							<Combobox.Input
								type="text"
								name="search"
								id="patient-search-input"
								value={searchTerm}
								placeholder="Search by Patient"
								onChange={(e) => setSearchTerm(e.target.value)}
								onKeyDown={handleKeyDown}
								className="block w-48 w-full rounded-md border-0 py-[6px] pl-8 pr-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-max-700 sm:text-sm sm:leading-6"
								autoComplete="off"
							/>
							<SearchIcon className="absolute inset-y-0 left-2 top-2 h-5 w-5 text-gray-500" aria-hidden="true" />
							{searchTerm && (
								<Combobox.Options className="absolute z-10 mt-1 top-full w-full overflow-auto rounded-md bg-white py-[6px] text-base shadow-lg ring-1 ring-gray-900 ring-opacity-5 focus:outline-none sm:text-sm">
									{renderComboboxOptions()}
								</Combobox.Options>
							)}
						</form>
					</Combobox>
				</div>
			)}

			{/* Search Bar for Large Screens */}
			<div className={classNames("relative flex items-center w-full hidden md:flex")}>
				<SearchIcon className="absolute inset-y-0 left-2 h-5 w-5 text-gray-500" aria-hidden="true" />
				<Combobox as="div" value={selectedAppointment} onChange={setSelectedAppointment}>
					<form className="relative flex flex-1" onSubmit={(e) => e.preventDefault()}>
						<Combobox.Input
							type="text"
							name="search"
							id="patient-search-input"
							value={searchTerm}
							placeholder="Search by Patient"
							onChange={(e) => setSearchTerm(e.target.value)}
							onKeyDown={handleKeyDown}
							className="block w-48 w-full rounded-md border-0 py-[6px] pl-8 pr-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-max-700 sm:text-sm sm:leading-6"
							autoComplete="off"
						/>
						<SearchIcon className="absolute inset-y-0 left-2 top-2 h-5 w-5 text-gray-500" aria-hidden="true" />
						{searchTerm && (
							<Combobox.Options className="absolute z-10 mt-1 top-full w-full overflow-auto rounded-md bg-white py-[6px] text-base shadow-lg ring-1 ring-gray-900 ring-opacity-5 focus:outline-none sm:text-sm">
								{renderComboboxOptions()}
							</Combobox.Options>
						)}
					</form>
				</Combobox>
			</div>
		</div>
	);
};


export default SearchBar;
