import SearchBar from "@common/components/SearchBar";
import CompleteDocuments from "@common/components/CompleteDocuments";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useAccountContext } from "@providers/AccountProvider";
import NotificationBanner from "@/common/components/NotificationBanner";
import ProfileMenu from "./ProfileMenu";
import TableTabs from "@/common/components/HDTabs";
import { RecordingState, TableTab } from "@/common/utils/types";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const notificationBannerMessage = import.meta.env.VITE_NOTIFICATIONS_BANNER_MESSAGE || ""


const Navbar = () => {
	const { accountData } = useAccountContext();
	const { selectedTableRecords } = useSelector((state: any) => state.patientTableRecords);
	const { globalRecordingState } = useSelector((state: any) => state.globalState);
	const location = useLocation();
	const navigate = useNavigate();

	const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
	const [isCompleteDocumentsVisible, setIsCompleteDocumentsVisible] = useState(false);

	const toggleSearchBar = () => {
		setIsSearchBarVisible(!isSearchBarVisible);
	};

	useEffect(() => {
		if (selectedTableRecords?.length > 0) {
			setIsSearchBarVisible(false);
			setIsCompleteDocumentsVisible(true);
		}

		return () => {
			setIsSearchBarVisible(false);
			setIsCompleteDocumentsVisible(false);
		}
	}, [selectedTableRecords]);


	const [tabs, setTabs] = useState<TableTab[]>([
		{
			header: "List View",
			isActive: location.pathname === "/",
			id: '/',
		},
		{
			header: "Calendar View",
			isActive: location.pathname === "/calendar",
			id: '/calendar',
		},
	]);


	/**
 * Change the tab and update the current stage in redux
 * @param id - The id of the tab
 */
	const changeTab = (id: string) => {

		if (location.pathname === id) {
			return;
		}

		setTabs((prev) =>
			prev.map((t) => {
				if (t.id === id) {
					return { ...t, isActive: true, };
				} else {
					return { ...t, isActive: false, };
				}
			}),
		);

		navigate(id);

	};

	const handleLogoClick = () => {

		if (location.pathname === "/") {
			return;
		}

		setTabs((prev) =>
			prev.map((t) => {
				if (t.id === "/") {
					return { ...t, isActive: true, };
				} else {
					return { ...t, isActive: false, };
				}
			}),
		);

		navigate("/");
	}

	const isRecording = globalRecordingState === RecordingState.recording || globalRecordingState === RecordingState.paused;

	return (
		<div className="fixed right-0 left-0 z-40 flex-col">
			{notificationBannerMessage &&
				<div>
					<NotificationBanner message={notificationBannerMessage} />
				</div>
			}

			<div
				className={classNames(
					"flex h-16 shrink-0 items-center border-b border-gray-300 bg-white px-4 shadow-sm"
				)}
			>

				{!isCompleteDocumentsVisible && !isSearchBarVisible && <div className="w-full flex items-center flex-1 gap-x-5 self-stretch">
					<button disabled={isRecording} onClick={handleLogoClick}>
						<img src="/favicon.png" alt="logo" className="h-8 w-auto" />
					</button>
					<h1 className="hidden lg:inline-flex text-xl font-semibold leading-6 text-gray-900">{accountData?.clinic_name || ""}</h1>
					<div className=" ">
						<TableTabs tabs={tabs} changeTab={changeTab} disabled={isRecording} paddingVertical="py-5  sm:py-[18px]" />
					</div>
				</div>}

				<div className="flex items-center gap-x-2 ml-auto">
					<div
						className={classNames(
							selectedTableRecords?.length > 0 ? "hidden" : " flex",
						)}
					>
						<SearchBar isSearchBarVisible={isSearchBarVisible} toggleSearchBar={toggleSearchBar} disabled={isRecording} />
					</div>

					<div className="flex items-center">
						<CompleteDocuments />
					</div>

					<ProfileMenu />
				</div>
			</div>
		</div>

	);
};
export default Navbar;
