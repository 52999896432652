import { FormattingOption } from "@/common/utils/types";
import FormatString from "./FormatString";

interface FormatListProps {
    list: string[];
    format: string;
}

/**
 * Render the value based on the format
 * @param props - the props containing list and format
 * @returns JSX.Element
 */
const FormatList: React.FC<FormatListProps> = ({ list, format }) => {
    switch (format) {
        case FormattingOption.Bulleted:
            return (
                <ul>
                    {list.map((item, index) => (
                        <li key={index} className="text-[12px] leading-4">• {item}</li>
                    ))}
                </ul>
            );
        case FormattingOption.Dashed:
            return (
                <ul>
                    {list.map((item, index) => (
                        <li key={index} className="text-[12px] leading-4">- {item}</li>
                    ))}
                </ul>
            );
        case FormattingOption.Numbered:
            return (
                <ul>
                    {list.map((item, index) => (
                        <li key={index} className="text-[12px] leading-4">{index + 1}. {item}</li>
                    ))}
                </ul>
            );
        case FormattingOption.FlatList:
            return (
                <ul>
                    {list.map((item, index) => (
                        <li key={index} className="text-[12px] leading-4">{item}</li>
                    ))}
                </ul>
            );
        case FormattingOption.Paragraph:
            const paragraph = list?.join(" ");
            return <FormatString value={paragraph} format={format} />;

        case FormattingOption.None:
            return <ul></ul>;
        default:
            return (
                <ul>
                    {list.map((item, index) => (<li key={index} className="text-[12px] leading-4">{item}</li>))}
                </ul>
            );
    }
}

export default FormatList;

