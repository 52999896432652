import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AudioDb, { INDEX_DB_VERSION } from "@common/classes/audioDb";
import { PatientRecord, PatientRecordTableRow } from "@common/utils/types";
import { deletePatientRecordsAsync, resetSelectedTableRecords } from "@redux/patientTableRecordsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useRealtimeContextApi } from "@providers/RealtimeProvider";
import XCloseIcon from "@icons/x-close.svg?react";
import ConfirmModal from "@/common/components/ConfirmModal";
import { setCompleting } from "@/state/redux/globalState";

function SelectedActionButtons() {
	const dispatch = useDispatch();
	const { selectedTableRecords } = useSelector((state: any) => state.patientTableRecords);

	const navigate = useNavigate();

	const [deleteApptModalOpen, setDeleteApptModalOpen] = useState(false);

	const { emitPatientDeletedEvent } = useRealtimeContextApi();

	const localAudioDb = useRef<AudioDb | null>(AudioDb.getInstance(INDEX_DB_VERSION));

	const handleStartCompleting = () => {
		if (selectedTableRecords.length > 0) {
			dispatch(setCompleting(true));
			navigate(`/appointments/${selectedTableRecords[0].id}`);
		}
	};

	const handleDeleteClick = () => {
		setDeleteApptModalOpen(true);
	};

	// clear selection button
	const handleDeselectAll = () => {
		dispatch(resetSelectedTableRecords());
	};

	const handleDeleteConfirmationClick = () => {
		const recordsToDelete = selectedTableRecords
			.map((record: PatientRecordTableRow) => record.id)
			.filter((id: string) => id !== undefined) as string[];
		dispatch(deletePatientRecordsAsync(recordsToDelete) as any);
		dispatch(resetSelectedTableRecords());

		// emit event to delete patient records
		recordsToDelete.forEach((patientRecordId) => {
			emitPatientDeletedEvent(patientRecordId);
		});

		setDeleteApptModalOpen(false);
		if (localAudioDb.current) {
			selectedTableRecords.forEach((appointment: PatientRecord) => {
				localAudioDb.current?.deleteAudioUsingPatientRecord(appointment.id as string);
			});
		}

		setDeleteApptModalOpen(false);
	}

	return (
		<>
			<div className="flex justify-around flex-grow  gap-x-2 items-center w-full ">
				<div className="flex flex-grow w-full">
					<button
						className="rounded-md grow bg-white px-2 sm:px-4 py-2.5 text-sm font-semibold hover:bg-gray-100 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300"
						onClick={() => {
							handleStartCompleting();
						}}
						disabled={selectedTableRecords.length === 0}
					>
						<span className="flex flex-row justify-center items-center whitespace-nowrap">
							<span>Review</span>

							<span className="ml-1 bg-max-700 px-2 py-0 flex items-center justify-center rounded-full h-4 w-4 sm:h-5 sm:w-5 text-xs font-boldd text-white ring-1 ring-inset ring-max-700 ">
								{selectedTableRecords?.length}
							</span>
						</span>
					</button>
				</div>
				<div className="flex flex-grow w-full">
					<button
						className="rounded-md grow bg-white px-2 sm:px-4 py-2.5 text-sm font-semibold hover:bg-gray-100 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300"
						onClick={handleDeleteClick}
					>
						<span className="flex flex-row items-center justify-center whitespace-nowrap">
							<span>Delete</span>
							<span className="ml-1 flex items-center justify-center rounded-full h-4 w-4 sm:h-5 sm:w-5 text-xs font-bold bg-max-700 px-2 py-0 text-white ring-1 ring-inset ring-max-700 ">
								{selectedTableRecords?.length}
							</span>
						</span>
					</button>
				</div>
				<div className="flex flex-grow w-full">
					<button
						onClick={handleDeselectAll}
						className="rounded-md grow bg-white px-2 sm:px-4 py-2.5 text-sm font-semibold hover:bg-gray-100 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300"
					>
						<span className="flex flex-row justify-center items-center whitespace-nowrap">
							<XCloseIcon className="h-4 w-4 text-gray-900 sm:mt-0.5 sm:mx-1" aria-hidden="true" />
							<span>Clear</span>

						</span>
					</button>
				</div>
			</div>
			<ConfirmModal
				id="delete-appointment"
				isOpen={deleteApptModalOpen}
				onCancel={() => setDeleteApptModalOpen(false)}
				headerText="Delete Appointments?"
				contentText="Are you sure you want to delete the selected appointments?"
				confirmButtonText="Delete"
				cancelButtonText="Cancel"
				onExit={() => setDeleteApptModalOpen(false)}
				onConfirm={handleDeleteConfirmationClick}
				confirmButtonColor="bg-red-600"
				confirmHoverButtonColor="bg-red-500"
			/>
		</>
	);
}

export default SelectedActionButtons;
