function UploadProgressBar({ percentage }: { percentage: number }) {
	return (
		<div className="w-full bg-gray-200 rounded-full">
			<div
				className="bg-max-700  text-xs font-medium text-white text-center p-0.5 leading-none rounded-full"
				style={{ width: `${percentage}%` }}
			>
				{percentage}%
			</div>
		</div>
	);
}

export default UploadProgressBar;
