import QuickStart from "./components/quickstart/QuickStart";
import Table from "./components/patient-table/PatientTable";
import { AudioRecorderProvider } from "./state/recordingProvider";
import Controls from "./components/Controls";

function PatientListView() {
	return (
		<div className="pt-14 pb-14">
			<AudioRecorderProvider>
				<div className="mt-2 flex flex-col-reverse md:flex-row gap-4 items-start justify-evenly ">
					<div className="w-full md:w-1/3">
						<Controls />
					</div>

					<QuickStart />


				</div>

				<div className="mt-4">
					<Table />
				</div>
			</AudioRecorderProvider>
		</div>
	);
}

export default PatientListView;
