import { RecordingState } from '@/common/utils/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GlobalState {
    confetti: boolean;
    completing: boolean;
    completionId: string | null;
    globalRecordingState: RecordingState;
    isUploadingGlobal: boolean;
}

const initialState: GlobalState = {
    confetti: false,
    completing: false,
    completionId: null,
    globalRecordingState: RecordingState.inactive,
    isUploadingGlobal: false,
};

const globalStateSlice = createSlice({
    name: 'globalState',
    initialState,
    reducers: {
        setConfetti(state, action: PayloadAction<boolean>) {
            state.confetti = action.payload;
        },
        setCompleting(state, action: PayloadAction<boolean>) {
            state.completing = action.payload;
        },
        setCompletionId(state, action: PayloadAction<string | null>) {
            state.completionId = action.payload;
        },
        setGlobalRecordingState(state, action: PayloadAction<RecordingState>) {
            state.globalRecordingState = action.payload;
        },
        setIsUploadingGlobal(state, action: PayloadAction<boolean>) {
            state.isUploadingGlobal = action.payload;
        },
    },
});

export const {
    setConfetti,
    setCompleting,
    setCompletionId,
    setGlobalRecordingState,
    setIsUploadingGlobal,
} = globalStateSlice.actions;

export default globalStateSlice.reducer;
