import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppointmentInfo from "./components/header/PatientInfo";
import GenerateDocuments from "./components/sidebar/GenerateDocuments";
import Recordings from "./components/sidebar/Recordings";
import TerminologyFlagger from "@common/components/TerminologyFlagger";
import DocumentTabs from "./components/documents/DocumentTabs";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "@common/components/Spinner";
import useGenerateDocuments from "./state/useGenerateDocuments";
import { getPatientStage, resetActivePatientData } from "./state/redux/activePatientDataSlice";
import { resetActiveDocuments } from "./state/redux/activeDocumentsSlice";
import { FETCH_ERROR_REFRESH, NO_PATIENT_RECORD } from "@common/utils/types";
import {
	getPatientRecordFetchStatus,
	getPatientRecordFetchError,
	fetchPatientData,
} from "./state/redux/activePatientDataSlice";
import { isUUID } from "validator";
import { ActivePatientStatus } from "@/common/utils/types";
import HDBackButton from "@/common/components/HDBackButton";
import StageSelect from "@/common/components/StageSelect";

/**
 * Component for the patient view page
 * @returns JSX.Element
 */
export default function PatientView() {
	const params = useParams();
	const { state } = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { completing } = useSelector((state: any) => state.globalState);
	const appointmentId = params?.appointment_id || state?.appointmentId;
	const fetchStatus = useSelector(getPatientRecordFetchStatus);
	const fetchError = useSelector(getPatientRecordFetchError);
	const currentStage = useSelector(getPatientStage);

	// Clear redux states
	function clearStates() {
		dispatch(resetActiveDocuments());
		dispatch(resetActivePatientData());
	}

	// Fetch patient data on mount and clear states on unmount 
	// if the appointmentId is a valid UUID navigate back to list view
	useEffect(() => {
		if (appointmentId && isUUID(appointmentId)) {
			dispatch<any>(fetchPatientData(appointmentId as string));
		} else if (appointmentId && !isUUID(appointmentId)) {
			// navigate back home ;)
			navigate("/");
		}

		return () => {
			clearStates();
		};
	}, [appointmentId]);

	const [completionId, setCompletionId] = useState<string | null>(null);

	const { generateDocument, completion, isLoadingCompletion, stop } = useGenerateDocuments({
		setCompletionId,
	});

	return (
		<div className={"pt-16"}>
			<div className="flex justify-between items-center">
				<HDBackButton backTo="list" />
				<div className="flex items-center">
					{fetchStatus === ActivePatientStatus.Succeeded && !completing && !fetchError ? (
						<div className="flex items-center ">
							<StageSelect currentStage={currentStage} patientId={appointmentId} showConfetti={true} />
						</div>
					) : null}
				</div>
			</div>

			<div className="mt-4">
				<AppointmentInfo />
			</div>

			<div className="min-h-[20px] mt-4">
				<TerminologyFlagger isIcon={false} />
			</div>

			{fetchStatus === ActivePatientStatus.Loading ? (
				<div className=" w-full h-full flex justify-center items-center">
					<Spinner size="medium" />
				</div>
			) : (fetchStatus === ActivePatientStatus.Succeeded || fetchStatus === ActivePatientStatus.Idle) && !fetchError ? (
				<div className="flex flex-col-reverse md:flex-row w-full md:mt-4 gap-4">
					<div className=" w-full md:w-2/3 xl:w-3/4">
						<DocumentTabs
							setCompletionId={setCompletionId}
							completionId={completionId}
							generateDocument={generateDocument}
							completion={completion}
							isLoadingCompletion={isLoadingCompletion}
							stop={stop}
						/>
					</div>
					<div className="w-full md:w-1/3 xl:w-1/3 flex flex-col-reverse gap-4 md:gap-0 md:flex-col">

						<div className="">
							<GenerateDocuments
								setCompletionId={setCompletionId}
								completionId={completionId}
								generateDocument={generateDocument}
								isLoadingCompletion={isLoadingCompletion}
							/>
						</div>
						<div className="mt-3">
							<Recordings />
						</div>
					</div>
				</div>
			) : fetchStatus === ActivePatientStatus.Failed && fetchError ? (
				<div className="flex justify-center">
					<div className="text-red-300 text-sm font-bold mt-20">{FETCH_ERROR_REFRESH}</div>
				</div>
			) : fetchError ? (
				<div className="flex justify-center">
					<div className="text-red-300 text-sm font-bold mt-20">{NO_PATIENT_RECORD}</div>
				</div>
			) : null}
		</div>
	);
}
