import { Fragment, useEffect, useRef, useState } from "react";
import AudioDb, { INDEX_DB_VERSION, TranscriptSectionIndexDB } from "../../classes/audioDb";
import { Transition, Dialog } from "@headlessui/react";
import classNames from "classnames";
import HelpDocumentation from "../HelpDocumentation";
import UploadModalRow from "./UploadModalRow";
import ArrowPathIcon from "@icons/refresh-cw-03.svg?react"
import UploadIcon from "@icons/upload-01.svg?react"
import { setIncompleteUploadCount, startPollingLocalDB, stopPollingLocalDB } from "@/state/redux/patientTableRecordsSlice";
import { useDispatch, useSelector } from "react-redux";

/*
Notes:
	- Incomplete uploads are stored in indexed db, see AudioDb class
	- AudioDb is somewhat separate from the rest of the app, so state is a bit weird
	- Poll the db for incomplete uploads every 30 seconds

There are two ways to upload incomplete uploads:
	- We will automatically try to upload incomplete only on refresh, when AudioDb is initialized
	- Otherwise the user can open this modal to see and retry incomplete uploads
*/
const UploadModal: React.FC<{
	disabled: boolean;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	showButton?: boolean;
}> = ({ disabled, open, setOpen, showButton = true }) => {

	const dispatch = useDispatch();
	const intervalRef = useRef<NodeJS.Timeout | null>(null);
	const polling = useSelector((state: any) => state.patientTableRecords.polling);
	const [incompleteUploads, setIncompleteUploads] = useState<TranscriptSectionIndexDB[]>([]);

	/**
	 * Get all incomplete uploads
	 * @returns {Promise<TranscriptSectionIndexDB[]>}
	 * */
	const getIncompleteUploads = async () => {
		const localAudioDb = AudioDb.getInstance(INDEX_DB_VERSION);
		const incompleteUploads = await localAudioDb.getIncompleteUploads();

		if (incompleteUploads) {
			setIncompleteUploads(incompleteUploads);
			dispatch(setIncompleteUploadCount(incompleteUploads.length));
		}

		return incompleteUploads;
	}

	useEffect(() => {
		// Recording in progress, don't poll
		if (disabled) {
			intervalRef.current && clearInterval(intervalRef.current);
			dispatch(stopPollingLocalDB());
			return;
		}

		if (!polling) {
			getIncompleteUploads()

			// Poll for incomplete uploads every 30 seconds
			intervalRef.current = setInterval(() => {
				getIncompleteUploads()
			}, 30000);

			dispatch(startPollingLocalDB());
		}

		return () => {
			intervalRef.current && clearInterval(intervalRef.current);
			dispatch(stopPollingLocalDB());
		};
	}, [disabled, open, dispatch, polling]);

	return (
		<Fragment>
			{showButton && <button
				id="incomplete-upload-modal"
				onClick={() => setOpen(!open)}
				disabled={disabled}
				className={classNames(
					disabled ? "opacity-50 cursor-not-allowed" : "bg-gray-100 text-gray-900 hover:bg-gray-200",
					"rounded bg-white grow px-4 py-2.5 text-[10px] sm:text-sm font-semibold bg-gray-100 hover:bg-gray-200 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300",
				)}
			>
				<span className="flex flex-row justify-center items-center whitespace-nowrap">
					<UploadIcon className="h-4 w-4 mr-1 text-gray-900" />
					<span className="sm:hidden">Upload</span>
					<span className="hidden sm:inline">Incomplete Uploads </span>
					{incompleteUploads &&
						// Use optional chaining with filter and immediately get the length
						// If incompleteUploads is null, this will short-circuit and return 0
						incompleteUploads.filter((upload) => !upload.isUploading).length > 0 && (
							<span className="ml-1 text-white px-2 py-0 bg-red-500 rounded-full">
								{incompleteUploads.filter((upload) => !upload.isUploading).length}
							</span>
						)}
				</span>
			</button>}

			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div" className="relative z-50" onClose={() => setOpen(!open)}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>
					<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
						<div className="flex min-h-screen items-center justify-center p-4 text-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all m-4 sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
									<div>
										<div className="text-center">
											<Dialog.Title
												as="h1"
												className="text-xl mb-3 font-semibold leading-6 text-gray-900 text-left flex flex-row items-center justify-between relative font-"
											>
												Locally Stored Audio
												<div className="absolute hidden md:inline-block top-0 left-[200px] z-50">
													<HelpDocumentation
														link="https://help.gohappydoc.com/en/articles/8563272-trouble-shooting-common-issues"
														displayText="Help Docs"
													/>
												</div>
												<button onClick={getIncompleteUploads}>
													<ArrowPathIcon className="h-4 w-4 ml-1 text-gray-900" />
												</button>
											</Dialog.Title>
											<ul className="flex flex-col justify-center mt-1 list-disc px-6 text-[14px]">
												<li>
													<p className="text-gray-500 text-left">
														This table shows all audio not fully uploaded for the last week.
													</p>
												</li>
												<li>
													<p className="text-gray-500  text-left">Local audio is automatically deleted after 1 week.</p>
												</li>
												<li>
													<p className="text-gray-500  text-left">
														Icognito mode or private browsing can prevent local audio from being saved on device.
													</p>
												</li>
											</ul>
										</div>
									</div>
									<div>
										<div className="mt-5 sm:mt-6">
											<table className="mt-6 min-w-full leading-normal">
												<thead>
													<tr>
														<th className="px-2.5 py-1.5 sm:px-5 sm:py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
															Details
														</th>
														<th className="min-w-400 ppx-2.5 py-1.5 sm:px-5 sm:py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
															Actions
														</th>
													</tr>
												</thead>
												<tbody>
													{incompleteUploads.map((upload, patientIndex) => (
														<UploadModalRow
															key={patientIndex}
															upload={upload}
															setIncompleteUploads={setIncompleteUploads}
															getIncompleteUploads={getIncompleteUploads}
														/>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</Fragment>
	);
};

export default UploadModal;
