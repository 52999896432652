import { PatientStage, Stage } from "../utils/types";
import { setPatientRecordStage } from "../lib/supabaseClient";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateTableRecordStage } from "@redux/patientTableRecordsSlice";
import FileSearchIcon from "@icons/file-search-01.svg?react"
import FileCheckIcon from "@icons/file-check-01.svg?react"
import FilePlusIcon from "@icons/file-plus-01.svg?react"
import { updatePatientData } from "@/features/patient-view/state/redux/activePatientDataSlice";
import ConfettiElement from "./ConfettiElement";
import { Combobox } from "@headlessui/react";
import classNames from "classnames";
import ChevronDownIcon from "@icons/chevron-down.svg?react";
import { setConfetti } from "@/state/redux/globalState";

/**
 * Reusable component for selecting the stage of a patient record
 * @param currentStage - The current stage of the patient record
 * @param patientId - The id of the patient record
 * @param showConfetti - Whether to show confetti when the stage is updated to finalized
 * @param disabled - Whether the stage select is disabled
 */
function StageSelect({
	currentStage,
	patientId,
	showConfetti = false,
	disabled = false,
}: {
	currentStage: string;
	patientId: string;
	showConfetti?: boolean;
	disabled?: boolean;
}) {
	const dispatch = useDispatch();


	const stages: Stage[] = [
		{ name: "Ongoing", icon: FilePlusIcon, value: PatientStage.Ongoing },
		{ name: "Review", icon: FileSearchIcon, value: PatientStage.Review },
		{ name: "Finalized", icon: FileCheckIcon, value: PatientStage.Finalized },
	];

	const updateStage = async (stage: PatientStage) => {
		if (currentStage === stage) return;

		await setPatientRecordStage(patientId, stage);
		dispatch(updateTableRecordStage({ id: patientId, newStage: stage }));
		dispatch(updatePatientData({ id: patientId, stage: stage as PatientStage }));

		toast.success("Stage updated successfully");

		if (stage === PatientStage.Finalized && showConfetti) {
			dispatch(setConfetti(true));
			<ConfettiElement />;
		}
	};

	const isSelected = (option: Stage) => {
		return currentStage === option.value;
	}

	function renderStageColor(stage: Stage) {
		switch (stage.value) {
			case PatientStage.Ongoing:
				return '#2EDEE5';

			case PatientStage.Review:
				return "#FCB718";

			case PatientStage.Finalized:
				return "#2D394D";

			default:
				return '#2EDEE5';
		}
	}

	return (
		<Combobox
			as="div"
			value={currentStage}
			onChange={updateStage}
			disabled={disabled}
		>

			<div className=" max-w-[155px] relative">
				<div className=" flex items-center">
					<div className=" absolute inset-y-0 left-0 flex items-center pl-[14px]">
						<div style={{
							backgroundColor: renderStageColor(stages.find(s => s.value === currentStage) || stages[0])
						}} className={classNames(
							'w-2.5 h-2.5 rounded-full',
						)} />
					</div>
					<Combobox.Input
						className={classNames(
							disabled ? 'text-gray-400 placeholder:text-gray-400' : 'text-gray-900',
							' max-w-[155px] font-semibold rounded-md  border-0 bg-white py-2 pl-[34px] shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-500  text-xs leading-tight sm:text-sm sm:leading-6'
						)}

						displayValue={(option: string) => stages.find(s => s.value === option)?.name || "Ongoing"}
						data-testid="staging-select"
						readOnly
					/>
					<Combobox.Button className="absolute inset-0 right-0 z-1" />
				</div>

				<Combobox.Button className="  absolute inset-y-0 mr-2 right-0 flex items-center rounded-r-md  focus:outline-none">
					<ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
				</Combobox.Button>

				<Combobox.Options className="absolute text-start z-50 mt-1 max-h-60  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-gray-900 ring-opacity-5 focus:outline-none sm:text-sm">
					{stages.map((option) => (
						<Combobox.Option
							key={option.value}
							value={option.value}
							className={({ active }) =>
								classNames(
									'relative cursor-default select-none  py-2 pl-3 pr-9',
									active ? 'bg-gray-200 ' : 'text-gray-900'
								)
							}
						>
							{({ active }) => (
								<div className="flex items-center text-center gap-4">
									<div className=" inset-y-0 left-0 flex items-center pl-0">
										<div style={{
											backgroundColor: renderStageColor(option)
										}} className={classNames(
											'w-2.5 h-2.5 rounded-full',
										)} />
									</div>
									<span className={classNames('block truncate', isSelected(option) && 'font-semibold')}>
										{option.name}
									</span>
								</div>
							)}
						</Combobox.Option>
					))}
				</Combobox.Options>

			</div>
		</Combobox>
	);
}
export default StageSelect;
