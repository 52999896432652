import { useRef, useEffect } from "react";

const ContentIsEditing = ({
	newHeader,
	setNewHeader,
	newContent,
	setNewContent,
}: {
	newHeader: string;
	setNewHeader: (value: string) => void;
	newContent: string;
	setNewContent: (value: string) => void;
}) => {
	const textareaRef = useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		if (textareaRef.current) {
			const { selectionStart, selectionEnd } = textareaRef.current;
			textareaRef.current.selectionStart = selectionStart;
			textareaRef.current.selectionEnd = selectionEnd;
		}
	}, [newContent]);

	return (
		<div className="flex flex-col gap-2">
			<div>
				<label className="block text-xs font-medium leading-tight text-gray-900">Name</label>
				<div className="mt-0.5">
					<input
						type="text"
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-max-700  sm:text-sm sm:leading-6"
						value={newHeader}
						onChange={(e) => setNewHeader(e.target.value)}
					/>
				</div>
			</div>
			<div>
				<label className="block text-xs font-medium leading-tight text-gray-900">Content</label>
				<div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-max-600 focus-within:ring-1 focus-within:ring-max-600">
					<textarea
						ref={textareaRef}
						rows={48}
						className="block w-full text-xs leading-tight whitespace-pre-line border-0 pt-2.5 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0"
						value={newContent}
						onChange={(e) => setNewContent(e.target.value)}
					/>
				</div>
			</div>
		</div>
	);
};

export default ContentIsEditing;
