import classNames from "classnames";
import { useRef, useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useSession } from "@supabase/auth-helpers-react";

// Helper function to convert HEX color to RGB array
const hexToRgb = (hex: string) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};

// Helper function to interpolate between two RGB colors
const interpolateColor = (color1: any, color2: any, factor: number) => {
	let result = color1.slice();
	for (let i = 0; i < 3; i++) {
		result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
	}
	return result;
};

const AudioLevels = ({
	darkColor = "#111827",
	brightColor = "#00FF00",
	fftSize = 256,
	barSpacing = 1,
	numBars = 64,
	recordingState = "inactive",
	audioStream,
}: any) => {
	const parentRef = useRef<any>(null);
	const canvasRef = useRef<any>(null);
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);
	const session = useSession();

	useEffect(() => {
		// Function to update size based on parent's size
		const updateSize = () => {
			if (parentRef.current) {
				const newWidth = parentRef.current.getBoundingClientRect().width;
				const newHeight = parentRef.current.getBoundingClientRect().height;
				setWidth(newWidth);
				setHeight(newHeight);
			}
		};

		// Initial update
		updateSize();

		// Update width on window resize
		window.addEventListener("resize", updateSize);

		// Cleanup
		return () => window.removeEventListener("resize", updateSize);
	}, []);

	useEffect(() => {
		let source: MediaStreamAudioSourceNode;
		let drawId: number;
		const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
		const analyser = audioContext.createAnalyser();
		analyser.fftSize = fftSize;
		const bufferLength = analyser?.frequencyBinCount;
		const dataArray = new Uint8Array(bufferLength);
		const binsPerBar = Math.floor(bufferLength / numBars);

		let canvasContext = canvasRef.current.getContext("2d");
		canvasContext.clearRect(0, 0, width, height);

		const draw = () => {
			drawId = requestAnimationFrame(draw);

			analyser.getByteFrequencyData(dataArray);

			canvasContext.clearRect(0, 0, width, height);

			const middleY = height / 2; // Middle of the canvas
			var barWidth = Math.floor(width / numBars - barSpacing);
			var totalBarsWidth = numBars * barWidth + (numBars - 1) * barSpacing;
			var barHeight;

			const darkColorRgb = hexToRgb(darkColor);
			const brightColorRgb = hexToRgb(brightColor);

			// Draw central blue line
			canvasContext.fillStyle = brightColor;
			canvasContext.fillRect(0, middleY - 1, width, 2);

			var x = width / 2; // Central point

			for (var i = 0; i < numBars; i++) {
				// Calculate the average frequency for this bar
				let avg = 0;
				for (var j = 0; j < binsPerBar; j++) {
					avg += dataArray[i * binsPerBar + j];
				}
				avg /= binsPerBar;
				barHeight = (avg / 255) * middleY; // Half for above, half for below

				const color = interpolateColor(darkColorRgb as any, brightColorRgb as any, avg / 255);
				canvasContext.fillStyle = `rgb(${color[0]},${color[1]},${color[2]})`;

				var barX;
				if (i % 2 === 0) {
					// Even iterations (including zero)
					barX = x + (i / 2) * (barWidth + barSpacing); // Draw to the right
				} else {
					// Odd iterations
					barX = x - ((i + 1) / 2) * (barWidth + barSpacing); // Draw to the left
				}

				// Draw bar above middle line
				canvasContext.fillRect(Math.floor(barX), middleY - barHeight, barWidth, barHeight);

				// Draw bar below middle line
				canvasContext.fillRect(Math.floor(barX), middleY, barWidth, barHeight);
			}
		};

		if (recordingState === "recording") {
			try {
				let stream = audioStream;
				source = audioContext.createMediaStreamSource(stream);

				source.connect(analyser);
				draw();
			} catch (err: any) {
				Sentry.captureException("error in audio levels", err);
			}
		}

		return () => {
			if (source) {
				source.disconnect(analyser);
			}

			if (drawId) {
				cancelAnimationFrame(drawId);
			}
		};
	}, [darkColor, brightColor, width, height, fftSize, recordingState, numBars]);

	return (
		<div ref={parentRef} className={classNames(recordingState === "recording" && "border-max-700", "w-full h-full")}>
			<canvas ref={canvasRef} width={width} height={height} />
		</div>
	);
};

export default AudioLevels;
