import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { getAppointments } from "@common/lib/api";
import { useDataContextApi } from "@providers/DataProvider";
import { useSession } from "@supabase/auth-helpers-react";
import { useAccountContext } from "@providers/AccountProvider";
import { useDispatch } from "react-redux";
import { setStatus } from "@redux/patientTableRecordsSlice";
import Spinner from "@common/components/Spinner";
import ArrowPathIcon from "@icons/refresh-cw-03.svg?react"
import CheckIcon from "@icons/check-circle.svg?react";
import * as Sentry from "@sentry/react";

const SyncAppointmentsBtn = ({ disabled }: { disabled: boolean }) => {
	const { isLoadingData, setIsLoadingData, selectedDate, refreshPatientRecords } = useDataContextApi();
	const { pmsIntegrationData } = useAccountContext();
	const session = useSession();
	const dispatch = useDispatch();

	const [isDisabled, setIsDisabled] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showCheckIcon, setShowCheckIcon] = useState(false);

	const getIntegratedAppointments = useCallback(async () => {
		let userId = session?.user?.id;
		if (!session || !userId) {
			Sentry.captureMessage("Session not found in SyncAppointmentsBtn.tsx", {
				level: "error",
			});
			toast.error("Uh oh, we're missing some data");
			return;
		}
		if (!pmsIntegrationData || pmsIntegrationData?.length === 0) {
			toast.error("Uh oh, no integration found");
			return;
		}

		setLoading(true);
		dispatch(setStatus("loading"));
		let queryStartDate = selectedDate?.startDate;

		//for every integration, get appointments
		const { newPatientRecords, error } = await getAppointments(
			session,
			userId,
			queryStartDate,
			pmsIntegrationData.map((m) => m.integration_name as string),
		);

		if (error) {
			toast.error("Uh oh, something went wrong syncing appointments");
			dispatch(setStatus("failed"));
			setLoading(false);
			return;
		}

		if (!newPatientRecords || newPatientRecords.length === 0) {
			toast.success("No new appointments to sync");
			dispatch(setStatus("succeeded"));
			setLoading(false);
			return;
		}
		toast.success("Appointments Synced!");
		dispatch(setStatus("succeeded"));
		setLoading(false);
		setShowCheckIcon(true);
		refreshPatientRecords();
	}, [session?.user?.id, selectedDate, setIsLoadingData, pmsIntegrationData]);

	useEffect(() => {
		if (!pmsIntegrationData || pmsIntegrationData?.length === 0 || isLoadingData) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [pmsIntegrationData, isLoadingData]);

	useEffect(() => {
		let timeoutId: NodeJS.Timeout;
		if (showCheckIcon) {
			timeoutId = setTimeout(() => {
				setShowCheckIcon(false);
			}, 5000);
		}

		return () => {
			clearTimeout(timeoutId);
		};
	}, [showCheckIcon]);

	return (
		<div className="flex flex-grow w-full  ">
			<button
				type="button"
				className={`flex w-full items-center justify-center rounded-md bg-white px-4 py-2.5 text-sm font-semibold hover:bg-gray-100 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-max-700 ${isDisabled || disabled ? "opacity-50 cursor-not-allowed" : "hover:cursor-pointer"}`}
				onClick={() => {
					if (!isDisabled && !disabled) {
						getIntegratedAppointments();
					}
				}}
				disabled={isDisabled || disabled}
			>
				<div className="flex flex-row items-center justify-center whitespace-nowrap">
					<span className=" flex items-center justify-center mr-1">
						{loading ? (
							<Spinner size="xsmall" />
						) : showCheckIcon ? (
							<CheckIcon className="h-5 w-5 inline-block transition-opacity duration-500" aria-hidden="true" />
						) : (
							<ArrowPathIcon className="h-4 w-4 mr-1" />
						)}
					</span>

					<span className="text-sm text-gray-700 font-semibold">Sync</span>
				</div>
			</button>
		</div>
	);
};
export default SyncAppointmentsBtn;
