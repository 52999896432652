import { useRef, useState, useEffect, useCallback, Fragment } from "react";
import { deleteTranscriptSection, deleteAllTranscriptSections } from "@common/lib/supabaseClient";
import { TranscriptSection } from "@common/utils/types";
import ConfirmModal from "@common/components/ConfirmModal";
import TranscriptionControlPanel from "./TranscriptionControlPanel";
import TranscriptLine from "./TranscriptionLine";
import { useRealtimeContextApi } from "@providers/RealtimeProvider";
import { Transition, Dialog } from "@headlessui/react";
import ReRunTranscriptModal from "./ReRunTranscriptModal";
import AudioDb, { INDEX_DB_VERSION } from "@common/classes/audioDb";
import { useDispatch } from "react-redux";
import { removeTranscriptSections } from "../../state/redux/activePatientDataSlice";
import { decrementRecordingCount } from "@redux/patientTableRecordsSlice";
import { toast } from "react-toastify";
import XIcon from "@icons/x-close.svg?react";

const TranscriptionModal = ({
	patientRecordId,
	transcriptionId,
	transcriptSections,
	isOpen,
	setIsOpen,
}: {
	patientRecordId: string;
	transcriptionId: string;
	transcriptSections: TranscriptSection[];
	isOpen: boolean;
	setIsOpen: (x: boolean) => void;
}) => {
	const localAudioDb = AudioDb.getInstance(INDEX_DB_VERSION);
	const dispatch = useDispatch();
	const { supaClientRef } = useRealtimeContextApi();

	const bottomListRef = useRef<HTMLDivElement | null>(null);
	const [prevSectionsLength, setPrevSectionsLength] = useState(0);
	const [prevSectionLastItemLoading, setPrevSectionLastItemLoading] = useState(false);
	const [transcriptToDelete, setTranscriptToDelete] = useState("");
	const [transcriptSectionToReRun, setTranscriptSectionToReRun] = useState<TranscriptSection>();
	const [isDeleteOneModalOpen, setIsDeleteOneModalOpen] = useState(false);
	const [isDeleteEntireModalOpen, setIsDeleteEntireModalOpen] = useState(false);
	const [isReRunModalOpen, setIsReRunModalOpen] = useState(false);
	//Disable for recording.
	const [isRecording, setIsRecording] = useState(false);

	// 1. HOOOKS
	useEffect(() => {
		// if there are new sections, scroll to bottom
		if (transcriptSections?.length > prevSectionsLength || prevSectionLastItemLoading) {
			bottomListRef.current?.scrollIntoView({ behavior: "smooth" });
		}
		if (transcriptSections.slice(-1)[0]?.data === "loading" && !prevSectionLastItemLoading) {
			bottomListRef.current?.scrollIntoView({ behavior: "smooth" });
		}

		setPrevSectionLastItemLoading(transcriptSections.slice(-1)[0]?.data === "loading" || false);
		setPrevSectionsLength(transcriptSections?.length || 0);
	}, [transcriptSections, transcriptSections.slice(-1)[0]?.data === "loading"]);

	// 2. FUNCTIONS
	//Removes and deletes a all a transcript section
	const removeAndDeleteTranscriptSection = useCallback(async () => {
		dispatch(removeTranscriptSections([transcriptToDelete]));
		dispatch(decrementRecordingCount({ transcriptionId: transcriptionId, sectionIds: [transcriptToDelete] }));
		await localAudioDb?.deleteTranscriptSectionAudio(transcriptionId, transcriptToDelete);
		await deleteTranscriptSection(transcriptToDelete);
	}, [transcriptToDelete]);

	// TODO move to transcrptionLine so that we can use uploadProgress and set errors
	const deleteTranscription = useCallback(async () => {
		const transcriptSectionIds = transcriptSections.map((section: TranscriptSection) => section.id);
		dispatch(removeTranscriptSections(transcriptSectionIds));
		dispatch(decrementRecordingCount({ transcriptionId: transcriptionId, sectionIds: transcriptSectionIds }));
		await deleteAllTranscriptSections(transcriptSectionIds);
	}, [transcriptSections]);

	return (
		<>
			<Transition.Root show={isOpen} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-40"
					onClose={() => {
						if (isDeleteEntireModalOpen || isDeleteOneModalOpen || isReRunModalOpen) return;
						if (isRecording) {
							toast.info("Please stop recording before closing.");
							return;
						}
						setIsOpen(false);
					}}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-40 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center md:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-2xl">
									<div className="bg-white">
										<div className="text-right p-2">
											<button
												type="button"
												className="inline-flex justify-center rounded-md border border-transparent bg-transparent p-2 text-sm font-medium text-neutral-900  focus:outline-none focus-visible:ring-2 focus-visible:ring-neutral-500 focus-visible:ring-offset-2"
												onClick={() => {
													if (isRecording) {
														toast.info("Please stop recording before closing.");
														return;
													}
													setIsOpen(false);
												}}
											>
												<XIcon className="h-6 w-6" aria-hidden="true" />
											</button>
										</div>
									</div>

									<div className="flex flex-col bg-white">
										<div className="max-h-96 h-96 overflow-auto px-2">
											{transcriptSections?.length === 0 ? (
												<div className="text-center">
													<span>No transcripts</span>
												</div>
											) : (
												transcriptSections.map((item: TranscriptSection, index: number) => (
													<TranscriptLine
														key={index}
														transcriptSection={item}
														transcriptId={transcriptionId}
														onDelete={() => {
															setTranscriptToDelete(item?.id as string);
															setIsDeleteOneModalOpen(true);
														}}
														onReRun={() => {
															setTranscriptSectionToReRun(item);
															setIsReRunModalOpen(true);
														}}
													/>
												))
											)}
											<div ref={bottomListRef} />
										</div>
									</div>

									<div className="p-4">
										<TranscriptionControlPanel
											transcriptionId={transcriptionId}
											setIsDeleteEntireModalOpen={setIsDeleteEntireModalOpen}
											supaClientRef={supaClientRef}
											setIsRecording={setIsRecording}
										/>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

			<ReRunTranscriptModal
				isReRunModalOpen={isReRunModalOpen}
				setIsReRunModalOpen={setIsReRunModalOpen}
				transcriptSectionToReRun={transcriptSectionToReRun}
				setTranscriptSectionToReRun={setTranscriptSectionToReRun}
				patientRecordId={patientRecordId}
				transcriptionId={transcriptionId}
			/>

			<ConfirmModal
				id="DeleteOneTranscript"
				headerText="Delete this transcript section?"
				cancelButtonText="Cancel"
				confirmButtonText="Delete"
				confirmButtonColor="bg-red-600"
				confirmHoverButtonColor="bg-red-500"
				onCancel={() => {
					setIsDeleteOneModalOpen(false);
				}}
				onConfirm={() => {
					removeAndDeleteTranscriptSection();
					setTranscriptToDelete("");
					setIsDeleteOneModalOpen(false);
				}}
				isOpen={isDeleteOneModalOpen}
			/>
			<ConfirmModal
				id="DeleteEntireTranscript"
				headerText="Delete the entire transcript?"
				cancelButtonText="Cancel"
				confirmButtonText="Delete"
				confirmButtonColor="bg-red-600"
				confirmHoverButtonColor="bg-red-500"
				onCancel={() => {
					setIsDeleteEntireModalOpen(false);
				}}
				onConfirm={() => {
					deleteTranscription();
					setIsDeleteEntireModalOpen(false);
				}}
				isOpen={isDeleteEntireModalOpen}
			/>
		</>
	);
};
export default TranscriptionModal;
