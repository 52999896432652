import { RecordingState, ErrorType, PatientRecordTableRow, DocumentType, TranscriptSection } from "@common/utils/types";
import StageSelect from "@/common/components/StageSelect";
import classNames from "classnames";
import PatientNameCol from "./PatientNameCol";
import { useCallback, useState } from "react";
import { useSession } from "@supabase/auth-helpers-react";
import { isRecordingError } from "@common/hooks/useAudioRecorder";
import { Link } from "react-router-dom";
import { useAudioRecordingProvider } from "@features/list-view/state/recordingProvider";
import { transcribeAudio } from "@common/lib/api";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useRealtimeContextApi } from "@providers/RealtimeProvider";
import { addSelectedTableRecord, removeSelectedTableRecord } from "@redux/patientTableRecordsSlice";
import { supabase } from "@common/lib/supabaseClient";
import FileSearchIcon from "@icons/file-search-02.svg?react";
import MicrophoneIcon from "@icons/microphone-01.svg?react";
import EditIcon from "@icons/edit-05.svg?react";
import * as Sentry from "@sentry/react";

interface NotRecordingProps {
	patient: PatientRecordTableRow;
	index: number;
	handleEditButtonClick: (patient: any) => void;
	activeRecordingId: string | null;
	setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
	uploadAudio: (
		audioFile: File,
		audioName: string,
		transcriptId: string,
		transcriptSectionId: string,
		patientRecord: PatientRecordTableRow,
		isQuickStart?: boolean,
	) => Promise<{
		transcriptSection: TranscriptSection | null;
		err: any;
		is409: Boolean;
	}>;
	uploadProgress: number;
	isUploading: boolean;
}

function NotRecordingRow({
	patient,
	index,
	handleEditButtonClick,
	activeRecordingId,
	setOpenModal,
	uploadAudio,
	uploadProgress,
	isUploading,
}: NotRecordingProps) {
	const [retryLoading, setRetryLoading] = useState(false);

	const { selectedTableRecords } = useSelector((state: any) => state.patientTableRecords);
	const session = useSession();
	const dispatch = useDispatch();
	const { supaClientRef } = useRealtimeContextApi();

	const { selectedDocuments } = useSelector((state: any) => state.documents);

	const {
		recorderControls: { startRecording, recordingState, isQuickStart },
	} = useAudioRecordingProvider();

	const hasError = (errorType: ErrorType) => {
		return patient.transcriptions?.transcript_sections?.some((section) => section.error === errorType) || false;
	};

	const isDisabled = useCallback(
		(rState: String, patient: any) => {
			// check if rState is recording
			if (
				((rState === RecordingState.recording || rState === RecordingState.paused) &&
					patient?.id !== activeRecordingId) ||
				isQuickStart
			) {
				return true;
			} else {
				return false;
			}
		},
		[activeRecordingId, isQuickStart],
	);

	const handleTranscriptionErrors = useCallback(async () => {
		if (!hasError(ErrorType.TRANSCRIBE_ERROR)) return;

		const erroredSections =
			patient.transcriptions?.transcript_sections?.filter((section) => section.error === ErrorType.TRANSCRIBE_ERROR) ||
			[];
		if (erroredSections.length === 0) return;

		// get session, make sure it is current
		const {
			data: { session },
			error,
		} = await supabase.auth.getSession();

		if (error) {
			Sentry.captureException(error);
			toast.error("Error while getting session");
			return;
		}

		if (!session) {
			Sentry.captureMessage("Session not found in NotRecordingRow.tsx", {
				level: "error",
			});
			toast.error("Uh oh, we're missing some data");
			return;
		}

		setRetryLoading(true);
		try {
			for (const section of erroredSections) {
				const { error } = await transcribeAudio(
					session,
					patient.transcriptions?.id || "",
					section.id,
					section?.unprocessed_filename || "",
					supaClientRef.current,
					patient.id,
					patient?.patient_name || "",
					patient?.doctors?.id || "",
					selectedDocuments.map((doc: DocumentType) => doc.id as string) || [],
				);
				if (error) throw new Error("Error while retrying transcription");
			}
		} catch (error) {
			Sentry.captureException(error);
			toast.error("Error while retrying transcription.");
		} finally {
			setRetryLoading(false);
		}
	}, [patient, session, supaClientRef, selectedDocuments]);

	const onAudioDataAvailable = async (
		audioFile: File,
		patientRecord: PatientRecordTableRow,
		transcriptSectionId: string,
	) => {
		if (!patientRecord.transcriptions?.id || !patientRecord.id) {
			Sentry.captureMessage("Transcription ID or Patient ID not found in onAudioDataAvailable - TableRow");
			return;
		}

		try {
			// upload audio
			const uploadResponse = await uploadAudio(
				audioFile,
				audioFile.name,
				patientRecord.transcriptions.id,
				transcriptSectionId,
				patientRecord,
			);
			if (uploadResponse.err) {
				throw new Error("Error while uploading audio");
			}
		} catch (error) {
			Sentry.captureException(error);
			toast.error("Error while uploading audio");
		}
	};

	const handleSelectionChange = (records: PatientRecordTableRow, isChecked: boolean) => {
		if (isChecked) {
			dispatch(addSelectedTableRecord(records));
		} else {
			dispatch(removeSelectedTableRecord({ id: records.id }));
		}
	};

	return (
		<>
			<tr
				key={`${patient?.id}-${index}-large`}
				className={classNames(
					patient?.id !== activeRecordingId &&
						!isQuickStart &&
						(recordingState === RecordingState.recording || recordingState === RecordingState.paused)
						? "opacity-80"
						: "truncate",
					hasError(ErrorType.UPLOAD_ERROR) || hasError(ErrorType.TRANSCRIBE_ERROR) ? "bg-red-50" : "bg-white",
					"hidden sm:table-row sm:h-auto",
				)}
			>
				{/* SELECT RECORD */}
				{/* Small Viewport Mobile Col 1 */}
				<td className="py-4 text-center ">
					<input
						disabled={isDisabled(recordingState, patient) || isUploading}
						type="checkbox"
						className="form-checkbox h-4 w-4 text-max-700 border-gray-300 rounded focus:ring-max-600"
						checked={selectedTableRecords.some((appt: PatientRecordTableRow) => appt.id === patient.id)}
						onChange={(e) => handleSelectionChange(patient, e.target.checked)}
					/>
				</td>
				{/* Small Viewport Mobile Col 1 */}
				<td
					className={classNames(
						isDisabled(recordingState, patient) ? "text-gray-400" : "text-gray-900",
						"w-1/2  py-4 px-1 sm:pl-3 sm:pr-3 text-xs sm:text-sm font-medium sm:w-auto",
					)}
				>
					<div className="flex justify-between items-center">
						<div className="text-wrap inline-flex w-full flex-col justify-start">
							<div className="truncate max-w-[100px] md:max-w-[125px] lg:max-w-[150px]">
								{patient?.patient_name ? (
									<span className="whitespace-normal"> {patient?.patient_name} </span>
								) : (
									<span className={classNames(!patient?.patient_name && "text-gray-500")}>-</span>
								)}
							</div>

							<dl className="mt-2 lg:hidden font-normal">
								<div>
									<PatientNameCol
										isUploading={isUploading}
										uploadProgress={uploadProgress}
										patient={patient}
										recordingState={recordingState}
										selectedTableRecords={selectedTableRecords}
										setOpenModal={setOpenModal}
										retryLoading={retryLoading}
										handleTranscriptionErrors={handleTranscriptionErrors}
										hasError={hasError}
										isDisabled={isDisabled}
									/>

									<dt className="sr-only sm:hidden">Time</dt>
									<dd
										className={classNames(
											isDisabled(recordingState, patient) ? "text-gray-400" : "text-gray-500",
											"mt-2 truncate text-[10px] sm:hidden",
										)}
									>
										{new Date(patient?.created_at as string).toLocaleTimeString("en-US", {
											hour: "numeric",
											minute: "numeric",
											hour12: true,
										})}
									</dd>
								</div>
							</dl>
						</div>

						{/* INLINE EDIT  */}
						<div>
							<button
								id="in-line-edit-patient"
								type="button"
								onClick={() => handleEditButtonClick(patient)}
								disabled={isDisabled(recordingState, patient)}
								className={classNames(
									isDisabled(recordingState, patient)
										? "hover:cursor-not-allowed text-gray-200"
										: "text-gray-400 hover:bg-gray-100",
									"sm:inline-block ml-1 rounded-md   px-2 py-1 text-sm font-semibold",
								)}
							>
								<EditIcon className="h-4 w-4" aria-hidden="true" />
							</button>
						</div>
					</div>
				</td>
				{/* Medium Viewport and Up */}
				<td
					className={classNames(
						isDisabled(recordingState, patient) ? "text-gray-400" : "text-gray-500",
						"hidden truncate lg:px-3 py-4 text-sm sm:table-cell",
					)}
				>
					{new Date(patient?.scheduled_at as string).toLocaleTimeString("en-US", {
						hour: "numeric",
						minute: "numeric",
						hour12: true,
					})}
				</td>
				{/* Patient Name */}
				<td
					className={classNames(
						isDisabled(recordingState, patient) ? "text-gray-400" : "text-gray-500",
						"hidden truncate max-w-[120px] px-1 py-4 text-sm xl:table-cell",
					)}
				>
					{patient?.doctors?.doctor_name || "None"}
				</td>
				{/* State */}
				<td
					className={classNames(
						isDisabled(recordingState, patient) ? "text-gray-400" : "text-gray-500",
						"hidden md:w-auto lg:table-cell  px-1 py-2 text-xs  md:px-3 md:py-4 md:text-sm  ",
					)}
				>
					<PatientNameCol
						isUploading={isUploading}
						uploadProgress={uploadProgress}
						patient={patient}
						recordingState={recordingState}
						selectedTableRecords={selectedTableRecords}
						setOpenModal={setOpenModal}
						retryLoading={retryLoading}
						handleTranscriptionErrors={handleTranscriptionErrors}
						hasError={hasError}
						isDisabled={isDisabled}
					/>
				</td>
				<td
					id="in-line-stage-buttons"
					className={classNames(
						isDisabled(recordingState, patient) ? "text-gray-400" : "text-gray-900",
						`hidden sm:table-cell px-1 lg:px-1 py-4 text-sm whitespace-nowrap items-center`,
					)}
				>
					<StageSelect
						currentStage={patient?.stage as string}
						patientId={patient?.id as string}
						disabled={isDisabled(recordingState, patient) || isUploading}
					/>
				</td>

				{/* Small Viewport Mobile Col 3 */}
				<td className="py-4 w-1/2 sm:w-auto sm:pl-0 pr-4 text-sm font-medium sm:pr-6 table-cell" colSpan={1}>
					<div className="flex w-full flex-col items-center justify-center">
						{
							<div className="w-full flex items-center justify-end gap-x-2">
								<button
									id="in-line-start-recording"
									className={classNames(
										isDisabled(recordingState, patient) || isRecordingError(recordingState) || isUploading
											? "text-gray-400 bg-max-200"
											: "text-white bg-max-700 hover:bg-max-800  focus-visible:outline-bg-max-700 ",
										"w-12 h-12 md:w-20 md:h-auto inline-flex items-center justify-center gap-x-1.5 rounded-full md:rounded-md  px-3 py-2 text-sm font-semibold  shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ",
									)}
									disabled={isDisabled(recordingState, patient) || isRecordingError(recordingState) || isUploading}
									onClick={(e) => {
										e.stopPropagation();
										startRecording(patient, onAudioDataAvailable, supaClientRef.current as any);
									}}
								>
									<MicrophoneIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
									<span className="hidden md:inline-block">
										{patient?.transcriptions?.transcript_sections?.length === 0 ? "Start" : "Add"}{" "}
									</span>
									<span className="sr-only">, {patient.patient_name}</span>

								</button>

								<Link
									id="in-line-view-appointment"
									type="button"
									className={classNames(
										isDisabled(recordingState, patient) ||
											recordingState === "recording" ||
											recordingState === "paused" ||
											isUploading
											? "bg-gray-50 text-gray-400 pointer-events-none"
											: "bg-white text-gray-900 hover:bg-gray-50",
										"h-12 w-12 md:w-auto md:h-auto inline-flex items-center justify-center gap-x-1.5 rounded-full md:rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300",
									)}
									to={
										isDisabled(recordingState, patient) ||
											recordingState === "recording" ||
											recordingState === "paused" ||
											isUploading
											? "#"
											: `/appointments/${patient.id}`
									}
									state={{ appointmentId: patient.id }}
									onClick={(e) => e.stopPropagation()}
								>
									<FileSearchIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
									<span className="hidden md:inline-block">View</span>
									<span className="sr-only">, {patient.patient_name}</span>

								</Link>
							</div>
						}
						<div id="in-line-stage-buttons" className="w-full flex justify-end sm:hidden mt-3">
							<StageSelect
								currentStage={patient?.stage as string}
								patientId={patient?.id as string}
								disabled={isDisabled(recordingState, patient) || isUploading}
							/>
						</div>
					</div>
				</td>
			</tr>
			{/* // -------------------------------------------SM AND BELOW -------------------------------------*/}
			<tr
				key={`${patient?.id}-${index}-small`}
				className={classNames(
					patient?.id !== activeRecordingId &&
						!isQuickStart &&
						(recordingState === RecordingState.recording || recordingState === RecordingState.paused)
						? "opacity-80"
						: "truncate",
					hasError(ErrorType.UPLOAD_ERROR) || hasError(ErrorType.TRANSCRIBE_ERROR) ? "bg-red-50" : "bg-white",
					"sm:hidden",
				)}
			>
				<td className="py-4 text-center sm:hidden min-h-64">
					<input
						disabled={isDisabled(recordingState, patient) || isUploading}
						type="checkbox"
						className="form-checkbox h-4 w-4 text-max-700 border-gray-300 rounded focus:ring-max-600"
						checked={selectedTableRecords.some((appt: PatientRecordTableRow) => appt.id === patient.id)}
						onChange={(e) => handleSelectionChange(patient, e.target.checked)}
					/>
				</td>
				{/* Small Viewport Mobile Col 1 */}
				<td
					className={classNames(
						isDisabled(recordingState, patient) ? "text-gray-400" : "text-gray-900",
						"w-full sm:hidden",
					)}
					colSpan={2}
				>
					<div className="flex flex-col justify-evenly min-h-56">
						<div className="flex flex-row justify-between items-center">
							<div className="flex justify-center w-1/2">
								<div className="flex flex-grow flex-col ms-6 gap-y-0.5 justify-start text-sm mt-6">
									<div className="truncate max-w-[130px]">
										{patient?.patient_name ? (
											<span className="font-bold whitespace-normal"> {patient?.patient_name} </span>
										) : (
											<span className="text-gray-500">-</span>
										)}
									</div>
									<span className="text-xs font-normal">
										{new Date(patient?.scheduled_at as string).toLocaleTimeString("en-US", {
											hour: "numeric",
											minute: "numeric",
											hour12: true,
										})}
									</span>
									<PatientNameCol
										isUploading={isUploading}
										uploadProgress={uploadProgress}
										patient={patient}
										recordingState={recordingState}
										selectedTableRecords={selectedTableRecords}
										setOpenModal={setOpenModal}
										retryLoading={retryLoading}
										handleTranscriptionErrors={handleTranscriptionErrors}
										hasError={hasError}
										isDisabled={isDisabled}
									/>
								</div>
							</div>

							<div className="w-1/2 flex items-center justify-evenly gap-x-2">
								<button
									id="in-line-add-recording"
									className={classNames(
										isDisabled(recordingState, patient) || isRecordingError(recordingState) || isUploading
											? "text-gray-400 bg-max-200"
											: "text-white bg-max-700 hover:bg-max-800  focus-visible:outline-bg-max-700 ",
										"w-14 h-14 rounded-full md:w-20 md:h-auto inline-flex whitespace-nowrap items-center justify-center gap-x-1.5 md:rounded-md  px-2.5 py-1.5 text-sm font-semibold  shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ",
									)}
									disabled={isDisabled(recordingState, patient) || isRecordingError(recordingState) || isUploading}
									onClick={(e) => {
										e.stopPropagation();
										startRecording(patient, onAudioDataAvailable, supaClientRef.current as any);
									}}
								>
									<MicrophoneIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
									<span className="hidden md:inline-block">
										{patient?.transcriptions?.transcript_sections?.length === 0 ? "Start" : "Add"}
									</span>
									<span className="sr-only">, {patient?.patient_name}</span>

								</button>

								<Link
									type="button"
									className={classNames(
										isDisabled(recordingState, patient) ||
											recordingState === "recording" ||
											recordingState === "paused" ||
											isUploading
											? "bg-gray-50 text-gray-400 pointer-events-none"
											: "bg-white text-gray-900 hover:bg-gray-50",
										"h-14 w-14 md:w-auto md:h-auto inline-flex items-center justify-center gap-x-1.5 rounded-full md:rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300",
									)}
									to={
										isDisabled(recordingState, patient) ||
											recordingState === "recording" ||
											recordingState === "paused" ||
											isUploading
											? "#"
											: `/appointments/${patient.id}`
									}
									state={{ appointmentId: patient.id }}
									onClick={(e) => e.stopPropagation()}
								>
									<FileSearchIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
									<span className="hidden md:inline-block">View</span>
									<span className="sr-only">, {patient.patient_name}</span>

								</Link>
							</div>
						</div>

						<div id="in-line-stage-buttons" className="flex flex-row items-center justify-center mt-6 mb-4">
							<StageSelect
								currentStage={patient?.stage as string}
								patientId={patient?.id as string}
								disabled={isDisabled(recordingState, patient) || isUploading}
							/>
						</div>
					</div>
				</td>
			</tr>
		</>
	);
}

export default NotRecordingRow;
