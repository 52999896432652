import { useCompletion } from "ai/react";
import { useState } from "react";
import { ChatCompletions, DocumentStatus } from "../../../common/utils/types";
import { useAccountContext } from "@providers/AccountProvider";
import { useDispatch, useSelector } from "react-redux";
import { addOrUpdateTab, resetProgress, updateDocumentInTab, updateDocumentThunk } from "../state/redux/activeDocumentsSlice";
import { supabase } from "@common/lib/supabaseClient";
import { VITE_HAPPYDOC_API_SERVER_URL } from "@common/utils/constants";
import { getPatientData } from "./redux/activePatientDataSlice";
import { incrementDocumentCount } from "@/state/redux/patientTableRecordsSlice";
import { cloneDocument } from "@/common/utils/helpers";
import * as Sentry from "@sentry/react";

interface UseGenerateProps {
	setCompletionId: (value: string | null) => void;
}

const useGenerateDocuments = ({ setCompletionId }: UseGenerateProps) => {
	const { accountData } = useAccountContext();
	const dispatch = useDispatch();
	const activePatient = useSelector(getPatientData);
	const [currentMessageId, setCurrentMessageId] = useState<string | null>(null);

	//Streaming Utility Library
	const {
		completion,
		complete,
		isLoading: isLoadingCompletion,
		stop,
	} = useCompletion({
		api: `${VITE_HAPPYDOC_API_SERVER_URL}/generate/generate`,
	});

	/**
	 * Generate Document
	 * @param newChatMessage - the new record that will be populated by the generated document
	 * @param tabId - tab id of the document
	 * @param multiplePatientCase - boolean to check if the document is generated for multiple patients
	 * @param patientNameToParse - patient name to parse in case of multiple patients
	 * @description This function is used to generate document for the chat message in the chat box.
	 * Update the document data in the tab state in redux store and also remove the patient data from local storage
	 * if the document is generated successfully. If there is an error in generating document,
	 * then update the document data in the tab state in redux store with error message.
	 * @returns void
	 */
	const generateDocument =
		async (
			newChatMessage: ChatCompletions,
			tabId: string,
			multiplePatientCase: boolean = false,
			patientNameToParse: string = "",
		) => {
			try {
				// get session
				const { data: sessionData } = await supabase.auth.getSession();
				// call generate Documents API
				let data = await complete("", {
					body: {
						document_type_id: newChatMessage?.document_type_id,
						transcriptionId: newChatMessage?.transcription_id,
						userId: accountData?.id ? accountData?.id : sessionData?.session?.user?.id,
						patientRecordId: newChatMessage?.patient_record_id,
						newMessageId: newChatMessage?.id,
						respondAsStream: true,
						multiplePatientCase: multiplePatientCase,
						patientNameToParse: patientNameToParse,
					},
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${sessionData?.session?.access_token}`,
					},
				});

				//Update Data
				if (data) {
					setCompletionId(null);
					setCurrentMessageId(null);

					// data or completion??
					let newChatMessageClone = cloneDocument(newChatMessage, completion, DocumentStatus.Completed);

					dispatch(updateDocumentInTab({ tabId, document: newChatMessageClone }));
					dispatch(
						addOrUpdateTab({
							tabId: tabId,
							isLoading: false,
						}),
					);
					dispatch(resetProgress({ tabId: tabId }));

					// increment document count in the patient table unpon successful document generation
					if (newChatMessage?.patient_record_id)
						dispatch(incrementDocumentCount({ id: newChatMessage?.patient_record_id }));

					return;
				}

				if (!data) throw new Error("Error generating document");
			} catch (e: any) {
				setCompletionId(null);
				setCurrentMessageId(null);

				// Check if the stop function was called to differentiate between actual errors and manual stops
				if (e.message !== "Request aborted") {
					Sentry.captureException(e);

					let newChatMessageClone = cloneDocument(newChatMessage, "Something went wrong, please refresh and try again.", DocumentStatus.Cancelled);

					// set cancelled in state and supa
					dispatch(updateDocumentInTab({ tabId, document: newChatMessageClone }));
					dispatch(updateDocumentThunk({ tabId, document: newChatMessageClone }) as any);
				}

				dispatch(addOrUpdateTab({ tabId: tabId, isLoading: false }));
			}
		};

	return {
		generateDocument,
		currentMessageId,
		setCurrentMessageId,
		stop,
		completion,
		isLoadingCompletion: isLoadingCompletion,
	};
};
export default useGenerateDocuments;
