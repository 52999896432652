import { Fragment, useCallback, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useSession } from "@supabase/auth-helpers-react";
import useFileUpload from "../../../../common/hooks/useFileUpload";
import { transcribeAudio } from "../../../../common/lib/api";
import { isAudioFileInSupabase } from "../../../../common/lib/supabaseClient";
import { useRealtimeContextApi } from "@providers/RealtimeProvider";
import AudioDb, { INDEX_DB_VERSION } from "@common/classes/audioDb";
import { useDispatch } from "react-redux";
import { updateOrAddTranscriptSection } from "../../state/redux/activePatientDataSlice";
import * as Sentry from "@sentry/react";

function ReRunOneTranscriptModal({
	isReRunModalOpen,
	setIsReRunModalOpen,
	setTranscriptSectionToReRun,
	transcriptionId,
	transcriptSectionToReRun,
	patientRecordId,
}: {
	isReRunModalOpen: boolean;
	setIsReRunModalOpen: (x: boolean) => void;
	setTranscriptSectionToReRun: (x: any) => void;
	transcriptionId: string;
	transcriptSectionToReRun: any;
	patientRecordId: string;
}) {
	const localAudioDb = AudioDb.getInstance(INDEX_DB_VERSION);
	const dispatch = useDispatch();

	const { supaClientRef } = useRealtimeContextApi();

	const { uploadAudio } = useFileUpload();
	const session = useSession();

	const [retryTemp, setRetryTemp] = useState(0);

	const reRunTranscriptSection = useCallback(
		async (retryTemp: number | undefined) => {
			if (
				!session?.user?.id ||
				!transcriptionId ||
				!transcriptSectionToReRun ||
				!transcriptSectionToReRun?.id ||
				!transcriptSectionToReRun?.unprocessed_filename ||
				retryTemp === undefined
			) {
				return;
			}
			dispatch(
				updateOrAddTranscriptSection({
					transcriptId: transcriptionId,
					transcriptSection: { ...transcriptSectionToReRun, data: "loading" },
				}),
			);

			//Check File Name is in Supabase
			let isAudioInSupabase = await isAudioFileInSupabase(
				session?.user?.id,
				transcriptionId,
				transcriptSectionToReRun.unprocessed_filename,
			);

			//If its not get it from local db
			if (!isAudioInSupabase) {
				//Attempt to get it from local db and upload
				let localAudio = await localAudioDb?.getTranscriptSectionAudio(transcriptionId, transcriptSectionToReRun.id);
				if (localAudio) {
					const audioBlob = localAudio.audioData;
					let audioName = transcriptSectionToReRun?.unprocessed_filename.substring(
						0,
						transcriptSectionToReRun?.unprocessed_filename.lastIndexOf("."),
					);
					let type = audioBlob?.type;
					const audioFile = new File([audioBlob], audioName, {
						type: type,
					});

					let patientRecord = {
						id: patientRecordId,
						transcriptions: {
							id: transcriptionId,
						},
					};

					const resp = await uploadAudio(
						audioFile,
						transcriptSectionToReRun.unprocessed_filename,
						transcriptionId,
						transcriptSectionToReRun.id,
						patientRecord,
					);
					if (resp?.err) {
						Sentry.captureException(resp?.err);
						return;
					}
					if (resp?.is409) {
						return; // If the upload is trying
					}
				}
			} else {
				if (!session) {
					Sentry.captureMessage("Session not found in ReRunOneTranscriptModal.tsx", {
						level: "error",
					});
				}

				const resp = await transcribeAudio(
					session,
					transcriptionId,
					transcriptSectionToReRun.id,
					transcriptSectionToReRun.unprocessed_filename,
					supaClientRef.current,
					patientRecordId,
				);

				return;
			}
		},
		[transcriptSectionToReRun, session, transcriptionId, supaClientRef.current],
	);

	return (
		<Transition.Root show={isReRunModalOpen} as={Fragment}>
			<Dialog as="div" className="relative z-40" onClose={() => setIsReRunModalOpen(false)}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-40 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
								<div className="p-6">
									<h1 className="text-start text-gray-900 mb-3">Re-transcribe this section?</h1>
									<p className="text-gray-500 text-[14px] text-start mb-2">
										For best outcomes, retry on the device that made the recording.
									</p>

									<div className="mt-4">
										<div className="text-sm font-semibold">Temperature</div>
										<div className="flex w-full gap-4 mt-2 items-center">
											<input
												type="range"
												min={0}
												max="100"
												value={retryTemp * 100}
												onChange={(e) => setRetryTemp(parseInt(e.target.value) / 100)}
												step={10}
												className="flex-1"
												style={{
													appearance: "none",
													height: "15px",
													borderRadius: "5px",
													outline: "none",
													background: `linear-gradient(to right, #403DC3 ${retryTemp * 100}%, #D1D5DB ${retryTemp * 100
														}%)`,
													WebkitAppearance: "none",
													WebkitTransition: "opacity .2s",
												}}
											/>
											<div className="text-sm font-semibold">{retryTemp.toFixed(2)}</div>
											<button
												className="bg-max-700  text-white px-4 py-2 rounded-md font-semibold hover:bg-max-800  "
												onClick={() => setRetryTemp(0)}
											>
												Reset to Default
											</button>
										</div>
									</div>

									<div className="flex gap-4 justify-center mt-6">
										<button
											className="bg-gray-400 text-white px-4 py-2 rounded-md font-semibold hover:bg-gray-300"
											onClick={() => setIsReRunModalOpen(false)}
										>
											Cancel
										</button>
										<button
											className=" bg-max-700 text-white px-4 py-2 rounded-md font-semibold hover:bg-max-800 "
											onClick={(e) => {
												e.preventDefault();
												reRunTranscriptSection(retryTemp);
												setTranscriptSectionToReRun(undefined);
												setIsReRunModalOpen(false);
											}}
										>
											Yes, Redo
										</button>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}

export default ReRunOneTranscriptModal;
