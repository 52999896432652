import SearchBar from "@/common/components/SearchBar";
import { moveRecording } from "@/common/lib/supabaseClient";
import { Appointment, TranscriptSection } from "@/common/utils/types";
import { Transition, Dialog } from "@headlessui/react";
import { useSession } from "@supabase/auth-helpers-react";
import classNames from "classnames";
import moment from "moment";
import { Fragment, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { removeTranscriptSections } from "../../state/redux/activePatientDataSlice";
import { decrementRecordingCount } from "@/state/redux/patientTableRecordsSlice";

type MoveRecordingModalProps = {
	patientId: string;
	transcriptSection: TranscriptSection;
	onCancel: () => void;
	onConfirm: () => void;
	onExit?: () => void;
	isOpen: boolean;
};

const MoveRecordingModal: React.FC<MoveRecordingModalProps> = ({
	patientId,
	transcriptSection,
	onCancel,
	onConfirm,
	onExit,
	isOpen,
}) => {
	const cancelButtonRef = useRef(null);
	const handleClose = (e: { preventDefault: () => void }) => {
		onCancel();
		setNewAppt(null);
	};

	const session = useSession();
	const dispatch = useDispatch();

	const handleConfirm = async (e: { preventDefault: () => void }) => {
		if (newAppt === null) return;
		e.preventDefault();

		const { error } = await moveRecording(
			transcriptSection.id,
			transcriptSection.transcription_id || "",
			newAppt?.id,
			session?.user.id || transcriptSection.account_id || "",
			transcriptSection.unprocessed_filename || "",
		);
		if (error) {
			toast.error("Error moving recording!");
		} else {
			toast.success("Recording moved successfully!");
			dispatch(removeTranscriptSections([transcriptSection.id]));
			dispatch(
				decrementRecordingCount({
					transcriptionId: transcriptSection.transcription_id || "",
					sectionIds: [transcriptSection.id],
				}),
			);
		}

		onConfirm();
		setNewAppt(null);
	};

	const [newAppt, setNewAppt] = useState<Appointment | null>(null);

	const formattedDate = moment(newAppt?.scheduled_at).format("MM/DD/YY");
	const formattedTime = moment(newAppt?.scheduled_at).format("h:mm A");

	return (
		<Transition.Root show={isOpen} as={Fragment}>
			<Dialog as="div" className="relative z-40" open={isOpen} onClose={onExit ? onExit : onCancel}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-40 w-screen overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-[24px] text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
								<div className=" text-left">
									<Dialog.Title as="h1" className="text-xl font-semibold leading-6 text-gray-900">
										Move Recording
									</Dialog.Title>
								</div>

								<div className="mt-[24px]">
									<p className="text-[14px] text-gray-500">
										Select a recent patient to move this recording to.
									</p>
								</div>

								<div className="mt-[24px]">
									<SearchBar
										lastNDays={7}
										notId={patientId}
										onSelect={(appointment) => {
											setNewAppt(appointment);
										}}
									/>
								</div>

								{newAppt && (
									<div className={`mt-[24px] cursor-default select-none px-4 py-[6px] text-gray-800 bg-max-50`}>
										<div className="font-semibold">{`${newAppt?.patient_name}`}</div>
										<div className={`text-xs text-gray-500 bg-max-50`}>{`${formattedDate}, ${formattedTime}`}</div>
									</div>
								)}

								<div className="mt-[24px]">
									<p className="text-sm text-[14px] text-gray-500">
										NOTE: Any existing documents will not be re-generated automatically.
									</p>
								</div>

								<div className="flex w-full w-10 mt-[24px] justify-between">
									<button
										type="button"
										className=" mt-3 inline-flex  justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
										onClick={handleClose}
										ref={cancelButtonRef}
									>
										Cancel
									</button>
									<button
										type="button"
										disabled={newAppt === null}
										className={classNames(
											`inline-flex ${newAppt === null ? "bg-gray-300" : "bg-max-700"
											} justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-max-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  sm:col-start-2`,
										)}
										onClick={handleConfirm}
									>
										Move Recording
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default MoveRecordingModal;
