import classNames from "classnames";
import XCloseIcon from "@icons/x-close.svg?react";
import AlertIcon from "@icons/alert-triangle.svg?react";
import CheckIcon from "@icons/check-circle.svg?react";
import InfoCircleIcon from "@icons/info-circle.svg?react";

// Define the props interface
interface AlertProps {
	alertType?: "error" | "warning" | "success" | "info";
	title?: string;
	description?: string;
}

const Alert = ({ alertType = "error", title = "Error", description = "Something went wrong." }: AlertProps) => {
	return (
		<div
			className={classNames(
				alertType === "error" && "bg-red-50",
				alertType === "warning" && "bg-yellow-50",
				alertType === "success" && "bg-green-50",
				alertType === "info" && "bg-blue-50",
				"rounded-md p-4",
			)}
		>
			<div className="flex">
				<div className="flex-shrink-0">
					{alertType === "error" && <XCloseIcon className="text-red-400 h-5 w-5" aria-hidden="true" />}
					{alertType === "warning" && (
						<AlertIcon className="text-yellow-400 h-5 w-5" aria-hidden="true" />
					)}
					{alertType === "success" && <CheckIcon className="text-green-400 h-5 w-5" aria-hidden="true" />}
					{alertType === "info" && <InfoCircleIcon className="text-blue-400 h-5 w-5" aria-hidden="true" />}
				</div>
				<div className="ml-3">
					<h3
						className={classNames(
							alertType === "error" && "text-red-800",
							alertType === "warning" && "text-yellow-800",
							alertType === "success" && "text-green-800",
							alertType === "info" && "text-blue-700",
							"text-sm font-medium",
						)}
					>
						{title}
					</h3>
					<div
						className={classNames(
							alertType === "error" && "text-red-700",
							alertType === "warning" && "text-yellow-700",
							alertType === "success" && "text-green-700",
							alertType === "info" && "text-blue-600",
							"mt-2 text-sm",
						)}
					>
						<p>{description}</p>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Alert;
