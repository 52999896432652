import classNames from "classnames";
import { StripeProduct } from "@common/utils/types";
import { useSession } from "@supabase/auth-helpers-react";
import { useState } from "react";
import { createCheckoutSession } from "@common/lib/api";
import CheckIcon from "@icons/check-circle.svg?react";
import * as Sentry from "@sentry/react";

function sortFeatures(features: {}): { [key: string]: any } {
	// sort features in this order:
	// "maximum_DVMs"
	// "documents_generated"
	// "custom_document_types"
	// "integrations"
	// "multi_location_support"
	const sortedFeatures = Object.entries(features).sort((a, b) => {
		if (a[0] === "maximum_DVMs") return -1;
		if (b[0] === "maximum_DVMs") return 1;
		if (a[0] === "documents_generated") return -1;
		if (b[0] === "documents_generated") return 1;
		if (a[0] === "custom_document_types") return -1;
		if (b[0] === "custom_document_types") return 1;
		if (a[0] === "integrations") return -1;
		if (b[0] === "integrations") return 1;
		if (a[0] === "multi_location_support") return -1;
		if (b[0] === "multi_location_support") return 1;
		return 0;
	});
	return Object.fromEntries(sortedFeatures);
}

// replace feature _ with space and cap first letters
function formatFeature(feature: string) {
	return feature
		.replace(/_/g, " ")
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ");
}

function formatPrice(price: number) {
	price = price / 100;
	return price.toLocaleString("en-US", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: price % 1 === 0 ? 0 : 2, // no decimals for whole dollar amounts
	});
}

function Product({ products, product, pindex }: { products: StripeProduct[]; product: StripeProduct; pindex: number }) {
	const fullURL = window.location.href;
	const session = useSession();
	const [loading, setLoading] = useState(false);

	const checkout = async (product: StripeProduct) => {
		setLoading(true);
		if (!session) {
			Sentry.captureMessage("Session not found in Product.tsx", {
				level: "error",
			});
			setLoading(false);
			return;
		}
		const { sessionId, url } = await createCheckoutSession(session, product.prices[0], product.metadata, fullURL);
		if (sessionId && url) {
			window.location.replace(url);
			setLoading(false);
		} else {
			Sentry.captureMessage("Stripe checkout session creation failed in Product.tsx", {
				level: "error",
			});
			setLoading(false);
		}
	};

	return (
		<div
			key={product.id}
			className={classNames(
				product.name === "Starter" ? "lg:z-10 lg:rounded-b-none" : "lg:mt-8",
				pindex === 0 ? "lg:rounded-r-none" : "",
				pindex === products.length - 1 ? "lg:rounded-l-none" : "",
				"flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 ",
			)}
		>
			<div className="mb-5">
				<div className="flex items-center justify-between gap-x-4">
					<h3
						id={product.id}
						className={classNames(
							product.name === "Starter" ? "text-max-700 " : "text-gray-900",
							"text-lg font-semibold leading-8",
						)}
					>
						{product.name}
					</h3>
					{product.name === "Starter" ? (
						<p className="rounded-full bg-max-700/10 px-2.5 py-1 text-xs font-semibold leading-5 text-max-700 ">
							Most popular
						</p>
					) : null}
				</div>
				<p className="mt-4 text-sm leading-6 text-gray-600">{product.description}</p>
				<p className="mt-6 flex items-baseline gap-x-1">
					<span className="text-4xl font-bold tracking-tight text-gray-900">
						{formatPrice(product.prices[0].unit_amount)}
					</span>
					<span className="text-sm font-semibold leading-6 text-gray-600">/month</span>
				</p>
				<ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
					{Object.entries(sortFeatures(product.prices[0].metadata)).map(([feature, value], index) =>
						value === "false" ? (
							<li key={index} className="flex gap-x-3">
								<CheckIcon className="h-6 w-5 flex-none text-gray-400" aria-hidden="true" />
								{formatFeature(feature)}
							</li>
						) : (
							<li key={index} className="flex gap-x-3">
								<CheckIcon className="h-6 w-5 flex-none text-max-700 " aria-hidden="true" />
								{formatFeature(feature)}
								{value === "true" ? "" : `: ${value.charAt(0).toUpperCase() + value.slice(1)} `}
							</li>
						),
					)}
				</ul>
			</div>
			<button
				aria-describedby={product.id}
				onClick={() => checkout(product)}
				className={classNames(
					product.name === "Starter"
						? "bg-max-700  text-white shadow-sm hover:bg-max-800 "
						: "text-max-700  ring-1 ring-inset ring-max-700 hover:ring-max-800",
					"mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bg-max-700 ",
				)}
			>
				{loading ? (
					<span className="flex justify-center items center text-center">
						<div
							className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
							role="status"
						></div>
					</span>
				) : (
					"Start 14 day free trial"
				)}
			</button>
		</div>
	);
}

export default Product;
