import { useNavigate } from "react-router-dom";
import ArrowLeftIcon from "@icons/arrow-left.svg?react";

interface HDBackButtonProps {
    backTo: string;
}

/**
 * Back button component
 * @param backTo - back to previous page or list view or custom page URL
 * @returns JSX.Element
 */
function HDBackButton({ backTo = "" }: HDBackButtonProps) {
    const navigate = useNavigate();

    /**
     * Handle click event to navigate back to the previous page or list view
     */
    const handleClick = () => {
        navigate(backTo === "list" ? "/" : backTo !== "" ? backTo : -1 as unknown as any);
    };

    return (
        <div className="flex items-center">
            <span className="flex items-center text-center cursor-pointer gap-1.5" onClick={handleClick}>
                <ArrowLeftIcon className="h-4 w-4 text-slate-600 flex-shrink-0" aria-hidden="true" />
                <p className="text-[12px] text-slate-600 font-semibold">
                    {backTo === "list" ? "Back to List View" : "Back to Previous Page"}
                </p>
            </span>
        </div>
    )
}

export default HDBackButton