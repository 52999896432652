import { createClient, Session } from "@supabase/supabase-js";
import {
	CreatorPromptField,
	Doctor,
	PatientRecord,
	MedicalRecordState,
	ChatCompletions,
	Transcript,
	TranscriptSection,
	AccountIntegration,
	DocumentType,
	PatientRecordFetchErrors,
	SectionType,
	DocumentStatus,
	ExamplePrompt,
	CreatorPromptFieldCategory,
	CustomFieldMeta,
} from "../utils/types";
import isUUID from "validator/lib/isUUID";
import isDate from "validator/lib/isDate";
import { invalidateDocumentCache, transcribeAudio } from "./api";
import { VITE_PUBLIC_SUPABASE_URL, VITE_PUBLIC_SUPABASE_ANON_KEY } from "@common/utils/constants";
import { v4 as uuidv4 } from "uuid";
import * as Sentry from "@sentry/react";

const supabaseUrl = VITE_PUBLIC_SUPABASE_URL;
const supabaseAnonKey = VITE_PUBLIC_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// DOCUMENT TYPES
export const getSupabaseDocumentTypes = async (abortSignal: AbortSignal, hiddenDocIDs: string[], accountID: string): Promise<DocumentType[]> => {

	if (!accountID) {
		return [];
	}

	const { data, error } = await supabase
		.from("document_types")
		.select(
			`id,
			 document_type_name,
			 account_id,
			 auto_generate,
			 is_default,
			 description,
			 last_updated_at,
			 is_archived,
			 prompt_components,
			 training_data,
			 is_active,
			 custom_document_type,
			 creator_prompt_components,
			 is_json,
			 document_foundation_id
			`,
		)
		.eq('account_id', accountID)
		.abortSignal(abortSignal)
		.filter("is_archived", "eq", false);

	if (error) {
		if (error?.code === "20") {
			// do nothing if the error is due to aborting the fetch
			return (data as any) || [];
		}

		Sentry.captureException(error);
	}

	const filter = buildFilterFromList(hiddenDocIDs);
	const { data: sharedDocs, error: sharedDocsError } = await supabase
		.from("document_sharing")
		.select(
			`
		document_types (
			id,
			account_id,
			document_type_name,
			auto_generate,
			is_default,
			description,
			last_updated_at,
			is_archived,
			prompt_components,
			training_data,
			is_active,
			custom_document_type,
			creator_prompt_components,
			is_json,
			document_foundation_id
		)
		`,
		)
		.abortSignal(abortSignal)
		.not("document_type_id", "in", filter)
		.not("owner_account_id", "eq", accountID)
		.or(`target_account_id.is.null,target_account_id.eq.${accountID}`)
		.filter("can_generate", "eq", true);

	if (sharedDocsError) {
		if (sharedDocsError?.code === "20") {
			// do nothing if the error is due to aborting the fetch
			return (data as any) || [];
		}

		Sentry.captureException(sharedDocsError);
	}

	const shared = sharedDocs?.map((doc: any) => doc.document_types) || [];

	const allDocs = data?.concat(shared) as DocumentType[];

	return allDocs || [];
};

export const getDocumentTemplates = async (signal: AbortSignal, hiddenDocIDs: string[], accountID: string): Promise<DocumentType[]> => {

	const filter = buildFilterFromList(hiddenDocIDs);

	const { data, error } = await supabase
		.from("document_sharing")
		.select(
			`
			document_types (
				id,
				account_id,
				document_type_name,
				auto_generate,
				is_default,
				description,
				last_updated_at,
				is_archived,
				prompt_components,
				training_data,
				is_active,
				custom_document_type,
				creator_prompt_components,
				is_json,
				document_foundation_id
			)
			`,
		)
		.abortSignal(signal)
		.not("document_type_id", "in", filter)
		.or(`target_account_id.is.null,target_account_id.eq.${accountID}`)
		.filter("can_clone", "eq", true);

	if (error) {
		if (error?.code === "20") {
			// do nothing if the error is due to aborting the fetch
			return (data as any) || [];
		}

		Sentry.captureException(error);
	}

	const templates = data?.map((doc: any) => doc.document_types) as DocumentType[];

	return templates || [];
};

export const getDocumentTypesByIdsList = async (ids: string[]): Promise<DocumentType[]> => {
	const { data, error } = await supabase
		.from("document_types")
		.select(
			`id,
			 document_type_name,
			 auto_generate,
			 is_default,
			 description,
			 last_updated_at,
			 is_archived,
			 prompt_components,
			 training_data,
			 creator_prompt_components,
			 is_json`,
		)
		.in("id", ids);
	if (error) {
		Sentry.captureException(error);
	}

	return (data as any) || [];
};

export const getSingleSupabaseDocumentType = async (
	id: string,
	abortSignal: AbortSignal,
): Promise<DocumentType | null> => {
	const { data, error } = await supabase
		.from("document_types")
		.select(
			`id, 
			document_type_name, 
			prompt, 
			prompt_components, 
			output_parser, 
			prompt_override, 
			training_data, 
			creator_prompt_components, 
			custom_document_type, 
			description, 
			is_archived,
			is_json, 
			document_foundation_id,
			document_exports ( 
				is_active 
			)`,
		)
		.abortSignal(abortSignal)
		.eq("id", id)
		.maybeSingle();

	if (error) {
		if (error?.code === "20") {
			// do nothing if the error is due to aborting the fetch
			return (data as any) || null;
		}
		Sentry.captureException(error);
	}

	return (data as any) || null;
};

export const toggleDocumentSupabase = async (id: string, isActive: boolean) => {
	let { error } = await supabase.from("document_types").update({ is_active: isActive }).eq("id", id);
	if (error) {
		Sentry.captureException(error);
	}

	return error;
};

export const saveNewDocumentType = async (documentType: Partial<DocumentType>): Promise<DocumentType | null> => {
	let { error } = await supabase.from("document_types").insert(documentType);
	if (error) {
		Sentry.captureException(error);
		return null
	}

	return documentType as DocumentType;

};

export const updateDocumentType = async (documentType: Partial<DocumentType>, isJson = true) => {
	let { error } = await supabase.from("document_types").update(documentType).eq("id", documentType.id);
	if (error) {
		Sentry.captureException(error);
		return;
	}

	if (!isJson) {
		await invalidateDocumentCache(documentType.id || "");
	}
};

export const updateDocumentTypeInclusion = async (docTypeId: string, isActive: boolean) => {
	const { error } = await supabase.from("document_types").update({ is_active: isActive }).eq("id", docTypeId);

	if (error) {
		Sentry.captureException(error);
		return false;
	}

	return true;
};


export const deleteDocumentFromSupabase = async (id: any) => {
	// set is_archived to true instead of deleting
	let { error } = await supabase.from("document_types").update({ is_archived: true }).eq("id", id);
	if (error) {
		Sentry.captureException(error);
	}
};

export const updateDocumentTypeToAutoGenerate = async (id: any, autoGenerate: any) => {
	let { error } = await supabase.from("document_types").update({ auto_generate: autoGenerate }).eq("id", id);
	if (error) {
		Sentry.captureException(error)
	}
};
// DOCTORS
export const getSupabaseDoctors = async (abortSignal: AbortSignal): Promise<Doctor[]> => {
	//Get All Doctors
	const { data: doctors, error: error } = await supabase
		.from("doctors")
		.select("id, doctor_name, created_at, email, account_id")
		.abortSignal(abortSignal)
		.order("created_at", { ascending: true }); // order by created_at so when you edit doctor name, order stays the same
	if (error) {
		if (error?.code === "20") {
			// do nothing if the error is due to aborting the fetch
			return doctors || [];
		}

		Sentry.captureException(error);
	}

	return doctors || [];
};

export const addDoctorToSupabase = async (doctor: Doctor) => {
	const { error } = await supabase.from("doctors").insert(doctor);
	if (error) {
		Sentry.captureException(error);
	}
};

export const deleteDoctorInSupabase = async (id: string) => {
	const { error } = await supabase.from("doctors").delete().eq("id", id);
	if (error) {
		Sentry.captureException(error);
	}
};

export const updateDoctorInSupabase = async (id: string, updatedDoctor: Partial<Doctor>) => {
	const { error } = await supabase.from("doctors").update(updatedDoctor).eq("id", id);

	if (error) {
		Sentry.captureException(error);
	}
};

//PATIENT RECORDS
export const insertPatientRecord = async (patientRecord: PatientRecord, transcriptionId: string | null = null) => {
	const { error } = await supabase.from("patient_record").upsert({
		...patientRecord,
	});
	if (error) {
		Sentry.captureException(error);
	}

	if (transcriptionId) {
		await upsertTranscription({
			id: transcriptionId,
			patient_record_id: patientRecord?.id,
			doctor_id: patientRecord?.doctor_id,
		});
	}

	return;
};

export const updatePatientRecordStatusFromTranscription = async (transcriptSectionId: string, status: string) => {
	try {
		const { data: patientRecordId, error: patientRecordError } = await supabase
			.from("transcriptions")
			.select("patient_record_id")
			.eq("id", transcriptSectionId);
		if (patientRecordError) {
			throw Error(`Error getting Patient Record ID from Supabase: ${patientRecordError?.message}`);
		}
		if (patientRecordId[0]?.patient_record_id) {
			const { error } = await supabase
				.from("patient_record")
				.update({
					status: status,
				})
				.eq("id", patientRecordId[0]?.patient_record_id);
			if (error) {
				throw Error(`Error updating Patient Record Status from Supabase: ${error?.message}`);
			}
		}
	} catch (error: any) {
		Sentry.captureException(error);
	}
};

export const updatePatientRecord = async (patientRecord: PatientRecord, id: string) => {
	try {
		const { error } = await supabase
			.from("patient_record")
			.update({ ...patientRecord })
			.eq("id", id);

		if (error) {
			throw error; // Throw the error to be caught in the catch block
		}

		return { data: id, error: null };
	} catch (error: any) {
		if (error?.message === "TypeError: Load failed" || error) {
			// Retry logic
			await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second

			// Retry logic here
			try {
				const retryResponse = await supabase
					.from("patient_record")
					.update({ ...patientRecord })
					.eq("id", id);

				if (retryResponse.error) {
					throw retryResponse.error; // Throw the error to be caught in the outer catch block
				}

				return { data: id, error: null };
			} catch (retryError: any) {
				Sentry.captureException(retryError);
				return { error: "Error updating Patient Record to supabase on retry" };
			}
		} else {
			// Other error handling
			Sentry.captureException(error);
			return { error: "Error updating Patient Record to supabase" };
		}
	}
};


export const fetchPatientRecord = async (patient_record_id: string): Promise<any> => {
	// check that patient_record_id is actually a uuid and not null
	if (!patient_record_id || !isUUID(patient_record_id)) {
		return null;
	}

	const { data, error } = await supabase
		.from("patient_record")
		.select(
			`
            id,
            patient_name,
            patient_id,
            created_at,
            doctors (
                id,
                doctor_name,
				pms_employee_id,
				pms_export_id
            ),
            status,
			linked_by_integration,
			scheduled_at,
			stage,
			chat_completions (
				id,
				created_at,
				role,
				type,
				chat_json,
				document_snapshot,
				transcription_id,
				document_type_id,
				patient_record_id,
				feedback,
				exported,
				status,
				header,
				document_types (
					id,
					is_json,
					creator_prompt_components,
					document_type_name,
					document_exports (
						id,
						is_active
					)
				)
			),
			transcriptions (
                id,
				transcript_sections (
					id,
					unprocessed_filename,
					processed_filename,
					data,
					error,
					created_at
				)
          	),
			patients (
				id,
				name,
				secondary_id
			),
			account_integrations (
				id,
				integration_name,
				connected_third_party
			)
            `,
		)
		.eq("id", patient_record_id)
		.eq("is_archived", false);

	if (data?.length === 0) {
		// patient record not found
		// this happens if a user deletes a patient record then hits the back button
		return { data: null, error: PatientRecordFetchErrors.NO_PATIENT_RECORD };
	}

	if (error) {
		Sentry.captureException(error);
		return { data: null, error: PatientRecordFetchErrors.FETCH_FAILED };
	}

	// sort transcription sections by created_at ascending to get correct chronological order
	const patientRecord = data[0];
	if (patientRecord.transcriptions) {
		if ((patientRecord.transcriptions as any).transcript_sections) {
			(patientRecord.transcriptions as any).transcript_sections.sort((a: TranscriptSection, b: TranscriptSection) => {
				return new Date(a.created_at as string).getTime() - new Date(b.created_at as string).getTime();
			});
		}
	}

	return { data: patientRecord, error: null };
};

export const fetchPatientRecords = async (
	selectedDate: any,
	selectedDoctorId: string | null,
	abortSignal: AbortSignal,
): Promise<any> => {
	// check selectedDate.startDate is a valid date
	if (selectedDate?.["startDate"] && !isDate(selectedDate?.["startDate"])) {

		Sentry.captureMessage("Invalid start date in fetchPatientRecords()", {
			extra: {
				selectedDate: selectedDate,
			},
			level: "error"
		});
		return { data: [], error: "Invalid start date" };
	}

	// Format selected date to start at 00:00:00
	// Format selected date to start at 00:00:00 in local time
	let queryStartDate = new Date(selectedDate?.["startDate"]);
	queryStartDate.setHours(0, 0, 0, 0);
	let queryStartDateString = queryStartDate.toISOString();

	// Format selected date to end at 23:59:59 in local time
	let queryEndDate = new Date(selectedDate?.["startDate"]);
	if (selectedDate?.["endDate"] && isDate(selectedDate?.["endDate"])) {
		queryEndDate = new Date(selectedDate?.["endDate"]);
	}
	queryEndDate.setHours(23, 59, 59, 999);
	let queryEndDateString = queryEndDate.toISOString();

	// Start with base query
	//create query for patient records, with transcriptions that have the same patient record id

	let query = supabase
		.from("patient_record")
		.select(
			`
          id,
		  appointment_duration,
		  scheduled_at,
          patient_name,
          patient_id,
          created_at,
		  status,
		  stage,
          doctors (
              id,
              doctor_name
          ),
          chat_completions (
				count
		  ),
          transcriptions (
                id,
				transcript_sections (
					id,
					unprocessed_filename,
					processed_filename,
					error
				)
          )
          `,
		)
		.abortSignal(abortSignal)
		.order("scheduled_at", { ascending: true })
		.gte("scheduled_at", queryStartDateString)
		.lte("scheduled_at", queryEndDateString)
		.eq("is_archived", false); // ignore archived records

	// If selectedDoctorId is provided, add filter for doctor
	if (selectedDoctorId) {
		query = query.eq("doctor_id", selectedDoctorId);
	}

	// Execute the query
	const { data, error } = await query;

	if (error) {
		if (error?.code === "20") {
			// do nothing if the error is due to aborting the fetch
			return { data: [], error: "Fetch aborted" };
		}

		Sentry.captureException(error)

		return { data: [], error: "Error querying patient records list" };
	}

	return { data: data || [], error: null };
};

export const deletePatientRecord = async (id: string) => {
	let { error } = await supabase.from("patient_record").update({ id, is_archived: true }).eq("id", id);

	if (error) {
		Sentry.captureException(error)
	}
};

export const deletePatientRecords = async (ids: string[]) => {
	for (const id of ids) {
		let { error } = await supabase.from("patient_record").update({ is_archived: true }).eq("id", id);
		if (error) {
			Sentry.captureException(error)
		}
	}
};

// CHAT COMPLETIONS
/**
 * Inserts a chat completion into the database.
 * @param {Object} params - The parameters for the chat completion.
 * @param {string} params.userId - The ID of the user.
 * @param {Object} params.documentType - The document type.
 * @param {string} params.documentType.document_type_name - The name of the document type.
 * @param {string} params.completion - The completion text.
 * @param {string} params.patientRecordId - The ID of the patient record.
 * @param {string} params.transcriptionId - The ID of the transcription.
 * @param {string} params.newMessageId - The ID of the new message.
 * @param {string} [params.header] - header (patient name) of the chat completion.
 * @returns {Object|null} - The newly inserted chat completion, or null if there was an error.
 */
export const insertChatCompletion = async (params: {
	userId: any;
	documentType: any;
	completion: any;
	patientRecordId: any;
	transcriptionId: any;
	header: any;
}) => {
	let { userId, documentType, completion, patientRecordId, transcriptionId, header } = params;
	let newMessageId = uuidv4();

	let newChatCompletion: any = {
		id: newMessageId,
		account_id: userId,
		chat_json: {
			chat_text: completion,
			header: header,
		},
		transcription_id: transcriptionId,
		document_type_id: documentType?.id,
		type: "audio",
		created_at: new Date().toISOString(),
		role: "assistant",
		patient_record_id: patientRecordId,
		chat_json_original: {
			chat_text: completion,
			header: documentType?.document_type_name,
		},
		header: header,
		status: DocumentStatus.Processing,
		document_snapshot: documentType?.creator_prompt_components,
	};

	const { error } = await supabase.from("chat_completions").upsert(newChatCompletion);
	if (error) {
		Sentry.captureException(error)
		return null;
	}

	newChatCompletion.document_types = documentType;

	return newChatCompletion;
};

export const upsertChatCompletions = async (chatMessages: ChatCompletions[] | ChatCompletions) => {
	const { error } = await supabase.from("chat_completions").upsert(chatMessages);
	if (error) {
		Sentry.captureException(error)
		return { error: "Error inserting Chat Completions into Database" };
	} else {
		return { error: null };
	}
};

export const deleteChatCompletions = async (id: string) => {
	const { error } = await supabase.from("chat_completions").delete().eq("id", id);
	if (error) {
		Sentry.captureException(error)
	}
};

export const updateChatCompletions = async (chatCompletions: ChatCompletions, id: string) => {
	const { error } = await supabase
		.from("chat_completions")
		.update({
			...chatCompletions,
		})
		.eq("id", id);
	if (error) {
		Sentry.captureException(error)
		//return error function to parent caller
		return { error: "updateChatCompletions()" };
	}
	return { error: null };
};
export const updateChatCompletionsBulk = async (chatCompletions: ChatCompletions[]) => {
	const { error } = await supabase.from("chat_completions").upsert([...chatCompletions]);
	if (error) {
		Sentry.captureException(error)
		//return error function to parent caller
		return { error: "updateChatCompletionsBulk()" };
	}
	return { error: null };
};

// TRANSCRIPTIONS
export const upsertTranscription = async (transcript: Transcript) => {
	const { error } = await supabase.from("transcriptions").upsert({
		...transcript,
	});
	if (error?.code === "23503" && transcript?.patient_record_id) {
		//foreign key error something got hung on the upsertPatientRecord
		const { error: pError } = await supabase.from("patient_record").upsert({
			id: transcript.patient_record_id,
		});
		const { error: tError } = await supabase.from("transcriptions").upsert({
			...transcript,
		});
		if (pError || tError) {
			Sentry.captureException(pError || tError);
			return { error: true };
		} else {
			return { error: null };
		}
	}
	if (error) {
		Sentry.captureException(error)
	}
	return { error: null };
};

export const setPatientRecordStage = async (id: string, stage: string) => {
	const { error } = await supabase
		.from("patient_record")
		.update({
			stage: stage,
		})
		.eq("id", id);
	if (error) {
		Sentry.captureException(error)
	}
};

export const fetchPreviousTranscripts = async (existingTranscripts: Transcript[], numberOfTranscripts: number = 10) => {
	// Fetch the three most recent transcripts
	const { data: transcriptData, error: transcriptError } = await supabase
		.from("transcriptions")
		.select(
			`
            id,
            created_at,
            patient_record_id (
                id,
                patient_name
            )`,
		)
		.not("id", "in", `(${existingTranscripts.map((t) => t?.id)})`)
		.order("created_at", { ascending: false })
		.limit(numberOfTranscripts);

	if (transcriptError) {
		Sentry.captureException(transcriptError)
		return { data: null, error: transcriptError };
	}

	// Fetch transcript sections for each of the above transcripts
	const { data: sectionsData, error: sectionsError } = await supabase
		.from("transcript_sections")
		.select(
			`
            transcription_id,
            data`,
		)
		.in(
			"transcription_id",
			transcriptData.map((t) => t.id),
		);

	if (sectionsError) {
		Sentry.captureException(sectionsError)
		return { data: null, error: sectionsError };
	}

	// Map the transcripts to their sections
	let newTranscripts = transcriptData.map((transcript: any) => {
		const sections = sectionsData
			.filter((section) => section.transcription_id === transcript.id)
			.map((section) => section.data)
			.join("\n");

		return {
			id: transcript.id,
			created_at: transcript.created_at,
			transcript: sections,
			name: transcript?.patient_record_id?.patient_name || "",
		};
	});

	return { data: newTranscripts, error: null };
};

// ACCOUNT DATA
export const fetchAccountData = async (abortSignal: AbortSignal): Promise<any> => {
	const { data, error } = await supabase
		.from("account")
		.select(`
			clinic_name, 
			clinic_location, 
			id, 
			is_self_serve, 
			feature_flags, 
			created_at, 
			meta_data, 
			api_key, 
			current_pims, 
			hidden_documents
		`)
		.abortSignal(abortSignal)
		.limit(1)
		.single();

	if (error) {
		if (error?.code === "20") {
			// do nothing if the error is due to aborting the fetch
			return null;
		}

		Sentry.captureException(error)
		return null;
	}

	return data || null;
};

export const fetchAccountSubscriptions = async (): Promise<any> => {
	// fetch account subscriptions using subscription_id from account table
	const { data, error } = await supabase.from("account").select("payment_subscription_id, id").limit(1).single();

	if (error) {
		Sentry.captureException(error)
		return null;
	}
	// fetch subscriptions using subscription_id from account table
	const { data: subscriptionData, error: subscriptionError } = await supabase
		.from("account_subscriptions")
		.select()
		.or(`subscription_id.eq.${data?.payment_subscription_id}, account_id.eq.${data?.id}`);

	if (subscriptionError) {
		Sentry.captureException(subscriptionError)
		return null;
	}

	return subscriptionData || null;
};

export const updateAccountData = async ({ id, clinic_name, clinic_location }: any) => {
	const { error } = await supabase
		.from("account")
		.update({
			clinic_name,
			clinic_location,
		})
		.eq("id", id);

	if (error) {
		Sentry.captureException(error)
	}
};

export const updateAccountPIMS = async ({ id, pims }: any): Promise<{ error: null | string }> => {
	const { error } = await supabase
		.from("account")
		.update({
			current_pims: pims,
		})
		.eq("id", id);

	if (error) {
		Sentry.captureException(error)
		return { error: "Error updating PIMs" };
	}

	return { error: null }
}

export const updateAccountHiddenDocs = async ({ id, hidden_documents }: any): Promise<{ error: null | string }> => {
	const { error } = await supabase
		.from("account")
		.update({
			hidden_documents: hidden_documents,
		})
		.eq("id", id);

	if (error) {
		Sentry.captureException(error)
		return { error: "Error updating hidden documents" };
	}

	return { error: null }
}

//TRANSCRIPT SECTIONS
export const upsertTranscriptSection = async (transcriptSection: TranscriptSection) => {
	try {
		const { error } = await supabase.from("transcript_sections").upsert({
			...transcriptSection,
		});

		if (error) {

			if (error.code === "23503" && transcriptSection?.transcription_id) {
				// Handle foreign key constraint
				const { error: transcriptError } = await upsertTranscription({
					id: transcriptSection.transcription_id,
				});
				const { error: sectionsError } = await supabase.from("transcript_sections").upsert({
					...transcriptSection,
				});

				if (transcriptError || sectionsError) {
					Sentry.captureException(transcriptError || sectionsError);
					return { error: true };
				}
			} else if (error.message === "TypeError: Load failed") {

				await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before retrying

				// Retry the transcript just in case.
				let retryErrorTranscript: any;
				if (transcriptSection?.transcription_id) {
					const { error: retry } = await upsertTranscription({
						id: transcriptSection.transcription_id,
					});
					retryErrorTranscript = retry;
				}
				const { error: retryError } = await supabase.from("transcript_sections").upsert({ ...transcriptSection });

				if (retryError) {
					Sentry.captureException(retryError);
					return { error: true };
				}
			} else {
				return { error: true };
			}
		}

		return { error: null };
	} catch (catchError) {
		Sentry.captureException(catchError)
		return { error: true };
	}
};

export const fetchAllTranscriptSections = async (transcriptId: string) => {
	const { data, error } = await supabase
		.from("transcript_sections")
		.select(
			`id,
        transcription_id,
        data,
        unprocessed_filename,
        was_removed,
        processed_filename
        `,
		)
		.eq("transcription_id", transcriptId)
		.order("created_at", { ascending: true });

	if (error) {
		Sentry.captureException(error)
		return { error: "Error Fetching Transcript Sections", data: null };
	}
	return { error: null, data: data };
};

export const deleteTranscriptSection = async (transcriptSectionId: string) => {
	const { error } = await supabase.from("transcript_sections").delete().eq("id", transcriptSectionId);
	if (error) {
		Sentry.captureException(error)
		return { error: "Deleting Transcript Sections" };
	}
	return { error: null };
};
export const deleteAllTranscriptSections = async (transcriptSectionIds: string[]) => {
	for (let i = 0; i < transcriptSectionIds.length; i++) {
		await deleteTranscriptSection(transcriptSectionIds[i]);
	}
};

export const getTranscriptSectionsLoading = async (transcription_ids: string[]): Promise<TranscriptSection[]> => {
	const { data, error } = await supabase
		.from("transcript_sections")
		.select("id, transcription_id, account_id, unprocessed_filename, data, created_at")
		.in("transcription_id", transcription_ids)
		.eq("data", "loading");

	if (error) {
		Sentry.captureException(error)
		return [];
	}
	return data || [];
};

//STORAGE API
export const isAudioFileInSupabase = async (userId: string, transcriptionId: string, audioName: string) => {
	let fileNameWithoutExtension = audioName.substring(0, audioName.lastIndexOf("."));
	const { data, error } = await supabase.storage.from("audio-storage").list(`${userId}/${transcriptionId}`, {
		search: fileNameWithoutExtension,
	});
	if (error) {
		Sentry.captureException(error)
		return false;
	}
	if (data && data.length > 0) {
		return true;
	} else {
		return false;
	}
};

export const uploadAudioFileToSupabase = async (
	audioFile: any,
	audioName: any,
	tId: any,
	userId: string | undefined,
) => {
	if (!userId) {
		const supabaseSession = await supabase.auth.getSession();
		userId = supabaseSession?.data?.session?.user?.id;
	}

	// check if audio already exists, return 409 if it does
	const isAudioInSupabase = await isAudioFileInSupabase(userId || "", tId, audioName);
	if (isAudioInSupabase) {
		return { error: false, is409: true };
	}

	const { error }: any = await supabase.storage
		.from("audio-storage")
		.upload(`${userId}/${tId}/${audioName}`, audioFile);

	if (error) {
		//capture the error with additional details for debugging
		Sentry.captureException(error, {
			extra: { audioName, tId, userId }
		});

		return { error: true, is409: error?.statusCode === "409" };
	}
	return { error: false, is409: false };
};

export const uploadAndTranscribeBulkAudio = async (
	transcriptionId: string,
	transcriptSectionId: string,
	audioData: Blob,
	audioName: string,
	realtimeClientId?: string | null,
	patientRecordId?: string | null,
	patientName: string = "",
	doctorId: string = "",
	documentIds: string[] = [],
): Promise<{ error: boolean | null }> => {
	try {
		let userId,
			session: Session | null = null;
		const supabaseSession = await supabase.auth.getSession();
		userId = supabaseSession?.data?.session?.user?.id;
		session = supabaseSession?.data?.session;
		if (!userId || !session) {
			Sentry.captureMessage("User ID or Session is missing in uploadAndTranscribeBulkAudio()", {
				extra: {
					userId,
					session,
				},
				level: "error"
			});
			return { error: true };
		}

		const { error, is409 } = await uploadAudioFileToSupabase(audioData, audioName, transcriptionId, userId);
		//If Error, nothing we can do really.
		if (error && !is409) {
			Sentry.captureException(error);
			return { error: true };
		}

		await upsertTranscriptSection({
			id: transcriptSectionId,
			transcription_id: transcriptionId,
			error: null,
			data: "loading",
		});

		const { error: transcribeError } = await transcribeAudio(
			session,
			transcriptionId,
			transcriptSectionId,
			audioName,
			realtimeClientId,
			patientRecordId,
			patientName,
			doctorId,
			documentIds,
			[],
		);

		if (transcribeError) {
			Sentry.captureException(transcribeError);
			return { error: true };
		}

		return { error: null };
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

export const downloadAudioFileFromSupabase = async (tId: any, audioName: any) => {
	const supabaseSession = await supabase.auth.getSession();
	const userId = supabaseSession?.data?.session?.user?.id;
	const { data, error } = await supabase.storage.from("audio-storage").download(`${userId}/${tId}/${audioName}`);
	if (error) {
		Sentry.captureException(error)
		return null;
	}
	return data;
};

//GET CUSTOM MODELS
export const getCustomModels = async () => {
	const { data, error } = await supabase.from("custom_models").select("model_name, model_id");
	if (error) {
		Sentry.captureException(error)
	}
	return data;
};

//ACCOUNT_INTEGRATIONS
export const getAccountIntegrations = async (abortSignal: AbortSignal) => {
	const { data, error } = await supabase
		.from("account_integrations")
		.select("integration_name, practice_id, connected_third_party, last_request, api_url, export_enabled")
		.abortSignal(abortSignal);

	if (error) {
		if (error?.code === "20") {
			// do nothing if the error is due to aborting the fetch
			return null;
		}
		Sentry.captureException(error)
	}
	if (data && data?.length !== 0) {
		return data as AccountIntegration[];
	} else {
		return null;
	}
};

// stripe
export async function getSubscription(abortSignal: AbortSignal) {
	try {
		const { data: subscription } = await supabase
			.from("subscriptions")
			.select("*, prices(*, products(*))")
			.in("status", ["trialing", "active"])
			.abortSignal(abortSignal)
			.maybeSingle()
			.throwOnError()

		return subscription;
	} catch (error: any) {

		if (error?.code === "20") {
			// do nothing if the error is due to aborting the fetch
			return null;
		}

		Sentry.captureException(error);
		return null;
	}
}

export const getActiveProductsWithPrices = async () => {
	const { data, error } = await supabase
		.from("products")
		.select("*, prices(*)")
		.eq("active", true)
		.eq("prices.active", true)
		.eq("display_self_serve", true)
		.order("metadata->index")
		.order("unit_amount", { foreignTable: "prices" });

	if (error) {
		Sentry.captureException(error);

	}
	return data ?? [];
};

//Prompt Fields
export const getCreatorPromptData = async (getJSON: boolean = false, getMeta: boolean = false, abortController: AbortSignal) => {
	const sectionType = getJSON ? [SectionType.JSON] : [SectionType.SECTION];
	if (getMeta) {
		sectionType.push(SectionType.META)
	}

	const { data, error } = await supabase
		.from("creator_prompt_fields")
		.select(
			`
				id, 
				field_name, 
				field_description, 
				field_other_names,
				has_normal_values, 
				section_type, 
				default_format,
				formatting_options, 
				display_transcript_value,
				response_source,
				hidden,
				display_title_default,
				display_output_default,
				account_id,
				instructions,
				custom_field_meta (
					custom_prompt
				),
				example_prompts (
					id,
					example
				)`,
		)
		.eq("custom_document_type", "SOAP")
		.in("section_type", sectionType)
		.eq("example_prompts.species", "other")
		.abortSignal(abortController)

	if (error) {
		Sentry.captureException(error);
		return [];
	}

	// get the categories for each field from the creator_prompt_field_categories mapping table
	const { data: creatorPromptFieldCategories, error: categoriesError } = await supabase
		.from("creator_prompt_field_categories")
		.select(`
			category_id, 
			creator_prompt_categories (
				display_name,
				id
			),
			field_id
		`)
		.abortSignal(abortController);

	if (categoriesError) {
		Sentry.captureException(categoriesError);
		return [];
	}

	// flatten the categories for each map element
	const flattenedCategoryFieldMap = creatorPromptFieldCategories.map(({ category_id, field_id, creator_prompt_categories }) => ({
		category_id,
		field_id,
		display_name: creator_prompt_categories?.display_name ?? undefined,
	}));

	// attach the mapped categories to the fields
	// transform db -> domain obj here
	const fieldsWithCategories: CreatorPromptField[] = data?.map((field) => ({
		...field,
		other_names: field.field_other_names,
		description: field.field_description,
		example: field.example_prompts[0]?.example || '',
		displayOutput: field.display_output_default,
		displayTitle: field.display_title_default,
		customPrompt: field.custom_field_meta[0]?.custom_prompt || '',
		categories: flattenedCategoryFieldMap
			.filter(({ field_id }) => field_id === field.id)
			.map(({ category_id, display_name }) => ({
				id: category_id,
				display_name,
			})),
	})) ?? [];

	return fieldsWithCategories;
};

/**
 * (inserts or updates) a new field into the creator_prompt_fields table.
 * @param field - The CreatorPromptField object to be inserted.
 * @returns A Promise that resolves to the inserted CreatorPromptField or null if the insertion fails.
 */
export const upsertField = async (field: CreatorPromptField): Promise<CreatorPromptField | null> => {
	try {
		const { data, error } = await supabase
			.from("creator_prompt_fields")
			.upsert(field)
			.select();

		if (error) {
			throw error;
		}

		return data?.[0] || null;
	} catch (error) {
		Sentry.captureException(error);
		return null;
	}
}

/**
 * (inserts or updates) a new example prompt into the example_prompts table.
 * @param examplePrompt - The ExamplePrompt object to be inserted.
 * @returns A Promise that resolves to the inserted ExamplePrompt or null if the insertion fails.
 */
export const upsertExamplePrompt = async (examplePrompt: ExamplePrompt): Promise<ExamplePrompt | null> => {
	try {
		const { data, error } = await supabase
			.from("example_prompts")
			.upsert(examplePrompt)
			.select();

		if (error) {
			throw error;
		}

		return data?.[0] || null;
	} catch (error) {
		Sentry.captureException(error);
		return null;
	}
}

/**
 * Inserts a new creator prompt field category mapping into the creator_prompt_field_categories table.
 * @param creatorPromptFieldCategories - The CreatorPromptFieldCategory object to be inserted.
 * @returns A Promise that resolves to the inserted CreatorPromptFieldCategory or null if the insertion fails.
 */
export const insertCreatorPromptFieldCategories = async (creatorPromptFieldCategories: CreatorPromptFieldCategory): Promise<CreatorPromptFieldCategory | null> => {
	try {
		const { data, error } = await supabase
			.from("creator_prompt_field_categories")
			.insert(creatorPromptFieldCategories)
			.select();
		if (error) {
			throw error;
		}

		return data?.[0] || null;
	} catch (error) {
		Sentry.captureException(error);
		return null;
	}
}

/**
 * (inserts or updates) a custom field meta record in the custom_field_meta table.
 * @param customFieldMeta - The CustomFieldMeta object to be upserted.
 * @returns A Promise that resolves to the upserted CustomFieldMeta or null if the operation fails.
 */
export const upsertCustomFieldMeta = async (customFieldMeta: CustomFieldMeta): Promise<CustomFieldMeta | null> => {
	try {
		const { data, error } = await supabase
			.from("custom_field_meta")
			.upsert(customFieldMeta, {
				onConflict: "account_id,field_id"
			}).select();

		if (error) {
			throw error;
		}

		return data?.[0] || null;
	} catch (error) {
		Sentry.captureException(error);
		return null;
	}
}

export const getCreatorPromptCategories = async (abortController: AbortSignal) => {

	const { data, error } = await supabase
		.from("creator_prompt_categories")
		.select("id, display_name")
		.abortSignal(abortController);

	if (error) {
		Sentry.captureException(error);
		return [];
	}

	return data ?? [];
}

export const insertSectionOrFieldRequest = async (name: string, description: string, exampleOutput: string) => {
	const { error } = await supabase
		.from("creator_prompt_requests")
		.insert({ request_section_or_field_name: name, request_description: description, example_output: exampleOutput });
	if (error) {
		Sentry.captureException(error);
	}
	return {
		error,
	};
};

export const getCreatorSectionNames = async (abortSignal: AbortSignal): Promise<Set<string>> => {
	const { data, error } = await supabase
		.from("creator_prompt_fields")
		.select("field_name, field_other_names")
		.eq("has_normal_values", false)
		.abortSignal(abortSignal);

	if (error || !data || !Array.isArray(data) || data?.length === 0) {

		if (error?.code === "20") {
			return new Set();
		}

		Sentry.captureException(error);

		return new Set();
	}

	const allNames = data?.reduce((acc: string[], { field_name, field_other_names }) => {
		acc.push(field_name.toLowerCase()); // Add the section_name
		if (Array.isArray(field_other_names)) {
			// Ensure field_other_names is an array
			let otherNames = field_other_names.map((name: string) => name.toLowerCase());
			acc.push(...otherNames);
		}
		return acc;
	}, []);

	const uniqueNames = new Set(allNames);

	return uniqueNames;
};

export const updateCopyForMarkdown = async (id: string, enableMarkdownCopy: boolean) => {
	if (!id) return { error: "No id provided" };
	const { error } = await supabase
		.from("account")
		.update({ meta_data: { markdown_copy_enabled: enableMarkdownCopy } })
		.eq("id", id);
	if (error) {
		Sentry.captureException(error);
	}
	return { error };
};

const moveAudioFile = async (userId: string, tId: string, audioName: string, newTId: string) => {
	// copy then delete the file because move doesn't work thanks supabase
	const { data, error } = await supabase.storage
		.from("audio-storage")
		.copy(`${userId}/${tId}/${audioName}`, `${userId}/${newTId}/${audioName}`);
	if (error) {
		Sentry.captureException(error);
		return { error: true };
	}

	const { error: deleteError } = await supabase.storage.from("audio-storage").remove([`${userId}/${tId}/${audioName}`]);
	if (deleteError) {
		Sentry.captureException(deleteError);
		return { error: null };
	}

	return { error: null };
};

export const moveRecording = async (
	oldTranscriptSectionId: string,
	oldTranscriptionId: string,
	newPatientRecordId: string,
	userId: string,
	audioName: string,
) => {
	const { data, error } = await supabase.rpc("move_recording", {
		old_transcript_section_id: oldTranscriptSectionId,
		new_patient_id: newPatientRecordId,
	});

	const newTranscriptId = data;

	if (error) {
		Sentry.captureException(error);
		return { error: true };
	}

	const { error: moveAudioError } = await moveAudioFile(userId, oldTranscriptionId, audioName, newTranscriptId);

	return { error: moveAudioError };
};

export const deleteRecording = async (transcriptSectionId: string) => {
	const { error } = await supabase.from("transcript_sections").delete().eq("id", transcriptSectionId);
	if (error) {
		Sentry.captureException(error);
		return;
	}
};

const buildFilterFromList = (l: any[]) => {
	let filter = "()";
	if (l.length > 0) {
		filter = "(";
		for (let doc_id of l) {
			filter += `${doc_id},`
		}
		filter = filter.slice(0, -1) + ")";
	}

	return filter;
}

