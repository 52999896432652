import { useState, useRef, useCallback } from "react";
import { toast } from "react-toastify";
import { downloadAudioFileFromSupabase } from "../lib/supabaseClient";
import { TranscriptSection } from "../utils/types";
import AudioDb, { INDEX_DB_VERSION } from "@common/classes/audioDb";
import * as Sentry from "@sentry/react";

const AudioPlayer = ({ transcriptSection }: { transcriptSection: TranscriptSection }) => {
	const localAudioDb = AudioDb.getInstance(INDEX_DB_VERSION);

	// States for audio URL and loading indicator
	const [audioURL, setAudioURL] = useState<string | null>(null);
	const [isLoading, setLoading] = useState(false);
	const audioRef = useRef<any>(null);

	// Function to fetch audio, called only when needed (i.e., when user clicks play)
	const fetchAudio = useCallback(async () => {
		if (audioURL) return; // If we already have the audio URL, no need to fetch again
		setLoading(true);
		try {
			let blob = await downloadAudioFileFromSupabase(
				transcriptSection?.transcription_id,
				transcriptSection?.unprocessed_filename,
			);
			if (!blob && localAudioDb && transcriptSection && transcriptSection.transcription_id) {
				let localAudio = await localAudioDb.getTranscriptSectionAudio(
					transcriptSection.transcription_id,
					transcriptSection.id,
				);

				if (localAudio) blob = localAudio.audioData;

				if (!blob) {
					throw new Error("Audio not found in local db");
				}
			}
			if (blob) {
				const url = URL.createObjectURL(blob);
				setAudioURL(url); // Set the audio URL state
			}
		} catch (e) {
			Sentry.captureException("Error fetching audio:", e as any);
			toast.error(
				"This audio is not in our system or on this computer. Try playing it on the computer you recorded it on.",
			);
		} finally {
			setLoading(false);
		}
	}, [audioURL, transcriptSection]);

	// Event handler for the audio element
	const handlePlay = useCallback(async () => {
		if (!audioURL) {
			await fetchAudio(); // Fetch the audio if not already done
		}

		if (audioRef.current) {
			// Use the ref to programmatically play the audio
			audioRef.current.play().catch((e: any) => {
				// don't do anything if there's an error with playing the audio, it throws unnecessary errors.
			});
		}
	}, [audioURL]);

	return (
		<div className="flex flex-col w-full">
			<div className="relative w-full h-8 cursor-pointer" onClick={() => handlePlay()}>
				{!audioURL && <div className="h-full w-full absolute z-10"></div>}
				<audio ref={audioRef} controls controlsList="nofullscreen nodownload noplaybackrate" className="w-full h-8">
					{audioURL && <source src={audioURL} />}
				</audio>
			</div>
			{/* Loading Indicator. Could add error messages here too */}
			{isLoading && (
				<div className="h-3">
					{" "}
					<p className="text-[8px] text-gray-500 leading-none">Loading audio...</p>
				</div>
			)}
		</div>
	);
};

export default AudioPlayer;
