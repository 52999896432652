import { useNavigate } from "react-router-dom";
import Navbar from "../features/navigation/Navbar";
import { useEffect } from "react";
import { ENDPOINTS } from "../common/utils/url-registry";
import { Subscription } from "../common/utils/types";
import { useSession } from "@supabase/auth-helpers-react";
import { useAccountContext } from "@providers/AccountProvider";
import CacheBuster from "@providers/CacheBuster";
import classNames from "classnames";

type Props = {
	children: React.ReactElement | React.ReactElement[];
	allowedPermissions?: string[];
};

/**
 * Check if the user has access to the self-serve features
 * @param {Subscription} subscription - The subscription data
 * @returns {boolean}
 */
function checkAccess(subscription: Subscription) {
	const now = new Date();
	const currentPeriodEnd = new Date(subscription.current_period_end);

	// user should have access if the current date is before the end of the current period
	// and if the subscription is not marked as ended
	return now < currentPeriodEnd && !subscription.ended_at;
}

/**
 * Wrapper component to handle permissions
 * @param {Props} props - The props
 * @returns {JSX.Element}
 */
function PermissionWrapper({ children, allowedPermissions }: Props) {
	const session = useSession();
	const { accountData, subscription, dataLoaded } = useAccountContext();
	const navigate = useNavigate();

	// Get the notification banner message from the environment variables
	const notificationBannerMessage = import.meta.env.VITE_NOTIFICATIONS_BANNER_MESSAGE || ""

	// Check if the user has access to the self-serve features and redirect if not
	useEffect(() => {
		// If the user is not logged in, do not proceed
		if (!accountData || !session) {
			return;
		}

		// If the account and subscription data is not loaded, OR the user is not self-serve, do not proceed
		if (!dataLoaded || !accountData?.is_self_serve) return

		// If the user doesn't have a subscription, redirect to the payment page
		if (!subscription) {
			navigate(ENDPOINTS.Payment);
			return;
		}

		// If the user is not self-serve, OR the subscription is active, do not redirect
		if (!subscription.is_self_serve || subscription.status === "active") {
			return;
		}

		// If the user doesn't have access, redirect to the payment page
		if (!checkAccess(subscription)) {
			navigate(ENDPOINTS.Payment);
			return;
		}
	}, [dataLoaded, subscription, accountData, navigate, session]);

	return (
		<>
			<CacheBuster>
				<div>
					{/* Top Nav Bar */}
					<Navbar />
					{/* Main content */}
					<main className={classNames(notificationBannerMessage ? "pt-32 sm:pt-24 lg:pt-20 h-full mb-5" : "p-4 h-full")}>
						<div className=" h-full">{children}</div>
						<div className="hidden happydoc-account-id">{accountData?.id}</div>
					</main>
				</div>
			</CacheBuster>
		</>
	);
}

export default PermissionWrapper;
