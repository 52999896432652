import { useEffect } from "react";
import Confetti from "react-confetti";
import { useDispatch, useSelector } from "react-redux";
import { setConfetti } from "@/state/redux/globalState";

const ConfettiElement = () => {
	const { confetti } = useSelector((state: any) => state.globalState);
	const dispatch = useDispatch();
	// watch for changes in confetti. if its true, set it to false after 2 seconds
	useEffect(() => {
		if (confetti) {
			setTimeout(() => {
				dispatch(setConfetti(false))
			}, 2000);
		}
	}, [confetti]);
	return (
		<Confetti
			width={window.screen.width}
			height={window.screen.height}
			numberOfPieces={confetti ? 1000 : 0}
			initialVelocityY={-7}
		/>
	);
};
export default ConfettiElement;
