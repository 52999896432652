import React from "react";
import ToolTip from "./ToolTip";
import InfoCircleIcon from "@icons/info-circle.svg?react";

interface HelpDocumentationProps {
	link: string;
	displayText?: string;
	isDisabled?: boolean;
	toolTipClassNames?: string;
}

const HelpDocumentation: React.FC<HelpDocumentationProps> = ({
	link,
	displayText = "",
	isDisabled = false,
	toolTipClassNames = null,
}) => {
	// Function to handle click on the button
	const handleClick = () => {
		if (!isDisabled) {
			// Open the link in a new tab when the button is not disabled
			window.open(link, "_blank", "noopener,noreferrer");
		}
	};

	return (
		<div>
			<ToolTip text={displayText} customClassNames={toolTipClassNames}>
				<button
					onClick={handleClick}
					disabled={isDisabled} // Use the native 'disabled' attribute of the button
					className={`flex flex-row text-[10px] items-center  ${isDisabled ? "text-gray-300 cursor-not-allowed" : "text-gray-500"
						} p-1`}
					aria-label="Help Documentation"
				>
					<InfoCircleIcon className={`h-[13px] w-[13px] mr-1 ${isDisabled ? "text-gray-50" : "text-gray-400"}`} />
				</button>
			</ToolTip>
		</div>
	);
};

export default HelpDocumentation;
