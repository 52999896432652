import React, { createContext, useContext } from "react";
import useAudioRecorder, { recorderControls } from "../../../common/hooks/useAudioRecorder";

interface AudioRecorderContextValue {
	recorderControls: recorderControls;
}

const AudioRecorderContext = createContext<AudioRecorderContextValue | undefined>(undefined);

export function useAudioRecordingProvider(): AudioRecorderContextValue {
	const context = useContext(AudioRecorderContext);
	if (!context) {
		throw new Error("useAudioRecorderContext must be used within a AudioRecorderProvider");
	}
	return context;
}

export function AudioRecorderProvider({ children }: { children: React.ReactNode }) {
	const {
		startRecording,
		stopRecording,
		togglePauseResume,
		recordingState,
		recordingTime,
		audioStream,
		activeRecordingId,
		setActiveRecordingId,
		isQuickStart,
		setIsQuickStart,
		resetRecorder,
		setRecordingState,
	} = useAudioRecorder();

	const recorderControlsValue: recorderControls = {
		startRecording,
		stopRecording,
		togglePauseResume,
		recordingState,
		recordingTime,
		audioStream,
		activeRecordingId,
		setActiveRecordingId,
		isQuickStart,
		setIsQuickStart,
		resetRecorder,
		setRecordingState,
	};

	return (
		<AudioRecorderContext.Provider value={{ recorderControls: recorderControlsValue }}>
			{children}
		</AudioRecorderContext.Provider>
	);
}
