import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDataContextApi } from "@providers/DataProvider";
import { PatientRecordTableRow, RecordingState, PatientStage, TableTab } from "@common/utils/types";
import EditAppointmentModal from "./components/modals/EditAppointmentModal";
import TableHeaderBtn from "./components/buttons/TableHeaderBtn";
import HelpDocumentation from "@common/components/HelpDocumentation";
import TableRow from "./components/TableRow";
import TableTabs from "@common/components/HDTabs";
import { useDispatch, useSelector } from "react-redux";
import {
	selectTableRecordStatus,
	selectSortedColumn,
	selectCurrentStage,
	selectSortedTableRecordsByStage,
	setSortedColumn,
	changeCurrentStage,
	addSelectedTableRecords,
	resetSelectedTableRecords,
	selectFinalizedRecordCount,
	selectOngoingRecordCount,
	selectReviewRecordCount,
} from "@redux/patientTableRecordsSlice";
import { useAudioRecordingProvider } from "@features/list-view/state/recordingProvider";
import { useLocation } from "react-router-dom";
import Spinner from "@/common/components/Spinner";

//TODO optimize stage count logic, sorting, and filtering
const PatientTable = () => {
	const { refreshPatientRecords, isLoadingData, selectedDate } = useDataContextApi();
	const location = useLocation();
	const { selectedDoctor } = useSelector((state: any) => state.doctors);
	const { selectedTableRecords } = useSelector((state: any) => state.patientTableRecords);
	const {
		recorderControls: { recordingState, activeRecordingId, isQuickStart },
	} = useAudioRecordingProvider();
	const dispatch = useDispatch();

	//Redux selectors
	const recordsToDisplay = useSelector(selectSortedTableRecordsByStage);
	const recordStatus = useSelector(selectTableRecordStatus);
	const sortedColumn = useSelector(selectSortedColumn);
	const currentStage = useSelector(selectCurrentStage);
	const ongoingCount = useSelector(selectOngoingRecordCount);
	const reviewCount = useSelector(selectReviewRecordCount);
	const finalizedCount = useSelector(selectFinalizedRecordCount);

	useEffect(() => {
		refreshPatientRecords();
	}, [location, selectedDate]);

	//Local State
	const [isEditAppointmentModalOpen, setIsEditAppointmentModalOpen] = useState(false);
	const [selectedTableRecord, setSelectedTableRecord] = useState<any>(null);
	const [openModal, setOpenModal] = useState(false);
	const [tabs, setTabs] = useState<TableTab[]>([
		{
			header: "Ongoing",
			isActive: currentStage === PatientStage.Ongoing,
			id: PatientStage.Ongoing,
			count: ongoingCount
		},
		{
			header: "Review",
			isActive: currentStage === PatientStage.Review,
			id: PatientStage.Review,
			count: reviewCount,
		},
		{
			header: "Finalized",
			isActive: currentStage === PatientStage.Finalized,
			id: PatientStage.Finalized,
			count: finalizedCount,
		},
	]);

	// seems excessive to have this useEffect, but it's necessary to update the tabs when the counts change
	// but need a better way, this will come with the data grid refactor
	useEffect(() => {
		setTabs([
			{
				header: "Ongoing",
				isActive: currentStage === PatientStage.Ongoing,
				id: PatientStage.Ongoing,
				count: ongoingCount
			},
			{
				header: "Review",
				isActive: currentStage === PatientStage.Review,
				id: PatientStage.Review,
				count: reviewCount,
			},
			{
				header: "Finalized",
				isActive: currentStage === PatientStage.Finalized,
				id: PatientStage.Finalized,
				count: finalizedCount,
			},
		]);
	}, [ongoingCount, reviewCount, finalizedCount, currentStage]);

	/**
	 * Sort the appointments by the column name
	 * @param columnName - The column name to sort by
	 */
	const handleSortAppointments = (columnName: string) => {
		if (sortedColumn.column === columnName) {
			dispatch(setSortedColumn({ column: columnName, direction: sortedColumn.direction === "asc" ? "desc" : "asc" }));
		} else {
			dispatch(setSortedColumn({ column: columnName, direction: "asc" }));
		}
	};

	/**
	 * Select all the records in the table
	 * @param isChecked - Whether the checkbox is checked
	 */
	const handleSelectAll = (isChecked: boolean) => {
		if (isChecked) {
			dispatch(addSelectedTableRecords(recordsToDisplay));
		} else {
			dispatch(resetSelectedTableRecords());
		}
	};

	/**
	 * Handle the edit button click
	 * @param patientTableRecord - The patient table record
	 */
	const handleEditButtonClick = (patientTableRecord: PatientRecordTableRow) => {
		setSelectedTableRecord(patientTableRecord);
		setIsEditAppointmentModalOpen(true);
	};

	/**
	 * Change the tab and update the current stage in redux
	 * @param id - The id of the tab
	 */
	const changeTab = (id: string) => {

		setTabs((prev) =>
			prev.map((t) => {
				if (t.id === id) {
					return { ...t, isActive: true, };
				} else {
					return { ...t, isActive: false, };
				}
			}),
		);
		dispatch(changeCurrentStage(id as PatientStage));
	};

	return (
		<div className="w-full shadow-sm  bg-gray-50 ring-1 ring-gray-300 rounded-md">
			<div className="w-full bg-gray-50 ring-gray-300 rounded-md pt-1">
				<div className="flex-col justify-evenly w-full bg-gray-50">
					<div
						className={classNames(
							selectedDoctor?.doctor_name ? "top-0" : "top-0",
							"absolute hidden  top-2 left-[70px] z-30",
						)}
					>
						<HelpDocumentation
							link="https://help.gohappydoc.com/en/articles/8858669-success-with-list-view"
							isDisabled={recordingState === RecordingState.paused || recordingState === RecordingState.recording}
							displayText="Patient List Help"
						/>
					</div>

					<div className="px-4 py-3 bg-gray-50">
						<h1>Visits</h1>
						{selectedDoctor?.doctor_name ? (
							<span className="text-[10px] text-sm text-gray-500"> {selectedDoctor?.doctor_name} </span>
						) : (
							<span className="text-[10px] text-sm text-gray-500"> All Doctors </span>
						)}
					</div>
				</div>
			</div>
			<div className="w-full bg-white ring-gray-300 ring-1 ">
				<div className="w-full px-4 ring-gray-300 ring-1 ">

					<TableTabs
						tabs={tabs}
						changeTab={changeTab}
						disabled={recordingState === RecordingState?.recording || recordingState === RecordingState?.paused}
					/>
				</div>
				<div className="bg-gray-50  ring-gray-300 ring-1 rounded-b-md ">
					<table className="w-full divide-y divide-gray-300 ">
						<thead className="bg-gray-50 select-none">
							<tr>
								<th className=" px-2 py-3.5 sm:px-2.5 text-center text-xs sm:text-sm font-semibold text-gray-900">
									{recordsToDisplay && recordsToDisplay?.length > 0 && (
										<input
											type="checkbox"
											disabled={
												recordingState === RecordingState?.recording || recordingState === RecordingState?.paused
											}
											checked={selectedTableRecords.length === recordsToDisplay.length}
											onChange={(e) => handleSelectAll(e.target.checked)}
											className="form-checkbox h-4 w-4 text-max-700 border-gray-300 rounded focus:ring-max-600"
										/>
									)}
								</th>
								<th
									scope="col"
									className="truncate text-wrap py-3.5 px-2.5 text-left text-sm font-semibold text-gray-900 w-auto"
								>
									<div className="hidden sm:table-cell">
										<TableHeaderBtn
											text="Patient Name"
											columnName="patient_name"
											sortedColumn={sortedColumn}
											handleClick={handleSortAppointments}
										/>
									</div>
									<div className="table-cell sm:hidden">
										<TableHeaderBtn
											text="Details"
											columnName="scheduled_at"
											sortedColumn={sortedColumn}
											handleClick={handleSortAppointments}
										/>
									</div>
								</th>

								<th
									scope="col"
									className="hidden lg:px-2.5 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-auto max-w-xs"
								>
									<TableHeaderBtn
										text="Scheduled Time"
										columnName="scheduled_at"
										sortedColumn={sortedColumn}
										handleClick={handleSortAppointments}
									/>
								</th>
								<th
									scope="col"
									className="hidden xl:table-cell px-1 py-3.5 text-left text-sm font-semibold text-gray-900"
								>
									Doctor
								</th>

								<th
									scope="col"
									className="hidden px-2.5 py-3.5 text-left text-xs sm:text-sm font-semibold text-gray-900 lg:table-cell w-auto"
								>
									State
								</th>

								<th
									scope="col"
									className="hidden sm:table-cell  sm:px-2.5 py-3.5 text-left text-xs sm:text-sm  font-semibold text-gray-900 w-auto "
								>
									Stage
								</th>
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200 bg-white ">
							{recordStatus === "loading" || isLoadingData ? (
								<tr>
									<td colSpan={100} className="text-center py-5">
										<div className="h-5 w-5 m-auto">
											<Spinner />
										</div>
									</td>
								</tr>
							) : recordStatus === "succeeded" && recordsToDisplay?.length > 0 ? (
								recordsToDisplay?.map((patient: PatientRecordTableRow, index) => (
									<TableRow
										key={patient.id}
										patient={patient}
										handleEditButtonClick={handleEditButtonClick}
										recordingState={recordingState}
										activeRecordingId={activeRecordingId}
										isQuickStart={isQuickStart}
										index={index}
										setOpenModal={setOpenModal}
									/>
								))
							) : recordStatus === "succeeded" && recordsToDisplay?.length === 0 ? (
								<tr>
									<td colSpan={100} className="text-sm text-center py-5">
										<p>No Records</p>
									</td>
								</tr>
							) : (
								<tr>
									<td colSpan={100} className="text-center text-sm font-semibold py-5 text-red-400">
										<p>Something went wrong. Please try refreshing!</p>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
			<EditAppointmentModal
				isOpen={isEditAppointmentModalOpen}
				onClose={() => {
					setIsEditAppointmentModalOpen(false);
					setSelectedTableRecord(null);
				}}
				patientTableRecord={selectedTableRecord}
			/>
		</div>
	);
};

export default PatientTable;
