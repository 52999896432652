import classNames from "classnames";
import { MedicalRecordState, PatientStage } from "../utils/types";
import HelpDocumentation from "./HelpDocumentation";

const Status = ({ status = false, isDisabled }: any) => {
	return (
		<span
			className={classNames(
				isDisabled
					? "text-gray-400 bg-gray-50 ring-gray-400/10"
					: status.toLocaleLowerCase() === MedicalRecordState.empty.toLocaleLowerCase()
						? "bg-gray-50 text-gray-600 ring-gray-500/10"
						: status.toLocaleLowerCase() === MedicalRecordState.recording.toLocaleLowerCase() ||
							status.toLocaleLowerCase() === MedicalRecordState.paused.toLocaleLowerCase()
							? "bg-yellow-50 text-yellow-800 ring-yellow-600/20"
							: status.toLocaleLowerCase() === MedicalRecordState.processing.toLocaleLowerCase()
								? "bg-purple-50 text-purple-700 ring-purple-700/10"
								: status.toLocaleLowerCase() === MedicalRecordState.transcribed.toLocaleLowerCase() ||
									status.toLocaleLowerCase() === PatientStage.Ongoing.toLocaleLowerCase()
									? "bg-orange-50 text-orange-500 ring-orange-700/10"
									: status.toLocaleLowerCase() === MedicalRecordState.generated.toLocaleLowerCase() ||
										status.toLocaleLowerCase() === PatientStage.Review.toLocaleLowerCase()
										? "bg-blue-50 text-blue-700 ring-blue-700/10"
										: status.toLocaleLowerCase() === MedicalRecordState.completed.toLocaleLowerCase() ||
											status.toLocaleLowerCase() === PatientStage.Finalized.toLocaleLowerCase()
											? "bg-green-50 text-green-700 ring-green-600/20"
											: (status.toLocaleLowerCase() === MedicalRecordState.error.toLocaleLowerCase() ||
												status.toLocaleLowerCase() === MedicalRecordState.FileSizeError.toLocaleLowerCase()) &&
											"bg-red-50 text-red-700 ring-red-600/10",
				"inline-flex items-center rounded-md px-1 py-0.5 text-[10px] sm:px-2 sm:py-1 sm:text-xs font-medium ring-1 ring-inset capitalize relative",
			)}
		>
			{status.split("_").join(" ").toLocaleLowerCase()}
			{status.toLocaleLowerCase() === MedicalRecordState?.error.toLocaleLowerCase() && (
				<div className="absolute md:inline-block top-0 -right-7 z-10">
					<HelpDocumentation
						link="https://help.gohappydoc.com/en/articles/8563272-trouble-shooting-common-issues"
						isDisabled={isDisabled}
						displayText="Help Docs"
						toolTipClassNames="absolute opacity-0 group-hover:opacity-100 -left-2 transform -translate-x-1/2 -top-5 px-2 py-0.5 bg-gray-700 text-white text-[10px] rounded-md z-50"
					/>
				</div>
			)}
		</span>
	);
};
export default Status;
