const Button = ({
	loading = false,
	disabled = false,
	onClick,
	type = "button",
	children,
	styles = "",
	...props
}: {
	loading?: boolean;
	disabled?: boolean;
	type: "button" | "submit" | "reset";
	children: React.ReactNode;
	styles?: string;
	onClick?: () => void;
}) => {
	const baseStyles =
		"flex w-full justify-center rounded-md bg-max-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-max-800  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bg-max-700 ";
	const disabledStyles = "bg-gray-400 text-gray-200 cursor-not-allowed";
	const loadingStyles =
		" bg-max-700 hover:bg-max-700 focus:ring-4 focus:ring-max-300 dark:bg-max-700  dark:hover:bg-max-800    dark:focus:ring-max-800";
	const normalStyles =
		" bg-max-700 hover:bg-max-700 focus:ring-4 focus:ring-max-300 dark:bg-max-700  dark:hover:bg-max-800    dark:focus:ring-max-800";

	const handleClick = () => {
		if (!loading && !disabled && onClick) {
			onClick();
		}
	};

	return (
		<button
			type={type}
			className={`${baseStyles} ${disabled ? disabledStyles : loading ? loadingStyles : normalStyles}`}
			onClick={handleClick}
			disabled={disabled || loading}
			{...props}
		>
			{loading ? (
				<span className="flex justify-center items center text-center">
					<div
						className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
						role="status"
					></div>
				</span>
			) : (
				children
			)}
		</button>
	);
};

export default Button;
