import { Fragment, useState } from "react";
import { addTerm, newMisspellingCorrectionSlack } from "@common/lib/api";
import { useSession } from "@supabase/auth-helpers-react";
import { toast } from "react-toastify";
import HelpDocumentation from "./HelpDocumentation";
import FlagIcon from "@icons/flag-05.svg?react";
import { Transition, Dialog } from "@headlessui/react";
import classNames from "classnames";
import { getActiveDocumentIndex, getActiveDocuments, updateDocumentInTab, getActiveTabId } from "@/features/patient-view/state/redux/activeDocumentsSlice";
import { useDispatch, useSelector } from "react-redux";
import { upsertChatCompletions } from "../lib/supabaseClient";
import { DisplayDocument } from "../utils/types";
import { isJSON } from "validator";
import SaveButton from "./HDSaveButton";
import * as Sentry from "@sentry/react";
import { Tooltip } from "react-tooltip";
import SaveIcon from "@icons/save-01.svg?react";
interface Props {
	isIcon: boolean
}

const TerminologyFlagger = ({ isIcon }: Props) => {

	//Terminology Modal state
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [success, setSuccess] = useState(false);
	const [misspelled, setMisspelled] = useState("");
	const [correctSpelling, setCorrectSpelling] = useState("");
	const [clicked, setClicked] = useState(false);
	const [isOpen, setIsTerminologyModalOpen] = useState(false);
	const [findAndReplace, setFindAndReplace] = useState(true);
	const currentDocumentIndex = useSelector(getActiveDocumentIndex);
	const content = useSelector(getActiveDocuments)[currentDocumentIndex];
	const activeTabId = useSelector(getActiveTabId);
	const dispatch = useDispatch();

	const openTerminologyModal = () => setIsTerminologyModalOpen(true);

	const session = useSession();

	// handle save to supabase and redux state
	const handleSave = async (newContent: any) => {
		// Save in supabase
		dispatch(
			updateDocumentInTab({
				tabId: activeTabId,
				document: { id: content?.id!, chat_json: { header: content?.chat_json?.header, chat_text: newContent } },
			}),
		);

		await upsertChatCompletions({ id: content?.id, chat_json: { header: content?.chat_json?.header, chat_text: newContent } });
	};

	// handle submission errors
	const handleError = (error: any, message: string) => {
		// log and dispaly to user
		Sentry.captureException(message, error);
		toast.error("Uh oh, this misspelling was not submitted. Please try again.");

		// close modal and clear state
		setIsTerminologyModalOpen(false);
		setMisspelled("");
		setCorrectSpelling("");
		return;
	}

	/**
	 * find and replace the misspelled term with the correct spelling in the document
	 * if the document is a string, replace the term in the string
	 * if the document is a DisplayDocument, loop through the json and replace the term in the chat_text
	 */
	const handleFindAndReplace = (
		newContent: string | DisplayDocument,
		isJson: boolean,
		misspelled: string,
		correctSpelling: string
	) => {
		// Case-insensitive regex for finding the misspelled word as a whole word
		const regex = new RegExp(`\\b${misspelled}\\b`, "gi");

		// Function to perform case-sensitive replacement in a string
		const replaceText = (text: string) =>
			text.replace(regex, (match) =>
				match[0] === match[0].toUpperCase()
					? correctSpelling.charAt(0).toUpperCase() + correctSpelling.slice(1)
					: correctSpelling
			);

		// Function to handle value replacement whether it's a string or an array of strings
		const replaceValue = (value: string | string[]) => {
			if (Array.isArray(value)) {
				return value.map(replaceText);
			} else {
				return replaceText(value);
			}
		};

		if (isJson && isJSON(JSON.stringify(newContent))) {
			// Ensure newContent is a JSON object
			if (typeof newContent === "string") {
				try {
					newContent = JSON.parse(newContent);
				} catch (error) {
					Sentry.captureException(error);
					return;
				}
			}

			// Loop through the JSON and replace the term in both `value` and `fields`
			const updatedContent = (newContent as DisplayDocument).map((item) => {
				const newItem = { ...item, value: replaceValue(item.value) };

				if (Array.isArray(newItem.fields)) {
					newItem.fields = newItem.fields.map((field) => ({
						...field,
						value: replaceValue(field.value),
					}));
				}

				return newItem;
			});

			// Save the updated content
			handleSave(updatedContent);
			return updatedContent;

		} else {
			// Handle string case
			if (typeof newContent === "string") {
				const updatedContent = replaceText(newContent);
				handleSave(updatedContent);
				return updatedContent;
			}
		}

	};


	// Handles the submit action
	const handleSubmit = async () => {
		if (!session) {
			Sentry.captureMessage("Session is not available - Terminology Flagger",
				{ level: "error" }
			);
			return;
		}

		setIsSubmitting(true);

		// submit the misspelling to slack
		let result = await newMisspellingCorrectionSlack(session, session?.user?.email, session?.user?.id, misspelled, correctSpelling);
		if ('error' in result) {
			handleError(result, "Error submitting misspelling correction");
			setIsSubmitting(false);
			return;
		}

		// add term to the term corrections list in DB
		result = await addTerm(session, misspelled, correctSpelling); // note (from @quistew): this should be moved to an account-specific terminology corrections list
		if ('error' in result) {
			handleError(result, "Error adding term to term corrections list");
			setIsSubmitting(false);

			return;
		}

		// if find and replace is checked, replace all instances of the misspelled term with the correct spelling
		if (findAndReplace) {
			handleFindAndReplace(content?.chat_json?.chat_text, content?.document_types?.is_json || true, misspelled, correctSpelling);
		}

		// toast success
		toast.success("Misspelling submitted! Thanks for the correction.");
		setSuccess(true);
		setIsSubmitting(false);
		// close modal and clear state
		setIsTerminologyModalOpen(false);
		setMisspelled("");
		setCorrectSpelling("");
	};

	// Handles the cancel action
	const handleCancel = () => {
		setIsTerminologyModalOpen(false);

		// clear state when modal closes
		setMisspelled("");
		setCorrectSpelling("");

	}

	return (
		<>
			{isIcon ?
				(
					<>
						<Tooltip content="Flag Terminology" anchorSelect="#flag-terminology-icon" />
						<button
							id="flag-terminology-icon"
							className={classNames(
								clicked ? "bg-orange-200 text-gray-900 " : "text-orange-300",
								"rounded-lg p-1 hover:bg-orange-300 hover:text-orange-50",
							)}
							onClick={() => {
								openTerminologyModal()
								setClicked(true)
							}}
						>
							<FlagIcon className="h-4 w-4" />
						</button>
					</>
				)
				:
				(<div className="hidden md:flex flex-row justify-end">
					<div className="z-12">
						<HelpDocumentation
							link="https://help.gohappydoc.com/en/articles/8563227-success-with-the-patient-page"
							isDisabled={false}
							displayText="Patient Page Help"
						/>
					</div>

					<div className="justify-end">
						<Tooltip content="Flag Terminology" anchorSelect="#flag-terminology-button" />
						<button
							id="flag-terminology-button"
							onClick={openTerminologyModal}
							type="button"
							className="flex gap-1 rounded-lg text-center bg-max-700 text-white ring-max-700/10 hover:bg-max-800 px-[10px] py-[8px] text-xs font-semibold shadow-sm ring-1 ring-inset mr-3 "
						>
							Flag Terminology
						</button>
					</div>
				</div>)
			}
			<Transition.Root show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-60" open={isOpen} onClose={handleCancel}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-40 w-screen overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-[24px] text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
									<div className="text-left">
										<Dialog.Title as="h1" className="text-xl font-semibold leading-6 text-gray-900">
											Flag Terminology
										</Dialog.Title>
									</div>

									<div className="mt-4 text-[14px] text-gray-500">
										Submit misspelled medical terminology and an associated correct spelling to improve spelling errors over time. Choose to find and replace in the current document, if desired.
									</div>

									<div className="px-1 py-3 mt-4">
										<label htmlFor="misspelled" className="block text-left font-semibold">
											Misspelling
										</label>
										<input
											type="text"
											id="misspelled"
											value={misspelled}
											onChange={(e) => setMisspelled(e.target.value)}
											className="ring-gray-300 lg:flex-1 block w-full rounded-md border-0 py-2 h-[40px] text-[#68727D] text-[14px] leading-[24px] shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-max-700 sm:text-md sm:leading-6"
										/>
									</div>

									<div className="px-1 py-3 mt-4">
										<label htmlFor="correctSpelling" className="block text-left font-semibold">
											Correct spelling
										</label>
										<input
											type="text"
											id="correctSpelling"
											value={correctSpelling}
											onChange={(e) => setCorrectSpelling(e.target.value)}
											className="ring-gray-300 lg:flex-1 block w-full rounded-md border-0 py-2 h-[40px] text-[#68727D] text-[14px] leading-[24px] shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-max-700 sm:text-md sm:leading-6"
										/>
									</div>

									<div className="flex items-center px-1 py-3 mt-4">
										<input
											type="checkbox"
											id="findAndReplace"
											checked={findAndReplace}
											onChange={(e) => setFindAndReplace(e.target.checked)}
											className="mr-2 bg-gray-300 border-0 rounded checkbox-max ring-2 ring-[#26b5bb] focus:ring-[#26b5bb]"
										/>
										<label htmlFor="findAndReplace" className="text-left text-gray-500">
											Find and replace in current document
										</label>
									</div>

									<div className="items-center px-4 py-3 flex justify-end gap-2 mt-6">
										<button
											type="button"
											onClick={handleCancel}
											className="px-4 py-2 bg-gray-200 text-gray-900 rounded hover:bg-gray-300"
										>
											Cancel
										</button>
										<SaveButton
											isLoading={isSubmitting}
											success={success}
											onClick={handleSubmit}
											text="Submit"
											disabled={misspelled === "" || correctSpelling === ""}
											icon={<SaveIcon className="h-4 w-4" aria-hidden="true" />}
										/>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root >
		</>

	);
};

export default TerminologyFlagger;
