import { DisplayDocument, DisplayField } from "@/common/utils/types";
import FormatString from "./FormatString";
import JsonDocInput from "./Input";
import List from "./List";

interface FieldProps {
    sectionKey: number;
    fieldKey: string;
    field: DisplayField | string;
    index: number;
    isEditing: boolean;
    editedJson: DisplayDocument | null;
    setEditedJson: React.Dispatch<React.SetStateAction<DisplayDocument | null>>
    handleFieldChange: (sectionKey: number, fieldIndex: number | null, newValue: string) => void
}

/**
 * Render the field based on the type of the field
 * @param props - the props containing sectionKey, key, field, index, and isEditing
 * @returns JSX.Element a field with the key and value pair
 */
const Field: React.FC<FieldProps> = ({ sectionKey, fieldKey, field, index, isEditing, editedJson, setEditedJson, handleFieldChange }) => {
    let value = <></>;
    if (field && typeof field === "object") {
        let fieldValue = field?.value

        if (fieldValue && Array.isArray(fieldValue)) {
            return (
                <div className={`grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-1 my-1 ${isEditing ? "items-start" : "items-start"}`} key={index}>
                    <span className={`text-[12px] leading-4 sm:text-right pr-[10px] ${isEditing ? "mt-[8px]" : 'self-start'}`}>{field?.name}:</span>
                    <span className="flex flex-col flex-1 w-full pl-[10px] lg:col-span-3 sm:col-span-2 col-span-1">
                        <List
                            sectionKey={sectionKey}
                            value={fieldValue}
                            fieldIndex={index}
                            format={field?.formatting}
                            isEditing={isEditing}
                            editedJson={editedJson}
                            setEditedJson={setEditedJson}
                            handleFieldChange={handleFieldChange} />
                    </span>
                </div>
            );
        }

        if (!field?.isNormal) {

            value = isEditing ? (
                <JsonDocInput
                    sectionKey={sectionKey}
                    value={fieldValue}
                    index={index}
                    isArrayInput={false}
                    isNormal={field?.isNormal}
                    fieldIndex={index}
                    handleFieldChange={handleFieldChange}
                    setEditedJson={setEditedJson}
                    editedJson={editedJson}
                />
            ) : (
                <strong><FormatString value={fieldValue as string} format={field?.formatting} /></strong>
            );
        } else {
            const isEdited = field?.isEdited;

            if (isEdited) {
                fieldValue = field?.value;
            }

            value = isEditing
                ? <JsonDocInput
                    sectionKey={sectionKey}
                    value={fieldValue}
                    index={index}
                    isArrayInput={false}
                    isNormal={field?.isNormal}
                    fieldIndex={index}
                    handleFieldChange={handleFieldChange}
                    setEditedJson={setEditedJson}
                    editedJson={editedJson}
                />
                : <FormatString value={fieldValue as string} format={field?.formatting} />
        }

        return (
            <span className={`grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-1 my-1 ${isEditing ? "items-center" : "items-start"}`} key={index}>
                {/* only render field title: if field name is not empty */}
                {field.name !== "" &&
                    <span className="text-[12px] leading-4 sm:text-right pr-[10px]">{field?.name}:</span>
                }
                <span className="text-[12px] leading-4 flex-1 w-full pl-[10px] lg:col-span-3 sm:col-span-2 col-span-1">{value}</span>
            </span>
        );
    }

    value = isEditing ? (
        <JsonDocInput
            sectionKey={sectionKey}
            value={field}
            index={index}
            isArrayInput={false}
            isNormal={false}
            fieldIndex={index}
            handleFieldChange={handleFieldChange}
            editedJson={editedJson}
            setEditedJson={setEditedJson}
        />
    ) : (
        <p className="text-[12px] leading-4">{field}</p>
    );

    return (
        <div className={`grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-1 my-1 ${isEditing ? "items-center" : "items-start"}`} key={index}>
            <span className="text-[12px] leading-4 sm:text-right pr-[10px]">{fieldKey}:</span>
            <span className="text-[12px] leading-4 flex-1 w-full pl-[10px] lg:col-span-3 sm:col-span-2 col-span-1">{value}</span>
        </div>
    );
};

export default Field;