import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

export const ToolTip = ({
	children,
	text,
	customClassNames,
	defaultPosition = 'bottom',
	arrow = false,
	backgroundColor = 'bg-gray-700',
	parentShowTooltip = false,
}: {
	children: React.ReactNode;
	text: string;
	customClassNames?: string | null;
	defaultPosition?: 'top' | 'bottom' | 'left' | 'right';
	arrow?: boolean;
	backgroundColor?: string;
	parentShowTooltip?: boolean;

}) => {
	const [showTooltip, setShowTooltip] = useState(parentShowTooltip);
	const [tooltipStyles, setTooltipStyles] = useState({ top: 0, left: 0 });
	const [tooltipPosition, setTooltipPosition] = useState(defaultPosition);
	const tooltipRef = useRef<HTMLDivElement>(null);
	const triggerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (showTooltip && tooltipRef.current && triggerRef.current) {
			const triggerRect = triggerRef.current.getBoundingClientRect();
			const tooltipRect = tooltipRef.current.getBoundingClientRect();
			const viewportWidth = window.innerWidth;
			const viewportHeight = window.innerHeight;

			let top, left;

			const fitsTop = triggerRect.top >= tooltipRect.height + 8;
			const fitsBottom = viewportHeight - triggerRect.bottom >= tooltipRect.height + 8;
			const fitsLeft = triggerRect.left >= tooltipRect.width + 8;
			const fitsRight = viewportWidth - triggerRect.right >= tooltipRect.width + 8;

			if (fitsBottom) {
				setTooltipPosition('bottom');
				top = triggerRect.bottom + 8;
				left = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2;
			} else if (fitsTop) {
				setTooltipPosition('top');
				top = triggerRect.top - tooltipRect.height - 8;
				left = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2;
			} else if (fitsRight) {
				setTooltipPosition('right');
				top = triggerRect.top + triggerRect.height / 2 - tooltipRect.height / 2;
				left = triggerRect.right + 8;
			} else if (fitsLeft) {
				setTooltipPosition('left');
				top = triggerRect.top + triggerRect.height / 2 - tooltipRect.height / 2;
				left = triggerRect.left - tooltipRect.width - 8;
			} else {
				setTooltipPosition('bottom');
				top = triggerRect.bottom + 8;
				left = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2;
			}

			// Ensure the tooltip doesn't go off the screen horizontally
			if (left < 8) left = 8;
			if (left + tooltipRect.width > viewportWidth - 8) left = viewportWidth - tooltipRect.width - 8;

			// Ensure the tooltip doesn't go off the screen vertically
			if (top < 8) top = 8;
			if (top + tooltipRect.height > viewportHeight - 8) top = viewportHeight - tooltipRect.height - 8;

			setTooltipStyles({ top, left });
		}
	}, [showTooltip]);

	const arrowPositionClasses = {
		top: 'top-full left-1/2 -mt-1 rotate-45',
		bottom: 'bottom-full left-1/2 mt-1 -rotate-45',
		left: 'left-full top-1/2 -ml-1 -rotate-45',
		right: 'right-full top-1/2 mr-1 rotate-45',
	};

	let widthClass = text.length > 60 ? 'min-w-[100px] max-w-[400px]' : 'text-nowrap';

	return (
		<div
			ref={triggerRef}
			className="relative inline-block"
			onMouseEnter={() => setShowTooltip(text === "" ? false : true)}
			onMouseLeave={() => setShowTooltip(false)}
		>
			<div className="flex items-center">
				<div className="group flex-col">
					{children}
					{showTooltip &&
						ReactDOM.createPortal(
							<div
								ref={tooltipRef}
								style={{ top: tooltipStyles.top, left: tooltipStyles.left, position: 'absolute' }}
								className={`absolute ${widthClass} px-[12px] py-[8px] text-[12px] font-semibold leading-5 text-white ${backgroundColor} rounded-[8px] z-50 ${customClassNames}`}
							>
								<p>{text}</p>
								{arrow && (
									<span
										className={`absolute ${arrowPositionClasses[tooltipPosition]} w-3 h-3 bg-gray-950 transform -translate-x-1/2`}
									></span>
								)}
							</div>,
							document.body,
						)}
				</div>
			</div>
		</div>
	);
};

export default ToolTip;
