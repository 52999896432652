import React, { useState } from 'react';
import CopyIcon from "@icons/copy-03.svg?react";
import CheckIcon from "@icons/check.svg?react";
import { Tooltip } from 'react-tooltip';

interface CopyButtonProps {
    onCopy: () => void;
    id: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ onCopy, id }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        onCopy();
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Revert back to copy icon after 2 seconds
    };

    return (
        <div>
            <Tooltip
                content={"Copy Section"}
                anchorSelect={`#${id}`}
            />
            <button
                id={id}
                data-happydoc-id={id}
                type="button"
                onClick={handleCopy}
                className="self-end rounded-[6px] border border-gray-300 bg-gray-100 px-4 py-2 font-semibold text-gray-500 hover:text-gray-600 hover:shadow-sm hover:ring-1 hover:ring-inset hover:ring-gray-100 hover:bg-gray-200"
            >
                {copied ? <CheckIcon className="w-4 h-4" /> : <CopyIcon className="w-4 h-4" />}
            </button>


        </div>
    );
};

export default CopyButton;