import {
	DisplayDocument,
	DisplaySection,
	DisplayField,
	FormattingOption,
} from "./types";
import * as Sentry from "@sentry/react";

/**
 * Check if the string is a json string
 * @param str  - string to check if it is a json string
 * @returns  boolean - true if the string is a json string
 */
export const isJSON = (str: string | object): boolean => {
	if (typeof str === "object") {
		return true;
	}
	if (!str || str?.charAt(0) !== "{") {
		return false;
	}
	return true;
};

/**
 * Check if the string is a json string
 * @param str  - string to check if it is a json string
 * @returns  boolean - true if the string is a json string
 */
export const isValidValue = (value: string | string[]): boolean => {
	if (Array.isArray(value)) {
		return value?.length > 0;
	}
	return value !== "" && value !== "undefined";
};

/**
 * Check if the string is a json string
 * @param str  - string to check if it is a json string
 * @returns  boolean - true if the string is a json string
 */
export function parseIfJson(input: string): any {
	try {
		return JSON.parse(input);
	} catch (e) {
		return input;
	}
}

/**
 * Create a json object from a string by sanitizing and parsing the string and building up the json object
 * Comes from the DB as a valid DisplayDocument
 * Comes from the stream as a string of json sections separated by \n, i.e. {section1}\n{section2}
 * @param jsonString
 * @returns json object
 */
export function createJson(jsonString: string | object): { json: DisplayDocument, progress: number | null } {
	if (typeof jsonString === "object") {
		return { json: jsonString as DisplayDocument, progress: null };
	}
	// we know if isstreaming the content will be coming in as json blobs and we need to parse it
	// then build up actual json to be rendered into html.

	// sanitize the content
	jsonString = jsonString?.replace(/\n/g, "");
	jsonString = jsonString?.replace(/\r/g, "");

	// get each json object and build up the json
	let splitContent = jsonString?.split("}{");

	// add back the closing bracket to the last object and opening bracket to the first object
	splitContent = splitContent?.map((json, index) => {
		if (splitContent?.length === 1) {
			return json;
		} else if (index === 0) {
			return json + "}";
		} else if (index === splitContent?.length - 1) {
			return "{" + json;
		} else {
			return "{" + json + "}";
		}
	});

	// we need to parse the content and build up the json
	const json = splitContent?.map((json) => {
		try {
			return JSON.parse(json);
		} catch (e) {
			Sentry.captureException(e);
			return {};
		}
	});

	// combine into one json object
	let combinedJson: DisplaySection[] = [];
	let progress = null
	json?.forEach((jsonObj) => {
		combinedJson = [...combinedJson, jsonObj.completion];
		progress = jsonObj.documentProgress;
	});

	return { json: combinedJson as DisplayDocument, progress };
}

/**
 * Convert the section to markdown
 * @param section - section to convert to markdown
 * @returns markdown string
 */
function formatList(list: string[], format: string): string {
	switch (format) {
		case FormattingOption.Bulleted:
			return list.map((item) => `• ${item}`).join("\n");
		case FormattingOption.Dashed:
			return list.map((item) => `- ${item}`).join("\n");
		case FormattingOption.Numbered:
			return list.map((item, index) => `${index + 1}. ${item}`).join("\n");
		case FormattingOption.FlatList:
			return list.join("\n")
		case FormattingOption.Paragraph:
			return formatString(list.join(" "), format);
		case FormattingOption.None:
			return "";
		default:
			return list.join("\n");
	}
}

/**
 * Format the string based on the format
 * @param value - value to format
 * @param format - format to use
 * @returns formatted string
 */
function formatString(value: string, format: string): string {
	switch (format) {
		case FormattingOption.Paragraph:
			return value;
		case FormattingOption.FlatList:
			return `${value}`;
		case FormattingOption.Numbered:
			return `1. ${value}`;
		case FormattingOption.Bulleted:
			return `• ${value}`;
		case FormattingOption.Dashed:
			return `- ${value}`;
		case FormattingOption.None:
			return "";
		default:
			return value;
	}
}

/**
 * Render the field markdown
 * @param field - field to render
 * @param markdown_copy_enabled - boolean to determine if markdown copy is enabled
 * @returns markdown string
 **/
function renderFieldMarkdown(field: DisplayField, markdown_copy_enabled: boolean = false): string {
	let fieldValue = field?.value;

	if (fieldValue && Array.isArray(fieldValue)) {
		return `\n${formatList(fieldValue, field?.formatting)}`;
	} else {
		const isEdited = field?.isEdited;

		if (isEdited) {
			fieldValue = field?.value;
		}

		if (!field?.isNormal && markdown_copy_enabled) {
			return `**${fieldValue}**`;
		}
		return formatString(fieldValue as string, field?.formatting) as string;
	}
}

/**
 * Render the section markdown
 * @param section - section to render
 * @param markdown_copy_enabled - boolean to determine if markdown copy is enabled
 * @returns markdown string
 **/
function renderSectionMarkdown(section: DisplaySection, markdown_copy_enabled: boolean): string {
	let text = "";
	let sectionValue = section?.value;

	// if errors, return empty string
	if (section.hasErrors) {
		return "";
	}

	let valueDefined = false;
	if (sectionValue && Array.isArray(sectionValue)) {
		text += formatList(sectionValue, section?.formatting);
		valueDefined = true;
	} else if (typeof sectionValue === "object" && sectionValue !== null) {
		text += formatString((sectionValue[section?.name?.toLowerCase()] as any).value, section?.formatting);
		valueDefined = true;
	} else if (sectionValue && sectionValue !== "undefined") {
		text += formatString(sectionValue, section?.formatting) as string;
		valueDefined = true;
	}

	if (section?.fields) {
		// if there was a section value defined, then add a newline before the fields
		// otherwise, fields can start populating immediately
		if (valueDefined) {
			text += "\n";
		}

		text += Object.entries(section?.fields)
			.map(([key, field]) => {
				if (field?.name) {
					return `${field?.name}: ${renderFieldMarkdown(field as DisplayField, markdown_copy_enabled)}`;
				} else {
					// no title for the field, just render the field
					return `${renderFieldMarkdown(field as DisplayField, markdown_copy_enabled)}`;
				}
			})
			.join("\n");
	}

	return text;
}

/**
 * Copy the section to the clipboard
 * @param key - key of the section
 * @param section - section to copy to the clipboard
 * @param markdown_copy_enabled - boolean to determine if markdown copy is enabled
 */
export function copySectionToClipboard(key: number, section: DisplaySection, markdown_copy_enabled: boolean = false) {
	let text = section?.name?.toUpperCase() + "\n" + renderSectionMarkdown(section, markdown_copy_enabled);

	navigator?.clipboard?.writeText(text);
}

/**
 * Copy the entire json to the clipboard
 * @param json - json to copy to the clipboard
 * @param markdown_copy_enabled - boolean to determine if markdown copy is enabled
 */
export function copyEntireJsonToClipboard(
	jsonString: string,
	markdown_copy_enabled: boolean = false,
) {
	let text = jsonToMarkdown(jsonString, markdown_copy_enabled);

	navigator?.clipboard?.writeText(text).catch((err) => {
		Sentry.captureException(err);
	});
}

/**
 * Convert the json to markdown
 * @param jsonString - json string to convert to markdown
 * @param markdown_copy_enabled - boolean to determine if markdown copy is enabled
 * @returns markdown string
 */
export function jsonToMarkdown(
	jsonString: string,
	markdown_copy_enabled: boolean = false,
) {
	const { json } = createJson(jsonString);

	let text = "";

	json.map((section) => {
		text += section?.name?.toUpperCase() + "\n" + renderSectionMarkdown(section as DisplaySection, markdown_copy_enabled) + "\n\n";
	});

	return text;
}
