import ThumbsDownIcon from "@icons/thumbs-down.svg?react";
import ThumbsUpIcon from "@icons/thumbs-up.svg?react"; import classNames from "classnames";
import { toast } from "react-toastify";
import { updateChatCompletions } from "@common/lib/supabaseClient";
import { Tooltip } from 'react-tooltip'
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { ChatCompletions } from "@common/utils/types";
import { getActiveTabId, updateDocumentInTab } from "@features/patient-view/state/redux/activeDocumentsSlice";
import FeedbackModal from "@common/components/FeedbackModal";
import { postNewFeedbackToSlack } from "@common/lib/api";
import { useSessionContext } from "@supabase/auth-helpers-react";
import * as Sentry from "@sentry/react";
import TerminologyFlagger from "@/common/components/TerminologyFlagger";

//enum for good bad and needs editing
enum DocumentFeedbackEnum {
	Good = "Output Perfect",
	NeedsEditing = "Formatting Issues",
	Bad = "Information Issues",
}

const showSuccess = () => {
	toast.success("Thank you for the feedback! We will use it to improve the output");
};

const FeedbackButtons = ({
	completionId,
	currentFeedback,
}: {
	completionId: string;
	currentFeedback: string | null;
}) => {
	const dispatch = useDispatch();
	const tabId = useSelector(getActiveTabId);
	const { supabaseClient, session } = useSessionContext();
	const [feedbackModalOpen, setFeedbackModal] = useState(false);
	const [feedbackType, setFeedbackType] = useState<DocumentFeedbackEnum | "">("");
	const [feedback, setFeedback] = useState("");

	const sendFeedback = async (feedbackValue: DocumentFeedbackEnum, feedbackDescription: string | null) => {
		// update FeedbackValue in the db regardless of if the user writes feedback or not
		if (completionId) {
			await updateChatCompletions({ feedback: feedbackValue, feedback_description: feedbackDescription }, completionId);
			dispatch(
				updateDocumentInTab({
					tabId: tabId,
					document: { id: completionId, feedback: feedbackValue } as ChatCompletions,
				}),
			);

			const { data, error } = await supabaseClient
				.from("chat_completions")
				.select("chat_json, account_id")
				.eq("id", completionId)
				.single();

			if (error) {
				Sentry.captureException(error);
				return;
			}

			const acct_id = data?.account_id;
			const doc_name = data?.chat_json?.header;
			const email = session?.user?.email;

			if (feedbackValue === DocumentFeedbackEnum.Good) {
				feedbackDescription = "<output perfect>";
			}

			// if the user has provided written feedback OR clicked green thumbs up, send this to slack
			if (feedbackDescription || feedbackValue === DocumentFeedbackEnum.Good) {
				try {
					if (!session) {
						Sentry.captureMessage("Session not found in FeedbackButtons.tsx", {
							level: "error",
						});
						return;
					}

					let description = feedbackDescription ? feedbackDescription : "";
					await postNewFeedbackToSlack(session, completionId, feedbackValue, description, acct_id, doc_name, email);
					showSuccess();
				} catch (error) {
					Sentry.captureException(error);
				}
			}
		}
	};

	const onCancel = () => {
		if (feedbackType !== "") {
			sendFeedback(feedbackType, null);
		}
		setFeedbackModal(false);
		setFeedbackType("");
	};

	const onSubmit = () => {
		if (feedbackType !== "") {
			sendFeedback(feedbackType, feedback);
		}
		setFeedbackModal(false);
		setFeedbackType("");
	};

	return (
		<div className="hidden sm:flex flex-row space-x-0.5 items-center flex-shrink">
			<Tooltip content="Output Perfect" anchorSelect="#thumbs-up" />
			<button
				id="thumbs-up"
				className={classNames(
					currentFeedback === DocumentFeedbackEnum.Good ? "bg-green-200 text-gray-900 " : "text-green-300",
					"rounded-lg p-1 hover:bg-green-300 hover:text-green-50",
				)}
				onClick={() => {
					sendFeedback(DocumentFeedbackEnum.Good, null);
				}}
			>
				<ThumbsUpIcon className="h-4 w-4" />
			</button>

			<TerminologyFlagger isIcon={true} />

			<Tooltip content="Information Issues" anchorSelect="#thumbs-down" />
			<button
				id="thumbs-down"
				className={classNames(
					currentFeedback === DocumentFeedbackEnum.Bad ? "bg-red-200 text-gray-900 " : "text-red-300",
					"rounded-lg p-1 hover:bg-red-300 hover:text-red-50",
				)}
				onClick={() => {
					setFeedbackModal(true);
					setFeedbackType(DocumentFeedbackEnum.Bad);
				}}
			>
				<ThumbsDownIcon className="h-4 w-4" />
			</button>
			<div className="w-full md:w-1/3 xl:w-1/4 flex flex-col-reverse md:flex-col">
				<div className="min-h-[20px]">
					<div className="hidden md:flex flex-row justify-end">
						<FeedbackModal
							isOpen={feedbackModalOpen}
							onCancel={onCancel}
							onSubmit={onSubmit}
							setFeedback={setFeedback}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FeedbackButtons;
