import { Disclosure, Menu, Transition } from "@headlessui/react";
import ChevronDownIcon from "@icons/chevron-down.svg?react";
import ChevronRightIcon from "@icons/chevron-right.svg?react";
import classNames from "classnames";
import { useState, Fragment, useEffect } from "react";
import Status from "../../../../common/components/Status";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../../../common/components/ConfirmModal";
import { deletePatientRecordsAsync } from "@redux/patientTableRecordsSlice";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "@common/components/Spinner";
import {
	getPatientData,
	updatePatientData,
	getPatientRecordFetchStatus,
	getPatientRecordFetchError,
} from "@features/patient-view/state/redux/activePatientDataSlice";
import { updatePatientRecordTableRow } from "@redux/patientTableRecordsSlice";
import { isSameDay } from "@common/utils/helpers";
import { useDataContextApi } from "@providers/DataProvider";
import { useRealtimeContextApi } from "@/state/providers/RealtimeProvider";

const AppointmentInfo = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const patientData = useSelector(getPatientData);
	const [editPatientData, setEditPatientData] = useState(patientData);
	const [isEditing, setIsEditing] = useState(false);
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const { doctors } = useSelector((state: any) => state.doctors);
	const selectedDoctor = useSelector((state: any) => state.doctors.selectedDoctor);
	const { selectedDate } = useDataContextApi();
	const fetchStatus = useSelector(getPatientRecordFetchStatus);
	const errorStatus = useSelector(getPatientRecordFetchError);
	const { emitPatientDeletedEvent } = useRealtimeContextApi();

	useEffect(() => {
		setEditPatientData(patientData); // Update local state when Redux state changes
	}, [patientData]);

	const handleSave = () => {
		if (!editPatientData || !editPatientData.id) {
			return;
		}
		// Determine if the patient table record needs to be updated based on certain conditions
		const updateTable =
			isSameDay(new Date(editPatientData.scheduled_at), selectedDate.startDate) &&
			(selectedDoctor?.id === editPatientData.doctors?.id || !selectedDoctor);

		// Dispatch the action to update the patient data in the Redux store, will update in supabase as well.
		dispatch<any>(
			updatePatientRecordTableRow({
				updatedTableRecord: { ...editPatientData },
				updateTable: updateTable,
			}),
		);
		dispatch(updatePatientData({ ...editPatientData }));

		setIsEditing(false);
	};

	const handleCancel = () => {
		setIsEditing(false);
		setEditPatientData(patientData); // Revert changes on cancel
	};

	const handleEdit = () => {
		//setPatientData(patientData);
		setIsEditing(true);
	};

	const handleDelete = () => {
		if (patientData?.id) {
			dispatch<any>(deletePatientRecordsAsync([patientData.id]));
			emitPatientDeletedEvent(patientData.id);

			navigate("/");
		}
	};

	const formatDateForInput = (d: string) => {
		const date = moment(d).local();
		return date.format("YYYY-MM-DDTHH:mm");
	};

	const formatDateandTime = (d: string) => {
		return moment(d).local().format("M/D/YYYY, h:mm A");
	};

	//TODO JT clean up
	if (!patientData && fetchStatus === "loading")
		return (
			<div className="border-b border-gray-200 bg-white px-4 py-4 sm:px-6 shadow ring-1 ring-gray-900 ring-opacity-5 rounded-lg flex justify-center">
				<Spinner size="small" />
			</div>
		);

	if (errorStatus) return <> </>;

	return (
		<Disclosure defaultOpen={true}>
			{({ open }) => (
				<>
					<div className="">
						<div className="border-b border-gray-200 bg-white px-4 py-4 sm:px-6 shadow ring-1 ring-gray-900 ring-opacity-5 rounded-lg">
							<div className="flex justify-between items-center">
								<div className="flex items-center sm:hidden">
									<Disclosure.Button className={"flex items-center p-0 m-0"}>
										<ChevronRightIcon className={classNames(open ? "rotate-90" : "", "h-5 w-5 mr-1")} />
										<h3 className="text-lg lg:text-xl xl:text-[24px] whitespace-nowrap">Patient Information</h3>
									</Disclosure.Button>
								</div>
								<div className="flex gap-x-1 items-center sm:hidden">
									{!isEditing && (
										<button
											type="button"
											className="rounded bg-max-700 px-2 py-1 text-xs font-medium text-white  shadow-sm hover:bg-max-800"
											onClick={handleEdit}
										>
											Edit
										</button>
									)}
									<button
										type="button"
										className="rounded bg-red-50 px-2 py-1 text-xs font-medium text-red-600 shadow-sm hover:bg-red-100"
										onClick={() => setIsConfirmModalOpen(true)}
									>
										Delete
									</button>
								</div>
							</div>
							<Disclosure.Panel>
								{isEditing ? (
									<div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6 2xl:gap-x-2">
										<div className="sm:col-span-3 md:col-span-6 2xl:col-span-3">
											<label className="block text-sm font-medium leading-tight text-gray-900">Doctor</label>
											<div className="mt-1">
												<DoctorPicker
													doctors={doctors}
													editPatientData={editPatientData}
													setEditPatientData={setEditPatientData}
												/>
											</div>
										</div>

										<div className="sm:col-span-3 md:col-span-6 2xl:col-span-3">
											<label htmlFor="scheduled-time" className="block text-sm font-medium leading-tight text-gray-900">
												Scheduled Time
											</label>
											<div className="mt-1">
												<input
													type="datetime-local"
													id="scheduled-time"
													name="scheduled-time"
													value={formatDateForInput(editPatientData.scheduled_at)}
													onChange={(e) => setEditPatientData({ ...editPatientData, scheduled_at: moment(e.target.value).utc() })}
													onClick={(e) => {
														(e.target as any).showPicker();
													}}
													className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-max-700  sm:text-sm sm:leading-6"
												/>
											</div>
										</div>

										<div className="sm:col-span-3 md:col-span-6 2xl:col-span-3">
											<label htmlFor="patient-name" className="block text-sm font-medium leading-tight text-gray-900">
												Name
											</label>
											<div className="mt-1">
												<input
													type="text"
													name="patient-name"
													id="patient-name"
													value={editPatientData.patient_name}
													onChange={(e) => setEditPatientData({ ...editPatientData, patient_name: e.target.value })}
													className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-max-700  sm:text-sm sm:leading-6"
												/>
											</div>
										</div>

										<div className="sm:col-span-3 md:col-span-6 2xl:col-span-3">
											<label htmlFor="patient-id" className="block text-sm font-medium leading-tight text-gray-900">
												Patient ID
											</label>

											{/* if you're coming from an integration you can't edit the patient ID - HDD-352 */}
											{patientData?.account_integrations != null ?
												<div className="mt-1">
													{/* if the integration is ezyvet, show the secondary_id (the code) instead of what the API gives us as the ID */}
													<input
														type="text"
														readOnly={true}
														value={(patientData?.account_integrations?.integration_name == "ezyvet" && patientData.patients?.secondary_id
															? patientData?.patients?.secondary_id
															: patientData?.patient_id)}
														className="text-gray-400 placeholder:text-gray-400 w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-max-700  text-xs leading-tight sm:text-sm sm:leading-6"
													/>
												</div>
												:
												<div className="mt-1">
													<input
														type="text"
														name="patient-id"
														id="patient-id"
														value={editPatientData.patient_id}
														onChange={(e) => setEditPatientData({ ...editPatientData, patient_id: e.target.value })}
														className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-max-700  sm:text-sm sm:leading-6"
													/>
												</div>}

										</div>

										<div className="flex justify-end gap-2 mt-2 sm:col-span-6">
											<button
												type="button"
												className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
												onClick={handleCancel}
											>
												Cancel
											</button>
											<button
												type="button"
												className="rounded bg-max-700 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-max-800  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bg-max-700 "
												onClick={() => {
													handleSave();
												}}
											>
												Save
											</button>
										</div>
									</div>
								) : (
									<div className="mt-2 grid grid-cols-3 sm:grid-cols-4 gap-x-2 gap-y-2 md:grid-cols-6 justify-items-stretch">
										<div className="col-span-1">
											<label htmlFor="patient-name" className="block text-xs font-medium leading-tight text-gray-900">
												Name
											</label>
											<div
												className={classNames(
													"break-words",
													patientData?.patient_name ? "text-sm font-medium" : "text-gray-400 text-sm",
												)}
											>
												{patientData?.patient_name || "(blank)"}
											</div>
										</div>

										<div className="col-span-1 hidden md:block">
											<label htmlFor="patient-id" className="block text-xs font-medium leading-tight text-gray-900">
												Patient ID
											</label>
											<div className={classNames(patientData?.patient_id ? "text-sm" : "text-gray-400 text-sm")}>
												{
													(patientData?.account_integrations?.integration_name == "ezyvet" && patientData?.patients?.secondary_id
														? patientData?.patients?.secondary_id
														: patientData?.patient_id) || "(blank)"
												}
											</div>
										</div>

										<div className="col-span-1 hidden md:block">
											<label className="block text-xs font-medium leading-tight text-gray-900">Doctor</label>
											<div className={classNames(patientData?.doctors?.id ? "text-sm" : "text-gray-400 text-sm")}>
												{patientData?.doctors?.doctor_name || "(blank)"}
											</div>
										</div>

										<div className="col-span-1">
											<label htmlFor="scheduled-time" className="block text-xs font-medium leading-tight text-gray-900">
												Scheduled Time
											</label>
											<div className="text-sm">{formatDateandTime(patientData?.scheduled_at)}</div>
										</div>

										<div className="col-span-1 justify-center flex">
											{patientData?.stage && (
												<div className="sm:col-span-3">
													<label className="block text-xs font-medium leading-tight text-gray-900">Stage</label>
													<Status status={patientData?.stage} />
												</div>
											)}
										</div>

										<div className="hidden sm:block col-span-1">
											<div className="flex gap-x-1 justify-end">
												{!isEditing && (
													<button
														type="button"
														className="rounded bg-max-700 px-2 py-1 text-xs font-medium text-white  shadow-sm hover:bg-max-800"
														onClick={handleEdit}
													>
														Edit
													</button>
												)}
												<button
													type="button"
													className="rounded bg-red-50 px-2 py-1 text-xs font-medium text-red-600 shadow-sm hover:bg-red-100"
													onClick={() => setIsConfirmModalOpen(true)}
												>
													Delete
												</button>
											</div>
										</div>
									</div>
								)}
							</Disclosure.Panel>
						</div>
					</div>
					<ConfirmModal
						id="DeleteAppointment"
						headerText="Are you sure you want to delete the entire appointment?"
						cancelButtonText="Cancel"
						confirmButtonText="Delete"
						onCancel={() => setIsConfirmModalOpen(false)}
						onConfirm={() => {
							handleDelete();
							setIsConfirmModalOpen(false);
						}}
						isOpen={isConfirmModalOpen}
						confirmButtonColor="bg-red-600"
						confirmHoverButtonColor="bg-red-500"
					/>
				</>
			)}
		</Disclosure>
	);
};
export default AppointmentInfo;

//TODO: Abstract out the DoctorPicker component
const DoctorPicker = ({ doctors, editPatientData, setEditPatientData }: any) => {
	const disabled = false;
	if (!doctors) return <div>Loading...</div>;
	return (
		<Menu
			as="div"
			className="relative w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-max-700  sm:text-sm sm:leading-6"
		>
			<Menu.Button className="-m-1.5 flex items-center py-1 sm:py-1.5 px-1.5 w-full" disabled={disabled}>
				<span className="sr-only">Select Doctors</span>
				<span className="flex items-center justify-between w-full">
					<span
						className={classNames(
							disabled ? "text-gray-400" : "text-gray-900",
							"ml-3 text-xs leading-tight sm:text-sm sm:font-semibold sm:leading-6 ",
						)}
						aria-hidden="true"
					>
						{editPatientData?.doctors?.doctor_name || "None"}
					</span>
					<ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
				</span>
			</Menu.Button>
			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none cursor-pointer">
					{doctors?.map((doctor: any, index: number) => (
						<Menu.Item key={index}>
							{({ active }) => (
								<div
									onClick={() => {
										setEditPatientData({
											...editPatientData,
											doctors: {
												doctor_name: doctor.doctor_name,
												id: doctor.id,
											},
										});
									}}
									className={classNames(
										active ? "bg-gray-50" : "",
										doctor?.id === editPatientData?.doctors?.id ? "bg-max-800    text-white" : "",
										"block px-3 py-1 text-sm leading-6 text-gray-900",
									)}
								>
									{doctor.doctor_name}
								</div>
							)}
						</Menu.Item>
					))}
					{/* seperator */}
					<div className="border-t border-gray-200" />
					<Menu.Item>
						{({ active }) => (
							<div
								onClick={() => {
									setEditPatientData({
										...editPatientData,
										doctors: null,
									});
								}}
								className={classNames(
									active ? "bg-gray-50" : "",
									!editPatientData?.doctors?.id ? "bg-max-800    text-white" : "",
									"block px-3 py-1 text-sm leading-6 text-gray-900",
								)}
							>
								None
							</div>
						)}
					</Menu.Item>
				</Menu.Items>
			</Transition>
		</Menu>
	);
};
