import { Disclosure, Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { useState, useEffect, Fragment } from "react";
import { toast } from "react-toastify";
import { upsertChatCompletions, deleteChatCompletions } from "@common/lib/supabaseClient";
import Content from "./Content";
import DocumentFeedback from "./FeedbackButtons";
import { useAccountContext } from "@providers/AccountProvider";
import { useDispatch, useSelector } from "react-redux";
import {
	getActiveDocumentsLength,
	setActiveDocumentIndex,
	getActiveDocumentIndex,
	getActiveDocuments,
	removeDocumentFromTab,
	updateDocumentInTab,
} from "../../state/redux/activeDocumentsSlice";
import { decrementDocumentCount } from "@redux/patientTableRecordsSlice";
import { exportCornerstoneRecord } from "@common/lib/api";
import { useSession } from "@supabase/auth-helpers-react";
import { useDataContextApi } from "@/state/providers/DataProvider";
import { DocumentStatus, DocumentTab, DocumentType } from "@/common/utils/types";
import Spinner from "@/common/components/Spinner";
import JsonContent from "./JsonContent";
import { copyEntireJsonToClipboard, jsonToMarkdown } from "@/common/utils/formatter";
import CopyIcon from "@icons/copy-03.svg?react";
import EditIcon from "@icons/edit-05.svg?react";
import RefreshIcon from "@icons/refresh-cw-03.svg?react";
import ShareIcon from "@icons/share-05.svg?react";
import DotsVerticalIcon from "@icons/dots-vertical.svg?react";
import ChevronRightIcon from "@icons/chevron-right.svg?react";
import ChevronLeftIcon from "@icons/chevron-left.svg?react";
import InformationCircleIcon from "@icons/info-circle.svg?react";
import SaveIcon from "@icons/save-01.svg?react";
import PrintIcon from "@icons/printer.svg?react";
import TrashIcon from "@icons/trash-03.svg?react";
import ConfirmModal from "@/common/components/ConfirmModal";
import StopIcon from "@icons/stop-circle.svg?react";
import { Tooltip } from 'react-tooltip'


// TODO could use a refactor
const Document = ({
	completionId,
	isLoadingCompletion,
	completion,
	handleRetry,
	activeTabId,
	tab,
	patientData,
	stop,
}: {
	completionId: string | null;
	isLoadingCompletion: boolean;
	completion: string | null; //Streaming completion
	handleRetry: (document: DocumentType, header: string) => void;
	activeTabId: string;
	tab: DocumentTab;
	patientData: any;
	stop: () => void;
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const dispatch = useDispatch();
	const session = useSession();
	const activeDocumentsLength = useSelector(getActiveDocumentsLength);
	const currentDocumentIndex = useSelector(getActiveDocumentIndex);
	const content = useSelector(getActiveDocuments)[currentDocumentIndex];
	const [newHeader, setNewHeader] = useState(content?.chat_json?.header);
	const [newContent, setNewContent] = useState(content?.chat_json?.chat_text);
	const [isExporting, setIsExporting] = useState(false);
	const [exportConfirmModalOpen, setExportConfirmModalOpen] = useState(false);

	const { accountData, containsFeatureFlag } = useAccountContext();
	const markdownEnabled = (accountData?.meta_data as { markdown_copy_enabled?: boolean })?.markdown_copy_enabled;
	//Formating for bold
	const { sectionsAndFieldsToBold } = useDataContextApi();

	useEffect(() => {
		if (content?.chat_json?.header) {
			setNewHeader(content?.chat_json?.header);
		}
		if (content?.chat_json?.chat_text) {
			setNewContent(content?.chat_json?.chat_text);
		}
	}, [content]);

	const handleSave = async () => {
		if (!newHeader) {
			toast.warn("Header cannot be empty");
			return;
		}

		// Save in supabase
		dispatch(
			updateDocumentInTab({
				tabId: activeTabId,
				document: { id: content?.id!, chat_json: { header: newHeader, chat_text: newContent } },
			}),
		);

		await upsertChatCompletions({ id: content?.id, chat_json: { header: newHeader, chat_text: newContent } });
		setIsEditing(false);
	};

	const handleCancel = () => {
		// reset the header and content to the original values
		setNewHeader(content?.chat_json?.header);
		setNewContent(content?.chat_json?.chat_text);
		setIsEditing(false);
	};

	const handleDelete = async () => {
		dispatch(removeDocumentFromTab({ tabId: activeTabId, documentId: content?.id! }));
		// delete from supabase
		if (content?.id) deleteChatCompletions(content.id);
		dispatch(decrementDocumentCount({ id: content?.patient_record_id }));
	};

	//Copies the entire Document
	const handleCopyEntireDocument = (isJson: boolean) => {
		if (isJson) {
			copyEntireJsonToClipboard(content?.chat_json?.chat_text, markdownEnabled);
		} else {
			// Split the text into lines
			const lines = content?.chat_json?.chat_text?.split("\n");
			let processedLines;
			// Process each line
			if (markdownEnabled) {
				processedLines = lines.map((line: string) => {
					// Prepare the line for comparison by converting it to lowercase and removing colons
					const lineForComparison = line.toLowerCase().replace(/:/g, "").trim();

					// Check if the processed line is in the creatorSectionNames set and not already wrapped in asterisks
					if (sectionsAndFieldsToBold.has(lineForComparison) && !line.startsWith("**") && !line.endsWith("**")) {
						// Wrap the line in asterisks for Markdown
						return `**${line}**`;
					}
					return line;
				});
			} else {
				processedLines = lines.map((line: string) => {
					// Remove asterisks from the line, whether or not it's in creatorSectionNames
					const cleanedLine = line.replace(/\*\*/g, "");
					return cleanedLine;
				});
			}
			// Join the processed lines back into a single string
			const processedText = processedLines?.join("\n");
			// Use navigator.clipboard.writeText to copy the processed text to the clipboard
			navigator.clipboard.writeText(processedText);
		}

		toast.success("Document copied to clipboard!");
	};

	/**
	 * Determines if the export functionality is enabled based on the patient's account integrations and doctors' information.
	 *
	 * @returns {boolean} - Returns `true` if the export is enabled, otherwise `false`.
	 *
	 * The export is enabled if:
	 * - The patient has account integrations and doctors information.
	 * - The integration name is "bitwerx" (case-insensitive).
	 * - The connected third party is "cornerstone" (case-insensitive).
	 * - Either the doctor's PMS export ID or PMS employee ID ends with "|provider" (case-insensitive).
	 */
	const exportEnabled = () => {
		if (!patientData?.account_integrations ||
			!patientData?.doctors) {
			return false;
		}

		if (patientData.account_integrations.integration_name.toLowerCase() === "bitwerx" &&
			patientData.account_integrations.connected_third_party.toLowerCase() === "cornerstone" &&
			((patientData.doctors.pms_export_id && patientData.doctors.pms_export_id.toLowerCase().endsWith("|provider")) ||
				patientData.doctors.pms_employee_id?.toLowerCase().endsWith("|provider"))) {
			return true;
		}

		return false;
	};

	const handleExport = async () => {
		if (!session) {

			return;
		}

		setIsExporting(true);

		const resp = await exportCornerstoneRecord(session, accountData?.id!, content?.id!);
		if (!resp || resp.error) {
			setIsExporting(false);
			toast.error("Failed to export document!");
			return;
		}

		setIsExporting(false);
		toast.success("Document exported successfully!");
	};

	const handlePrint = (isJson: boolean) => {
		const json = isJson ? jsonToMarkdown(content?.chat_json?.chat_text, markdownEnabled) : "";

		const printWindow = window.open("", "PRINT", "");
		// style the body to whitespace: pre-line
		printWindow?.document.write('<html><head><title>Print</title></head><body style="white-space: pre-line;">');
		//Remove Asterisks
		const cleaned = isJson ? json.replace(/\*\*/g, "") : content?.chat_json?.chat_text.replace(/\*\*/g, "");
		printWindow?.document.write(cleaned);
		printWindow?.document.write("</body></html>");
		printWindow?.document.close(); // necessary for IE >= 10
		printWindow?.focus(); // necessary for IE >= 10*/
		printWindow?.print();
	};

	return (
		<Disclosure defaultOpen={true}>
			{({ open }) => (
				<>
					<div className="border-b border-gray-200 bg-white px-4 py-4 sm:px-6 shadow ring-1 ring-gray-900 ring-opacity-5 rounded-b-lg">
						<div className="flex justify-between w-full ">
							<div className="flex items-center w-full max-w-[40%]">
								{isEditing && content?.id === completionId ? (
									<button className={"flex items-center p-0 m-0"}>
										<ChevronRightIcon className={classNames(open ? "rotate-90" : "", "h-5 w-5 mr-1")} />
										<p className="font-semibold text-gray-900 max-w-1/2 truncate">{content?.chat_json?.header}</p>
									</button>
								) : isLoadingCompletion && content?.id === completionId ? (
									<>
										<p className="font-semibold text-gray-900 max-w-1/2 truncate">{content?.chat_json?.header}</p>
									</>
								) : (
									<div className="flex flex-col xl:flex-row w-full">
										<Disclosure.Button className={"flex items-center p-0 m-0"}>
											<ChevronRightIcon className={classNames(open ? "rotate-90" : "", "h-5 w-5 mr-1")} />
											<p className="font-semibold text-sm text-gray-900 truncate">{content?.chat_json?.header}</p>
										</Disclosure.Button>

										<div className="flex mt-2 xl:mt-1 ml-6 xl:ml-4 items-center">
											<div className="">
												<button
													type="button"
													className={classNames(
														currentDocumentIndex < 1
															? "bg-gray-50 text-gray-400 cursor-default"
															: "bg-white text-gray-900 hover:bg-gray-50",
														"rounded  px-1 py-1 text-xs font-semibold  shadow-sm ring-1 ring-inset ring-gray-300 ",
													)}
													onClick={() => {
														dispatch(setActiveDocumentIndex(currentDocumentIndex - 1));
													}}
													disabled={currentDocumentIndex === 0}
												>
													<ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
												</button>
											</div>
											<div className="text-xs text-gray-900 mx-1 whitespace-nowrap">
												{currentDocumentIndex + 1} of {activeDocumentsLength}
											</div>
											<div className="">
												<button
													type="button"
													className={classNames(
														currentDocumentIndex === activeDocumentsLength - 1
															? "bg-gray-50 text-gray-400 cursor-default"
															: "bg-white text-gray-900 hover:bg-gray-50",
														"rounded  px-1 py-1 text-xs font-semibold  shadow-sm ring-1 ring-inset ring-gray-300 ",
													)}
													onClick={() => {
														dispatch(setActiveDocumentIndex(currentDocumentIndex + 1));
													}}
													disabled={currentDocumentIndex === activeDocumentsLength - 1}
												>
													{currentDocumentIndex === activeDocumentsLength - 1 ? (
														<ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
													) : (
														<ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
													)}
												</button>
											</div>
										</div>
									</div>
								)}
							</div>

							<div className="flex items-center gap-x-2 gap-y-2 self-start justify-end flex-wrap">
								{isLoadingCompletion && content?.id === completionId ? (
									<>
										<Tooltip
											anchorSelect="#retry-doc-button"
											content={"Retry Generation"}
										/>
										<button
											id="retry-doc-button"
											type="button"
											className="rounded bg-white px-3 py-2 sm:px-2 sm:py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100"
											onClick={() => {
												if (content?.document_types && content?.header) {
													handleRetry(content?.document_types, content?.header);
												} else {
													toast.error("Unable to retry document, Refresh the page and try again.");
												}
											}}
										>
											<div className="flex gap-x-1 items-center">
												<RefreshIcon className="h-4 w-4" />
												<span className="hidden sm:inline-flex">Retry</span>
											</div>
										</button>
										<Tooltip
											anchorSelect="#stop-doc-button"
											content={"Stop Generating Document"}
										/>
										<button
											id="stop-doc-button"
											type="button"
											className="text-white rounded bg-max-700 px-3 py-2 sm:px-2 sm:py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-max-800"
											onClick={() => stop()}
										>
											<div className="flex gap-x-1 items-center">
												<StopIcon className="h-4 w-4 " />
												<span className="hidden sm:inline-flex">Stop</span>
											</div>
										</button>
									</>
								) : isEditing ? (
									<>
										<Tooltip
											content={"Cancel Editing"}
											anchorSelect="#cancel-doc-button"
										/>
										<button
											type="button"
											className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
											onClick={() => {
												handleCancel();
											}}
										>
											Cancel
										</button>
										<Tooltip
											anchorSelect="#save-doc-button"
											content={"Save Edits"}
										/>
										<button
											id="save-doc-button"
											type="button"
											className="rounded flex items-center justify-center gap-[4px] text-center bg-max-700 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-max-800  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bg-max-700 "
											onClick={() => {
												handleSave();
											}}
										>
											<SaveIcon className="h-4 w-4" />
											Save
										</button>
									</>
								) : (
									<>
										<DocumentFeedback completionId={content?.id || ""} currentFeedback={content?.feedback || ""} />

										{!isLoadingCompletion &&
											<>

												<Tooltip
													anchorSelect="#retry-doc-button"
													content={"Retry Generation"}
												/>
												<button
													id="retry-doc-button"
													type="button"
													className="rounded bg-white px-3 py-2 sm:px-2 sm:py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100"
													onClick={() => {
														if (content?.document_types && content?.header) {
															handleRetry(content?.document_types, content?.header);
														} else {
															toast.error("Unable to retry document, Refresh the page and try again.");
														}
													}}
												>
													<div className="flex gap-x-1 items-center">
														<RefreshIcon className="h-4 w-4" />
														<span className="hidden lg:inline-flex">Retry</span>
													</div>
												</button>
											</>
										}

										<Tooltip
											content={"Edit Document"}
											anchorSelect="#edit-doc-button"
										/>
										<button
											title="Edit"
											id="edit-doc-button"
											type="button"
											className="rounded bg-white px-3 py-2 sm:px-2 sm:py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100"
											onClick={() => {
												setIsEditing(true);
											}}
										>
											<div className="flex gap-x-1 items-center">
												<EditIcon className="h-4 w-4" />
												<span className="hidden lg:inline-flex">Edit</span>
											</div>
										</button>
										<Tooltip
											content={"Copy Document"}
											anchorSelect="#copy-doc-button"
										/>
										<button
											id="copy-doc-button"
											type="button"
											className="inline-flex items-center gap-x-1.5 rounded bg-max-700 px-3 py-2 sm:px-2 sm:py-1 text-xs font-semibold text-white shadow-sm hover:bg-max-800  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bg-max-700 "
											onClick={() => handleCopyEntireDocument(content?.document_types?.is_json || false)}
										>
											<CopyIcon className="h-4 w-4" />
											<span className="hidden lg:inline-flex">Copy</span>
										</button>


										{containsFeatureFlag("EXPORT_CORNERSTONE") &&
											patientData?.linked_by_integration &&
											content?.document_types?.is_json &&
											content?.document_types?.document_exports?.is_active && (
												<>
													<Tooltip
														anchorSelect="#export-doc-button"
														content={exportEnabled() ? "Export Document" : "Please validate Doctor settings"}
													/>
													<button
														id="export-doc-button"
														type="button"
														disabled={!exportEnabled()}
														className={classNames(!exportEnabled() ? "text-gray-400 bg-max-200" : "bg-max-700 text-white hover:bg-max-800", "inline-flex items-center justify-center gap-x-1.5 rounded  px-3 py-2 sm:px-2 sm:py-1 text-xs font-semibold  shadow-sm   focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bg-max-700")}
														onClick={() => setExportConfirmModalOpen(true)}
													>
														<div className="flex gap-x-1">
															{isExporting ? (
																<Spinner size="xsmall" color="white" />
															) : (
																<>
																	<ShareIcon className="h-4 w-4" />
																	<span className="hidden lg:inline-flex">Export</span>
																</>
															)}
														</div>
													</button>
												</>


											)}
									</>
								)}
								{/* Three Dot Menu */}
								{isLoadingCompletion && content?.id === completionId ? (
									<></>
								) : (
									<Menu as="div" className="relative flex-none select-none">
										<Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
											<span className="sr-only">Open options</span>
											<DotsVerticalIcon className="h-5 w-5" />
										</Menu.Button>
										<Transition
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
												<Menu.Item>
													{({ active }) => (
														<button
															className={classNames(
																active ? "bg-gray-50" : "",
																"flex items-center w-full text-left px-3 py-1 text-sm leading-6 text-gray-900",
															)}
															onClick={() => {
																handlePrint(content?.document_types?.is_json || false);
															}}
														>
															<PrintIcon className="h-4 w-4 mr-2" />
															Print
														</button>
													)}
												</Menu.Item>
												<Menu.Item>
													{({ active }) => (
														<button
															className={classNames(
																active ? "bg-gray-50" : "",
																"flex items-center w-full text-left px-3 py-1 text-sm leading-6 text-gray-900",
															)}
															onClick={() => {
																handleDelete();
															}}
														>
															<TrashIcon className="h-4 w-4 mr-2" />
															Delete
														</button>
													)}
												</Menu.Item>
											</Menu.Items>
										</Transition>
									</Menu>
								)}
							</div>
						</div>
						{/* CONTENT */}
						<Disclosure.Panel>
							<div className="mt-2">
								<>
									{content?.status === DocumentStatus.Failed && content?.id === completionId ? (
										// Show an error message if there was an error loading the document
										<div className="flex flex-row gap-x-1">
											<InformationCircleIcon className="h-4 w-4 text-gray-900 opacity-30" />
											<p>Unable to generate document. Please Retry.</p>
										</div>

									) : content?.status === DocumentStatus.Cancelled && content?.id === completionId ? (
										<div>
											<p>
												Document generation was cancelled. Please retry to generate the document.
											</p>
										</div>

									) : isLoadingCompletion && content?.status === DocumentStatus.Processing && completionId === content?.id && !content?.document_types?.is_json ? (
										// Show content while it is being loaded and it is not JSON
										<Content
											key={content?.id}
											content={completion}
											sectionsAndFieldsToBold={sectionsAndFieldsToBold}
											isEditing={isEditing}
											newHeader={newHeader}
											setNewHeader={setNewHeader}
											newContent={newContent}
											setNewContent={setNewContent}
											copyAsMarkdown={false}
											activeDocument={content}
											isStreaming={isLoadingCompletion && completionId === content?.id}
											tabId={tab?.tabId}
										/>
									) : isLoadingCompletion && content?.status === DocumentStatus.Processing && completionId === content?.id && content?.document_types?.is_json ? (
										// Show JSON content if the document type is JSON and it matches the completion ID
										<JsonContent
											key={content?.id}
											isStreaming={isLoadingCompletion && completionId === content?.id}
											content={completion || ""}
											isEditing={isEditing}
											newHeader={newHeader}
											newContent={newContent}
											setNewHeader={setNewHeader}
											setNewContent={setNewContent}
											activeDocument={content}
											tabId={tab?.tabId}
										/>
									) : content?.document_types?.is_json ? (
										// Show JSON content if the document type is JSON
										<JsonContent
											key={content?.id}
											isStreaming={isLoadingCompletion && completionId === content?.id}
											content={content?.chat_json?.chat_text}
											isEditing={isEditing}
											newHeader={newHeader}
											newContent={newContent}
											setNewHeader={setNewHeader}
											setNewContent={setNewContent}
											activeDocument={content}
											tabId={tab?.tabId}
										/>
									) : (
										// Show regular content otherwise
										<Content
											key={content?.id}
											content={content?.chat_json?.chat_text}
											sectionsAndFieldsToBold={sectionsAndFieldsToBold}
											isEditing={isEditing}
											newHeader={newHeader}
											setNewHeader={setNewHeader}
											newContent={newContent}
											setNewContent={setNewContent}
											copyAsMarkdown={false}
											activeDocument={content}
											isStreaming={isLoadingCompletion && completionId === content?.id}
											tabId={tab?.tabId}
										/>
									)}
								</>
							</div>
						</Disclosure.Panel>
						{exportConfirmModalOpen && (
							<ConfirmModal
								isOpen={exportConfirmModalOpen}
								id="confirm-export-modal"
								headerText="Export Document"
								cancelButtonText="Cancel"
								confirmButtonText="Export"
								confirmButtonColor="bg-max-700"
								confirmHoverButtonColor="bg-max-800"
								contentText="Are you sure you want to export this document?"
								onConfirm={() => {
									handleExport();
									setExportConfirmModalOpen(false);
								}}
								onCancel={() => setExportConfirmModalOpen(false)}
							/>
						)}
					</div>
				</>
			)
			}
		</Disclosure >
	);
};

export default Document;
