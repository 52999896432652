import { uploadAndTranscribeBulkAudio } from "../lib/supabaseClient";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
export interface TranscriptSectionIndexDB {
	// Combination of transcriptId and transcriptSectionId
	audioData: Blob;
	fileName: string;
	createdAt: Date;
	bytesUploaded: number;
	uploadUrl: string;
	transcriptId: string;
	transcriptSectionId: string;
	patientRecordId: string;
	realtimeClientId: string;
	patientName: string;
	isUploading: boolean;
	retryCount: number;
}

/*
	Note: we could manage global isRecording and isUploading and block retry if true,
	but I don't think we need to since we are only auto-retrying on startup (refresh), and
	can assume there is no recording or uploading happening at that time.
*/

export const INDEX_DB_VERSION = 5;
const RETRY_LIMIT = 3;
class AudioDb {
	private static instance: AudioDb;
	private dbName: string;
	//private storeNames: { transcript: string; transcriptSections: string }
	private storeNames: { transcriptSections: string };
	private db: IDBDatabase | null;
	constructor(databaseVersion: number) {
		this.dbName = "AudioDatabase";
		this.storeNames = {
			//transcript: 'transcript',
			transcriptSections: "transcriptSections",
		};
		this.db = null;
		this.init(databaseVersion);
	}

	//Singleton
	static getInstance(databaseVersion: number = INDEX_DB_VERSION) {
		if (!AudioDb.instance) {
			AudioDb.instance = new AudioDb(databaseVersion);
		}
		return AudioDb.instance;
	}

	// Initialize the database connection and create object stores if they don't exist
	init(databaseVersion = INDEX_DB_VERSION) {
		try {
			const openRequest = window.indexedDB.open(this.dbName, databaseVersion);
			openRequest.onupgradeneeded = (event: any) => {
				const db = event?.target?.result;

				// clean up old version's object stores if they exist
				if (event.oldVersion < INDEX_DB_VERSION) {
					if (db.objectStoreNames.contains(this.storeNames.transcriptSections)) {
						db.deleteObjectStore(this.storeNames.transcriptSections);
					}
				}

				if (!db.objectStoreNames.contains(this.storeNames.transcriptSections)) {
					const store = db.createObjectStore(this.storeNames.transcriptSections);
					store.createIndex("createdAtIndex", "createdAt", { unique: false });
				}
			};

			openRequest.onsuccess = async () => {
				this.db = openRequest.result; // Set the db instance
				this.cleanupOldEntries(); // Cleanup old entries
				let audioBlobTest = await this.testAudioBlobUrlCompatibility();
				await this.setAllUploadsNotUploading();
				await this.retryIncompleteUploads(true);
				if (!audioBlobTest) {
					toast.error(
						"Uh oh! This browser may not support saving audio files and may degrade performance. Please ensure you are not in Private Browsing or Icognito mode. See Help Center or reach out to support for more help.",
						{
							autoClose: false,
						},
					);
				}
			};

			openRequest.onerror = (event: any) => {
				Sentry.captureException(event);
			};
		} catch (error: any) {
			Sentry.captureException(error)
		}
	}

	// Will add two things to the DB:
	//(1) The transcript section audio given by key transcriptId/transcriptSectionId
	//(2) Will add an array of transcriptSectionIds to the transcript to keep track of in order (probably not needed)
	async addTranscriptSectionAudio(
		transcriptId: string,
		transcriptSectionId: string,
		blob: Blob,
		audioName: string,
		bytesUploaded: number,
		patientRecordId: string,
		realtimeClientId?: string,
		patientName?: string,
		doctorId?: string,
	): Promise<boolean> {
		return new Promise((resolve, reject) => {
			try {
				if (!this.db) {
					throw new Error("Database not initialized")
				}

				const transaction = this.db?.transaction([this.storeNames.transcriptSections], "readwrite");
				const sectionStore = transaction?.objectStore(this.storeNames.transcriptSections);
				const key = `${transcriptId}/${transcriptSectionId}`;
				const data = {
					audioData: blob,
					fileName: audioName,
					createdAt: new Date(),
					transcriptId: transcriptId,
					transcriptSectionId: transcriptSectionId,
					bytesUploaded: bytesUploaded,
					patientRecordId: patientRecordId,
					realtimeClientId: realtimeClientId,
					uploadUrl: null,
					patientName: patientName || "",
					doctorId: doctorId || "",
					isUploading: false,
					retryCount: 0,
				};
				const sectionStoreRequest = sectionStore?.put(data, key);

				if (!sectionStoreRequest) {
					throw new Error("Bad Request - Unable to add audio to index db")
				}

				sectionStoreRequest.onsuccess = () => {
					resolve(true);
				};

				sectionStoreRequest.onerror = (event) => {
					Sentry.captureException(event);
				};


			} catch (error: any) {
				Sentry.captureException(error);
				resolve(false);
			}
		});
	}

	async testAudioBlobUrlCompatibility(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			try {
				if (!this.db) {
					throw new Error("Database not initialized")
				}

				const transaction = this.db?.transaction([this.storeNames.transcriptSections], "readwrite");
				const sectionStore = transaction?.objectStore(this.storeNames.transcriptSections);
				const key = `audioBlobTest/HappyDocTest`;
				let audioBlob = new Blob(["test"], { type: "audio/wav" });

				const data = {
					audioData: audioBlob,
				};
				const sectionStoreRequest = sectionStore?.put(data, key);

				if (!sectionStoreRequest) {
					throw new Error("Bad Request - Unable to test audio blob url compatibility")
				}

				sectionStoreRequest.onsuccess = () => {
					resolve(true);
				};

				sectionStoreRequest.onerror = (event) => {
					Sentry.captureException(event);
				};

			} catch (error: any) {
				Sentry.captureException(error);
				resolve(false);
			}
		});
	}

	async setAllUploadsNotUploading(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			try {
				if (!this.db) {
					throw new Error("Database not initialized")
				}

				const transaction = this.db.transaction([this.storeNames.transcriptSections], "readwrite");
				const sectionStore = transaction.objectStore(this.storeNames.transcriptSections);
				const request = sectionStore.openCursor();

				if (!request) {
					throw new Error("Failed to create request to open cursor")
				}

				request.onsuccess = () => {
					const cursor = request.result;
					if (cursor) {
						const data = cursor.value;
						data.isUploading = false; // Set isUploading to false

						// Update the record with the modified data
						cursor.update(data);

						cursor.continue(); // Move to the next record
					} else {
						// No more records to process, resolve true
						resolve(true);
					}
				};

				request.onerror = (event) => {
					Sentry.captureException(event);
				};

			} catch (error) {
				Sentry.captureException(error);
				resolve(false); // Resolve false on catch
			}
		});
	}

	async updateUploadState(
		transcriptId: string,
		transcriptSectionId: string,
		uploadUrl: string,
		bytesUploaded?: number,
		isUploading?: boolean,
		retryCount?: number,
	): Promise<boolean> {
		try {
			if (!this.db) {
				throw new Error("Database not initialized");
			}

			const transaction = this.db?.transaction([this.storeNames.transcriptSections], "readwrite");
			if (!transaction) throw new Error("Failed to create transaction");

			const sectionStore = transaction.objectStore(this.storeNames.transcriptSections);
			const sectionKey = `${transcriptId}/${transcriptSectionId}`;
			const request = sectionStore.get(sectionKey);

			if (!request) throw new Error("Failed to create request to get section");

			return await new Promise((resolve, reject) => {
				request.onsuccess = () => {

					const data = request.result;
					if (bytesUploaded) data.bytesUploaded = bytesUploaded;
					data.isUploading = isUploading;
					if (retryCount) data.retryCount = retryCount;
					data.uploadUrl = uploadUrl;

					const updateRequest = sectionStore.put(data, sectionKey);
					if (!updateRequest) {
						throw new Error("Failed to create request to update section")
						return;
					}

					updateRequest.onsuccess = () => {
						resolve(true);
					};

					updateRequest.onerror = (event) => {
						Sentry.captureException(event);
					};
				};

				request.onerror = (event) => {
					Sentry.captureException(event);
				};
			});
		} catch (error) {
			Sentry.captureException(error);
			console.error("Error in updateUploadState:", error);
			return false;
		}
	}


	async getIncompleteUploads(): Promise<TranscriptSectionIndexDB[]> {
		return new Promise((resolve, reject) => {
			try {
				if (!this.db) {
					resolve([]);
					return
				}

				const transaction = this.db.transaction([this.storeNames.transcriptSections], "readonly");
				const sectionStore = transaction.objectStore(this.storeNames.transcriptSections);
				const request = sectionStore.openCursor();


				if (!request) {
					throw new Error("Failed to create request to open cursor")
				}

				const results: any = [];

				request.onsuccess = () => {
					const cursor = request.result;
					if (cursor) {
						if (cursor.value.bytesUploaded < cursor.value.audioData.size) {
							results.push(cursor.value);
						}
						cursor.continue();
					} else {
						resolve(results);
					}

					request.onerror = (event) => {
						Sentry.captureException(event);
					};
				}
			} catch (error: any) {
				Sentry.captureException(error);
				resolve([]);
			}
		});
	}

	async findIncompleteRecordersByPatientRecordIds(patientRecordIds: string[]): Promise<any[]> {
		return new Promise((resolve, reject) => {

			try {
				if (!this.db) {
					throw new Error("Database not initialized")
				}

				const transaction = this.db?.transaction([this.storeNames.transcriptSections], "readonly");
				const sectionStore = transaction?.objectStore(this.storeNames.transcriptSections);
				const request = sectionStore?.openCursor();

				if (!request) {
					throw new Error("Failed to create request to open cursor")
				}

				let incompleteRecords: TranscriptSectionIndexDB[] = [];

				request.onsuccess = (event) => {
					const cursor = request.result;
					if (cursor) {
						// Check if the patientRecordId is in the provided array and the recorder is incomplete
						if (
							patientRecordIds.includes(cursor.value.patientRecordId) &&
							cursor.value.bytesUploaded < cursor.value.audioData.size
						) {
							incompleteRecords.push(cursor.value); // Add the incomplete recorder to the list

							// Check if the number of incomplete records found is >= the length of patientRecordIds array
							if (incompleteRecords.length >= patientRecordIds.length) {
								resolve(incompleteRecords); // Resolve and return the array
								return; // Exit the onsuccess handler
							}
						}
						cursor.continue();
					} else {
						// Iteration complete
						resolve(incompleteRecords);
					}
				};

				request.onerror = (event) => {
					Sentry.captureException(event);
				};

			} catch (error: any) {
				Sentry.captureException(error);
				resolve([]);
			}
		});
	}

	//Will return in individual transcript blob if we have it
	async getTranscriptSectionAudio(
		transcriptId: string,
		transcriptSectionId: string,
	): Promise<TranscriptSectionIndexDB | null> {
		return new Promise((resolve, reject) => {
			try {
				if (!this.db) {
					throw new Error("Database not initialized")
				}

				const transaction = this.db?.transaction([this.storeNames.transcriptSections], "readonly");
				const sectionStore = transaction?.objectStore(this.storeNames.transcriptSections);
				const sectionKey = `${transcriptId}/${transcriptSectionId}`;
				const request = sectionStore?.get(sectionKey);

				if (!request) {
					throw new Error("Failed to create request to get section")
				}

				request.onsuccess = () => {
					if (request.result) {
						resolve(request.result);
					} else {
						resolve(null);
					}
				};

				request.onerror = (event) => {
					Sentry.captureException(event);
				};

			} catch (error: any) {
				Sentry.captureException(error);
				resolve(null);
			}
		});
	}

	async deleteTranscriptSectionAudio(transcriptId: string, transcriptSectionId: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			try {
				if (!this.db) {
					throw new Error("Database not initialized")
				}

				const transaction = this.db?.transaction([this.storeNames.transcriptSections], "readwrite");
				const sectionStore = transaction?.objectStore(this.storeNames.transcriptSections);
				const sectionKey = `${transcriptId}/${transcriptSectionId}`;
				const deleteRequest = sectionStore?.delete(sectionKey);

				if (!deleteRequest) {
					throw new Error("Failed to create request to delete section")
				}

				deleteRequest.onsuccess = () => {
					resolve(true);
				};

				deleteRequest.onerror = (event) => {
					Sentry.captureException(event);
				};


			} catch (error: any) {
				Sentry.captureException(error);
				resolve(false);
			}
		});
	}

	async deleteAudioUsingPatientRecord(patientRecordId: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			try {
				if (!this.db) {
					throw new Error("Database not initialized")
				}

				const transaction = this.db?.transaction([this.storeNames.transcriptSections], "readwrite");
				const sectionStore = transaction?.objectStore(this.storeNames.transcriptSections);
				const request = sectionStore?.openCursor();

				if (!request) {
					throw new Error("Failed to create request to open cursor")
				}

				request.onsuccess = (event: any) => {
					const cursor = event?.target?.result;
					if (cursor) {
						// Assuming each object in your store has a 'patientRecordId' field
						if (cursor.value.patientRecordId === patientRecordId) {
							// Delete the record if the patientRecordId matches
							const deleteRequest = cursor.delete();
							deleteRequest.onsuccess = () => {
								resolve(true);
							};

							deleteRequest.onerror = (event: any) => {
								Sentry.captureException(event);
							};

							return; // Stop looking through the records
						}
						cursor.continue();
					} else {
						resolve(true);
					}
				};

				request.onerror = (event) => {
					Sentry.captureException(event);
				};

			} catch (error: any) {
				Sentry.captureException(error);
				resolve(false);
			}
		});
	}

	async cleanupOldEntries(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			try {
				if (!this.db) {
					throw new Error("Database not initialized")
				}

				let deleteTimeFrame = new Date(new Date().setDate(new Date().getDate() - 5));

				// if ios delete 2 days old entries else delete 5 days old entries
				if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
					deleteTimeFrame = new Date(new Date().setDate(new Date().getDate() - 2));
				}

				const transaction = this.db.transaction([this.storeNames.transcriptSections], "readwrite");
				const sectionStore = transaction.objectStore(this.storeNames.transcriptSections);
				const request = sectionStore.openCursor();

				if (!request) {
					throw new Error("Failed to create request to open cursor")
				}

				request.onsuccess = (event: any) => {
					const cursor = event?.target?.result;
					if (cursor) {
						if (new Date(cursor.value.createdAt) < deleteTimeFrame) {
							const deleteRequest = sectionStore.delete(cursor.primaryKey);
							deleteRequest.onerror = (deleteEvent: any) => {
								console.error("Error deleting an old entry:", deleteEvent?.target?.error);
								resolve(false);
							};
						}
						cursor.continue();
					} else {
						resolve(true);
					}
				};

				request.onerror = (event: any) => {
					Sentry.captureException(event);
				};
			} catch (error: any) {
				Sentry.captureException(error);
				resolve(false);
			}
		});
	}

	async doesTranscriptSectionAudioExist(transcriptId: string, transcriptSectionId: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			try {
				if (!this.db) {
					throw new Error("Database not initialized")
				}

				const transaction = this.db?.transaction([this.storeNames.transcriptSections], "readonly");
				const sectionStore = transaction?.objectStore(this.storeNames.transcriptSections);
				const sectionKey = `${transcriptId}/${transcriptSectionId}`;
				const request = sectionStore?.count(sectionKey);

				if (!request) {
					throw new Error("Failed to create request to count section")
				}

				request.onsuccess = () => {
					if (request.result > 0) {
						resolve(true);
					} else {
						resolve(false);
					}
				};

				request.onerror = (event) => {
					Sentry.captureException(event)
				};

			} catch (error: any) {
				Sentry.captureException(error);
				resolve(false);
			}
		});
	}

	async getAllTranscriptSectionAudios(transcriptId: string): Promise<Array<{ sectionId: string; audio: Blob }>> {
		return new Promise((resolve, reject) => {
			try {
				if (!this.db) {
					throw new Error("Database not initialized")
				}

				const transaction = this.db.transaction([this.storeNames.transcriptSections], "readonly");
				const sectionStore = transaction.objectStore(this.storeNames.transcriptSections);
				const request = sectionStore.openCursor();

				if (!request) {
					throw new Error("Failed to create request to open cursor")
				}

				const results: any = [];

				request.onsuccess = (event) => {
					const cursor = (event.target as IDBRequest).result as IDBCursorWithValue;
					if (cursor) {
						if (typeof cursor.key === "string" && cursor.key.startsWith(`${transcriptId}/`)) {
							const keyParts = cursor.key.split("/");
							const transcriptId = keyParts[0];
							const sectionId = keyParts[1];
							results.push({ transcriptId, sectionId, data: cursor.value });
						}
						cursor.continue();
					} else {
						// No more entries
						resolve(results);
					}
				};

				request.onerror = (event) => {
					Sentry.captureException(event);
				};
			} catch (error: any) {
				Sentry.captureException(error);
				resolve([]);
			}
		});
	}

	/**
	 * Retries incomplete uploads
	 * This is the auto-retry, it is only called internally to this class on init
	 * @param isStartup 
	 * @returns new state of incomplete uploads, successful uploads are removed from the list
	 */
	async retryIncompleteUploads(isStartup: Boolean = false) {
		const incompleteUploads = await this.getIncompleteUploads();

		for (let incomplete of incompleteUploads) {
			try {
				if (incomplete.retryCount >= RETRY_LIMIT) {
					continue;
				}

				if (incomplete.isUploading) {
					continue;
				}

				if (+new Date() - +incomplete.createdAt < 5 * 60000 && !isStartup) {
					//don't upload if it was createdAt in the last 5 minutes && its not a startup retry
					continue;
				}

				// try traditional upload
				const { error } = await uploadAndTranscribeBulkAudio(
					incomplete.transcriptId,
					incomplete.transcriptSectionId,
					incomplete.audioData,
					incomplete.fileName,
					null,
					incomplete.patientRecordId,
					incomplete?.patientName || "",
					"",
					[],
				);
				if (error) {
					throw error;
				}

				// successful, get rid of the incomplete record
				await this.deleteTranscriptSectionAudio(incomplete.transcriptId, incomplete.transcriptSectionId);

			} catch (error: any) {
				// failed, update the retry count
				incomplete.retryCount = incomplete.retryCount + 1;
				await this.updateUploadState(
					incomplete.transcriptId,
					incomplete.transcriptSectionId,
					"",
					incomplete.bytesUploaded,
					false,
					incomplete.retryCount,
				);

				Sentry.captureException(error);
			}
		}
	}
}

// Usage
export default AudioDb;
