import ProgressBar from "@/common/components/ProgressBar";
import {
	createJson,
	isJSON,
	copySectionToClipboard,
} from "@/common/utils/formatter";
import React, { useState, useEffect } from "react";
import {
	ChatCompletions,
	DocumentStatus,
	DisplayDocument,
	PIMSType
} from "@/common/utils/types";
import { useAccountContext } from "@/state/providers/AccountProvider";
import { ErrorBoundary } from "@sentry/react";
import { cloneDeep } from "lodash";
import SyncConfirmation from "../sidebar/SyncConfirmation";
import Section from "@/common/components/json-document/Section";
import SendIcon from "@icons/send-01.svg?react";
import HDDCopyButton from "@/common/components/HDCopyButton";
import classNames from "classnames";

interface JsonContentProps {
	content: string;
	isStreaming: boolean;
	isEditing: boolean;
	newHeader: string;
	newContent: string;
	setNewHeader: React.Dispatch<React.SetStateAction<string>>;
	setNewContent: React.Dispatch<React.SetStateAction<any>>;
	activeDocument: ChatCompletions | null;
	tabId: string;
}

/**
 * Render the JSON content of the document
 * @param props - the props containing content, isStreaming, isEditing, newHeader, newContent, setNewHeader, setNewContent, activeDocument, and tabId
 * @returns JSX.Element
 */
const JsonContent: React.FC<JsonContentProps> = ({
	content,
	isStreaming,
	isEditing,
	newHeader,
	newContent,
	setNewHeader,
	setNewContent,
	activeDocument,
	tabId,
}) => {
	const [open, setOpen] = useState(false);
	const [editedJson, setEditedJson] = useState<DisplayDocument | null>(null);
	const [progress, setProgress] = useState<number>(0);

	const { accountData, hasChromeExtension } = useAccountContext();
	const markdownEnabled = (accountData?.meta_data as { markdown_copy_enabled?: boolean })?.markdown_copy_enabled;

	// Update the edited JSON object when the content changes and the user is streaming
	useEffect(() => {
		if (isJSON(content) && isStreaming && activeDocument) {
			const { json, progress } = createJson(content);

			if (progress) {
				setProgress(progress);
			}

			setEditedJson(json ? cloneDeep(json) : null);
		}
	}, [content, isStreaming, activeDocument]);


	// Update the edited JSON object when the content changes and the user is not streaming
	useEffect(() => {
		if (isJSON(newContent) && !isStreaming && !isEditing && activeDocument) {
			const { json } = createJson(newContent);

			setEditedJson(json ? cloneDeep(json) : null);
		}
	}, [newContent, isStreaming, activeDocument, isEditing]);

	// Update the new content when the edited JSON object changes
	useEffect(() => {
		if (editedJson && !isStreaming && isEditing) {
			setNewContent(cloneDeep(editedJson));
		}
	}, [editedJson, setNewContent, isStreaming, isEditing]);

	// Add event listener to handle copying of text
	useEffect(() => {
		const handleCopy = (e: any) => {
			const selection = window.getSelection();
			const selectedText = selection?.toString();

			if (!selectedText) {
				// copy as normal if no text is selected
				return;
			}
			e.clipboardData.setData('text/plain', selectedText);
			e.preventDefault();
		};

		document.addEventListener('copy', handleCopy);

		return () => {
			document.removeEventListener('copy', handleCopy);
		};
	}, []);

	/**
	 * Convert the json object to JSX
	 * @param json
	 * @returns JSX.Element
	 */
	const jsonToJSX = (json: DisplayDocument) => {
		if (!Array.isArray(json)) {
			return null;
		}

		return json?.map((section, index) => {
			// check the next section to see if the title is empty
			// if so, don't render the <hr> tag
			// will eval to false if out of bounds (thanks ?.)
			const nextSection = json[index + 1];
			const nextSectionHasTitle = nextSection?.name !== "";

			return (
				<React.Fragment key={index}>
					<div key={index} className="flex flex-row w-full">
						<div className="flex flex-1 items-center p-1">
							<div className="flex-1 ml-4">
								<span>
									<strong className="text-[12px] leading-4">{section?.name?.toUpperCase()}</strong>
								</span>
								<div className="whitespace-pre-wrap">
									<ErrorBoundary fallback={<p>Unable to generate document. Please Retry.</p>} showDialog={true}>
										<Section section={section} isEditing={isEditing} sectionKey={index} editedJson={editedJson} setEditedJson={setEditedJson} />
									</ErrorBoundary>
								</div>
							</div>
						</div>
						{isEditing ? null : (
							<HDDCopyButton
								id={`copy-section-${section?.id}`}
								onCopy={() => copySectionToClipboard(index, section, markdownEnabled)}
							/>
						)}
					</div>
					<div className={classNames("my-2 flex justify-end gap-2 happydoc-ext-section-btns", showSyncButtons() ? "" : "hidden")}>
						<button
							id="approve-section"
							type="button"
							className="inline-flex items-center gap-x-1.5 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 happydoc-exportbtn"
							data-happydoc-id="happydoc-exportbtn"
							data-happydoc-content={JSON.stringify(section)}
							data-happydoc-section={section?.name}
						>
							<SendIcon className="w-4 h-4" />
							Approve & Sync
						</button>
					</div>
					{index < Object.keys(json)?.length - 1 && nextSectionHasTitle && <hr className="my-2" />}
				</React.Fragment>
			)

		});
	};

	// if the content is not a JSON string, render it as a paragraph
	if (!isJSON(content)) {

		return (<div>
			{activeDocument?.status === DocumentStatus?.Processing ? <ProgressBar seconds={60} currentProgress={progress} tabId={tabId} isJson={isStreaming ? true : false} /> : null}
			<p>{content}</p>
		</div>)
	}

	// function to check if we should show the sync buttons
	const showSyncButtons = () => {
		// show sync buttons if the user has the chrome extension, the current pims is ezyvet, and the user is not editing
		return hasChromeExtension && accountData?.current_pims === PIMSType.EZYVET && !isEditing;
	}

	return (
		<ErrorBoundary fallback={<p>Unable to generate document. Please Retry.</p>} showDialog={true}>
			<div>
				{isEditing && (
					<div>
						<label className="block text-xs font-medium leading-tight text-gray-900">Name</label>
						<div className="mt-0.5">
							<input
								type="text"
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-max-600  sm:text-sm sm:leading-6"
								value={newHeader}
								onChange={(e) => setNewHeader(e.target.value)}
							/>
						</div>
					</div>
				)}
				{activeDocument?.status === DocumentStatus?.Processing ? <ProgressBar seconds={60} currentProgress={progress} tabId={tabId} isJson={isStreaming ? true : false} /> : null}
				{editedJson && jsonToJSX(editedJson)}
				<div className={classNames("mb-2 mt-4 w-full happydoc-approveAllSection", showSyncButtons() ? "" : "hidden")}>
					<button
						id="approve-all-section"
						data-happydoc-id="happydoc-exportbtn"
						type="button"
						className="w-full inline-flex items-center justify-center gap-x-1.5 rounded bg-max-700 px-2 py-2 text-xs font-semibold text-white shadow-sm hover:bg-max-800  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bg-max-700 "
						onClick={() => {
							setOpen(true);
						}}
					>
						<SendIcon className="w-4 h-4" />
						Approve & Sync ALL
					</button>
				</div>
				<SyncConfirmation open={open} setOpen={setOpen} content={JSON.stringify(editedJson) || ""} />
			</div>
		</ErrorBoundary>
	);
};

export default JsonContent
