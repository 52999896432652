import { useSession } from "@supabase/auth-helpers-react";
import classNames from "classnames";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { TranscriptSectionIndexDB } from "../../classes/audioDb";
import useFileUpload from "../../hooks/useFileUpload";
import UploadProgressBar from "../UploadProgressBar";
import { uploadAndTranscribeBulkAudio } from "../../lib/supabaseClient";
import { useState } from "react";
import AudioDb, { INDEX_DB_VERSION } from "../../classes/audioDb";
import { useDispatch, useSelector } from "react-redux";
import { selectTableRecordById, updateTranscriptSectionError } from "@redux/patientTableRecordsSlice";

const UploadModalRow = ({
	upload,
	setIncompleteUploads,
	getIncompleteUploads
}: {
	upload: TranscriptSectionIndexDB;
	setIncompleteUploads: React.Dispatch<React.SetStateAction<TranscriptSectionIndexDB[]>>;
	getIncompleteUploads: () => Promise<TranscriptSectionIndexDB[]>
}) => {
	const { uploadAudio, isUploading, uploadProgress } = useFileUpload();
	const session = useSession();

	//TODO: Rip out when we know upload data contains everything to recreate a patient record row
	const currentPatientRecord = useSelector((state) => selectTableRecordById(state, upload?.patientRecordId || ""));
	const dispatch = useDispatch();

	const [isUploadingLocal, setIsUploadingLocal] = useState(false);

	const handleUpload = useCallback(
		async (upload: TranscriptSectionIndexDB) => {
			try {
				setIsUploadingLocal(true);
				let uploadError = null;
				const localAudioDb = AudioDb.getInstance(INDEX_DB_VERSION);

				if (currentPatientRecord) {
					const audioFile = new File([upload.audioData], upload.fileName, { type: upload.audioData.type });
					let { err, is409 } = await uploadAudio(
						audioFile,
						upload.fileName,
						upload.transcriptId,
						upload.transcriptSectionId,
						currentPatientRecord,
						false,
					);
					uploadError = err;

					await getIncompleteUploads()

					if (is409) {
						toast.info(`${upload.fileName} is already attempting to upload. Try again later!`);
						setIsUploadingLocal(false);
						return;
					} // If the upload is trying
				}
				if (uploadError || !currentPatientRecord) {
					const { error: error2 } = await uploadAndTranscribeBulkAudio(
						upload.transcriptId,
						upload.transcriptSectionId,
						upload.audioData,
						upload.fileName,
						null,
						upload.patientRecordId,
						upload?.patientName || "",
						"",
						[],
					);
					if (error2) {
						throw error2;
					}
				}

				// success, rm from local db, update state
				await localAudioDb.deleteTranscriptSectionAudio(upload.transcriptId, upload.transcriptSectionId);

				// rm the err from patient row
				dispatch(updateTranscriptSectionError({ id: upload.patientRecordId, sectionId: upload.transcriptSectionId, error: null }));

				toast.success(`Successfully uploaded ${upload.fileName}`);
				setIncompleteUploads((prev) => prev.filter((prevUpload) => prevUpload.fileName !== upload.fileName));
				setIsUploadingLocal(false);
			} catch (error) {
				toast.error(`Uh Oh, something went wrong! ${upload.fileName}`);
				setIsUploadingLocal(false);
			}
		},
		[uploadAudio, session?.user.id],
	);

	return (
		<tr
			className={classNames(isUploading || upload.isUploading ? "bg-gray-200" : "bg-white")}
			key={upload.transcriptSectionId}
		>
			<td
				className={classNames(
					isUploading || upload.isUploading ? "bg-gray-200" : "bg-white",
					"pr-2.5 py-1 sm:px-5 sm:py-2 border-b border-gray-200 text-sm",
				)}
			>
				<p className="text-gray-900 whitespace-no-wrap">{upload.patientName || "-"}</p>
				<p className="text-gray-500 whitespace-no-wrap">{upload.fileName || "-"}</p>
			</td>
			<td
				className={classNames(
					isUploading || upload.isUploading ? "bg-gray-200" : "bg-white",
					"min-w-300 pr-2.5 py-2.5 sm:px-5 sm:py-5 border-b border-gray-200text-sm",
				)}
			>
				{isUploading ? (
					<UploadProgressBar percentage={uploadProgress} />
				) : upload.isUploading ? (
					<b className="text-max-700 ">Uploading</b>
				) : (
					<button
						className={classNames(
							isUploading || upload.isUploading || isUploadingLocal
								? "bg-gray-200 no-cursor"
								: "bg-max-700  hover:bg-max-800 ",
							"rounded  font-semibold px-2 sm:px-4 py-1 sm:py-2.5 text-sm text-white h-full ",
						)}
						onClick={() => handleUpload(upload)}
						disabled={isUploading || upload.isUploading || isUploadingLocal}
					>
						Upload
					</button>
				)}
			</td>
		</tr>
	);
};

export default UploadModalRow;
