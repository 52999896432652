import PlayIcon from '@icons/play-circle.svg?react';
import PauseIcon from '@icons/pause-circle.svg?react';
import StopIcon from '@icons/stop-circle.svg?react';
import { RecordingState } from "@common/utils/types";
import AudioLevels from "@common/components/AudioLevels";
import DocumentSelect from "@common/components/DocumentSelect";
import Spinner from "@common/components/Spinner";
import StageSelect from "@/common/components/StageSelect";
import Timer from "@common/components/Timer";
import { useAudioRecordingProvider } from "@features/list-view/state/recordingProvider";
interface Props {
	patient: any;
	index: number;
}

function RecordingRow({ patient, index }: Props) {
	const {
		recorderControls: { recordingState, audioStream, recordingTime, isQuickStart, stopRecording, togglePauseResume },
	} = useAudioRecordingProvider();

	return (
		<tr key={`${patient?.id}-${index}`} className="w-full min-h-56 sm:min-h-0 sm:h-auto bg-max-50">
			<td className="sm:py-4 sm:pl-0 text-sm font-medium sm:pr-6" colSpan={7}>
				<div className="flex flex-col justify-evenly min-h-56 sm:min-h-0">
					<div className="flex flex-row items-center sm:min-h-0 h-full gap-x-5">
						<div className="hidden ml-8 sm:flex flex-grow flex-col gap-y-1">
							<span className="text-sm ml-1 text-gray-700 opacity-40 truncate max-w-[200px]">
								{patient?.patient_name || "-"}
							</span>
							<span className="text-sm ml-1 text-gray-700 opacity-40"> ID: {patient?.patient_id || "- "} </span>
							<span className="text-sm ml-1 text-gray-700 opacity-40">
								{new Date(patient?.created_at as string).toLocaleTimeString("en-US", {
									hour: "numeric",
									minute: "numeric",
									hour12: true,
								}) || "-"}{" "}
							</span>
						</div>
						<div className="ms-2 sm:ms-0 flex flex-col w-1/2 sm:w-1/3">
							<span className="text-xs sm:text-sm ml-1 mb-1 text-gray-700"> Generate On Stop </span>
							<DocumentSelect removeChildren={false}></DocumentSelect>
						</div>

						<div className="flex w-1/2 sm:w-1/3 pr-2 mb-4 flex-row items-center justify-evenly relative ">
							{recordingState === RecordingState.loading && !isQuickStart ? (
								<Spinner size="small" />
							) : (
								<>
									<button
										id="stop-recording"
										type="button"
										className=" bg-red-500 focus-visible:outline-red-600
														w-12 h-12 rounded-full flex flex-col items-center justify-center z-20 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 cursor-pointer"
										onClick={() => {
											stopRecording();
										}}
									>
										<StopIcon className="h-8 w-8 text-white " />
									</button>
									<button
										id="pause-recording"
										type="button"
										className="bg-max-600  hover:bg-max-800  focus-visible:outline-bg-max-700 
									w-12 h-12 rounded-full flex flex-col items-center justify-center text-xs font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 z-20 cursor-pointer"
										onClick={() => togglePauseResume()}
									>
										{recordingState === RecordingState.recording && (
											<>
												<PauseIcon className="h-8 w-8 text-white " />
											</>
										)}
										{recordingState === RecordingState.paused && (
											<>
												<PlayIcon className="h-8 w-8 text-white " />
											</>
										)}
									</button>
									<>
										<div className="absolute inset-0">
											<AudioLevels
												darkColor="#26B5BB"
												brightColor="#26B5BB"
												barSpacing={1}
												numBars={64}
												recordingState={recordingState}
												audioStream={audioStream}
											/>
										</div>
										<Timer
											time={recordingTime}
											recordingState={recordingState}
											topDivClassName="absolute bottom-[-2rem] left-0 z-10 items-center justify-center w-full text-sm text-gray-400 flex flex-col"
										/>
									</>
								</>
							)}
						</div>
					</div>
					<div className="flex flex-row items-center justify-center mt-6 mb-4 sm:hidden">
						<StageSelect currentStage={patient?.stage as string} patientId={patient?.id as string} disabled={true} />
					</div>
				</div>
			</td>
		</tr>
	);
}

export default RecordingRow;
