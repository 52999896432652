import { DisplayDocument, DisplaySection, FormattingOption } from "@/common/utils/types";
import Field from "./Field";
import List from "./List";
import { cloneDeep } from "lodash";
import FormatString from "./FormatString";
import { ErrorBoundary } from "@sentry/react";
interface SectionProps {
    sectionKey: number;
    section: DisplaySection;
    isEditing: boolean;
    editedJson: DisplayDocument | null;
    setEditedJson: React.Dispatch<React.SetStateAction<DisplayDocument | null>>
}

/**
 * Render the section of the document based on the type of the section
 * @param props - the props containing sectionKey, section, and isEditing
 * @returns JSX.Element
 */
const Section: React.FC<SectionProps> = ({ sectionKey, section, isEditing, editedJson, setEditedJson }) => {
    /**
 * Handles changes to individual fields within a section.
 * @param {string} sectionIndex - The key of the section containing the field.
 * @param {string} fieldIndex - The key of the field to be updated.
 * @param {string} newValue - The new value for the field.
 */
    const handleValueChange = (sectionIndex: number, fieldIndex: number | null, newValue: string) => {
        if (editedJson) {
            setEditedJson((prev) => {
                if (prev && prev[sectionIndex] && prev[sectionIndex]?.fields) {
                    const section = prev[sectionIndex];
                    let fields = section?.fields;

                    // Check if fields is an array. If it's an object, convert it to an array.
                    if (!Array.isArray(fields)) {
                        fields = Object.keys(fields).map(key => ({ key, ...fields[key as any] }));
                    }

                    if (fieldIndex !== null && fields && fields[fieldIndex]) {
                        const updatedJson = [
                            ...(Array.isArray(prev) ? prev.slice(0, sectionIndex) : []),
                            {
                                ...section,
                                fields: [
                                    ...fields.slice(0, fieldIndex),
                                    {
                                        ...fields[fieldIndex],
                                        value: newValue,
                                        isEdited: true
                                    },
                                    ...fields.slice(fieldIndex + 1),
                                ],
                            },
                            ...(Array.isArray(prev) ? prev.slice(sectionIndex + 1) : []),
                        ];
                        return cloneDeep(updatedJson);
                    } else {
                        const updatedJson = [
                            ...prev.slice(0, sectionIndex),
                            { ...section, value: newValue, isEdited: true },
                            ...prev.slice(sectionIndex + 1),
                        ];
                        return cloneDeep(updatedJson);
                    }

                }
                return prev;
            });
        }
    };


    let fieldsRender = null;
    let valueRender = null;

    if (section?.hasErrors) {
        return <small><em>There was an error with this section. Please try again.</em></small>;
    }

    if (section?.fields) {
        fieldsRender = Object.entries(section.fields).map(([key, field], index) =>
            <ErrorBoundary key={index} fallback={<p>Unable to generate field. Please Retry.</p>} showDialog={true}>
                <Field
                    key={index}
                    fieldKey={key}
                    sectionKey={sectionKey}
                    field={field}
                    index={index}
                    isEditing={isEditing}
                    editedJson={editedJson}
                    setEditedJson={setEditedJson}
                    handleFieldChange={handleValueChange}
                />
            </ErrorBoundary>
        );
    }

    if (section?.formatting === FormattingOption.None) {
        valueRender = <></>;
    } else if (section?.value && Array.isArray(section?.value)) {
        valueRender =
            <List
                sectionKey={sectionKey}
                value={section?.value}
                fieldIndex={null}
                format={section?.formatting}
                isEditing={isEditing}
                editedJson={editedJson}
                setEditedJson={setEditedJson}
                handleFieldChange={handleValueChange}
            />;
    } else if (section?.value && typeof section?.value === "object" && section?.value !== null) {
        valueRender = <FormatString value={(section?.value[section?.name?.toLowerCase() as any] as any).value} format={section?.formatting} />;
    } else if (section?.value && section?.value !== "undefined") {
        valueRender = isEditing ? (
            <textarea
                rows={10}
                value={section?.value}
                onChange={(e) => handleValueChange(sectionKey, null, e.target.value)}
                className="text-[12px] leading-4 border border-gray-300 rounded h-24 min-w-[300px] p-1 w-full m-1"
            />
        ) : (
            <FormatString value={section?.value as string} format={section?.formatting} />
        );
    }

    return (
        <>
            {/* <ErrorBoundary fallback={<p>Unable to generate section. Please Retry.</p>} showDialog={true}> */}
            {valueRender}
            {fieldsRender}
            {/* </ErrorBoundary> */}

        </>
    );
};

export default Section;