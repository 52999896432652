import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import MoveRecordingModal from "./MoveRecordingModal";
import { TranscriptSection } from "@/common/utils/types";
import ConfirmModal from "@/common/components/ConfirmModal";
import { useDispatch } from "react-redux";
import { removeTranscriptSections } from "../../state/redux/activePatientDataSlice";
import { decrementRecordingCount } from "@/state/redux/patientTableRecordsSlice";
import { deleteTranscriptSection } from "@/common/lib/supabaseClient";
import AudioDb, { INDEX_DB_VERSION } from "@/common/classes/audioDb";
import DotsVerticalIcon from "@icons/dots-vertical.svg?react";
import SwitchHorizontalIcon from "@icons/switch-horizontal-01.svg?react";
import TrashIcon from "@icons/trash-03.svg?react";

type Props = {
	patientId: string;
	transcriptSection: TranscriptSection;
};

function ThreeDotRecordingMenu({ patientId, transcriptSection }: Props) {
	const [moveRecordingModalOpen, setMoveRecordingModalOpen] = React.useState(false);
	const [isDeleteOneModalOpen, setIsDeleteOneModalOpen] = React.useState(false);

	const dispatch = useDispatch();
	const localAudioDb = AudioDb.getInstance(INDEX_DB_VERSION);

	const removeAndDeleteTranscriptSection = async () => {
		const id = transcriptSection.id;
		const transcriptionId = transcriptSection.transcription_id;

		if (!transcriptionId) {
			toast.error("Error deleting recording");
			return;
		}

		dispatch(removeTranscriptSections([id]));
		dispatch(decrementRecordingCount({ transcriptionId: transcriptSection.transcription_id || "", sectionIds: [id] }));
		await localAudioDb?.deleteTranscriptSectionAudio(transcriptSection.transcription_id || "", id);
		await deleteTranscriptSection(id);
	};

	return (
		<div>
			<Menu as="div" className="relative flex-none select-none">
				<Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
					<span className="sr-only">Open options</span>
					<DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
				</Menu.Button>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className="absolute right-0 flex-col items-start justify-start z-20 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
						<Menu.Item>
							{({ active }) => (
								<button
									id="MoveRecording"
									className={classNames(
										active ? "bg-gray-50" : "",
										" flex justify-start items-center block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900",
									)}
									onClick={(e) => {
										setMoveRecordingModalOpen(true);
									}}
								>
									<SwitchHorizontalIcon className="h-4 w-4 mr-2" aria-hidden="true" />
									Move
								</button>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<button
									id="DeleteOneTranscript"
									className={classNames(
										active ? "bg-gray-50" : "",
										"flex justify-start items-center block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900",
									)}
									onClick={() => {
										setIsDeleteOneModalOpen(true);
									}}
								>
									<TrashIcon className="h-4 w-4 mr-2" aria-hidden="true" />
									Delete
								</button>
							)}
						</Menu.Item>
					</Menu.Items>
				</Transition>
			</Menu>

			<MoveRecordingModal
				patientId={patientId}
				transcriptSection={transcriptSection}
				isOpen={moveRecordingModalOpen}
				onCancel={() => setMoveRecordingModalOpen(false)}
				onConfirm={() => {
					setMoveRecordingModalOpen(false);
				}}
			/>

			<ConfirmModal
				id="DeleteOneTranscript"
				headerText="Delete this transcript section?"
				cancelButtonText="Cancel"
				confirmButtonText="Delete"
				confirmButtonColor="bg-red-600"
				confirmHoverButtonColor="bg-red-500"
				onCancel={() => {
					setIsDeleteOneModalOpen(false);
				}}
				onConfirm={() => {
					removeAndDeleteTranscriptSection();
					setIsDeleteOneModalOpen(false);
				}}
				isOpen={isDeleteOneModalOpen}
			/>
		</div>
	);
}

export default ThreeDotRecordingMenu;
