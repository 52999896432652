import classNames from "classnames";
import { TableTab } from "@common/utils/types";

/**
 * Reusable Component for tabs in the app
 * @param tabs - Array of tabs 
 * @param changeTab - Function to change the tab
 * @param disabled - Whether the tabs are disabled
 * @returns JSX.Element
 */
function TableTabs({
	tabs,
	changeTab,
	disabled,
	paddingVertical = "py-3",
}: {
	tabs: TableTab[];
	changeTab: (tab: string) => void;
	disabled?: boolean;
	paddingVertical?: string;
}) {

	return (
		<div className="mt-2 flex flex-grow  w-full xl:w-auto justify-between">
			<div className="sm:block grow flex-shrink-0">
				<div className="border-b border-gray-300">
					<nav className="-mb-px flex justify-start space-x-2 sm:space-x-4" aria-label="Tabs">
						{tabs.map((tab) => (
							<button
								id="patient-list-tabs"
								disabled={disabled}
								key={tab.id}
								onClick={() => changeTab(tab.id)}
								className={classNames(
									tab.isActive
										? "border-max-600 text-max-700"
										: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
									paddingVertical,
									"group inline-flex items-center border-b-2 px-2 sm:px-4 text-sm gap-x-1 font-semibold",
								)}
								aria-current={tab.isActive ? "page" : undefined}
							>
								{tab?.icon ? <tab.icon /> : null}
								<span className="text-xs sm:text-sm font-semibold">{tab.header}</span>
								{tab?.count !== undefined ? <span
									className={classNames(
										tab.isActive ? "bg-max-50 text-max-700 border border-max-200" : "bg-gray-50 text-gray-700 border border-gray-200",

										"flex text-center items-center p-2 justify-center rounded-full h-4 w-4 sm:h-6 sm:w-6 text-xs font-semibold",
									)}
								>
									{tab.count}
								</span> : null}
							</button>
						))}
					</nav>
				</div>
			</div>
		</div>
	);
}

export default TableTabs;
