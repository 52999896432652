import { createSlice, PayloadAction, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { ChatCompletions, DocumentStatus, DocumentTab } from "@common/utils/types";
import { createTabId } from "@/common/utils/helpers";
import { upsertChatCompletions } from "@/common/lib/supabaseClient";

type ActiveDocuments = {
	allDocuments: ChatCompletions[];
	allDocumentTabs: DocumentTab[];
	activeTabId: string;
	activeDocumentIndex: number;
	activeDocumentContent: string;
	activeDocument: ChatCompletions | null;
	activeTab: DocumentTab | null;
	progressStates: { [key: string]: number };
};

const initialState: ActiveDocuments = {
	allDocuments: [],
	allDocumentTabs: [],
	activeTabId: "",
	activeDocumentIndex: 0,
	activeDocumentContent: "",
	activeDocument: null,
	activeTab: null,
	progressStates: {},
};

const activeDocumentsSlice = createSlice({
	name: "activeDocuments",
	initialState,
	reducers: {
		setAllDocuments: (state, action: PayloadAction<ChatCompletions[]>) => {
			state.allDocuments = action.payload;
		},
		setAllDocumentTabs: (state, action: PayloadAction<DocumentTab[]>) => {
			state.allDocumentTabs = action.payload;
		},
		addOrUpdateTab: (state, action: PayloadAction<Partial<DocumentTab>>) => {
			const { tabId } = action.payload;
			if (tabId) {
				const index = state.allDocumentTabs.findIndex((t) => t.tabId === tabId);
				if (index !== -1) {
					// Update the tab at the found index
					state.allDocumentTabs[index] = { ...state.allDocumentTabs[index], ...action.payload };
				} else {
					// Add the new tab
					state.allDocumentTabs.push(action.payload as DocumentTab);
				}
			}
		},
		setActiveTabId: (state, action: PayloadAction<string>) => {
			state.activeTabId = action.payload;
		},
		setActiveDocumentIndex: (state, action: PayloadAction<number>) => {
			state.activeDocumentIndex = action.payload;
		},
		addDocumentToTab: (state, action: PayloadAction<{ tabId: string; document: ChatCompletions }>) => {
			const { tabId, document } = action.payload;
			const tab = state.allDocumentTabs.find((t) => t.tabId === tabId);
			if (tab) {
				tab.documents.push(document);
			}
		},
		setActiveDocument: (state, action: PayloadAction<ChatCompletions>) => {
			state.activeDocument = action.payload;
		},
		setActiveTab: (state, action: PayloadAction<string>) => {
			state.activeTab = state.allDocumentTabs.find((tab) => tab.tabId === action.payload) || null;
		},
		removeDocumentFromTab: (state, action: PayloadAction<{ tabId: string; documentId: string }>) => {
			const { tabId, documentId } = action.payload;
			const tab = state.allDocumentTabs.findIndex((t) => t.tabId === tabId);
			if (tab !== -1) {
				const newDocuments = state.allDocumentTabs[tab].documents.filter((doc) => doc.id !== documentId);

				// If the document being removed is the active document, update the active document index
				if (newDocuments.length === 0) {
					//Remove the tab and set documents.
					let newTabs = state.allDocumentTabs.filter((t) => t.tabId !== tabId);
					state.allDocumentTabs = newTabs;
					if (newTabs.length > 0) {
						state.activeTabId = newTabs[newTabs.length - 1].tabId;
						state.activeDocumentIndex = newTabs[newTabs.length - 1].documents.length - 1;
					}
				} else {
					//Decrement the active tab and set documents.
					state.activeDocumentIndex = newDocuments.length - 1;
					state.allDocumentTabs[tab].documents = newDocuments;
				}
			}
		},
		updateDocumentDataIntab: (
			state,
			action: PayloadAction<{ tabId: string; chatCompletionId: string; updatedData: string }>,
		) => {
			const { tabId, chatCompletionId, updatedData } = action.payload;
			const tab = state.allDocumentTabs.find((t) => t.tabId === tabId);
			if (tab) {
				const docIndex = tab.documents.findIndex((chatCompletion) => chatCompletion.id === chatCompletionId);
				if (docIndex !== -1) {
					tab.documents[docIndex].chat_json.chat_text = updatedData;
				}
			}
		},
		updateDocumentIndex: (state, action: PayloadAction<{ tabId: string }>) => {
			const tab = state.allDocumentTabs.find((t) => t.tabId === action.payload.tabId);
			if (tab) {
				state.activeDocumentIndex = tab.documents.length - 1;
			}
		},
		//NOTE: Use this judiciously and only with partial/single values, or entire chatcompletion object updates
		updateDocumentInTab: (state, action: PayloadAction<{ tabId: string; document: ChatCompletions }>) => {
			const { tabId, document } = action.payload;

			// Find the tab with the given tabId
			const tab = state.allDocumentTabs.find((t) => t.tabId === tabId);

			// If the tab is found, find the document with the given document id and update it
			if (tab) {
				const docIndex = tab.documents.findIndex((doc) => doc.id === document.id);
				if (docIndex !== -1) {
					tab.documents[docIndex] = { ...tab.documents[docIndex], ...document };
				}

				// If the tab is not found, create a new tab with the given document
			} else {
				state.allDocumentTabs.push({
					tabId,
					documents: [document],
					header: document.chat_json.header,
					isLoading: false,
					error: null,
				});
			}
		},
		setProgress: (state, action: PayloadAction<{ tabId: string; progress: number }>) => {
			state.progressStates[action.payload.tabId] = action.payload.progress;
		},
		resetProgress: (state, action: PayloadAction<{ tabId: string; }>) => {
			delete state.progressStates[action.payload.tabId];
		},
		resetActiveDocuments: () => initialState,
	},
	extraReducers: (builder) => { },
});

//ACTIONS
export const {
	setAllDocuments,
	setActiveTabId,
	setActiveDocumentIndex,
	setAllDocumentTabs,
	addDocumentToTab,
	updateDocumentIndex,
	updateDocumentDataIntab,
	addOrUpdateTab,
	updateDocumentInTab,
	resetActiveDocuments,
	removeDocumentFromTab,
	setActiveDocument,
	setActiveTab,
	setProgress,
	resetProgress,
} = activeDocumentsSlice.actions;

//SELECTORS
export const getAllDocumentTabs = (state: { activeDocuments: { allDocumentTabs: DocumentTab[] } }) =>
	state.activeDocuments.allDocumentTabs;

export const getActiveTabId = (state: { activeDocuments: { activeTabId: string } }) =>
	state.activeDocuments.activeTabId;

export const getActiveDocumentIndex = (state: { activeDocuments: { activeDocumentIndex: number } }) =>
	state.activeDocuments.activeDocumentIndex;

export const getActiveDocument = createSelector(
	[getAllDocumentTabs, getActiveTabId, getActiveDocumentIndex], // input selectors
	(allDocumentTabs, activeTabId, activeDocumentIndex) => {
		// Find the DocumentTabs object for the active tab
		const activeTab = allDocumentTabs.find((tab) => tab.tabId === activeTabId);

		// If the active tab is found, return the document at the active index, otherwise return null
		return activeTab ? activeTab.documents[activeDocumentIndex] : null;
	},
);

export const getActiveDocuments = createSelector(
	[getAllDocumentTabs, getActiveTabId], // input selectors
	(allDocumentTabs, activeTabId) => {
		// Find the DocumentTabs object for the active tab
		const activeTab = allDocumentTabs.find((tab) => tab.tabId === activeTabId);

		// If the active tab is found, return its documents, otherwise return an empty array
		return activeTab ? activeTab.documents : [];
	},
);
// Memoized selector to get the length of the active documents
export const getActiveDocumentsLength = createSelector(
	[getActiveDocuments], // This should use the getActiveDocuments selector
	(activeDocuments) => {
		return activeDocuments.length; // Simply return the length of the active documents array
	},
);

export const getProgress = (state: { activeDocuments: ActiveDocuments }, tabId: string) =>
	state.activeDocuments.progressStates[tabId] || 0;

//Thunks
export const computeTabs = createAsyncThunk("activeDocuments/computeTabs", async (_, { getState, dispatch }) => {
	const state = getState() as { activeDocuments: ActiveDocuments };
	const { allDocuments } = state.activeDocuments;
	if (!allDocuments.length) return;

	const uniqueDocTypes = Array.from(
		new Set(
			allDocuments.map((doc: ChatCompletions) => createTabId(doc?.document_type_id as string, doc.chat_json.header)),
		),
	);

	const newDocTypeMap = uniqueDocTypes.map((docType) => {
		const filteredDocs = allDocuments.filter(
			(doc: ChatCompletions) => createTabId(doc?.document_type_id as string, doc.chat_json.header) === docType,
		);

		const sortedDocs = filteredDocs.sort((a: ChatCompletions, b: ChatCompletions) => {
			const dateA = new Date(a?.created_at!);
			const dateB = new Date(b?.created_at!);
			return dateA.getTime() - dateB.getTime();
		});

		const header = sortedDocs[0]?.chat_json.header || "";
		return {
			tabId: docType,
			header,
			documents: sortedDocs,
			isLoading: false,
			error: null,
		};
	});

	dispatch(setAllDocumentTabs(newDocTypeMap));
	dispatch(setActiveTabId(newDocTypeMap[0].tabId));
	dispatch(setActiveDocumentIndex(newDocTypeMap[0].documents.length - 1));
});

export const updateActiveTabAndDocumentIndex = createAsyncThunk(
	"activeDocuments/updateActiveTabAndDocumentIndex",
	async (tabId: string, { dispatch, getState }) => {
		dispatch(setActiveTabId(tabId));

		await Promise.resolve();
		const state = getState() as { activeDocuments: ActiveDocuments };
		const documents = getActiveDocuments(state); // This retrieves the documents for the newly active tab
		dispatch(setActiveDocumentIndex(documents.length - 1)); // Set the index to the last document
	},
);

//Used In Retry
export const addActiveDocumentAndUpdateIndex = createAsyncThunk(
	"activeDocuments/addActiveDocumentAndUpdateIndex",
	async (document: ChatCompletions, { getState, dispatch }) => {
		const state = getState() as { activeDocuments: ActiveDocuments };
		const tabId = state.activeDocuments.activeTabId;

		dispatch(addDocumentToTab({ tabId, document }));
		dispatch(updateDocumentIndex({ tabId }));
	},
);

export const addOrUpdateTabAndAddDocument = createAsyncThunk(
	"activeDocuments/addOrUpdateTabAndAddDocument",
	async (args: { tabId: string; document: ChatCompletions }, { getState, dispatch }) => {
		const { tabId, document } = args;
		const state = getState() as { activeDocuments: ActiveDocuments };
		const { allDocumentTabs } = state.activeDocuments;

		// Check if the tab already exists
		let tab = allDocumentTabs.find((t) => t.tabId === tabId);

		if (!tab) {
			// If the tab doesn't exist, create a new tab with the document
			const newTab: DocumentTab = {
				tabId: tabId,
				header: document.chat_json.header, // Assuming chat_json is always defined and has a header
				documents: [document],
				isLoading: false,
				error: null,
			};
			dispatch(addOrUpdateTab(newTab));
		} else {
			// If the tab exists, add the document to this tab
			dispatch(addDocumentToTab({ tabId, document }));
		}
		// Update the document index only once after handling the tab addition/update
		dispatch(updateDocumentIndex({ tabId }));
		dispatch(setActiveTabId(tabId));
	},
);

export const updateDocumentThunk = createAsyncThunk(
	"activeDocuments/updateDocumentThunk",
	async (args: { tabId: string; document: ChatCompletions }, { dispatch }) => {
		const { tabId, document } = args;
		if (document.document_types) {
			delete document.document_types;
		}
		await upsertChatCompletions(document);
	},
);


export default activeDocumentsSlice.reducer;
