import { useState, useCallback } from "react";
import { downloadAudioFileFromSupabase } from "@common/lib/supabaseClient";
import { ErrorType } from "@common/utils/types";
import AudioDb, { INDEX_DB_VERSION } from "@common/classes/audioDb";
import TrashIcon from "@icons/trash-03.svg?react";
import RefreshIcon from "@icons/refresh-cw-03.svg?react";
import PlayCircleIcon from "@icons/play-circle.svg?react";
import InfoCircleIcon from "@icons/info-circle.svg?react";
import XIcon from "@icons/x-close.svg?react";
import * as Sentry from "@sentry/react";

const TranscriptLine = ({ transcriptSection, transcriptId, onDelete = () => { }, onReRun = () => { } }: any) => {
	const localAudioDb = AudioDb.getInstance(INDEX_DB_VERSION);

	const handleDelete = (e: { stopPropagation: () => void; preventDefault: () => void }) => {
		e.stopPropagation();
		e.preventDefault();
		onDelete();
	};
	const handleReRun = (e: { stopPropagation: () => void; preventDefault: () => void }) => {
		e.stopPropagation();
		e.preventDefault();
		onReRun();
	};

	const [showMoreInfo, setShowMoreInfo] = useState(false);
	const [audioURL, setAudioURL] = useState("");
	const [loadingAudio, setLoadingAudio] = useState(false);
	const [displayAudio, setDisplayAudio] = useState(false);

	const wasRemoved = "No audio or removed during post-processing.";
	const wasRemovedDescriptive =
		"The transcription returned here was blank. Either no audio data existed or in select cases, very short audio <10s or poor quality audio, the data gets removed during post processing. If this is a concern contact support.";
	const error =
		"An error occurred processing this line. Auto retry will happen twice. If that does not work try re-transcribing or contact support.";

	const getAudio = useCallback(async () => {
		if (!audioURL && !displayAudio) {
			setLoadingAudio(true);
			try {
				let blob = await downloadAudioFileFromSupabase(transcriptId, transcriptSection.unprocessed_filename);
				if (!blob) {
					let localAudio = await localAudioDb?.getTranscriptSectionAudio(
						transcriptSection.transcription_id,
						transcriptSection.id,
					);

					if (localAudio) {
						blob = localAudio.audioData;
					} else {
						throw new Error("No audio found.");
					}
				}
				let url = blob ? URL.createObjectURL(blob) : "";
				if (url) setAudioURL(url);
			} catch (e) {
				Sentry.captureException(e);
				setDisplayAudio(false);
			}
			setDisplayAudio(true);
			setLoadingAudio(false);
		} else {
			setDisplayAudio(!displayAudio);
		}
	}, [
		audioURL,
		displayAudio,
		localAudioDb,
		transcriptSection.id,
		transcriptSection.transcription_id,
		transcriptSection.unprocessed_filename,
	]);

	return (
		<div className="flex flex-col">
			<div className="flex flex-row justify-start hover:cursor-pointer hover:bg-gray-100 pt-2 pb-2">
				<div className="flex-grow items-center">
					{displayAudio && (
						<div className="w-full">
							<audio controls>
								<source src={audioURL} />
							</audio>
						</div>
					)}
					{transcriptSection?.data === "loading" ? (
						<div className="w-full">
							<span className="loading loading-dots loading-sm"></span>

							<div className="flex flex-row gap-x-1">
								<InfoCircleIcon className="h-4 w-4 text-gray-900 opacity-30" />
								<span className="text-[10px] opacity-80">Retry if greater than 10 minutes.</span>
							</div>
						</div>
					) : transcriptSection?.was_removed ? (
						<div
							className="w-full whitespace-pre-line text-xs text-gray-500 flex flex-row justify-start items-center"
							onMouseEnter={() => setShowMoreInfo(true)}
							onMouseLeave={() => setShowMoreInfo(false)}
						>
							<InfoCircleIcon className="h-4 w-4 text-gray-500" />
							{showMoreInfo ? wasRemovedDescriptive : wasRemoved}
						</div>
					) : transcriptSection?.error && transcriptSection?.error === ErrorType.UPLOAD_ERROR ? (
						<div className="w-full whitespace-pre-line text-xs text-red-400 flex flex-row justify-start items-center">
							Upload error, please retry.
						</div>
					) : transcriptSection?.data === "error" ? (
						<div className="w-full whitespace-pre-line text-xs text-red-400 flex flex-row justify-start items-center">
							{error}
						</div>
					) : (
						<div className="w-full whitespace-pre-line text-gray-900 transcriptSection">{transcriptSection.data}</div>
					)}
				</div>
				<div className="flex h-full flex-row space-x-2 rounded-[6px] border border-gray-300 bg-gray-100 px-4 py-2 font-semibold text-gray-500 hover:text-gray-600 hover:shadow-sm hover:ring-1 hover:ring-inset hover:ring-gray-100 hover:bg-gray-200">
					<div
						onClick={getAudio}
						className={loadingAudio ? "loading loading-spinner loading-xs" : "btn btn-ghost btn-xs btn-circle"}
					>
						{displayAudio ? (
							<XIcon className="text-gray-900 h-4 w-4" />
						) : (
							<PlayCircleIcon className="text-gray-900 h-4 w-4" />
						)}
					</div>
					<div onClick={handleReRun} className="btn btn-ghost btn-xs btn-circle">
						<RefreshIcon className=" text-gray-900 h-4 w-4" />
					</div>
					<div onClick={handleDelete} className="btn btn-ghost btn-xs btn-circle">
						<TrashIcon className=" text-gray-900 h-4 w-4" />
					</div>
				</div>
			</div>
		</div>
	);
};
export default TranscriptLine;
