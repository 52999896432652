import { Account, AccountIntegration, Subscription } from "@common/utils/types";
import { useSession } from "@supabase/auth-helpers-react";
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { fetchAccountData, getAccountIntegrations, getSubscription } from "@common/lib/supabaseClient";
import { HD_CHROME_EXTENSION_ID } from "@/common/utils/constants";

/**
 * Provides a context for managing and accessing account information throughout the application.
 */
export const AccountContext = createContext<
	| {
		accountData: Account | undefined;
		setAccountData: React.Dispatch<React.SetStateAction<Account | {}>>;
		pmsIntegrationData: AccountIntegration[] | null;
		containsFeatureFlag: (featureKey: string) => boolean;
		isTestAccount: () => boolean;
		containsFeatureFlagOrIsTest: (featureKey: string) => boolean;
		subscription: Subscription | undefined;
		setSubscription: React.Dispatch<React.SetStateAction<Subscription | undefined>>;
		dataLoaded: boolean;
		hasChromeExtension: boolean;
	}
	| undefined
>(undefined);

/**
 * The AccountProvider component fetches and provides account, doctor, PMS integration,
 * and subscription data for children components. It manages state related to the current user's
 * session and data, initializing with session check and data fetching, and provides functions
 * to manipulate this data.
 * @param {React.ReactNode} children - The children components that will consume the context.
 */
export const AccountProvider = ({ children }: { children: React.ReactNode }) => {
	// Initialize the session using Supabase's useSession hook for user authentication.
	const session = useSession();

	// State management
	const [accountData, setAccountData] = useState<Account | {}>({});
	const [pmsIntegrationData, setPmsIntegrationData] = useState<AccountIntegration[] | null>(null);
	const [subscription, setSubscription] = useState<Subscription | undefined>();
	const [dataLoaded, setDataLoaded] = useState<boolean>(false);
	const [hasChromeExtension, setHasChromeExtension] = useState<boolean>(false);

	const dataFetchedRef = useRef(false);
	const abortControllerRef = useRef<AbortController | null>(null);

	// Utility function to abort previous requests
	const abortPreviousRequest = () => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
			abortControllerRef.current = null;
		}
	};

	// Utility function to clean up the AbortController reference
	const cleanUpController = (controller: AbortController) => {
		if (abortControllerRef.current === controller) {
			abortControllerRef.current = null;
		}
	};

	// Function to identify expected errors
	const isExpectedError = (error: any) => {
		return error instanceof TypeError && (error.message === "Failed to fetch" || error.message === "Load fail");
	};

	// Function to check if the Chrome extension is installed
	const checkChromeExtension = async () => {
		try {
			// Don't think we need to check if HD_CHROME_EXTENSION_ID is defined as it's a constant
			if (!HD_CHROME_EXTENSION_ID) {
				return;
			}

			await fetch(`chrome-extension://${HD_CHROME_EXTENSION_ID}/img/mascot.png`);
			setHasChromeExtension(true);
		} catch (error) {
			setHasChromeExtension(false);

			if (!isExpectedError(error)) {
				// Do not capture errors that are not due to the extension not being installed
				return
			}
		}
	};

	useEffect(() => {
		// Fetches account data, subscription, and PMS integration data.
		const fetchData = async () => {
			if (!session?.user || !session?.user?.email) return;

			// Set the current user in Sentry for error tracking
			Sentry.setUser({ id: session.user.id, email: session.user.email });

			// Abort any previous fetch requests.
			abortPreviousRequest();

			const controller = new AbortController();
			abortControllerRef.current = controller;

			try {
				if (!dataFetchedRef.current) {
					// Fetch account, subscription, and PMS integration data in parallel
					const [accountData, subscriptionData, pmsData] = await Promise.all([
						fetchAccountData(controller.signal),
						getSubscription(controller.signal),
						getAccountIntegrations(controller.signal),
					]);

					// Set fetched data in state
					setAccountData({ ...accountData, email: session.user.email });
					setSubscription(subscriptionData);
					setPmsIntegrationData(pmsData);

					dataFetchedRef.current = true;

					// Check if the user has the Happy Doc Chrome extension installed
					checkChromeExtension();
				}
			} catch (error: any) {
				Sentry.captureException(error);
			} finally {
				setDataLoaded(true);

				cleanUpController(controller);
			}
		};

		fetchData();

		return () => {
			abortPreviousRequest();
		}

	}, [session]);

	// Utility function to check if the account has a specific feature flag enabled.
	const containsFeatureFlag = useCallback(
		(featureKey: string) => {
			if (typeof accountData === "object" && accountData !== null && "feature_flags" in accountData) {
				const flags = accountData.feature_flags;
				return Array.isArray(flags) && flags.includes(featureKey);
			}
			return false;
		},
		[accountData],
	);

	const testDomains = ['@gohappydoc.com', '@happydoc.ai', '@test.com'];
	const isTestAccount = useCallback(() => {
		if (typeof accountData === "object" && accountData !== null && "email" in accountData) {
			return testDomains.some(domain => accountData.email?.endsWith(domain));
		}
		return false;
	}, [accountData]);

	// Utility function to check if the account has a specific feature flag enabled.
	const containsFeatureFlagOrIsTest = useCallback(
		(featureKey: string) => {
			if (typeof accountData === "object" && accountData !== null && "feature_flags" in accountData) {
				const flags = accountData.feature_flags;
				return (Array.isArray(flags) && flags.includes(featureKey)) || isTestAccount();
			}
			return false;
		},
		[accountData],
	);

	return (
		<AccountContext.Provider
			value={{
				accountData,
				setAccountData,
				pmsIntegrationData,
				containsFeatureFlag,
				isTestAccount,
				containsFeatureFlagOrIsTest,
				subscription,
				setSubscription,
				dataLoaded,
				hasChromeExtension,
			}}
		>
			{children}
		</AccountContext.Provider>
	);
};

/**
 * Custom hook to consume account context. It ensures the context is used within its provider.
 * Throws an error if used outside the `AccountProvider`.
 * @returns The account context with user's account data, doctors, PMS integration data, subscription info, and utility functions.
 */
export const useAccountContext = () => {
	const context = useContext(AccountContext);
	if (context === undefined) {
		throw new Error("useAccountContext must be used within a AccountProvider");
	}
	return context;
};
