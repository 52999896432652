import { createSlice, PayloadAction, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { PatientRecordTableRow, PatientStage } from "@common/utils/types";
import { fetchPatientRecords, insertPatientRecord, deletePatientRecords } from "@common/lib/supabaseClient";
import { transformTableRecordToPatientRecord } from "../../common/utils/helpers";

/*
NOTES:

(1) Redux toolkit allows us to write "mutating" logic in reducers. It uses Immer inside to allow us to write simpler immutable updates. In all other places, direct object/array mutations are not allowed.

*/

export type SortedColumn = {
	column: "scheduled_at" | "patient_name" | string;
	direction: "asc" | "desc";
};

export type status = "idle" | "loading" | "succeeded" | "failed";

const initialState: {
	tableRecords: PatientRecordTableRow[];
	status: status;
	error: string | null;
	sortedColumn: SortedColumn;
	currentStage: PatientStage;
	// may change to uuid
	selectedTableRecords: PatientRecordTableRow[];
	incompleteUploadsCount: number
	pollingLocalDB: boolean;

} = {
	tableRecords: [],
	status: "idle",
	error: null,
	sortedColumn: {
		column: "scheduled_at",
		direction: "asc",
	},
	currentStage: PatientStage.Ongoing,
	selectedTableRecords: [],
	incompleteUploadsCount: 0,
	pollingLocalDB: false,
};

export const patientTableRecordsSlice = createSlice({
	name: "patientTableRecords",
	initialState,
	reducers: {
		addRecord: (state, action: PayloadAction<PatientRecordTableRow>) => {
			const record = state.tableRecords.find((record) => record.id === action.payload.id);
			if (!record) {
				state.tableRecords.push(action.payload);
			}
		},
		removeRecord: (state, action: PayloadAction<{ id: string }>) => {
			state.tableRecords = state.tableRecords.filter((record) => record.id !== action.payload.id);
		},
		removeRecords: (state, action: PayloadAction<{ ids: string[] }>) => {
			state.tableRecords = state.tableRecords.filter((record) => !action.payload.ids.includes(record?.id));
		},
		incrementDocumentCount: (state, action: PayloadAction<{ id: string }>) => {
			const record = state.tableRecords.find((record) => record.id === action.payload.id);
			if (record) {
				if (!record?.chat_completions || !record?.chat_completions[0]?.count) {
					record.chat_completions = [{ count: 1 }];
				} else {
					record.chat_completions[0].count++;
				}
			}
		},
		decrementDocumentCount: (state, action: PayloadAction<{ id: string | null | undefined }>) => {
			const record = state.tableRecords.find((record) => record.id === action.payload.id);
			if (record) {
				if (record?.chat_completions && record?.chat_completions[0]?.count) {
					record.chat_completions[0].count--;
				}
			}
		},
		incrementRecordingCount: (
			state,
			action: PayloadAction<{ transcriptionId: string; sectionId: string; error: string | null }>,
		) => {
			const record = state.tableRecords.find((record) => record.transcriptions?.id === action.payload.transcriptionId);
			if (record && record.transcriptions?.transcript_sections) {
				let found = false;
				//If the section is already in the list, update the error
				let updatedTranscriptSections = record.transcriptions.transcript_sections.map((section) => {
					if (section.id === action.payload.sectionId) {
						found = true;
						return { ...section, error: action.payload.error };
					}
					return section;
				});
				//If the section is not in the list, add it
				if (!found) {
					updatedTranscriptSections.push({ id: action.payload.sectionId, error: action.payload.error });
				}
				record.transcriptions.transcript_sections = updatedTranscriptSections;
			}
		},
		decrementRecordingCount: (state, action: PayloadAction<{ transcriptionId: string; sectionIds: string[] }>) => {
			const record = state.tableRecords.find((record) => record.transcriptions?.id === action.payload.transcriptionId);
			if (record && record.transcriptions?.transcript_sections) {
				const sectionIdsSet = new Set(action.payload.sectionIds);
				const filteredSections = record.transcriptions.transcript_sections.filter(
					(section) => !sectionIdsSet.has(section.id),
				);
				record.transcriptions.transcript_sections = filteredSections;
			}
		},
		updateTableRecordStage: (state, action: PayloadAction<{ id: string; newStage: PatientStage }>) => {
			const record = state.tableRecords.find((record) => record.id === action.payload.id);
			if (record) {
				record.stage = action.payload.newStage;
			}
		},
		//WARNING: This dangerous and can lead to overwriting unnested data like transcriptions or chat completions. Only pass full values here.
		updateTableRecord: (state, action: PayloadAction<{ id: string; updatedRecord: PatientRecordTableRow }>) => {
			let record = state.tableRecords.findIndex((record) => record.id === action.payload.id);
			if (record !== -1) {
				state.tableRecords[record] = { ...state.tableRecords[record], ...action.payload.updatedRecord };
			}
		},
		//WARNING: This dangerous and can lead to overwriting unnested data like transcriptions or chat completions. Only pass full values here.
		addOrUpdateRecord: (state, action: PayloadAction<PatientRecordTableRow>) => {
			let record = state.tableRecords.findIndex((record) => record.id === action.payload.id);
			if (record !== -1) {
				state.tableRecords[record] = { ...state.tableRecords[record], ...action.payload };
			} else {
				state.tableRecords.push(action.payload);
			}
		},
		updateTranscriptSectionError: (state, action: PayloadAction<{ id: string; sectionId: string; error: string | null }>) => {
			const record = state.tableRecords.find((record) => record.id === action.payload.id);
			if (record && record.transcriptions?.transcript_sections) {
				let updatedTranscriptSections = record.transcriptions.transcript_sections.map((section) => {
					if (section.id === action.payload.sectionId) {
						return { ...section, error: action.payload.error };
					}
					return section;
				}
				);
				record.transcriptions.transcript_sections = updatedTranscriptSections;
			}
		},
		setSortedColumn: (state, action: PayloadAction<SortedColumn>) => {
			state.sortedColumn = action.payload;
		},
		changeCurrentStage: (state, action: PayloadAction<PatientStage>) => {
			state.currentStage = action.payload;
		},
		addSelectedTableRecord: (state, action: PayloadAction<PatientRecordTableRow>) => {
			const record = state.selectedTableRecords.find((record) => record.id === action.payload.id);
			if (!record) state.selectedTableRecords.push(action.payload);
		},
		removeSelectedTableRecord: (state, action: PayloadAction<{ id: string }>) => {
			state.selectedTableRecords = state.selectedTableRecords.filter((record) => record.id !== action.payload.id);
		},
		resetSelectedTableRecords: (state) => {
			state.selectedTableRecords = [];
		},
		addSelectedTableRecords: (state, action: PayloadAction<PatientRecordTableRow[]>) => {
			state.selectedTableRecords = action.payload;
		},
		setStatus: (state, action: PayloadAction<status>) => {
			state.status = action.payload;
		},
		setIncompleteUploadCount: (state, action) => {
			state.incompleteUploadsCount = action.payload;
		},
		startPollingLocalDB: (state) => {
			state.pollingLocalDB = true;
		},
		stopPollingLocalDB: (state) => {
			state.pollingLocalDB = false;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchPatientRecordsAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchPatientRecordsAsync.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.tableRecords = action.payload;
			})
			.addCase(fetchPatientRecordsAsync.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error?.message || null;
			})

	},
});

//Export Async Thunks
interface FetchPatientRecordsArgs {
	selectedDate: any;
	doctorId: string;
	abortSignal: AbortSignal;
}

export const fetchPatientRecordsAsync = createAsyncThunk(
	"patientRecords/fetchPatientRecordsAsync",
	async ({ selectedDate, doctorId, abortSignal }: FetchPatientRecordsArgs, thunkAPI) => {
		const response = await fetchPatientRecords(selectedDate, doctorId, abortSignal);
		return response.data;
	},
);

interface InsertPatientRecordArgs {
	newTableRecord: PatientRecordTableRow; //NOTE: This is not a patientRecordTable row
	addToTable: boolean;
}

export const insertPatientRecordTableRow = createAsyncThunk(
	"patientRecords/insertPatientRecordTableRow",
	async ({ newTableRecord, addToTable = true }: InsertPatientRecordArgs, thunkAPI) => {
		let pRecord = transformTableRecordToPatientRecord(newTableRecord);
		let transcriptionId = newTableRecord.transcriptions?.id;
		await insertPatientRecord(pRecord, transcriptionId);
		if (addToTable) {
			thunkAPI.dispatch(addRecord(newTableRecord));
		}
	},
);
interface UpdatePatientRecordArgs {
	updatedTableRecord: PatientRecordTableRow;
	updateTable: boolean;
}

export const updatePatientRecordTableRow = createAsyncThunk(
	"patientRecords/updatePatientRecordTableRow",
	async ({ updatedTableRecord, updateTable }: UpdatePatientRecordArgs, thunkAPI) => {
		let updated = transformTableRecordToPatientRecord(updatedTableRecord);
		await insertPatientRecord(updated);
		if (updateTable) {
			thunkAPI.dispatch(updateTableRecord({ id: updatedTableRecord.id, updatedRecord: updatedTableRecord }));
		} else {
			//Remove it, update value different from filters
			thunkAPI.dispatch(removeRecord({ id: updatedTableRecord.id }));
		}
	},
);

export const deletePatientRecordsAsync = createAsyncThunk(
	"patientRecords/deletePatientRecordsAsync",
	async (ids: string[], thunkAPI) => {
		thunkAPI.dispatch(removeRecords({ ids }));
		await deletePatientRecords(ids);
	},
);

//Export the actions (add these here to call them into thunks)
export const {
	addRecord,
	removeRecord,
	addOrUpdateRecord,
	removeRecords,
	incrementDocumentCount,
	updateTableRecord,
	setSortedColumn,
	changeCurrentStage,
	updateTableRecordStage,
	incrementRecordingCount,
	addSelectedTableRecord,
	removeSelectedTableRecord,
	resetSelectedTableRecords,
	addSelectedTableRecords,
	decrementRecordingCount,
	setStatus,
	decrementDocumentCount,
	updateTranscriptSectionError,
	setIncompleteUploadCount,
	startPollingLocalDB,
	stopPollingLocalDB,
} = patientTableRecordsSlice.actions;

//Export Selectors
export const selectAllTableRecords = (state: { patientTableRecords: { tableRecords: PatientRecordTableRow[] } }) =>
	state.patientTableRecords.tableRecords;

export const selectTableRecordStatus = (state: { patientTableRecords: { status: status } }) =>
	state.patientTableRecords.status;

export const selectSortedColumn = (state: { patientTableRecords: { sortedColumn: SortedColumn } }) =>
	state.patientTableRecords.sortedColumn;

export const selectCurrentStage = (state: { patientTableRecords: { currentStage: PatientStage } }) =>
	state.patientTableRecords.currentStage;

export const selectSortedTableRecordsByStage = createSelector(
	[
		selectAllTableRecords,
		(state) => state.patientTableRecords.sortedColumn,
		(state) => state.patientTableRecords.currentStage,
	],
	(tableRecords, sortedColumn, currentStage) => {
		return tableRecords
			.filter((record) => record.stage === currentStage)
			.sort((a, b) => {
				let valA = a[sortedColumn.column as keyof PatientRecordTableRow];
				let valB = b[sortedColumn.column as keyof PatientRecordTableRow];

				if (sortedColumn.column === "patient_name") {
					// Convert patient names to lowercase for case-insensitive sorting
					valA = typeof valA === "string" ? valA.toLowerCase() : "";
					valB = typeof valB === "string" ? valB.toLowerCase() : "";
				}
				if (!valA || !valB) return 0;
				return (valA < valB ? -1 : 1) * (sortedColumn.direction === "asc" ? 1 : -1);
			});
	},
);

export const selectTableRecordById = createSelector(
	[selectAllTableRecords, (state, id: string) => id],
	(tableRecords, id) => {
		const record = tableRecords.find((record) => record.id === id);
		return record ? record : null;
	},
);

export const selectOngoingRecordCount = createSelector([selectAllTableRecords], (tableRecords) => {
	return tableRecords.filter((record) => record.stage === PatientStage.Ongoing).length;
});

export const selectReviewRecordCount = createSelector([selectAllTableRecords], (tableRecords) => {
	return tableRecords.filter((record) => record.stage === PatientStage.Review).length;
});

export const selectFinalizedRecordCount = createSelector([selectAllTableRecords], (tableRecords) => {
	return tableRecords.filter((record) => record.stage === PatientStage.Finalized).length;
});

//Export Reducer
export default patientTableRecordsSlice.reducer;
