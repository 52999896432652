import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { ErrorType, PatientRecordTableRow } from "@common/utils/types";
import ToolTip from "@common/components/ToolTip";
import UploadProgressBar from "@common/components/UploadProgressBar";
import RefreshIcon from "@icons/refresh-cw-03.svg?react";
import UploadIcon from "@icons/upload-01.svg?react";

interface Props {
	isUploading: boolean;
	uploadProgress: number;
	patient: any;
	recordingState: string;
	selectedTableRecords: PatientRecordTableRow[];
	setOpenModal: (x: boolean) => void;
	retryLoading: boolean;
	handleTranscriptionErrors: () => void;
	hasError: (x: ErrorType) => boolean;
	isDisabled: (x: string, y: any) => boolean;
}

function PatientNameCol({
	isUploading,
	uploadProgress,
	patient,
	recordingState,
	selectedTableRecords,
	setOpenModal,
	retryLoading,
	handleTranscriptionErrors,
	hasError,
	isDisabled,
}: Props) {
	return (
		<div className="flex flex-col gap-y-0.5 items-start w-full">
			{isUploading ? (
				<div className="flex-grow w-full">
					<UploadProgressBar percentage={uploadProgress} />
				</div>
			) : (
				patient?.transcriptions?.transcript_sections && (
					<div className="flex-grow">
						<p className="inline whitespace-nowrap text-xs lg:text-sm mr-1 w-26">
							<b className="mr-1 inline-block text-right">{`${patient.transcriptions?.transcript_sections?.length || 0
								}`}</b>
							Recording(s)
						</p>

						{patient.chat_completions && (
							<div className="flex-grow items-center">
								<p className="whitespace-nowrap inline text-xs lg:text-sm mr-1 w-26">
									<b className="mr-1 inline-block text-right">
										{patient.chat_completions[0]?.count !== undefined
											? patient.chat_completions[0]?.count
											: patient.chat_completions?.length}
									</b>
									Document(s)
								</p>
							</div>
						)}
						{hasError(ErrorType.UPLOAD_ERROR) ? (
							<button
								disabled={isDisabled(recordingState, patient) || selectedTableRecords.length > 0}
								className="mt-2 flex flex-row justify-between w-full items-center rounded-md px-1 py-1 font-semibold mr-1 w-26 bg-red-500  text-white ring-1 ring-inset ring-red-600/10 hover:cursor-pointer"
								onClick={() => setOpenModal(true)}
							>
								<ToolTip
									text="Upload Error! Click to Retry"
								>
									Retry
								</ToolTip>

								<UploadIcon className="h-4 w-4 text-white font-semibold" />
							</button>
						) : hasError(ErrorType.TRANSCRIBE_ERROR) ? (
							//
							<button
								disabled={isDisabled(recordingState, patient) || selectedTableRecords.length > 0}
								className="mt-2 flex flex-row justify-between w-full items-center rounded-md px-1 py-1 font-semibold mr-1 w-26 bg-red-500  text-white ring-1 ring-inset ring-red-600/10 hover:cursor-pointer"
								onClick={handleTranscriptionErrors}
							>
								<ToolTip
									text="Transcribe Error! Click to Retry"
								>
									Retry
								</ToolTip>
								{retryLoading ? (
									<RefreshIcon className="h-4 w-4 text-white font-semibold animate-spin" />
								) : (
									<RefreshIcon className="h-4 w-4 text-white font-semibold" />
								)}
							</button>
						) : (
							<></>
						)}
					</div>
				)
			)}
		</div>
	);
}

export default PatientNameCol;
