import { FunctionComponent, useState, ComponentType, useEffect } from "react";

interface AsyncComponentProps {
	loadComponent: () => Promise<{ default: React.ComponentType<any> }>;
	tabIndex?: string;
	renderType?: string;
}

// This component is used to lazy load components (pages) asynchronously
// https://reactjs.org/docs/code-splitting.html#reactlazy
const AsyncComponent: FunctionComponent<AsyncComponentProps> = ({ loadComponent, tabIndex, renderType }) => {
	const [Component, setComponent] = useState<ComponentType<any> | null>(null);
	const [loadAttempts] = useState(Number(localStorage.getItem("loadAttempts")) || 0);
	const [loadError, setLoadError] = useState(false);

	const handleRetry = () => {
		setLoadError(false);
		localStorage.setItem("loadAttempts", String(loadAttempts + 1));
		window.location.reload();
	};

	useEffect(() => {
		loadComponent()
			.then((module) => {
				setComponent(() => module.default);
			})
			.catch(() => {
				if (loadAttempts < 3) {
					localStorage.setItem("loadAttempts", String(loadAttempts + 1));
					window.location.reload();
				} else {
					localStorage.removeItem("loadAttempts"); // Clear after limit reached
					setLoadError(true);
				}
			});
	}, [loadComponent, loadAttempts]);

	if (loadError) {
		return (
			<div>
				<p>{`Sorry, we couldn't load the page. Please check your internet connection and try again.`}</p>
				<button onClick={handleRetry}>Retry</button>
			</div>
		);
	}

	return Component ? <Component tabIndex={tabIndex} renderType={renderType} /> : null;
};

export default AsyncComponent;
