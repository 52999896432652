import { useState } from "react";
import Alert from "../../common/components/Alert";
import { Link, useNavigate } from "react-router-dom";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import LogoBlue from "../../assets/HD-Horizontal-Blue.png";
import Button from "../../common/components/Button";

function Login() {
	const navigate = useNavigate();
	const [formState, setFormState] = useState({
		email: "",
		password: "",
	});
	const [error, setError] = useState<string | null>(null);
	const supabase = useSupabaseClient();
	const [loading, setLoading] = useState(false);
	async function signInWithEmail(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		setLoading(true);
		const { data, error } = await supabase.auth.signInWithPassword({
			email: formState.email,
			password: formState.password,
		});

		if (error) {
			setError(error.message);
			setLoading(false);
			return;
		}
		setLoading(false);
		navigate("/");
	}

	return (
		<div className="flex item-center min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<img className="mx-auto h-24 w-auto" src={LogoBlue} alt="HappyDoc" />
				<h2 className="mt-10 text-center text-xl font-semibold leading-9 tracking-tight text-gray-900">
					Sign in to your account
				</h2>
			</div>

			<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
				<form className="space-y-6" onSubmit={signInWithEmail}>
					<div>
						<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
							Email address
						</label>
						<div className="mt-2">
							<input
								id="email"
								name="email"
								type="email"
								autoComplete="email"
								autoFocus={true}
								required
								onChange={(e) => setFormState({ ...formState, email: e.target.value })}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-max-700  sm:text-sm sm:leading-6 pl-2"
							/>
						</div>
					</div>

					<div>
						<div className="flex items-center justify-between">
							<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
								Password
							</label>
						</div>
						<div className="mt-2">
							<input
								id="password"
								name="password"
								type="password"
								autoComplete="current-password"
								required
								onChange={(e) => setFormState({ ...formState, password: e.target.value })}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-max-700  sm:text-sm sm:leading-6 pl-2"
							/>
						</div>
						<div className="text-sm text-right mt-2">
							<Link to={"/reset-password"} className="font-semibold text-max-700  hover:text-max-700" tabIndex={-1}>
								Forgot password?
							</Link>
						</div>
					</div>

					<div>
						<Button loading={loading} type="submit">
							Sign in
						</Button>
					</div>

					{/* HIDDING SIGN UP BUTTON FOR NOW */}
					<div className="flex justify-center">
						<button
							className="font-semibold text-sm text-max-700  hover:text-max-700"
							onClick={() => window.open("https://calendly.com/jennagohappydoc/30min", "_blank")}
						>
							No account? Reach out for a free trial 🥳 🎉
						</button>
					</div>
				</form>
				<div className="mt-4">{error && <Alert alertType="error" title="Error" description={error} />}</div>
			</div>
		</div>
	);
}

export default Login;
