import { Combobox } from "@headlessui/react";
import classNames from "classnames";
import { useState, useEffect } from "react";
import { DocumentType } from "@common/utils/types";
import { useSelector, useDispatch } from "react-redux";
import { documentSelected } from "@redux/documentSlice";
import { useRealtimeContextApi } from "@providers/RealtimeProvider";
import CheckIcon from "@icons/check-circle.svg?react";
import ChevronUpDownIcon from "@icons/chevron-selector-vertical.svg?react";
import XIcon from "@icons/x-close.svg?react";

const DocumentSelect = ({ disabled, verticalLayout = false, removeChildren = false, from }: any) => {
	const { selectedDocuments, documentTypes, hiddenDocuments } = useSelector((state: any) => state.documents);
	const dispatch = useDispatch();
	const { selectedDocumentsRef } = useRealtimeContextApi();

	const [query, setQuery] = useState("");
	const [filteredDocuments, setFilteredDocuments] = useState(documentTypes);

	// filter and sort documents
	const filterAndSortDocuments = (query: string, documentTypes: DocumentType[]) => {
		return documentTypes
			.filter((document: DocumentType) => document?.is_active === true)
			.filter((document: DocumentType) =>
				query === "" || document?.document_type_name?.toLowerCase().includes(query.toLowerCase())
			)
			.filter((document: DocumentType) => {
				if (hiddenDocuments) {
					return !hiddenDocuments?.includes(document?.id || "");
				}
				return true;
			})
			.sort((a: DocumentType, b: DocumentType) =>
				(a.document_type_name && b.document_type_name) ? a.document_type_name.localeCompare(b.document_type_name) : 0
			);
	};

	// filter and sort documents
	useEffect(() => {
		setFilteredDocuments(filterAndSortDocuments(query, documentTypes));
	}, [query, documentTypes, selectedDocuments]);

	// check if document is selected
	const isSelected = (document: DocumentType) => {
		return selectedDocuments.some((selectedDoc: DocumentType) => selectedDoc.id === document.id);
	};

	//selectedDocumentRef used to synchronously update the selectedDocuments state for handleUploadCall
	const handleSetSelectedDocuments = (documents: any) => {
		// combobox is totally busted if we load in with a selected doc
		// if you go to unselect that doc, it will not unselect
		// it will come to this function as [selectedDoc, selectedDoc] 
		// so use duplicated documents as a signal to remove that document
		// get an id count and filter out the count != 1.... yikes
		const idCount = documents.reduce((acc: any, doc: any) => {
			acc[doc.id] = (acc[doc.id] || 0) + 1;
			return acc;
		}, {});

		documents = documents.filter((doc: any) => idCount[doc.id] === 1);

		dispatch(documentSelected(documents));
		selectedDocumentsRef.current = documents;
	};


	return (
		<div className={classNames("grid w-full gap-2", verticalLayout ? "grid-cols-1" : "grid-cols-1")}>
			<Combobox
				className=""
				as="div"
				value={selectedDocuments}
				onChange={handleSetSelectedDocuments}
				multiple
				disabled={disabled}
			>
				<div
					id={`select-documents-from-${from}`}
					// click for Sentry
					onClick={() => {
						document.getElementById(`select-documents-from-${from}`)?.click();
					}}
					className="relative"
				>
					<div className="relative">
						<Combobox.Input
							className={classNames(
								disabled ? "text-gray-400 placeholder:text-gray-400" : "text-gray-900",
								"w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10  shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-max-700  text-xs leading-tight sm:text-sm sm:leading-6",
							)}
							onChange={(event) => {
								setQuery(event.target.value);
							}}
							displayValue={(documentTypes) => {
								return query;
							}}
							data-testid="document-select"
							placeholder="Select documents..."
						/>
						<Combobox.Button className="absolute inset-0 z-1" /> {/* Overlays the input so it'll open when clicked */}
					</div>

					<Combobox.Button className="absolute z-10 inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
						<ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
					</Combobox.Button>

					{filteredDocuments.length > 0 && (
						<Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-gray-900 ring-opacity-5 focus:outline-none sm:text-sm">
							{filteredDocuments?.map((document: DocumentType) => (
								<Combobox.Option
									key={document.id}
									value={document}

									className={({ active }) =>
										classNames(
											"relative cursor-default select-none py-2 pl-3 pr-9",
											active ? "bg-max-700  text-white" : "text-gray-900",
										)
									}
								>
									{({ active, selected }) => (
										<>
											<span className={classNames("block truncate", isSelected(document) && "font-semibold")}>
												{document.document_type_name}
											</span>

											{isSelected(document) && (
												<span
													className={classNames(
														"absolute inset-y-0 right-0 flex items-center pr-4",
														active ? "text-white" : "text-max-700 ",
													)}
												>
													<CheckIcon className="h-5 w-5" aria-hidden="true" />
												</span>
											)}
										</>
									)}
								</Combobox.Option>
							))}
						</Combobox.Options>
					)}
				</div>
			</Combobox>

			<div className={classNames(removeChildren ? "hidden" : "")}>
				{selectedDocuments?.length > 0 &&
					selectedDocuments?.map((document: any) => (
						<span
							key={document?.id}
							className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-[10px] sm:text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 mr-0.5 cursor-pointer select-none"
							onClick={() => {
								if (!disabled) {
									// remove document from selectedDocuments
									let removed = selectedDocuments?.filter(
										(selectedDocument: any) => selectedDocument.id !== document.id,
									);
									handleSetSelectedDocuments(removed);
								}
							}}
						>
							{document?.document_type_name}
							<button type="button" className="group relative -mr-1 h-3.5 w-3.5 rounded-sm ">
								<span className="sr-only">Remove</span>
								<XIcon className="h-3.5 w-3.5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
								<span className="absolute -inset-1" />
							</button>
						</span>
					))}
			</div>
		</div>
	);
};

export default DocumentSelect;
