import { useSession } from "@supabase/auth-helpers-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LogoBlue from "../../assets/HD-Horizontal-Blue.png";

const NotFoundPage = () => {
	const session = useSession();
	const navigate = useNavigate();

	useEffect(() => {
		if (!session) {
			navigate("/");
		}
	}, [session, navigate]);

	return (
		<div className="flex items-center justify-center h-screen bg-gray-100">
			<div className="text-center">
				<img className="mx-auto h-24 w-auto" src={LogoBlue} alt="HappyDoc" />
				<h1 className="text-6xl mt-10">404</h1>
				<p className="text-2xl text-gray-600">Page not found</p>
				<p className="mt-4 text-gray-500">The page you're looking for doesn't seem to exist.</p>
				<a
					href="/"
					className="mt-6 inline-block px-6 py-2 text-sm font-medium leading-6 text-center text-white uppercase transition bg-max-700 rounded shadow ripple hover:shadow-lg hover:bg-max-800 focus:outline-none"
				>
					Go back home
				</a>
			</div>
		</div>
	);
};

export default NotFoundPage;
